import React from 'react';
import ReactGA from 'react-ga4';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

/* import Container from 'react-bootstrap/Container'; */

/* import Breadcrumbs from '../../Components/Breadcrumb'; */


import * as ContentfulMarkdown from 'react-markdown';
/* import Carousel from 'react-bootstrap/Carousel'; */
import 'bootstrap/dist/css/bootstrap.min.css';
import { TopNavBar } from '../../Components/TopNavBar';
import { BottomNavBar } from '../../Components/BottomNavBar';
import { LargeButton } from '../../Components/LargeButton';
import { TestimonialsSlide1 } from './TestimonialsSlide1';
import { TestimonialsSlide2 } from './TestimonialsSlide2';
import { TestimonialsSlide3 } from './TestimonialsSlide3';
import { TestimonialsSlide4 } from './TestimonialsSlide4';
import { TestimonialsSlide5 } from './TestimonialsSlide5';
import { TestimonialsSlide6 } from './TestimonialsSlide6';
import { TestimonialsSlide7 } from './TestimonialsSlide7';
import { TestimonialsSlide8 } from './TestimonialsSlide8';
import { TestimonialsSlide9 } from './TestimonialsSlide9';

/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */

/* import { WHOLink } from '../../Components/WHOLink'; */


export class Testimonials extends React.Component {
    constructor(props) {
      super(props)
  
      this.state = {
        page: 1,
        carouselIndex: 0,
        title1: '',
        feedbackHowTo: '',
        contactButtonText: ''
      }
    }
  
  
    componentDidMount() {
      window.scrollTo(0, 0)
      ReactGA.send("/testimonials")
  
      document.title = "Testimonials"

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */


      const getEntries = localStorage.getItem('ayaAppEntries')
      const entries = JSON.parse(getEntries)
      // Content type ID
      const getTestimonialsEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'testimonials');
      // Then Entry ID instead
      const getpageEntries = getTestimonialsEntries.filter(obj => obj.sys.id === '6jjrpV50PHuMdbe0n4mmpj');
      
      var pageEntries = []
      var feedbackHowTo = ''
      var title1 = ''
      var contactButtonText = ''
  
      if (getpageEntries && getpageEntries.length > 0) {
        pageEntries.push(getpageEntries[0].fields)

        if (pageEntries.length > 0) {

          if (pageEntries[0].title1) {
            title1 = pageEntries[0].title1[this.props.appLanguage]
        }
  
        if (pageEntries[0].contactButtonText) {
          contactButtonText = pageEntries[0].contactButtonText[this.props.appLanguage]
        }

        if (pageEntries[0].feedbackHowTo) {
          feedbackHowTo = pageEntries[0].feedbackHowTo[this.props.appLanguage]
      }

    

      this.setState({
        title1: title1,
        feedbackHowTo: feedbackHowTo,
        contactButtonText: contactButtonText
      })
    }
    }  
  }
  
    nextSlide = (selectedIndex, e) => {
      window.scrollTo(0, 0)
      e.preventDefault()
  
      this.setState({
        carouselIndex: selectedIndex,
      })

    }; //this moves the carousel to the next slide
   
    goBack = () => {
      this.props.history.goBack()
    }

    
    
    render() {
  
/*       var bellColor = ''
      var menuIconColor = '' */
      if (this.state.page === 1) {
/*         bellColor = 'white'
        menuIconColor = 'white' */
      } else {
/*         bellColor = 'white'
        menuIconColor = 'white' */
      }
  
      return (
        <div className='minPageHeight100vh flexColumn flexGrow1 appBackgroundColor pageWithBottomMainNav'>
        
        
        <TopNavBar 
        bgColor="#fff" 
        showHamburgerMenu={true} 
        menuIconColor={'blue'} 
        logoColor={'peach'} 
        bellColor={'blue'} 
        newNotificationColor={'peach'} 
        {...this.props} 
        appLanguage={this.props.appLanguage} />
        <div className="minPageHeight100vh selfCarePage ">
          { this.renderChildren() } {/* Down below */}
        </div>
        </div>
      )
    }
  
    renderChildren() {
      const { page,  title1, /* contactButtonText, feedbackHowTo  */} = this.state
  
      if (page === 1 || page === 2) {
        return (
        

          <div className='pageWithBottomMainNav  backgroundColorBlue flexColumn flexGrow1 textAlignCenter paddingTop125 paddingLeftRight5 backgroundColorBlue'>
            
            <div className='backgroundColorBlue'>

            <ContentfulMarkdown children={title1} className='boldFont colorPeach6 fontSize28 lineHeight20 marginBottom40 marginLeftRightAuto psMargin0 contentfulMarkdown' allowDangerousHtml={true} />
            {/* <div className='boldFont largeButton boldFont fontSize17 lineHeight20 colorPeach4 marginBottom16 marginLeftRightAuto psMargin0 contentfulMarkdown'>
              {/* <h1 className='colorPeach6'>Testimonios</h1> */}
            {/* </div> */}
           {/* <div className='boldFont largeButton boldFont fontSize17 lineHeight20 colorPeach4 marginBottom16 marginTop12 marginLeftRightAuto psMargin0 contentfulMarkdown'>
              <h1 className='colorPeach6'>Testimonios</h1>
            </div> */}

           
            
             <OwlCarousel
                className="owl-theme positionRelative"
                stagePadding={50}
                //loop
                margin={10}
                nav= {false}
                items={1}
                dots={false}
                touchDrag={true}
             
              >
              {/* <div className="marginBottom50 paddingBottom18 paddingLeftRight5">
  
                <img alt="testimonials coral speech bubble" src={TestimonialsImageSlide1}/>
              </div> */}
              
              <div className="noBackgroundColor marginBottom20 paddingLeft5">
              <TestimonialsSlide1 appLanguage={this.props.appLanguage} />
              </div>
              <div className="marginBottom20 paddingLeft5">
              <TestimonialsSlide2 appLanguage={this.props.appLanguage} />
              </div>
              <div className="marginBottom20 paddingLeft5">
              <TestimonialsSlide3 appLanguage={this.props.appLanguage} />
              </div>
              <div className="marginBottom20 paddingLeft5">
              <TestimonialsSlide4 appLanguage={this.props.appLanguage} />
              </div>
              <div className="marginBottom20 paddingLeft5">
              <TestimonialsSlide5 appLanguage={this.props.appLanguage} />
              </div>
              <div className="marginBottom20 paddingLeft5">
              <TestimonialsSlide6 appLanguage={this.props.appLanguage} />
              </div>
              <div className="marginBottom20 paddingLeft5">
              <TestimonialsSlide7 appLanguage={this.props.appLanguage} />
              </div>
              <div className="marginBottom20 paddingLeft5">
              <TestimonialsSlide8 appLanguage={this.props.appLanguage} />
              </div>
              <div className="marginBottom20 paddingLeft5">
              <TestimonialsSlide9 appLanguage={this.props.appLanguage} />
              </div>
            </OwlCarousel>
            
            {/* coral quotation marks that are meant to stick on top of the speech bubbles, implementing responsivesness
            is the challenge here */}
            {/* <div>
             <img src={TestimonialsCoralQuotes} className="coral-quotes positionAbsolute" />
             </div> */}

            </div>
            <div className='flexGrow1 backgroundColorPeach2 topRoundDivlonger'>
              <div className='paddingTop60 topRoundDivInnerDiv'>

                  <ContentfulMarkdown children={this.state.feedbackHowTo} className='boldFont fontSize20 lineHeight22 colorWhite hasColorPeach4Span marginBottom30 contentfulMarkdown' allowDangerousHtml={true} />
                 
{/*                   <WHOLink 
                        fontColor="#fff"
                        appLanguage={this.props.appLanguage}
                        {...this.props} /> {/* This is the WHO link that is necessary for iOS app store */}
                
                  <LargeButton
                    largeButtonFunc={() => this.props.history.push('/resources-emotional-support')}
                    text={<ContentfulMarkdown children={this.state.contactButtonText} className='fontSize18 marginBottom0 marginTop10' allowDangerousHtml={true} />}
                    /* text={<p className='alignCenter marginBottom0'>Contáctanos</p>} */
                    textColor={"colorWhite"}
                    backgroundColor={"backgroundColorBlue8"}
                  />
                
              </div>
            </div>
            <div className='paddingLeft15'>
          
              
            </div>
              <div className='marginBottom8'></div>
  
            <BottomNavBar appLanguage={this.props.appLanguage} />
          </div>
        
        
        )
      } 
    }
}
