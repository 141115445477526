import './ImmiSupport.modules.css'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';
import BasicCard from '../../Components/BasicCard'
import { useState, useEffect } from 'react'
import  { ResourcesTopNav }  from '../../Components/ResourcesTopNav';
import { BottomNavBar } from '../../Components/BottomNavBar'
import ReactGA from 'react-ga4';

function ImmiSupport(props) {
	const [pageTitleOne, setPageTitleOne] = useState('')
	const [pageImageOne, setPageImageOne] = useState('')
	const [pageDescriptionOne, setPageDescriptionOne] = useState('')
	const [cardOneLogo, setCardOneLogo] = useState('')
	const [cardTwoLogo, setCardTwoLogo] = useState('')
	const [cardThreeLogo, setCardThreeLogo] = useState('')
	const [cardOneTitle, setCardOneTitle] = useState('')
	const [cardTwoTitle, setCardTwoTitle] = useState('')
	const [cardThreeTitle, setCardThreeTitle] = useState('')
	const [cardOneDescription, setCardOneDescription] = useState('')
	const [cardTwoDescription, setCardTwoDescription] = useState('')
	const [cardThreeDescription, setCardThreeDescription] = useState('')
	const [helpLineButton, setHelpLineButton] = useState('')
	const [phoneIcon, setPhoneIcon] = useState('');
	const [arrowIconTwo, setArrowIconTwo] = useState('');
	const [lastButton, setLastButton] = useState('')
	const [lastButtonArrow, setLastButtonArrow] = useState('');
	const [selectedItem, setSelectedItem] = useState(null);
	const [navButtonOne, setNavButtonOne] = useState('')
	const [navButtonTwo, setNavButtonTwo] = useState('')
	const [navButtonThree, setNavButtonThree] = useState('')
	const [navButtonFour, setNavButtonFour] = useState('')
	const [navButtonFive, setNavButtonFive] = useState('')
	const [navButtonSix, setNavButtonSix] = useState('')

	const location = useLocation();
  const currentPath = location.pathname;

	const items = [
		{ name: navButtonSix, path: "/resources-support"},
		{ name: navButtonOne, path: "/resources-legal-assistance", backgroundColor: "#E5F1FA" },
		{ name: navButtonTwo, path: "/resources-pills-and-clinics", backgroundColor: "#FAE7E7" },
		{ name: navButtonThree, path: "/resources-medical-advice", backgroundColor: "#FFEBFF" },
		{ name: navButtonFour, path: "/resources-financial-help", backgroundColor: "#FFF5E2" },
		{ name: navButtonFive, path: "/resources-emotional-support", backgroundColor: "#F6EFFF" },
	  ];

	  const settings = {
		dots: false,
		infinite: true,
		speed: 200,
		arrows: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: true,
  		centerPadding: '5px', 
		variableWidth: true,
		beforeChange: (current, next) => setSelectedItem(items[next].name),
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				centerPadding: '10px',
			  },
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				initialSlide: 2
				/* centerPadding: '5px', */
			  },
			},
		  ]
	};


	useEffect(() => {
	// GA4 code
	ReactGA.send("/resources-support");
	//this makes sure the page always scrolls to the top of the page when rendered
	window.scrollTo(0, 0); 

	document.title = "Resources Support";
	
        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:show"]) */

		// GET ALL ENTRIES
		const getEntries = localStorage.getItem('ayaAppEntries');
    const entries = JSON.parse(getEntries);


		// GET MODEL
		const getModel = entries.filter(
      (obj) => obj.sys.contentType.sys.id === "resourcesCategory"
    );
		
		// GET CONTENT
		const getContent = getModel.filter(
		(obj) => obj.sys.id === "3so8WlOy0mczkcgtq8kyaZ"
		)

		

		// GET NAVITEM ONE
		let navItem1 = ''
			if (getContent?.length > 0) {
				const navItemOneData = getContent[0].fields;
				navItem1 = navItemOneData.topNavButton1? navItemOneData.topNavButton1[props.appLanguage] : '';
      }
			setNavButtonOne(navItem1);


			
		// GET NAVITEM TWO
		let navItem2 = ''
		if (getContent?.length > 0) {
			const navItemTwoData = getContent[0].fields;
			navItem2 = navItemTwoData.topNavButton2? navItemTwoData.topNavButton2[props.appLanguage] : '';
		}
		setNavButtonTwo(navItem2);



		// GET NAVITEM THREE
		let navItem3 = ''
		if (getContent?.length > 0) {
			const navItemThreeData = getContent[0].fields;
			navItem3 = navItemThreeData.topNavButton3? navItemThreeData.topNavButton3[props.appLanguage] : '';
		}
		setNavButtonThree(navItem3);



		// GET NAVITEM FOUR
		let navItem4 = ''
		if (getContent?.length > 0) {
			const navItemFourData = getContent[0].fields;
			navItem4 = navItemFourData.topNavButton4? navItemFourData.topNavButton4[props.appLanguage] : '';
		}
		setNavButtonFour(navItem4);



		// GET NAVITEM FIVE 
		let navItem5 = ''
		if (getContent?.length > 0) {
			const navItemFiveData = getContent[0].fields;
			navItem5 = navItemFiveData.topNavButton5? navItemFiveData.topNavButton5[props.appLanguage] : '';
		}
		setNavButtonFive(navItem5);



		// GET NAVITEM SIX
		let navItem6 = ''
		if (getContent?.length > 0) {
			const navItemSixData = getContent[0].fields;
			navItem6 = navItemSixData.topNavButton6? navItemSixData.topNavButton6[props.appLanguage] : '';
		}
		setNavButtonSix(navItem6);


		
		// GET PAGE title
			let pageTitle = ''
				if (getContent?.length > 0) {
					const pageTitleData = getContent[0].fields;
					pageTitle = pageTitleData.title? pageTitleData.title[props.appLanguage] : '';
				}
				setPageTitleOne(pageTitle);



		// GET PAGE description
			let pageDescription = ''
			if (getContent?.length > 0) {
				const pageDescriptionData = getContent[0].fields;
				pageDescription = pageDescriptionData.description? pageDescriptionData.description[props.appLanguage] : '';
			}
			setPageDescriptionOne(pageDescription);



		// GET HELPLINE ACCESS BUTTON text
		let helplineAccessButton = ''
			if (getContent?.length > 0) {
				const helpLineAccessButtonData = getContent[0].fields;
				helplineAccessButton = helpLineAccessButtonData.helplineAccessButton? helpLineAccessButtonData.helplineAccessButton[props.appLanguage] : '';
			}
			setHelpLineButton(helplineAccessButton);



		// GET CARD ONE titlE
			let cardOneTitleText = ''
				if (getContent?.length > 0) {
					const cardOneTitleData = getContent[0].fields;
					cardOneTitleText = cardOneTitleData.card1Title? cardOneTitleData.card1Title[props.appLanguage] : '';
				}
				setCardOneTitle(cardOneTitleText);



		// GET CARD ONE description
			let cardOneDescriptionText = ''
			if (getContent?.length > 0) {
				const cardOneDescriptionData = getContent[0].fields;
				cardOneDescriptionText = cardOneDescriptionData.card1DescriptionLong? cardOneDescriptionData.card1DescriptionLong[props.appLanguage] : '';
			}
			setCardOneDescription(cardOneDescriptionText);



		// GET CARD TWO title
			let cardTwoTitleText = ''
				if (getContent?.length > 0) {
					const cardTwoTitleData = getContent[0].fields;
					cardTwoTitleText = cardTwoTitleData.card2Title? cardTwoTitleData.card2Title[props.appLanguage] : '';
				}
				setCardTwoTitle(cardTwoTitleText);



		// GET CARD TWO description
			let cardTwoDescriptionText = ''
			if (getContent?.length > 0) {
				const cardTwoDescriptionData = getContent[0].fields;
				cardTwoDescriptionText = cardTwoDescriptionData.card2Description? cardTwoDescriptionData.card2Description[props.appLanguage] : '';
			}
			setCardTwoDescription(cardTwoDescriptionText);



		// GET CARD THREE title
			let cardThreeTitleText = ''
				if (getContent?.length > 0) {
					const cardThreeTitleData = getContent[0].fields;
					cardThreeTitleText = cardThreeTitleData.card3Title? cardThreeTitleData.card3Title[props.appLanguage] : '';
				}
				setCardThreeTitle(cardThreeTitleText);



		// GET CARD THREE description
		let cardThreeDescriptionText = ''
			if (getContent?.length > 0) {
				const cardThreeDescriptionData = getContent[0].fields;
				cardThreeDescriptionText = cardThreeDescriptionData.card3Description? cardThreeDescriptionData.card3Description[props.appLanguage] : '';
			}
			setCardThreeDescription(cardThreeDescriptionText);



		// GET LAST button TEXT
		let lastButton1 = ''

		if (getContent?.length > 0) {
			const lastButtonData = getContent[0].fields;
			lastButton1 = lastButtonData.lastButton  ? lastButtonData.lastButton[props.appLanguage] : '';
		}
		setLastButton(lastButton1)


		// GET ASSETS
		const getAssets = localStorage.getItem('ayaAppAssets');
    const assets = JSON.parse(getAssets);


		// GET MAIN PAGE IMAGE ONE
		const getImages = assets.filter(
      obj => obj.sys.id === '4DU2tzCRbFElZcH4UwCe0i'
    );

    let imageOne = '';
    if (getImages?.length > 0 && getImages[0]?.fields?.file?.[props.appLanguage]?.url) {
			imageOne = 'https:' + getImages[0].fields.file[props.appLanguage].url;
		}
    setPageImageOne(imageOne)



		// GET CARD ONE logo
		const getCardOneLogo = assets.filter(
      obj => obj.sys.id === '7HH7BjXQn6UtoHNzTwUJ7s'
    );

    let logoOne = '';
    if (getCardOneLogo?.length > 0 && getCardOneLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
			logoOne = 'https:' + getCardOneLogo[0].fields.file[props.appLanguage].url;
		}
    setCardOneLogo(logoOne)



		// GET CARD TWO logo
		const getCardTwoLogo = assets.filter(
      obj => obj.sys.id === '1aTclhAUhB2jW2h1l5fpOs'
    );

    let logoTwo = '';
    if (getCardTwoLogo?.length > 0 && getCardTwoLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
			logoTwo = 'https:' + getCardTwoLogo[0].fields.file[props.appLanguage].url;
		}
    setCardTwoLogo(logoTwo)



		// GET CARD THREE logo
		const getCardThreeLogo = assets.filter(
			obj => obj.sys.id === '6Qcf4BXau7YJXQml74vZgA'
		);
	
		let logoThree = '';
		if (getCardThreeLogo?.length > 0 && getCardThreeLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
			logoThree = 'https:' + getCardThreeLogo[0].fields.file[props.appLanguage].url;
		}
		setCardThreeLogo(logoThree)



		// GET PHONE ICON
		const getPhoneIcon = assets.filter(
      obj => obj.sys.id === '2ROiC1rzhVlik9fUki9smc'
    );

    let phoneIconOne = '';
    if (getPhoneIcon?.length > 0 && getPhoneIcon[0]?.fields?.file?.[props.appLanguage]?.url) {
			phoneIconOne = 'https:' + getPhoneIcon[0].fields.file[props.appLanguage].url;
		}
    setPhoneIcon(phoneIconOne);



		// GET ARROW ICON TWO 
		const getArrowIconTwo = assets.filter(
      obj => obj.sys.id === '3Xegltxk8ROHntvPdStc3A'
    );

    let arrowIconTwo = '';
    if (getArrowIconTwo?.length > 0 && getArrowIconTwo[0]?.fields?.file?.[props.appLanguage]?.url) {
			arrowIconTwo = 'https:' + getArrowIconTwo[0].fields.file[props.appLanguage].url;
		}
    setArrowIconTwo(arrowIconTwo);



		// GET WHITE ARROW ICON (last Button arrow)
		const getWhiteArrowIcon = assets.filter(
      obj => obj.sys.id === '1H7uB5gW6dKQU9jin0AmK6'
    );

    let whiteArrowIcon = '';
    if (getWhiteArrowIcon?.length > 0 && getWhiteArrowIcon[0]?.fields?.file?.[props.appLanguage]?.url) {
			whiteArrowIcon = 'https:' + getWhiteArrowIcon[0].fields.file[props.appLanguage].url;
		}
    setLastButtonArrow(whiteArrowIcon);
	


	}, [props.appLanguage])



	return(
		<>
			<ResourcesTopNav bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...props} appLanguage={props.appLanguage} />

			<div className="carousel-container">
            <div className="dots-container">
            </div>
						<div className={`custom-dot-container`}>
                <Slider {...settings}>
                    {items.map((item, index) => (
                    <div key={index} className="slide-item">
                    <Link
                    to={item.path}
                    className={`link-button custom-dot ${item.path === currentPath ? "active" : ""}`}
                    style={{ backgroundColor: item.backgroundColor }} // Apply the background color of the buttons here
                    >
                    {item.name}
                    </Link>
                    </div>
                    ))}
                </Slider>
							</div>
            </div>


		<div className='immiSupportContainer' style={{ backgroundColor: 'white' }}>
			
		<div className='support-title'>{pageTitleOne}</div>
		<img className='mainImage'src={pageImageOne} alt='support' />

		<div style={{maxWidth: '340px'}}>
		<div className='support-page-description'>{pageDescriptionOne}</div>
			<div className='help-line-container'>
								<a
									href='tel:8448682812'
									style={{
										textDecoration: 'none', 
										color: 'inherit', 
										display: 'flex', 
										alignItems: 'center', 
									}}
								>
									{helpLineButton}
									<div className='phone-icon-container'>
										<img className='phone-icon' src={phoneIcon} alt='phone icon' />
									</div>
								</a>
			</div>
		</div>


		<div className='cardsContainer'>
    	<a href='https://www.nilc.org/' target='_blank' rel='noopener noreferrer'>
        <BasicCard
            image={cardOneLogo}
            title={
							<div style={{ paddingTop: '10px' }}>
								{cardOneTitle}
							</div>
						}
            content={cardOneDescription}
            arrow={arrowIconTwo}
        />
   		 </a>
  	  <a href='http://mariposafund.org/' target='_blank' rel='noopener noreferrer'>
        <BasicCard
            image={cardTwoLogo}
            title={cardTwoTitle}
            content={cardTwoDescription}
            arrow={arrowIconTwo}
        />
   		 </a>
   		 <a href='https://fronterafundrgv-org.translate.goog/?_x_tr_sl=en&_x_tr_tl=es&_x_tr_hl=en&_x_tr_pto=wapp' target='_blank' rel='noopener noreferrer'>
        <BasicCard
            image={cardThreeLogo}
            title={cardThreeTitle}
            content={cardThreeDescription}
            arrow={arrowIconTwo}
        />
  		  </a>
		</div>

		<div style={{maxWidth: '340px'}}>
		<div className='last-button'>
  				<a href='/resources-more-info'>
   					 {lastButton}
   				 <img src={lastButtonArrow} alt='Arrow' className='arrow-image' />
  				</a>
				</div>
		</div>

			<div className='end-of-page'></div>
			<BottomNavBar appLanguage={props.appLanguage} />
		</div>
		</>
	)
}

export default ImmiSupport



