import React from 'react'
import ReactGA from 'react-ga4'
import { SingleContraceptionOptionShell } from './SingleContraceptionOptionShell'



export class ContraceptionCondoms extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            content: {}
        }
    }


    componentDidMount() {
        ReactGA.send("/contraception-condoms")
        window.scrollTo(0, 0)

        document.title = "Contraception Condoms"

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)

        const getContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'contraceptionOption');

        const getPageEntries = getContentEntries.filter(obj => obj.sys.id === '7gXjtquJYnodgftVCIln8J');

        var pageEntries = []
        var contentObj = {}

        if (getPageEntries && getPageEntries.length > 0) {
            pageEntries.push(getPageEntries[0].fields)

            if (pageEntries.length > 0) {
                if (pageEntries[0].title) {
                    contentObj.title = pageEntries[0].title[this.props.appLanguage]
                }
                if (pageEntries[0].optionsAndTime) {
                    contentObj.optionsAndTime1 = pageEntries[0].optionsAndTime[this.props.appLanguage]
                }
                if (pageEntries[0].optionsAndTime2) {
                    contentObj.optionsAndTime2 = pageEntries[0].optionsAndTime2[this.props.appLanguage]
                }
                if (pageEntries[0].paragraph) {
                    contentObj.paragraph = pageEntries[0].paragraph[this.props.appLanguage]
                }
                if (pageEntries[0].ctaCopy1) {
                    contentObj.btn1Text = pageEntries[0].ctaCopy1[this.props.appLanguage]
                }
                if (pageEntries[0].ctaCopy2) {
                    contentObj.btn2Text = pageEntries[0].ctaCopy2[this.props.appLanguage]
                }
                if (pageEntries[0].ctaCopy3) {
                    contentObj.btn3Text = pageEntries[0].ctaCopy3[this.props.appLanguage]
                }
            }
        }



        // Get page Assets
        const getAssets = localStorage.getItem('ayaAppAssets')
        const assets = JSON.parse(getAssets)

        const getImg1 = assets.filter(obj => obj.sys.id === '5yFr4LjHnkBNmTaCjMR2pt');
        const getImg2 = assets.filter(obj => obj.sys.id === '1SsIR33crDG9CKYhnted3O');
        const getImg3 = assets.filter(obj => obj.sys.id === '14vzclWZtWAOtTkiYMr6lG');

        if (getImg1 && getImg1.length > 0 && getImg1[0].fields.file[this.props.appLanguage].url) {
            contentObj.image1 = 'https:' + getImg1[0].fields.file[this.props.appLanguage].url
            contentObj.image1Alt = getImg1[0].fields.description[this.props.appLanguage]
        }
        // padding makes div 320px
        contentObj.image1Width = 'width64Percent'

        if (getImg2 && getImg2.length > 0 && getImg2[0].fields.file[this.props.appLanguage].url) {
            contentObj.image2 = 'https:' + getImg2[0].fields.file[this.props.appLanguage].url
            contentObj.image2Alt = getImg2[0].fields.description[this.props.appLanguage]
        }
        // padding makes div 288px. Keep same?
        contentObj.image2Width = 'width76Percent'

        if (getImg3 && getImg3.length > 0 && getImg3[0].fields.file[this.props.appLanguage].url) {
            contentObj.image3 = 'https:' + getImg3[0].fields.file[this.props.appLanguage].url
            contentObj.image3Alt = getImg3[0].fields.description[this.props.appLanguage]
        }
        // padding makes div 288px
        contentObj.image3Width = 'width69Percent'



        this.setState({
            content: contentObj,
        })
    }

    render() {

        const { content } = this.state

        return (
            <div className='flexColumn flexGrow1'>
                <SingleContraceptionOptionShell
                    appLanguage={this.props.appLanguage}
                    history={this.props.history}
                    content={content}
                />
            </div>
        )
    }
}
