import React from 'react'
import PropTypes from 'prop-types'

import prevBtnImgWhite from '../Images/prevBtnImage-whiteAndBlue.svg'
import prevBtnImgBlue from '../Images/prevBtnImage-blueAndWhite.svg'
import nextBtnImg from '../Images/nextBtnImage-whiteAndBlue.svg'

/* text, colors, size change. props. auto or fuller width */
export class PrevAndNextBottomNav extends React.Component {
  // constructor(props) {
  //   super(props)
  // }

  prevBtnFunc = (e) => {
    this.props.prevBtnFunc(e)
  }

  nextBtnFunc = (e) => {
    this.props.nextBtnFunc(e)
  }

  render() {

    const { showPrevButton, showNextBtn } = this.props;
    // const text = this.props.text
    // const textColor = this.props.textColor
    // const backgroundColor = this.props.backgroundColor
    // const linkUrl = this.props.linkUrl

    // const className = '' + textColor + backgroundColor + '';

    return (
      <div className="flexRow justifyBetween absoluteTopLeft0 alignCenter paddingLeftRight16 prevAndNextBottomNav">
        <div>
          {showPrevButton ?
            <button className='navBtn prevBtn' onClick={(e) => this.prevBtnFunc(e)}>
              <img src={this.props.blue ? prevBtnImgBlue : prevBtnImgWhite} className='image' alt='back' />
            </button>
            : null}
        </div>
        <div>
          {showNextBtn ?
            <button className='navBtn nextBtn' onClick={(e) => this.nextBtnFunc(e)}>
              <img src={nextBtnImg} className='image' alt='next' />
            </button>
            : null}
        </div>
      </div>
    )
  }
}

PrevAndNextBottomNav.propTypes = {
  // info: PropTypes.object
  showPrevButton: PropTypes.bool,
  showNextButton: PropTypes.bool,
  prevBtnFunc: PropTypes.func,
  nextBtnFunc: PropTypes.func,
};