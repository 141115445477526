import styles from './MoreInfo.modules.css'
import BasicCard from '../../Components/BasicCard'
import { useState, useEffect } from 'react'
import  { TopNavBar }  from '../../Components/TopNavBar';
import { BottomNavBar } from '../../Components/BottomNavBar'
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import React from 'react';
import ReactMarkdown from 'react-markdown';




function MoreInfo (props) {
    const [pageTitleOne, setPageTitleOne] = useState('')
    const [cardOneLogo, setCardOneLogo] = useState('')
    /* const [cardTwoLogo, setCardTwoLogo] = useState('') */
    const [cardThreeLogo, setCardThreeLogo] = useState('')
    const [cardOneTitle, setCardOneTitle] = useState('')
    /* const [cardTwoTitle, setCardTwoTitle] = useState('') */
    const [cardThreeTitle, setCardThreeTitle] = useState('')
    const [cardOneDescription, setCardOneDescription] = useState('')
    /* const [cardOneDescriptionLong, setCardOneDescriptionLong] = useState('') */
    /* const [cardTwoDescription, setCardTwoDescription] = useState('') */
    const [cardThreeDescription, setCardThreeDescription] = useState('')
    const [arrowIconOne, setArrowIconOne] = useState('')
  /*   const [arrowIconTwo, setArrowIconTwo] = useState('') */
    const [arrowIconThree, setArrowIconThree] = useState('')
    const [phoneIcon, setPhoneIcon] = useState('')
    const [buttonOne, setButtonOne] = useState('')
    const [buttonTwo, setButtonTwo] = useState('')
    
     // URLs for English and Spanish versions of the site
     const lasLibresUrlEn = "https://www.plancpills.org/las-libres"; // Placeholder URL for English
     const lasLibresUrlEs = "https://es.plancpills.org/las-libres"; // Placeholder URL for Spanish


    useEffect(() => {
    // GA4 code
    ReactGA.send("/resources-more-info");
    //this makes sure the page always scrolls to the top of the page when rendered
    window.scrollTo(0, 0); 

    document.title = "Resources More Info"; 
    
        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:show"]) */

        // GET ALL ENTRIES
        const getEntries = localStorage.getItem('ayaAppEntries');
        const entries = JSON.parse(getEntries);


        // GET MODEL
        const getModel = entries.filter(
      (obj) => obj.sys.contentType.sys.id === "resourcesCategory"
    );
        
        // GET CONTENT
        const getContent = getModel.filter(
        (obj) => obj.sys.id === "1ml9rNlRzHF0VhtNFH6mPd"
        )


        // GET MORE INFO PAGE TITLE
        let pageTitle = ''
            if (getContent?.length > 0) {
                const pageTitleData = getContent[0].fields;
                pageTitle = pageTitleData.title? pageTitleData.title[props.appLanguage] : '';
            }
        setPageTitleOne(pageTitle);

                

        // GET CARD ONE title
        let cardOneTitleText = ''
            if (getContent?.length > 0) {
                const cardOneTitleData = getContent[0].fields;
                cardOneTitleText = cardOneTitleData.card1Title? cardOneTitleData.card1Title[props.appLanguage] : '';
            }
        setCardOneTitle(cardOneTitleText);
    
        // GET CARD ONE description
        let cardOneDescriptionText = ''
        if (getContent?.length > 0) {
            const cardOneDescriptionData = getContent[0].fields;
            cardOneDescriptionText = cardOneDescriptionData.card1DescriptionLong? cardOneDescriptionData.card1DescriptionLong[props.appLanguage] : '';
            }
        setCardOneDescription(cardOneDescriptionText);


        // GET CARD ONE description Long
/*         let cardOneDescriptionLongText = '';
        const cardOneDescriptionLongData = getContent[0].fields.card1DescriptionLong; // Adjust according to your data structure
        if(cardOneDescriptionLongData) {
            cardOneDescriptionLongText = cardOneDescriptionLongData[props.appLanguage] ? cardOneDescriptionLongData[props.appLanguage] : '';
        }
        setCardOneDescriptionLong(cardOneDescriptionLongText); */





        // GET CARD ONE button one
        let button1 = ''
        if (getContent?.length > 0) {
            const buttonOneData = getContent[0].fields;
            button1 = buttonOneData.card1Button1? buttonOneData.card1Button1[props.appLanguage] : '';
        }
        setButtonOne(button1);


        // GET CARD ONE button two
        let button2 = ''
        if (getContent?.length > 0) {
            const buttonTwoData = getContent[0].fields;
            button2 = buttonTwoData.card1Button2? buttonTwoData.card1Button2[props.appLanguage] : '';
        }
        setButtonTwo(button2);



        // GET CARD TWO title
/*         let cardTwoTitleText = ''
        if (getContent?.length > 0) {
            const cardTwoTitleData = getContent[0].fields;
            cardTwoTitleText = cardTwoTitleData.card2Title? cardTwoTitleData.card2Title[props.appLanguage] : '';
        }
        setCardTwoTitle(cardTwoTitleText); */



        // GET CARD TWO description
/*         let cardTwoDescriptionText = ''
        if (getContent?.length > 0) {
            const cardTwoDescriptionData = getContent[0].fields;
            cardTwoDescriptionText = cardTwoDescriptionData.card2Description? cardTwoDescriptionData.card2Description[props.appLanguage] : '';
        }
        setCardTwoDescription(cardTwoDescriptionText); */

        // GET CARD THREE title
        let cardThreeTitleText = ''
        if (getContent?.length > 0) {
            const cardThreeTitleData = getContent[0].fields;
            cardThreeTitleText = cardThreeTitleData.card3Title? cardThreeTitleData.card3Title[props.appLanguage] : '';
        }
        setCardThreeTitle(cardThreeTitleText);

        // GET CARS THREE description
        let cardThreeDescriptionText = ''
        if (getContent?.length > 0) {
            const cardThreeDescriptionData = getContent[0].fields;
            cardThreeDescriptionText = cardThreeDescriptionData.card3Description? cardThreeDescriptionData.card3Description[props.appLanguage] : '';
        }
        setCardThreeDescription(cardThreeDescriptionText);



        // GET ASSETS
        const getAssets = localStorage.getItem('ayaAppAssets');
        const assets = JSON.parse(getAssets);


        // GET CARD logo ONE
        const getCardOneLogo = assets.filter(
      obj => obj.sys.id === '4cCyc30wngyGHAVBIiglua'
    );

    let logoOne = '';
    if (getCardOneLogo?.length > 0 && getCardOneLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
            logoOne = 'https:' + getCardOneLogo[0].fields.file[props.appLanguage].url;
        }
    setCardOneLogo(logoOne)



        // GET CARD logo TWO 
/*         const getCardTwoLogo = assets.filter(
      obj => obj.sys.id === '5hQqbGLKpOsWc6IpAtlx0Y'
    );

    let logoTwo = '';
    if (getCardTwoLogo?.length > 0 && getCardTwoLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
            logoTwo = 'https:' + getCardTwoLogo[0].fields.file[props.appLanguage].url;
        }
    setCardTwoLogo(logoTwo) */

            // GET CARD logo THREE 
            const getCardThreeLogo = assets.filter(
                obj => obj.sys.id === 'u8Qy8UQ3sJabbVhnXWHvz'
              );
          
              let logoThree = '';
              if (getCardThreeLogo?.length > 0 && getCardThreeLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
                      logoThree = 'https:' + getCardThreeLogo[0].fields.file[props.appLanguage].url;
                  }
        setCardThreeLogo(logoThree)





        // GET ARROW ICON
        const getArrowIcon = assets.filter(
      obj => obj.sys.id === '3Xegltxk8ROHntvPdStc3A'
    );

    let arrowIcon = '';
    if (getArrowIcon?.length > 0 && getArrowIcon[0]?.fields?.file?.[props.appLanguage]?.url) {
            arrowIcon = 'https:' + getArrowIcon[0].fields.file[props.appLanguage].url;
        }
    setArrowIconOne(arrowIcon);



        // GET PHONE ICON
        const getPhoneIcon = assets.filter(
      obj => obj.sys.id === '2ROiC1rzhVlik9fUki9smc'
    );

    let phoneIconOne = '';
    if (getPhoneIcon?.length > 0 && getPhoneIcon[0]?.fields?.file?.[props.appLanguage]?.url) {
            phoneIconOne = 'https:' + getPhoneIcon[0].fields.file[props.appLanguage].url;
        }
    setPhoneIcon(phoneIconOne);


        // GET ARROW ICON TWO 
/*         const getArrowIconTwo = assets.filter(
      obj => obj.sys.id === '3Xegltxk8ROHntvPdStc3A'
    );

    let arrowIconTwo = '';
    if (getArrowIconTwo?.length > 0 && getArrowIconTwo[0]?.fields?.file?.[props.appLanguage]?.url) {
            arrowIconTwo = 'https:' + getArrowIconTwo[0].fields.file[props.appLanguage].url;
        }
    setArrowIconTwo(arrowIconTwo); */

        // GET ARROW ICON THREE 
        const getArrowIconThree = assets.filter(
            obj => obj.sys.id === '3Xegltxk8ROHntvPdStc3A'
            );
          
            let arrowIconThree = '';
            if (getArrowIconThree?.length > 0 && getArrowIconThree[0]?.fields?.file?.[props.appLanguage]?.url) {
                    arrowIconThree = 'https:' + getArrowIconThree[0].fields.file[props.appLanguage].url;
                }
            setArrowIconThree(arrowIconThree);


        
        // this shows the Crisp Chat 
        /* $crisp.push(["do", "chat:show"]) */
    
  }, [props.appLanguage]);

  const lasLibresUrl = props.appLanguage === 'es-US' ? lasLibresUrlEs : lasLibresUrlEn;


    
    return (
    <>
            <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...props} appLanguage={props.appLanguage} />
            
      <div className='moreInfoContainer' style={{ backgroundColor: 'white' }}>

         <div style={{maxWidth: '350px'}}> 
        <div className='more-info-title'>{pageTitleOne}</div>
            </div>

        <div className='cardsContainer'>
                <div>
                <a
                        href={lasLibresUrl} // Use dynamic URL based on language
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <BasicCard
                            image={cardOneLogo}
                            title={cardOneTitle}
                            content={
                                <ReactMarkdown 
                                children={cardOneDescription} // Assuming `cardOneDescription` is a string with Markdown content
                                className="regularFont fontSize15 lineHeight18 textAlignLeft psMargin0 marginBottom18 contentfulMarkdown"
                                allowDangerousHtml={true}
                                />
                            }
                            button1={buttonOne}
                            button2={
                                <div>
                                <a href="tel:+524731727025" style={{
                                    textDecoration: 'none', 
                                    color: '#051D58', 
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center', // Center align text
                                    maxWidth: '190px',
                                    width: '100%',
                                    marginBottom: '5px',
                                    boxSizing: 'border-box'
                                }}>
                                    {buttonTwo}
                                </a>
                                </div>
                            }
                            arrowIconOne={arrowIconOne}
                            phoneIcon={phoneIcon}
                            />

                    </a>
                </div>

{/*           <a href="https://www-redstateaccess-org.translate.goog/?_x_tr_sl=en&_x_tr_tl=es&_x_tr_hl=en&_x_tr_pto=wapp" target="_blank" rel="noopener noreferrer">
            <BasicCard
              image={cardTwoLogo}
              title={cardTwoTitle}
              content={cardTwoDescription}
              arrow={arrowIconTwo}
            />
          </a> */}

          <a href="https://www.necesitoabortar.mx/" target="_blank" rel="noopener noreferrer">
            <BasicCard
              image={cardThreeLogo}
              title={cardThreeTitle}
              content={cardThreeDescription}
              arrow={arrowIconThree}
            />

          </a>
        </div>
                <div className='end-of-page'></div>
                <BottomNavBar appLanguage={props.appLanguage} />
      </div>
    </>
  );
}

export default withRouter(MoreInfo);