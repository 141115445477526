import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { SingleContraceptionOptionShell } from './SingleContraceptionOptionShell';

const ContraceptionDiaphragm = (props) => {
    const [content, setContent] = useState({});

    useEffect(() => {
        ReactGA.send("/contraception-diaphragm");
        window.scrollTo(0, 0);
        document.title = "Contraception Diaphragm";

        const getEntries = localStorage.getItem('ayaAppEntries');
        const entries = JSON.parse(getEntries);

        const getContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'contraceptionOption');
        const getPageEntries = getContentEntries.filter(obj => obj.sys.id === '1wIkokDoswrEH03EuBkRKR');

        let pageEntries = [];
        let contentObj = {};

        if (getPageEntries && getPageEntries.length > 0) {
            pageEntries.push(getPageEntries[0].fields);

            if (pageEntries.length > 0) {
                const pageEntry = pageEntries[0];
                const appLanguage = props.appLanguage;

                contentObj = {
                    title: pageEntry.title && pageEntry.title[appLanguage],
                    optionsAndTime1: pageEntry.optionsAndTime && pageEntry.optionsAndTime[appLanguage],
                    optionsAndTime2: pageEntry.optionsAndTime2 && pageEntry.optionsAndTime2[appLanguage],
                    paragraph: pageEntry.paragraph && pageEntry.paragraph[appLanguage],
                    btn1Text: pageEntry.ctaCopy1 && pageEntry.ctaCopy1[appLanguage],
                    btn2Text: pageEntry.ctaCopy2 && pageEntry.ctaCopy2[appLanguage],
                    btn3Text: pageEntry.ctaCopy3 && pageEntry.ctaCopy3[appLanguage]
                };
            }
        }

        const getAssets = localStorage.getItem('ayaAppAssets');
        const assets = JSON.parse(getAssets);

        const getImg1 = assets.filter(obj => obj.sys.id === '4pXsZIQAUCRJA70Yfr12Mm');

        if (getImg1 && getImg1.length > 0 && getImg1[0].fields.file[props.appLanguage].url) {
            contentObj.image1 = 'https:' + getImg1[0].fields.file[props.appLanguage].url;
            contentObj.image1Alt = getImg1[0].fields.description[props.appLanguage];
        }

        contentObj.image1Width = 'width78Percent';

        setContent(contentObj);


        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]); */
        
    }, [props.appLanguage]);

    return (
        <div className='flexColumn flexGrow1 linkHasUnderline'>
            <SingleContraceptionOptionShell
                appLanguage={props.appLanguage}
                history={props.history}
                content={content}
            />
        </div>
    );
}

export default ContraceptionDiaphragm;
