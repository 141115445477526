import React from 'react';
import ReactGA from 'react-ga4';
import { TopNavBar } from '../../Components/TopNavBar'
import * as ContentfulMarkdown from 'react-markdown'
import { LargeButton } from '../../Components/LargeButton'
/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg' */
import { WHOLink } from '../../Components/WHOLink';


export class AbortionAssessmentPassed extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            headerImage: '',
            headerImageAlt: '',
            title: '',
            text: '',
            btnText: '',
        }
    }


    componentDidMount() {
        ReactGA.send("/abortion-assessment-passed")
        window.scrollTo(0, 0)

        document.title = "Abortion Assessment Passed"

        const checkPageAccess = localStorage.getItem('isAbortionAssessmentComplete')
        if (checkPageAccess !== 'true') {
            this.props.history.goBack()
        }

                        
        // this hides the Crisp Chat Window from the page
        /* $crisp.push(["do", "chat:hide"]); */


        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)
        // Content type ID
        const getContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'sectionBeginningendingScreen');
        // Then Entry ID instead
        const getAbAPassedEntries = getContentEntries.filter(obj => obj.sys.id === '1IaeQK36Szyd3he9fsKW7u');

        var pageEntries = []
        var title1 = ''
        var text1 = ''
        var ctaCopy = ''

        if (getAbAPassedEntries && getAbAPassedEntries.length > 0) {
            pageEntries.push(getAbAPassedEntries[0].fields)

            if (pageEntries.length > 0) {

                if (pageEntries[0].title) {
                    title1 = pageEntries[0].title[this.props.appLanguage]
                }
                if (pageEntries[0].paragraph) {
                    text1 = pageEntries[0].paragraph[this.props.appLanguage]
                }
                if (pageEntries[0].ctaCopy1) {
                    ctaCopy = pageEntries[0].ctaCopy1[this.props.appLanguage]
                }
            }
        }


        this.setState({
            title: title1,
            text: text1,
            btnText: ctaCopy
        })




        // Get page Assets
        const getAssets = localStorage.getItem('ayaAppAssets')
        const assets = JSON.parse(getAssets)

        const getHeaderImg = assets.filter(obj => obj.sys.id === '7dGJJ8AYHU9CKxV23Xe0zZ');

        var pageMainImage = ''
        var pageMainImageAlt = ''

        if (getHeaderImg && getHeaderImg.length > 0 && getHeaderImg[0].fields.file[this.props.appLanguage].url) {
            pageMainImage = 'https:' +  getHeaderImg[0].fields.file[this.props.appLanguage].url
            pageMainImageAlt = getHeaderImg[0].fields.description[this.props.appLanguage]
        }

        this.setState({
            headerImage: pageMainImage,
            headerImageAlt: pageMainImageAlt,
        })


    } // componentDidMount()



    goBack = () => {
        this.props.history.goBack()
    }

    continue = () => {
        this.props.history.push('/abortion');
    }

    render() {

        const { headerImage, headerImageAlt, title, text, btnText } = this.state

        return (
            <div className="minPageHeight100vh flexColumn flexGrow1 backgroundColorWhite">
                <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />
                
                <div className='marginTop95 flexColumn flexGrow1 justifyBetween textAlignCenter pagesInnerDiv'>
                    <img src={headerImage} className='width100Percent marginBottom36' alt={headerImageAlt} />

                    <div className='flexGrow1 paddingLeftRight66 landscapeWidth67Percent'>
                        <ContentfulMarkdown children={title} className='boldFont fontSize18 lineHeight20 colorPeach3 psMargin0 marginBottom21 contentfulMarkdown' />

                        <ContentfulMarkdown children={text} className='regularFont fontSize15 lineHeight18 colorBlue8 psMargin0 marginBottom30 hasColorPeach4Span contentfulMarkdown' allowDangerousHtml={true} />


                        <LargeButton
                            largeButtonFunc={() => this.continue()}
                            text={btnText}
                            textColor={'colorWhite'}
                            backgroundColor={'backgroundColorBlue8'}
                        />


                        <WHOLink 
                            fontColor="#071f56"
                            appLanguage={this.props.appLanguage}
                            {...this.props}
                        /> {/* This is the WHO link that is necessary for iOS app store */}
                    </div>

                    
                </div>
            </div>
        )
    }
}
