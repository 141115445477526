import React from 'react';
import * as ContentfulMarkdown from 'react-markdown'
import { LargeButton } from '../../Components/LargeButton'
import messageIcon from '../../Images/message-icon-white.svg'
import { WHOLink } from '../../Components/WHOLink';


export class SelfCareSlide4 extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            headerImage: '',
            headerImageAlt: '',
            title: '',
            text: '',
            btn1Text: '',
            btn2Text: '',
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)
        // Content type ID
        const getSelfCareEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'selfCareAdviceSlider');
        // Then Entry ID instead
        const getpageEntries = getSelfCareEntries.filter(obj => obj.sys.id === '1z84u7F6JEDfJMuhKMbTgi');

        var pageEntries = []
        var title1 = ''
        var text1 = ''
        var ctaCopy1 = ''
        var ctaCopy2 = ''

        if (getpageEntries && getpageEntries.length > 0) {
            pageEntries.push(getpageEntries[0].fields)

            if (pageEntries.length > 0) {

                if (pageEntries[0].title) {
                    title1 = pageEntries[0].title[this.props.appLanguage]
                }
                if (pageEntries[0].copy1) {
                    text1 = pageEntries[0].copy1[this.props.appLanguage]
                }
                if (pageEntries[0].ctaCopy1) {
                    ctaCopy1 = pageEntries[0].ctaCopy1[this.props.appLanguage]
                }
                if (pageEntries[0].ctaCopy2) {
                    ctaCopy2 = pageEntries[0].ctaCopy2[this.props.appLanguage]
                }
            }
        }


        this.setState({
            title: title1,
            text: text1,
            btn1Text: ctaCopy1,
            btn2Text: ctaCopy2,
        })




        // Get page Assets
        const getAssets = localStorage.getItem('ayaAppAssets')
        const assets = JSON.parse(getAssets)

        const getHeaderImg = assets.filter(obj => obj.sys.id === '3JJdkb8DjoV4MrELa9Gn1K');

        var pageMainImage = ''
        var pageMainImageAlt = ' '

        if (getHeaderImg && getHeaderImg.length > 0 && getHeaderImg[0].fields.file[this.props.appLanguage].url) {
            pageMainImage = 'https:' + getHeaderImg[0].fields.file[this.props.appLanguage].url

            if (getHeaderImg[0].fields.description) {
                pageMainImageAlt = getHeaderImg[0].fields.description[this.props.appLanguage]
            }
        }


        this.setState({
            headerImage: pageMainImage,
            headerImageAlt: pageMainImageAlt,
        })


    } // componentDidMount()



    goToEmotionalSupport = () => {
        this.props.history.push('/resources-emotional-support');
    }

    goToAbortion = () => {
        this.props.history.push('/abortion');
    }

    render() {

        const { headerImage, headerImageAlt, title, text, btn1Text, btn2Text } = this.state

        return (
            <div className='flexColumn flexGrow1 backgroundColorBlue8 selfCareSlides slide4 paddingTop70'>
                <div className='flexColumn headerArea'>
                    <img src={headerImage} className='width71Percent positionAbsolute absoluteTopRight0 zIndex50' alt={headerImageAlt} />
                </div>

                <div className='flexGrow1 backgroundColorWhite topRoundDiv'>
                    <div className='flexColumn paddingTop23PercentVw paddingLeftRight35 paddingBottom40 topRoundDivInnerDiv'>
                        <ContentfulMarkdown children={title} className='boldFont fontSize24 lineHeight26 colorBlue8 psMargin0 marginBottom24 contentfulMarkdown' />

                        <ContentfulMarkdown children={text} className='regularFont fontSize15 lineHeight16 colorDarkGrey psMargin0 contentfulMarkdown' allowDangerousHtml={true} />

                        <div className='marginBottom30'></div>

                        <LargeButton
                            largeButtonFunc={() => this.goToEmotionalSupport()}
                            text={<p className='width100Percent flexRow justifyCenter alignCenter marginBottom0'>
                                {/* <img src={messageIcon} style={{ width: '18px', height: 'auto', marginRight: '10px' }} alt='' /> */}
                                {btn1Text}
                            </p>}
                            textColor={'colorWhite'}
                            backgroundColor={'backgroundColorBlue8'}
                        />

                        <div className='marginBottom15'></div>

                        <LargeButton
                            largeButtonFunc={() => this.goToAbortion()}
                            text={btn2Text}
                            textColor={'colorWhite'}
                            backgroundColor={'backgroundColorBlue6'}
                        />

                        <WHOLink 
                            fontColor="#071f56"
                            appLanguage={this.props.appLanguage}
                            {...this.props} 
                        /> {/* This is the WHO link that is necessary for iOS app store */}
                    </div>
                </div>
            </div>
        )
    }
}
