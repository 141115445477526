import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { TopNavBar } from "../../Components/TopNavBar";
import * as ContentfulMarkdown from "react-markdown";
import { BottomNavBar } from "../../Components/BottomNavBar";
import "./Language.css";  

function Language(props) {
  const [selectedLanguage, setSelectedLanguage] = useState(props.appLanguage);
  const [shortText1, setShortText1] = useState("");
  const [shortText2, setShortText2] = useState("");
  const [languageOptionLabel, setLanguageOptionLabel] = useState('Idioma'); // Default label is 'Language'


  useEffect(() => {
    console.log("useEffect triggered for language:", selectedLanguage);

    ReactGA.send("pageview", "/language");
    window.scrollTo(0, 0);
    document.title = "Languages";
  
    const getEntries = localStorage.getItem("ayaAppEntries");
    const entries = JSON.parse(getEntries);
  
    // GET MODEL
    const getModel = entries.filter(
      (obj) => obj.sys.contentType.sys.id === "simplistic"
    );
  
    // GET CONTENT
    const getContent = getModel.filter(
      (obj) => obj.sys.id === "5UP9IidLYxE6A3HacJ9w1B"
    );
    console.log("getContent:", getContent);

  
    if (getContent?.length > 0) {
      const shortTextData = getContent[0].fields;
      const fetchedShortText1 = shortTextData.shortText1
        ? shortTextData.shortText1[selectedLanguage]
        : "";
      const fetchedShortText2 = shortTextData.shortText2
        ? shortTextData.shortText2[selectedLanguage]
        : "";
        console.log("Fetched texts for new language:", fetchedShortText1, fetchedShortText2);

      setShortText1(fetchedShortText1);
      setShortText2(fetchedShortText2);
    } else {
      console.log('No content found for language:', selectedLanguage);
    }
  }, [selectedLanguage]);

const handleLanguageChange = (newLanguage) => {
  const languageCode = newLanguage === "es-VE" ? "es-VE" : "en";
  console.log("Changing language to:", languageCode);
  setSelectedLanguage(languageCode);
  setLanguageOptionLabel(languageCode === "es-VE" ? "Idioma" : "Language");
    // Log before function call
    console.log("Before handleLanguageChange prop call");

    // Call handleLanguageChange prop
    props.handleLanguageChange(languageCode);
  
    // Log after function call
    console.log("After handleLanguageChange prop call");
  
    // Log before toggling hamburger menu reload
    console.log("Before toggling hamburger menu reload");
  
    // Call toggleHamburgerMenuReload prop
    props.toggleHamburgerMenuReload();
  
    // Log after toggling hamburger menu reload
    console.log("After toggling hamburger menu reload");

};
  

  return (
    <div className="minPageHeight100vh flexColumn flexGrow1 pageWithBottomMainNav aboutVitalaPage">
      <TopNavBar
        bgColor="#fff"
        showHamburgerMenu={true}
        menuIconColor={"blue"}
        logoColor={"peach"}
        bellColor={"blue"}
        newNotificationColor={"peach"}
        {...props}
        appLanguage={props.appLanguage}
        handleLanguageChange={handleLanguageChange} 

      />
      <div id="scroll" className="pagesInnerDiv paddingTop40">
        <div className="paddingTop90 justText headerArea">
          <div className="padding0-30-57"> 
        <p className="boldFont fontSize20 lineHeight26 colorWhite">{languageOptionLabel}</p>
         </div>
           
        </div>

        <div className="flexGrow1 backgroundColorWhite topRoundDiv">
          <div className="padding56-21-30-34 topRoundDivInnerDiv">
            <label className="language-selection">
              <input
                type="radio"
                name="language"
                value="es-VE"
                checked={selectedLanguage === "es-VE"}
                onChange={() => handleLanguageChange("es-VE")}
               
              />
              {shortText1} {/* Spanish */}
              <span className="checkmark"></span>
            </label>
            <label className="language-selection">
              <input
                type="radio"
                name="language"
                value="en"
                checked={selectedLanguage === "en"}
                onChange={() => handleLanguageChange("en")}
               
              />
              {shortText2} {/* English */}
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
      </div>{" "}
      <BottomNavBar appLanguage={props.appLanguage} />
    </div>
  );
}

export default Language;
