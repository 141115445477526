import BasicAccordion from '../../Components/BasicAccordion';
import { useState, useEffect } from 'react';
import styles from './ResourcesHome.modules.css'
import  { TopNavBar }  from '../../Components/TopNavBar';
import { BottomNavBar } from '../../Components/BottomNavBar'
import ReactGA from 'react-ga4';



function ResourcesHome (props) {
  const [resourcePageTitle, setResourcePageTitle] = useState('')
	const [resourceDescription, setResourceDescription] = useState('')
	const [accordionTextOne, setAccordionTextOne] = useState('')
	const [accordionContentOne, setAccordionContentOne] = useState('')
	const [dropDownSubtitleOne, setDropDownSubtitleOne] = useState('')
	const [linkOne, setLinkOne] = useState('')
	const [linkTwo, setLinkTwo] = useState('')
	const [linkThree, setLinkThree] = useState('')
	const [linkFour, setLinkFour] = useState('')
	const [linkFive, setLinkFive] = useState('')
	const [subtitleOne, setSubtitleOne] = useState('')
	const [cardOne, setCardOne] = useState('');
	const [cardTwo, setCardTwo] = useState('');
	const [cardThree, setCardThree] = useState('');
	const [cardFour, setCardFour] = useState('');
	const [cardFive, setCardFive] = useState('');
	const [cardSix, setCardSix] = useState('');
	const [arrowIconOne, setArrowIconOne] = useState('');




  useEffect(() => {
	// GA4 code
	ReactGA.send("/resources");
	//this makes sure the page always scrolls to the top of the page when rendered
	window.scrollTo(0, 0); 

	document.title = "Resources Home";
	
        // this hides the Crisp Chat 
       /*  $crisp.push(["do", "chat:hide"]) */

		// GET ALL ENTRIES 
	const getEntries = localStorage.getItem('ayaAppEntries');
    const entries = JSON.parse(getEntries);

		// GET MODEL
		const getModel = entries.filter(
      (obj) => obj.sys.contentType.sys.id === "resourcesMain"
    );
		
		// GET CONTENT
		const getContent = getModel.filter(
		(obj) => obj.sys.id === "2qCV4D8AaYRJMYltPY6EyT"
		)

		// GET PAGE TITLE
		let pageTitle = ''
		if (getContent?.length > 0) {
			const pageTitleData = getContent[0].fields;
			pageTitle = pageTitleData.pageTitle ? pageTitleData.pageTitle[props.appLanguage] : '';
		}
		setResourcePageTitle(pageTitle);



		// GET PAGE DESCRIPTION
		let pageDescription = ''

		if (getContent?.length > 0) {
			const pageDescriptionData = getContent[0].fields;
			pageDescription = pageDescriptionData.description ? pageDescriptionData.description[props.appLanguage] : '';
		}
		setResourceDescription(pageDescription)



		// GET ACCORDION TEXT
		let accordionText = ''

		if (getContent?.length > 0) {
			const accordionTextData = getContent[0].fields;
			accordionText = accordionTextData.dropdownButton ? accordionTextData.dropdownButton[props.appLanguage] : '';
		}
		setAccordionTextOne(accordionText)



		// GET ACCORDION CONTENT
	  let accordionContent = ''

		if (getContent?.length > 0) {
			const accordionContentData = getContent[0].fields;
			accordionContent = accordionContentData.dropdownButtonDescription ? accordionContentData.dropdownButtonDescription[props.appLanguage] : '';
		}
		setAccordionContentOne(accordionContent)



		// GET ACCORDION DROPDOWN SUBTITLE
		let dropDownSubtitle = ''

		if (getContent?.length > 0) {
			const dropDownSubtitleData = getContent[0].fields;
			dropDownSubtitle  = dropDownSubtitleData.dropdownButtonSubtitle ? dropDownSubtitleData.dropdownButtonSubtitle[props.appLanguage] : '';
		}
		setDropDownSubtitleOne(dropDownSubtitle)



		// GET IMAGE heading
		let subtitleText = ''

		if (getContent?.length > 0) {
			const subtitleTextData = getContent[0].fields;
			subtitleText = subtitleTextData.subtitle ? subtitleTextData.subtitle[props.appLanguage] : '';
		}
		setSubtitleOne(subtitleText)



		// GET DROPDOWN LINK ONE
		let oneLink = ''

		if (getContent?.length > 0) {
			const oneLinkData = getContent[0].fields;
			oneLink = oneLinkData.dropdownLink1 ? oneLinkData.dropdownLink1[props.appLanguage] : '';
		}
		setLinkOne(oneLink)



		// GET DROPDOWN LINK TWO
		let twoLink = ''

		if (getContent?.length > 0) {
			const twoLinkData = getContent[0].fields;
			twoLink = twoLinkData.dropdownLink2 ? twoLinkData.dropdownLink2[props.appLanguage] : '';
		}
		setLinkTwo(twoLink)



		// GET DROPDOWN LINK THREE
		let threeLink = ''

		if (getContent?.length > 0) {
			const threeLinkData = getContent[0].fields;
			threeLink = threeLinkData.dropdownLink3 ? threeLinkData.dropdownLink3[props.appLanguage] : '';
		}
		setLinkThree(threeLink)



		// GET DROPDOWN LINK FOUR
		let fourLink = ''

		if (getContent?.length > 0) {
			const fourLinkData = getContent[0].fields;
			fourLink = fourLinkData.dropdownLink4 ? fourLinkData.dropdownLink4[props.appLanguage] : '';
		}
		setLinkFour(fourLink)



		// GET DROPDOWN LINK FIVE 
		let fiveLink = ''

		if (getContent?.length > 0) {
			const fiveLinkData = getContent[0].fields;
			fiveLink = fiveLinkData.dropdownLink5 ? fiveLinkData.dropdownLink5[props.appLanguage] : '';
		}
		setLinkFive(fiveLink)



		// ASSETS
		// GET IMAGES
    const getAssets = localStorage.getItem('ayaAppAssets');
    const assets = JSON.parse(getAssets);

		// GET IMAGE ONE
    const getImageOne = assets.filter(
      obj => obj.sys.id === '3EcHSienPOWyvdS1UDuTwt'
    );

    let imageOne = '';
    if (getImageOne?.length > 0 && getImageOne[0]?.fields?.file?.[props.appLanguage]?.url) {
			imageOne = 'https:' + getImageOne[0].fields.file[props.appLanguage].url;
		}

    setCardOne(imageOne);


		
		// GET IMAGE TWO
		const getImageTwo = assets.filter(
      obj => obj.sys.id === '69opVeBjjWoTzDh9O5h8VZ'
    );

    let imageTwo = '';
    if (getImageTwo?.length > 0 && getImageTwo[0]?.fields?.file?.[props.appLanguage]?.url) {
			imageTwo = 'https:' + getImageTwo[0].fields.file[props.appLanguage].url;
		}
    setCardTwo(imageTwo);



		// GET IMAGE THREE
		const getImageThree = assets.filter(
      obj => obj.sys.id === '6FTxiENUGAei6CDL4t3B8Z'
    );

    let imageThree = '';
    if (getImageThree?.length > 0 && getImageThree[0]?.fields?.file?.[props.appLanguage]?.url) {
			imageThree = 'https:' + getImageThree[0].fields.file[props.appLanguage].url;
		}
    setCardThree(imageThree);



		// GET IMAGE FOUR 
		const getImageFour = assets.filter(
      obj => obj.sys.id === '59fDFcVEOULrHa5U3ILnml'
    );

    let imageFour = '';
    if (getImageFour?.length > 0 && getImageFour[0]?.fields?.file?.[props.appLanguage]?.url) {
			imageFour = 'https:' + getImageFour[0].fields.file[props.appLanguage].url;
		}
    setCardFour(imageFour);



		// GET IMAGE FIVE
		const getImageFive = assets.filter(
      obj => obj.sys.id === '1qjG1fBIkXrxGmTXZ4fdwY'
    );

    let imageFive = '';
    if (getImageFive?.length > 0 && getImageFive[0]?.fields?.file?.[props.appLanguage]?.url) {
			imageFive = 'https:' + getImageFive[0].fields.file[props.appLanguage].url;
		}
    setCardFive(imageFive);



		// GET IMAGE SIX
		const getImageSix = assets.filter(
      obj => obj.sys.id === 'e1NCcZvkeZSJC9Xmf5vjd'
    );

    let imageSix = '';
    if (getImageSix?.length > 0 && getImageSix[0]?.fields?.file?.[props.appLanguage]?.url) {
			imageSix = 'https:' + getImageSix[0].fields.file[props.appLanguage].url;
		}
    setCardSix(imageSix);


		// GET ARROW ICON
		const getArrowIcon = assets.filter(
      obj => obj.sys.id === '3Xegltxk8ROHntvPdStc3A'
    );

    let arrowIcon = '';
    if (getArrowIcon?.length > 0 && getArrowIcon[0]?.fields?.file?.[props.appLanguage]?.url) {
			arrowIcon = 'https:' + getArrowIcon[0].fields.file[props.appLanguage].url;
		}
    setArrowIconOne(arrowIcon);

  }, [props.appLanguage]);



  return (
    <>
		<TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...props} appLanguage={props.appLanguage} />

      <div className="resourcesHomeContainer" style={{ backgroundColor: 'white' }}>

				<div className='resources-home-page-title'>{resourcePageTitle}</div>

				<div style={{maxWidth: '340px'}}>
				<div className='resources-home-page-description'>	{resourceDescription}</div>
				<BasicAccordion
          heading={accordionTextOne}
          content={[
						<div> {accordionContentOne}</div>,

						<div className= 'resources-home-dropDown-subtitle'> {dropDownSubtitleOne}</div>,
						
							<div key="linkOne" className="link-style custom-link">
								<a
								href="https://digitaldefensefund.org/privacidad-del-aborto"
								target="_blank"
								rel="noopener noreferrer"
								>
								<img src={arrowIconOne} alt='arrow' /> {linkOne}
								</a>
							</div>,

							<div key="linkTwo" className="link-style custom-link">
								<a href="/resources-plan-c">
              	<img src={arrowIconOne} alt='arrow' /> {linkTwo}
                </a>
							</div>,
					
							<div key="linkThree" className="link-style custom-link">
 							 <a href="/resources-steps">
   						 <img src={arrowIconOne} alt='arrow' /> {linkThree}
 							 </a>
							</div>,

							<div key="linkFour" className="link-style custom-link">
								<a
  							href="https://www.plannedparenthood.org/es/guia-para-el-aborto"
    						target="_blank"
   						 	rel="noopener noreferrer"
  							>
    						<img src={arrowIconOne} alt='arrow' /> {linkFour}
  							</a>
							</div>,

							<div key="linkFive" className="link-style custom-link">
								<a href="/abortion-step-1">
   						 <img src={arrowIconOne} alt='arrow' /> {linkFive}
                </a>
							</div>,
				]}
          headingStyle={{ color: 'white', fontSize: '14px', fontFamily: 'GTWalsheimPro-Bold, sans-serif' }}
          contentStyle={{ color: '#524F4F', fontSize: '14px', fontFamily: 'GTWalsheimPro-Regular, sans-serif'}}
        />

		</div>
				
        <div className='cardsContainer'>
					<div className='resource-home-subtitle'>{subtitleOne}</div>
						<a href="/resources-legal-assistance">
  						<img src={cardOne} alt="Card One" className='resource-category-images' />
						</a>
						<a href="/resources-pills-and-clinics">
  						<img src={cardTwo} alt="Card Two" className='resource-category-images' />
						</a>
						<a href="/resources-medical-advice">
  						<img src={cardThree} alt="Card Three" className='resource-category-images'/>
						</a>
						<a href="/resources-financial-help">
						  <img src={cardFour} alt="Card Four" className='resource-category-images'/>
						</a>
						<a href="/resources-emotional-support">
  						<img src={cardFive} alt="Card Five" className='resource-category-images'/>
						</a>
						<a href="/resources-support">
  						<img src={cardSix} alt="Card Six" className='resource-category-images'/>
						</a>
       	</div>

				<div className='end-of-page'></div>
				<BottomNavBar appLanguage={props.appLanguage} />
				
      </div>
    </>
  );
}

export default ResourcesHome;


