import React from 'react'
import PropTypes from 'prop-types'



export class LargeButton extends React.Component {


  handleClick = () => {
    this.props.largeButtonFunc()
  }

  render() {

    const { text, textColor, backgroundColor, marginBottom } = this.props

    const className = '' + textColor + ' ' + backgroundColor + ' largeButton';

    return (
      <div className="largeButtonContainer" style={{ marginBottom: marginBottom}}>
        <button className={className} onClick={this.handleClick}>
          <span className='boldFont fontSize15 alignCenter'>{text}</span>
        </button>
      </div>
    )
  }
}

LargeButton.propTypes = {
  // info: PropTypes.object
  largeButtonFunc: PropTypes.func,
  title: PropTypes.string,
  paragraph: PropTypes.string,
  buttonText: PropTypes.string,
  marginBottom: PropTypes.string,
  // linkUrl: PropTypes.string,
};