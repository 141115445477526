import React from 'react';
import { TopNavBar } from '../../Components/TopNavBar'
import { ContraceptionMenuCardContent } from './ContraceptionMenuCardContent'
import * as ContentfulMarkdown from 'react-markdown'
import { LargeButton } from '../../Components/LargeButton'
import { BottomNavBar } from '../../Components/BottomNavBar'
/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */
import ReactGA from 'react-ga4';



export class ContraceptionMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pageTitle: '',
      btn1Text: '',
      menuCardContent: [],
    }
  }


  componentDidMount() {
    window.scrollTo(0, 0)
    ReactGA.send("/contraception")

    document.title = "Contraception"

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

    const getEntries = localStorage.getItem('ayaAppEntries')
    const entries = JSON.parse(getEntries)

    const getMenuContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'contraceptionMenu');

    const getMenuEntries = getMenuContentEntries.filter(obj => obj.sys.id === '3i790TbDY6K9AXBrWkwlSJ');

    var menuEntries = []
    var menuCardContentArray = []
    var menuPageTitle = ''
    var ctaCopy = ''
    var card1 = {}
    var card2 = {}
    var card3 = {}
    var card4 = {}
    var card5 = {}
    var card6 = {}
    var card7 = {}
    var card8 = {}
    var card9 = {}
    var card10 = {}
    var card11 = {}
    var card12 = {}
    var card13 = {}
    var card14 = {}
    var card15 = {}


    if (getMenuEntries && getMenuEntries.length > 0) {
      menuEntries.push(getMenuEntries[0].fields)

      if (menuEntries.length > 0) {
        if (menuEntries[0].title) {
          menuPageTitle = menuEntries[0].title[this.props.appLanguage]
        }

        if (menuEntries[0].ctaCopy) {
          ctaCopy = menuEntries[0].ctaCopy[this.props.appLanguage]
        }

        if (menuEntries[0].option1) {
          card1['title'] = menuEntries[0].option1[this.props.appLanguage]
          card1['goToPageLink'] = '/contraception-pills'
        }

        if (menuEntries[0].option2) {
          card2['title'] = menuEntries[0].option2[this.props.appLanguage]
          card2['goToPageLink'] = '/contraception-copper-iud'
        }

        if (menuEntries[0].option3) {
          card3['title'] = menuEntries[0].option3[this.props.appLanguage]
          card3['goToPageLink'] = 'contraception-mirena-ius'
        }

        if (menuEntries[0].option4) {
          card4['title'] = menuEntries[0].option4[this.props.appLanguage]
          card4['goToPageLink'] = '/contraception-implant'
        }

        if (menuEntries[0].option5) {
          card5['title'] = menuEntries[0].option5[this.props.appLanguage]
          card5['goToPageLink'] = '/contraception-injection'
        }

        if (menuEntries[0].option6) {
          card6['title'] = menuEntries[0].option6[this.props.appLanguage]
          card6['goToPageLink'] = '/contraception-condoms'
        }

        if (menuEntries[0].option7) {
          card7['title'] = menuEntries[0].option7[this.props.appLanguage]
          card7['goToPageLink'] = '/contraception-tl'
        }

        if (menuEntries[0].option8) {
          card8['title'] = menuEntries[0].option8[this.props.appLanguage]
          card8['goToPageLink'] = '/contraception-ec'
        }

        if (menuEntries[0].option9) {
          card9['title'] = menuEntries[0].option9[this.props.appLanguage]
          card9['goToPageLink'] = '/contraception-vasectomia'
        }

        if (menuEntries[0].option10) {
          card10['title'] = menuEntries[0].option10[this.props.appLanguage]
          card10['goToPageLink'] = '/contraception-fam'
        }

        if (menuEntries[0].option11) {
          card11['title'] = menuEntries[0].option11[this.props.appLanguage]
          card11['goToPageLink'] = '/contraception-withdrawal'
        }

        if (menuEntries[0].option12) {
          card12['title'] = menuEntries[0].option12[this.props.appLanguage]
          card12['goToPageLink'] = '/contraception-ring'
        }

        if (menuEntries[0].option13) {
          card13['title'] = menuEntries[0].option13[this.props.appLanguage]
          card13['goToPageLink'] = '/contraception-patch'
        }

        if (menuEntries[0].option14) {
          card14['title'] = menuEntries[0].option14[this.props.appLanguage]
          card14['goToPageLink'] = '/contraception-diaphragm'
        }

        if (menuEntries[0].option15) {
          card15['title'] = menuEntries[0].option15[this.props.appLanguage]
          card15['goToPageLink'] = '/contraception-cap'
        }

      } // menuEntries.length
    } // getAbortionMenuEntries.length

    menuCardContentArray.push(card1, card2, card3, card4, card5, card6, card7, card8, card9, card10, card11, card12, card13, card14, card15)



    this.setState({
      pageTitle: menuPageTitle,
      btn1Text: ctaCopy,
      menuCardContent: menuCardContentArray,
    })

  } // componentDidMount()



  goToContraceptionAssessment = () => {
  this.props.history.push('/contraception-assessment')
  }

  goBack = () => {
    this.props.history.goBack()
  }


  render() {

    const { pageTitle, btn1Text, menuCardContent } = this.state
    const backgroundColors = ['backgroundColorBlue8', 'backgroundColorBlue7', 'backgroundColorBlue6', 'backgroundColorBlue5', 'backgroundColorBlue4', 'backgroundColorBlue3', 'backgroundColorBlue2', 'backgroundColorBlue3', 'backgroundColorBlue4', 'backgroundColorBlue5', 'backgroundColorBlue6', 'backgroundColorBlue7', 'backgroundColorBlue8', 'backgroundColorBlue7', 'backgroundColorBlue6' ]

    const menuCardContentItems = menuCardContent.map((info, i) => <div className={'' + backgroundColors[i] + ' topRoundDiv'} id={i} key={i}><ContraceptionMenuCardContent id={i} key={i} content={info} /></div>)


    return (
      <div className="minPageHeight100vh flexColumn flexGrow1 pageWithBottomMainNav backgroundColorWhite optionsMenuPage contraceptionMenuPage">
        
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

        <div className='pagesInnerDiv paddingTop40'>
          <div className='flexColumn alignCenter textAlignCenter justText headerArea'>
            <div className='widthMatchLargeButton padding90-0-62'>
              <ContentfulMarkdown children={pageTitle} className='boldFont fontSize23 lineHeight24 colorBlue8 textAlignCenter psMargin0 marginBottom26 contentfulMarkdown' allowDangerousHtml={true} />

              <LargeButton
                largeButtonFunc={() => this.goToContraceptionAssessment()}
                text={btn1Text}
                textColor={'colorWhite'}
                backgroundColor={'backgroundColorPeach2'}
              />

            </div>
          </div> {/* .headerArea */}

          <div className='flexColumn'>
            {menuCardContentItems}
          </div>

        </div> {/* .pagesInnerDiv */}

        <BottomNavBar appLanguage={this.props.appLanguage} />
      </div>
    )
  }
}