/* Need to account for light or dark logo, notification icon, and new notification alert circle color */
/* props.backgroundColor: There are 3 color schemes for the top nav bar. If background color = this, icons = this? */

import React from "react";
import { Link } from "react-router-dom";

import { HamburgerMenu } from "./HamburgerMenu";
import hamburgerMenuIconWhite from "../Images/hamburger-menu-icon-stripes-white.svg";
import hamburgerMenuIconBlue from "../Images/hamburger-menu-icon-stripes-blue.svg";
import logoPeach from "../Images/small-logo-peach.svg";
import logoBlue from "../Images/small-logo-blue.svg";
/* import bellWhite from "../Images/notification-bell-white.svg";
import bellBlue from "../Images/notification-bell-blue.svg"; */
import prevBtnImageBlue from "../Images/prevBtnImage-blue.svg";
import prevBtnImageWhite from "../Images/prevBtnImg-white.svg";
import padlockImage from "../Images/padlock-icon.svg";
import padlockImageWhite from "../Images/padlock-icon-white.svg";

export class TopNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      hamburgerMenuIsOpen: false,
      newNotifications: true,
      reloadHamburgerMenu: false, 

    };
  }

  openHamburgerMenu = (event) => {
    event.preventDefault();

    this.setState({
      hamburgerMenuIsOpen: true,
    });
  };

  closeHamburgerMenu = (event) => {
    this.setState({
      hamburgerMenuIsOpen: false,
    });
  };


  goBack = () => {
    this.props.history.goBack();
  };



  //  goBack = () => {
  //   this.props.history.push("/resources");
  // };

  render() {
    const { showHamburgerMenu } = this.props;
    const { hamburgerMenuIsOpen } = this.state;

    const { bgColor } = this.props;
    const navBackgroundColor = {
      backgroundColor: bgColor,
      maxWidth: "600px",
    };

    

    // the backgroundcolor of the topnavbar needs bgColor="" as props passed in each file //

    return (
      <>
        <div
          style={navBackgroundColor}
          className="width100Percent positionAbsolute topNavBar"
        >
          {/* <div className="marginBottom30 "></div> */}
          <HamburgerMenu
            hamburgerMenuIsOpen={hamburgerMenuIsOpen}
            reloadHamburgerMenu={this.state.reloadHamburgerMenu}  
            closeHamburgerMenu={(event) => this.closeHamburgerMenu(event)}
            appLanguage={this.props.appLanguage}
            key={this.props.appLanguage}
            selectedLanguage={this.props.selectedLanguage}  
            toggleHamburgerMenuReload={this.toggleHamburgerMenuReload}  
            handleLanguageChange={this.props.handleLanguageChange}    
                     
          />

          <div className="paddingTop17 paddingRight25 paddingLeft5 paddingBottom13 flexRow justifyBetween">
            <div className="containerDivs paddingLeft5 spaceTop hamburgerMenuContainer">
              {showHamburgerMenu ? (
                <button onClick={(event) => this.openHamburgerMenu(event)}>
                  <div className="widthAndHeight-100">
                    {this.props.menuIconColor === "white" ? (
                      <img
                        src={hamburgerMenuIconWhite}
                        className="widthAndHeight-80"
                        alt="Menu"
                      />
                    ) : null}
                    {this.props.menuIconColor === "blue" ? (
                      <img
                        src={hamburgerMenuIconBlue}
                        className="widthAndHeight-80"
                        alt="Menu"
                      />
                    ) : null}
                  </div>
                </button>
              ) : null}
              {this.props.menuIconColor === "blue" ? (
                <button onClick={() => this.goBack()}>
                  <img
                    className="generalCalendarPrevButton paddingLeft5"
                    src={prevBtnImageBlue}
                    alt=""
                  />
                </button>
              ) : null}
              {this.props.menuIconColor === "white" ? (
                <button onClick={() => this.goBack()}>
                  <img
                    className="generalCalendarPrevButton paddingLeft5"
                    src={prevBtnImageWhite}
                    alt=""
                  />
                </button>
              ) : null}
            </div>

            <div className="containerDivs logoIconContainer">
              <Link to="/home">
                <div className="widthAndHeight-100 paddingRight30">
                  {this.props.logoColor === "peach" ? (
                    <img
                      src={logoPeach}
                      className="width100HeightAuto"
                      alt="Aya"
                    />
                  ) : null}
                  {this.props.logoColor === "blue" ? (
                    <img
                      src={logoBlue}
                      className="width100HeightAuto"
                      alt="Aya"
                    />
                  ) : null}
                </div>
              </Link>
            </div>

            <div className="flexRow justifyEnd containerDivs spaceTop notificationBellContainer">
              <Link to="/pin-setup">
                <div className="widthAndHeight-100">
                  {this.props.bellColor === "white" ? (
                    <img
                      src={padlockImageWhite}
                      className="bellIcon"
                      alt="Black Padlock"
                    />
                  ) : null}
                  {this.props.bellColor === "blue" ? (
                    <img
                      src={padlockImage}
                      className="bellIcon"
                      alt="Black Padlock"
                    />
                  ) : null}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}
