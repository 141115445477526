import React from 'react';
import { TopNavBar } from '../../Components/TopNavBar' /* <-- peach colored TopNavBar */
import * as ContentfulMarkdown from 'react-markdown'
import { LargeButton } from '../../Components/LargeButton'
import messageIcon from '../../Images/talking-bubble-icon-blue.svg'
/* import prevBtnImg from '../../Images/prevBtnImage-whiteAndBlue.svg' */
/* import prevBtnImg from '../../Images/prevBtnImg-white.svg'; */


import { HelpfulPopUp } from '../../Components/HelpfulPopUp'
/* import { WHOLink } from '../../Components/WHOLink'; */


export class SingleContraceptionAssessmentWithOptions extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            page: 1,
            option1: false,
            option2: false,
            option3: false,
            option4: false,
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */
    }




    toggleRadio = (stateName) => {
        const getOptionStateName = this.state[stateName]

        if (getOptionStateName === true) {
            this.setState({
                [stateName]: false,
            })
        } else if (getOptionStateName === false) {
            this.setState({
                [stateName]: true,
            })
        }
    }



    continue = () => {
        const answerBoolArray = [this.state.option1, this.state.option2, this.state.option3, this.state.option4]
        const trueCount = answerBoolArray.filter(Boolean).length

        this.props.continue(trueCount)
    }

    showPopUp1 = () => {
        this.setState({
            page: 'popUp1'
        })
    }


    showPopUp2 = () => {
        this.setState({
            page: 'popUp2'
        })
    }


    comeBackToMainQuestion = () => {
        this.setState({
            page: 1
        })
    }


    goBack = () => {
        this.props.history.goBack()
    }




    render() {
        return (
            <div className='flexColumn flexGrow1'>
                { this.renderChildren()} {/* Down below */}
            </div>
        )
    }

// checkbox

    renderChildren = () => {
        const { page } = this.state
        const { content, popUp1Content, popUp2Content } = this.props

        const questionOptions = content.options.map((info, i) =>
            <div className='flexRow textAlignLeft marginBottom16 questionsDiv' id={i} key={i}>
                <div>
                    <input
                        type='checkbox'
                        // name={'question2'}
                        checked={this.state[info.stateName]}
                        className='flexColumn justifyCenter alignCenter marginRight10 backgroundColorNone radioCheckBlue mediumRadioBtn'
                        onChange={() => this.toggleRadio(info.stateName)}
                    />
                </div>
                <span className='regularFont fontSize13 lineHeight17 colorWhite'>{info.option}</span>
            </div>
        )



        if (page === 1) {
            return (
                <div className='minPageHeight100vh flexGrow1 alignCenter backgroundColorPeach1 contraceptionAssessmentPage'>
                    
                <TopNavBar bgColor="#F77478" showHamburgerMenu={true} menuIconColor={'white'} logoColor={'blue'} bellColor={'white'} newNotificationColor={'blue'} {...this.props} appLanguage={this.props.appLanguage} />

                    <div className='flexColumn flexGrow1 justifyCenter alignCenter textAlignCenter paddingTop80'>
                        <div className={this.props.questionWidth + ' landscapeWidth67Percent'}>
                            <ContentfulMarkdown children={content.stepNumber} className='boldFont fontSize12 lineHeight14 colorBlue8 marginBottom32 contentfulMarkdown' />

                            <ContentfulMarkdown children={content.question} className='boldFont fontSize22 lineHeight25 colorWhite psMargin0 contentfulMarkdown' />

                            {content.subTitle ?
                                <div className='marginBottom16'></div>
                                :
                                <div className='marginBottom30'></div>
                            }

                            {content.subTitle ?
                                <ContentfulMarkdown children={content.subTitle} className='boldFont fontSize15 lineHeight18 colorWhite psMargin0 marginBottom30 contentfulMarkdown' />
                            : null}
                        </div>

                        <form className='width78Percent landscapeWidth67Percent flexColumn textAlignLeft regularFont fontSize15 lineHeight18 colorWhite answerDiv'>
                            {questionOptions}
                        </form>

                        {content.noneApplyText ?
                            <div className='marginBottom9'></div>
                            :
                            <div className='marginBottom30'></div>
                        }

                        {content.noneApplyText ?
                            <ContentfulMarkdown children={content.noneApplyText} className='regularFont fontSize13 lineHeight17 colorBlue8 psMargin0 padding0-25-25 contentfulMarkdown' />
                        : null}


                        <div className='width78Percent landscapeWidth75Percent'>
                            <LargeButton
                                largeButtonFunc={() => this.continue()}
                                text={content.btn1Text}
                                textColor={'colorBlue8'}
                                backgroundColor={'backgroundColorWhite'}
                            />

                            <div className='marginBottom12'></div>

{/*                             <WHOLink 
                        fontColor="#fff"
                        appLanguage={this.props.appLanguage}
                        {...this.props} />  */}

                            {content.linkToPopUpText1 ?
                                <div className='flexRow paddingLeftRight40'>
                                    <img src={messageIcon} className='width16Height16 marginTop3 marginRight5' alt='...' />
                                    <button onClick={() => this.showPopUp1()} >
                                        <ContentfulMarkdown children={content.linkToPopUpText1} className='boldFont fontSize13 lineHeight15 colorWhite underline textAlignLeft psMargin0 contentfulMarkdown' />
                                    </button>
                                </div>
                            : null}

                            {content.linkToPopUpText1 ?
                                <div className='marginBottom12'></div>
                            : null}

                            {content.linkToPopUpText2 ?
                                <div className='flexRow paddingLeftRight40'>
                                    <img src={messageIcon} className='width16Height16 marginTop3 marginRight5' alt='...' />
                                    <button onClick={() => this.showPopUp2()} >
                                        <ContentfulMarkdown children={content.linkToPopUpText2} className='boldFont fontSize13 lineHeight15 colorWhite underline textAlignLeft psMargin0 contentfulMarkdown' />
                                    </button>
                            </div>
                        : null }
                        </div>
                    </div>

                    {/* <button onClick={() => this.goBack()} className='width30Height30 margin20 justifySelfFlexEnd alignSelfFlexStart'>
                        <img src={prevBtnImg} className='width100Percent prevImg' alt='back' />
                    </button> */}
                </div >
            )
        } else if (page === 'popUp1') {
            return (
                <HelpfulPopUp
                    pageBackgroundColor={'backgroundColorPeach1'}
                    logoColor={'blue'}
                    bellColor={'white'}
                    newNotificationColor={'blue'}
                    appLanguage={this.props.appLanguage}
                    helpfulDivPaddingLeftRight={'paddingLeftRight35'}
                    helpfulDivBackgroundColor={'backgroundColorBlue8'}
                    helpfulDivTextColor={'colorWhite'}
                    content={popUp1Content}
                    btn1TextColor={'colorBlue8'}
                    btn1BackgroundColor={'backgroundColorWhite'}
                    // btn2TextColor={'colorWhite'}
                    // btn2BackgroundColor={'backgroundColorBlue8'}
                    btn1Function={() => this.comeBackToMainQuestion()}
                    // btn2Function={() => this.comeBackToMainQuestion()}
                    backToFunc={() => this.comeBackToMainQuestion()}
                />
            )
        } else if (page === 'popUp2') {
            return (
                <HelpfulPopUp
                    pageBackgroundColor={'backgroundColorPeach1'}
                    logoColor={'blue'}
                    bellColor={'white'}
                    newNotificationColor={'blue'}
                    appLanguage={this.props.appLanguage}
                    helpfulDivPaddingLeftRight={'paddingLeftRight35'}
                    helpfulDivBackgroundColor={'backgroundColorBlue8'}
                    helpfulDivTextColor={'colorWhite'}
                    content={popUp2Content}
                    btn1TextColor={'colorBlue8'}
                    btn1BackgroundColor={'backgroundColorWhite'}
                    // btn2TextColor={'colorWhite'}
                    // btn2BackgroundColor={'backgroundColorBlue8'}
                    btn1Function={() => this.comeBackToMainQuestion()}
                    // btn2Function={() => this.comeBackToMainQuestion()}
                    backToFunc={() => this.comeBackToMainQuestion()}
                />
            )
        }
    }
}
