import React from 'react';
import { TopNavBar } from '../../Components/TopNavBar'
import importantIconPeach from '../../Images/important-outline-icon-peach.svg'
import * as ContentfulMarkdown from 'react-markdown'
import { LargeButton } from '../../Components/LargeButton'
/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg' */
/* import prevBtnImgWhite from '../../Images/prevBtnImg-white.svg'; */
import messageIcon from '../../Images/message-icon-white.svg'
import { WHOLink } from '../../Components/WHOLink';

export class GestationalAgeAlert extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            gestationalWeekText1: '',
            gestationalWeekText2: '',
            title: '',
            text: '',
            btnText: '',
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)
        
        // this shows the Crisp Chat 
        /* $crisp.push(["do", "chat:show"]) */

        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)
        // Content type ID
        const getContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'alert');
        // Then Entry ID instead
        const getpageEntries = getContentEntries.filter(obj => obj.sys.id === '6H9DFqGQ2tqfEEaDm5z073');

        var pageEntries = []
        var weekText1 = ''
        var weekText2 = ''
        var title1 = ''
        var text1 = ''
        var ctaCopy = ''

        if (getpageEntries && getpageEntries.length > 0) {
            pageEntries.push(getpageEntries[0].fields)

            if (pageEntries.length > 0) {

                if (pageEntries[0].numberOfDaysText) {
                    weekText1 = pageEntries[0].numberOfDaysText[this.props.appLanguage]
                }
                if (pageEntries[0].weeks) {
                    weekText2 = pageEntries[0].weeks[this.props.appLanguage]
                }

                if (pageEntries[0].title) {
                    title1 = pageEntries[0].title[this.props.appLanguage]
                }
                if (pageEntries[0].paragraph) {
                    text1 = pageEntries[0].paragraph[this.props.appLanguage]
                }
                if (pageEntries[0].ctaCopy) {
                    ctaCopy = pageEntries[0].ctaCopy[this.props.appLanguage]
                }
            }
        }


        this.setState({
            gestationalWeekText1: weekText1,
            gestationalWeekText2: weekText2,
            title: title1,
            text: text1,
            btnText: ctaCopy
        })
    } // componentDidMount()



    goBack = () => {
        this.props.backToFunc()
    }

    goToFeedback = () => {
        this.props.history.push('/feedback')
    }

    render() {

        const { gestationalWeekText1, gestationalWeekText2, title, text, btnText } = this.state
        const { userGestationalAgeInWeeks } = this.props

        return (
            <div className="minPageHeight100vh flexColumn flexGrow1 backgroundColorWhite gestationalAgeAlert">
                 
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

                <div className='flexColumn flexGrow1 justifyCenter alignCenter textAlignCenter paddingLeftRight35 marginTop75 landscapeWidth75Percent landscapeMarginTop95 pagesInnerDiv paddingTop40'>

                    <img src={importantIconPeach} className='width52Height52 marginBottom25 marginLeftRightAuto' alt='!' />

                    <div className='boldFont fontSize15 lineHeight26 colorBlue8 marginBottom4'>
                        <span>{gestationalWeekText1}</span>
                        <span> {userGestationalAgeInWeeks} </span>
                        <span>{gestationalWeekText2}</span>
                    </div>

                    <ContentfulMarkdown children={title} className='boldFont fontSize18 lineHeight23 colorPeach4 psMargin0 marginBottom15 contentfulMarkdown' />

                    <ContentfulMarkdown children={text} className='regularFont fontSize15 lineHeight18 colorBlue8 psMargin0 marginBottom40 contentfulMarkdown' />


{/*                     <LargeButton
                        largeButtonFunc={() => this.goToFeedback()}
                        text={<p className='width100Percent flexRow justifyCenter alignCenter marginBottom0'>
                            <img src={messageIcon} style={{ width: '18px', height: 'auto', marginRight: '10px' }} alt='' />
                            {btnText}
                        </p>}
                        textColor={'colorWhite'}
                        backgroundColor={'backgroundColorBlue8'}
                    /> */}

                    <WHOLink 
                        fontColor="#fff"
                        appLanguage={this.props.appLanguage}
                        {...this.props} /> {/* This is the WHO link that is necessary for iOS app store */}
                </div>

                {/* <button onClick={() => this.goBack()} className='width30Height30 margin20 justifySelfFlexEnd'>
                    <img src={prevBtnImg} className='width100Percent prevImg' alt='back' />
                </button> */}
            </div>
        )
    }
}
