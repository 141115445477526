import React from 'react';

export class ContraceptionLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sources: [
        {
          linkText: {
            'en': 'Bedsider',
            'es-US': 'Bedsider'
          },
          linkUrl: {
            'en': 'https://www.bedsider.org',
            'es-US': 'https://www.bedsider.org/es'
          }
        },
        {
          linkText: {
            'en': 'Guttmacher Institute',
            'es-US': 'Instituto Guttmacher'
          },
          linkUrl: 'https://www.guttmacher.org/global/contraception',
        },
        {
          linkText: {
            'en': 'Planned Parenthood',
            'es-US': 'Planned Parenthood'
          },
          linkUrl: {
          'en': 'https://www.plannedparenthood.org/learn/birth-control',
            'es-US': 'https://www.plannedparenthood.org/es/temas-de-salud/anticonceptivos'
          },
        },
      ]
    };
  }

  render() {
    const { fontColor, appLanguage } = this.props;
    const linkFontColor = {
      color: fontColor,
    };

    return (
      <div className="WHOLink-div WHOLink">
        <span className="sourceTitle" style={linkFontColor}>
          {appLanguage === 'es-US' ? '* Fuentes:' : '* Sources:'}
          <br />
        </span>

        {this.state.sources.map((source, index) => (
          <div key={index} className="link-container">
            <a 
              href={typeof source.linkUrl === 'string' ? source.linkUrl : (source.linkUrl[appLanguage] || source.linkUrl['en'])} 
              style={linkFontColor} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              {source.linkText[appLanguage] || source.linkText['en']}
            </a>
            <br />
          </div>
        ))}
      </div>
    );
  }
}
