import React from 'react'
import ReactGA from 'react-ga4'
import { SingleContraceptionAssessmentWithYesNO } from './SingleContraceptionAssessmentWithYesNO'


export class ContraceptionAssessment2  extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1,
      contentObj: [],
    }
  }



  componentDidMount() {
    ReactGA.send("/contraception-assessment-q2")
    window.scrollTo(0, 0)

    document.title = "Contraception Assessment Q2"

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

    const getEntries = localStorage.getItem('ayaAppEntries')
    const entries = JSON.parse(getEntries)

    const getContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'multipleChoice');
    const getPageEntries = getContentEntries.filter(obj => obj.sys.id === '6H4Uex1keKwzAnp3ajpPfo');

    var pageEntries = []
    var pageContentObj = {}

    if (getPageEntries && getPageEntries.length > 0) {
      pageEntries.push(getPageEntries[0].fields)

      if (pageEntries.length > 0) {
        
        if (pageEntries[0].stepNumber) {
          pageContentObj['stepNumber'] = pageEntries[0].stepNumber[this.props.appLanguage]
        }
        if (pageEntries[0].title) {
          pageContentObj['question'] = pageEntries[0].title[this.props.appLanguage]
        }
        if (pageEntries[0].choice1) {
          pageContentObj['yesOptionText'] = pageEntries[0].choice1[this.props.appLanguage]
        }
        if (pageEntries[0].choice2) {
          pageContentObj['noOptionText'] = pageEntries[0].choice2[this.props.appLanguage]
        }
        if (pageEntries[0].ctaCopy1) {
          pageContentObj['btn1Text'] = pageEntries[0].ctaCopy1[this.props.appLanguage]
        }
      }
    }

    this.setState({
      contentObj: pageContentObj,
    })
  }


  continue = (answer) => {
    window.localStorage.setItem('contraceptionAssessmentQ2Answer', answer)

    this.props.history.push('/contraception-assessment-q3')
  }



  goBackFunc = () => {
    this.props.history.goBack()
  }



  render() {

    const { contentObj } = this.state
    
    return (
      <div className='flexColumn flexGrow1'>
        <SingleContraceptionAssessmentWithYesNO
          appLanguage={this.props.appLanguage}
          history={this.props.history}
          content={contentObj}
          questionWidth={'width91Percent'}
          continue={this.continue}
          goBack={() => this.goBackFunc()}
        />
      </div>
    )
  }
}