import React from 'react';
import * as ContentfulMarkdown from 'react-markdown'
import TestimonialsTextWhiteCoral from '../../Images/testimonialsTextWhiteCoral.svg';


export class TestimonialsSlide7 extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            /* title: '', */
            text1: ''
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */


        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)
        // Content type ID
        const getTestimonialsEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'testimonials');
        // Then Entry ID instead
        const getpageEntries = getTestimonialsEntries.filter(obj => obj.sys.id === '6O3j0Ejx5RcKp97ydJ54U9');

        var pageEntries = []
        /* var title1 = '' */
        var text1 = ''

        if (getpageEntries && getpageEntries.length > 0) {
            pageEntries.push(getpageEntries[0].fields)

            if (pageEntries.length > 0) {
 
                if (pageEntries[0].text1) {
                    text1 = pageEntries[0].text1[this.props.appLanguage]
                }
            }
        }


        this.setState({
            /* title: title1, */
            text1: text1
        })

    } // componentDidMount()


    render() {

        const { text1 } = this.state

        return (
           
            <div className='flexColumn flexGrow1 paddingBottom64'>
                        <div className="marginBottom20 paddingLeftRight5 positionRelative">
                        <img alt="testimonials white speech bubble" src={TestimonialsTextWhiteCoral}/>                        
                        <ContentfulMarkdown children={text1} className='textAlignLeft slide-4 colorBlue8 mediumFont contentfulMarkdown' allowDangerousHtml={true} />
                        </div>                
            </div>       
        )
    }
}