import React from 'react';
import ReactGA from 'react-ga4';
import { TopNavBar } from '../../Components/TopNavBar'
/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */
import { LargeButton } from '../../Components/LargeButton'
import * as ContentfulMarkdown from 'react-markdown'
import { BottomNavBar } from '../../Components/BottomNavBar'
import messageIcon from '../../Images/message-icon-white.svg'
import { WHOLink } from '../../Components/WHOLink';


export class AbortionStep7 extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            headerImage: '',
            stepNum: '',
            title: '',
            text1: '',
            yesOptionText: '',
            noOptionText: '',
            questions: [],
            question1YesSelected: false,
            question1NoSelected: false,

            question2YesSelected: false,
            question2NoSelected: false,

            question3YesSelected: false,
            question3NoSelected: false,

            question4YesSelected: false,
            question4NoSelected: false,

            question5YesSelected: false,
            question5NoSelected: false,

            question6YesSelected: false,
            question6NoSelected: false,

            question7YesSelected: false,
            question7NoSelected: false,

            question8YesSelected: false,
            question8NoSelected: false,
            btn1Text: '',
            btn2Text: '',
            btn3Text: '',
        }
    }


    componentDidMount() {
        ReactGA.send("/abortion-step-7")
        window.scrollTo(0, 0)

        document.title = "Abortion Step 7"
                        
        // this hides the Crisp Chat Window from the page
        /* $crisp.push(["do", "chat:show"]); */


        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)
        // Content type ID
        const getAbortionStepsEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'stepsCompleteYourAbortion');
        // Then Entry ID instead
        const getStepEntries = getAbortionStepsEntries.filter(obj => obj.sys.id === '7EWY5f1C1ehIbR6uJ8caR4');

        var stepEntries = []
        var stepNumber = ''
        var stepTitle = ''
        var stepText1 = ''
        var allQuestions = []
        var yesText = ''
        var noText = ''
        // var question1Text = ''
        // var question2Text = ''
        // var question3Text = ''
        // var question4Text = ''
        // var question5Text = ''
        // var question6Text = ''
        // var question7Text = ''
        // var question8Text = ''
        var ctaCopy1 = ''
        var ctaCopy2 = ''
        var ctaCopy3 = ''


        if (getStepEntries && getStepEntries.length > 0) {
            stepEntries.push(getStepEntries[0].fields)

            if (stepEntries.length > 0) {

                if (stepEntries[0].stepNumber) {
                    stepNumber = stepEntries[0].stepNumber[this.props.appLanguage]
                }
                if (stepEntries[0].title) {
                    stepTitle = stepEntries[0].title[this.props.appLanguage]
                }
                if (stepEntries[0].text) {
                    stepText1 = stepEntries[0].text[this.props.appLanguage]
                }
                if (stepEntries[0].yes1) {
                    yesText = stepEntries[0].yes1[this.props.appLanguage]
                }
                if (stepEntries[0].no1) {
                    noText = stepEntries[0].no1[this.props.appLanguage]
                }

                if (stepEntries[0].question1) {
                    allQuestions.push({ 'question': stepEntries[0].question1[this.props.appLanguage], 'yesState': 'question1YesSelected', 'noState': 'question1NoSelected' })
                }
                if (stepEntries[0].question2) {
                    allQuestions.push({ 'question': stepEntries[0].question2[this.props.appLanguage], 'yesState': 'question2YesSelected', 'noState': 'question2NoSelected' })
                }
                if (stepEntries[0].question3) {
                    allQuestions.push({ 'question': stepEntries[0].question3[this.props.appLanguage], 'yesState': 'question3YesSelected', 'noState': 'question3NoSelected' })
                }
                if (stepEntries[0].question4) {
                    allQuestions.push({ 'question': stepEntries[0].question4[this.props.appLanguage], 'yesState': 'question4YesSelected', 'noState': 'question4NoSelected' })
                }
                if (stepEntries[0].question5) {
                    allQuestions.push({ 'question': stepEntries[0].question5[this.props.appLanguage], 'yesState': 'question5YesSelected', 'noState': 'question5NoSelected' })
                }
                if (stepEntries[0].question6) {
                    allQuestions.push({ 'question': stepEntries[0].question6[this.props.appLanguage], 'yesState': 'question6YesSelected', 'noState': 'question6NoSelected' })
                }
                if (stepEntries[0].question7) {
                    allQuestions.push({ 'question': stepEntries[0].question7[this.props.appLanguage], 'yesState': 'question7YesSelected', 'noState': 'question7NoSelected' })
                }
                if (stepEntries[0].question8) {
                    allQuestions.push({ 'question': stepEntries[0].question8[this.props.appLanguage], 'yesState': 'question8YesSelected', 'noState': 'question8NoSelected' })
                }

                if (stepEntries[0].ctaCopy1) {
                    ctaCopy1 = stepEntries[0].ctaCopy1[this.props.appLanguage]
                }
                if (stepEntries[0].ctaCopy2) {
                    ctaCopy2 = stepEntries[0].ctaCopy2[this.props.appLanguage]
                }
                if (stepEntries[0].ctaCopy3) {
                    ctaCopy3 = stepEntries[0].ctaCopy3[this.props.appLanguage]
                }
            } // stepEntries.length
        } // getAbortionStepsEntries.length

        this.setState({
            stepNum: stepNumber,
            title: stepTitle,
            text1: stepText1,
            yesOptionText: yesText,
            noOptionText: noText,
            questions: allQuestions,
            btn1Text: ctaCopy1,
            btn2Text: ctaCopy2,
            btn3Text: ctaCopy3,
        })


        // Get page Assets
        const getAssets = localStorage.getItem('ayaAppAssets')
        const assets = JSON.parse(getAssets)

        const getAbortionStepHeaderImg = assets.filter(obj => obj.sys.id === 's1TU4mqrMAGKF1Ip42840');

        var stepMainImage = ''

        if (getAbortionStepHeaderImg && getAbortionStepHeaderImg.length > 0 && getAbortionStepHeaderImg[0].fields.file[this.props.appLanguage].url) {
            stepMainImage = 'https:' + getAbortionStepHeaderImg[0].fields.file[this.props.appLanguage].url
        }

        this.setState({
            headerImage: stepMainImage,
        })

    } // componentDidMount()




    toggleRadio = (currentSelectedStateName, otherStateName) => {
        // Get the state name of the radio selected for updates and update it's counter part radio state name
        var getSelectedStateName = this.state[currentSelectedStateName]

        if (getSelectedStateName === true) {
            this.setState({
                [currentSelectedStateName]: false,
                [otherStateName]: true
            })
        } else if (getSelectedStateName === false){
            this.setState({
                [currentSelectedStateName]: true,
                [otherStateName]: false
            })
        }

    }




    goBackAStep = () => {
        this.props.history.push('/abortion-step-7');
    }

    goToResources = () => {
        this.props.history.push('/resources');
    }

    completeThisStep = () => {

        if (this.state.question1NoSelected === true && this.state.question2NoSelected === true && this.state.question3NoSelected === true && this.state.question4NoSelected === true && this.state.question5YesSelected === true && this.state.question6NoSelected === true && this.state.question7NoSelected === true && this.state.question8NoSelected === true) {
            // Alert text A
            window.localStorage.setItem('isAbortionStep7Complete', true)
            window.localStorage.setItem('showAbortionStep7FailText', 'A')
            this.props.history.push('/abortion-step-7-failed');

        } else if (this.state.question1YesSelected === true && this.state.question2YesSelected === true && this.state.question3YesSelected === true && this.state.question4YesSelected === true && this.state.question5NoSelected === true && this.state.question6YesSelected === true && this.state.question7YesSelected === true && this.state.question8YesSelected === true) {
            // Alert text B
            window.localStorage.setItem('isAbortionStep7Complete', true)
            window.localStorage.setItem('showAbortionStep7FailText', 'B')
            this.props.history.push('/abortion-step-7-failed');

        } else if (this.state.question1NoSelected === true && this.state.question2NoSelected === true && this.state.question3NoSelected === true && this.state.question4NoSelected === true && this.state.question5YesSelected === true && this.state.question6YesSelected === true && this.state.question7YesSelected === true && this.state.question8YesSelected === true) {
            // Alert text C
            window.localStorage.setItem('isAbortionStep7Complete', true)
            window.localStorage.setItem('showAbortionStep7FailText', 'C')
            this.props.history.push('/abortion-step-7-failed');

        } else if (this.state.question1YesSelected === true && this.state.question2YesSelected === true && this.state.question3YesSelected === true && this.state.question4YesSelected === true && this.state.question5NoSelected === true && this.state.question6NoSelected === true && this.state.question7NoSelected === true && this.state.question8NoSelected === true) {
            window.localStorage.setItem('isAbortionStep7Complete', true)
            this.props.history.push('/abortion-step-7-passed');

        } else {
            window.localStorage.setItem('isAbortionStep7Complete', true)
            window.localStorage.setItem('showAbortionStep7FailText', 'C')
            this.props.history.push('/abortion-step-7-failed');
        }
    }

    goToSelfCare = () => {
        this.props.history.push('/self-care-advice');
    }

    goBack = () => {
        this.props.history.goBack()
      }

    render() {
        const { headerImage, stepNum, title, text1, yesOptionText, noOptionText, questions, btn1Text, btn2Text, btn3Text } = this.state


        const questionsContent = questions.map((info, i) =>
            <div className='regularFont fontSize15 lineHeight20 colorBlack4 marginBottom26 questionsDiv' id={i} key={i}>
                <p className='marginBottom10'>{info.question}</p>
                <div className='flexRow alignCenter marginBottom10'>
                    <input
                        type='radio'
                        name={'question' + (i + 1) + ''}
                        checked={this.state[info.yesState]}
                        className='flexColumn justifyCenter alignCenter marginRight10 backgroundColorGrey1 radioCheckPeach smallRadioBtn'
                        onChange={() => this.toggleRadio(info.yesState, info.noState)}
                    />
                    <span className='lineHeight15'>{yesOptionText}</span>
                </div>
                <div className='flexRow alignCenter'>
                    <input
                        type='radio'
                        name={'question' + (i + 1) + ''}
                        checked={this.state[info.noState]}
                        className='flexColumn justifyCenter alignCenter marginRight10 backgroundColorGrey1 radioCheckPeach smallRadioBtn'
                        onChange={() => this.toggleRadio(info.noState, info.yesState)}
                    />
                    <span className='lineHeight15'>{noOptionText}</span>
                </div>
            </div>
        ) // questionsContent


        return (
            <div className="minPageHeight100vh flexColumn flexGrow1 pageWithBottomMainNav backgroundColorBlue9 step7Page abortionStepPages">
                
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

                <div className='pagesInnerDiv paddingTop100'>
                    <div className='backgroundImgWAutoH77Percent backgroundImgXLeft backgroundImgY105Percent dontRepeatImage backgroundColorBlue9 headerArea' style={{ backgroundImage: `url(${headerImage})` }}>
                        {/* <div className='flexRow justifyEnd alignFlexEnd padding0-25-25'>
                            <button onClick={() => this.goBackAStep()} className='width50Height50'>
                                <img src={prevBtnImg} className='width100Percent prevImg' alt='back' />
                            </button>
                        </div> */}
                    </div> {/* .headerArea */}

                    <div className='flexGrow1 backgroundColorWhite topRoundDiv'>
                        <div className='flexColumn alignCenter padding37-30-34 topRoundDivInnerDiv'>
                            <div className='backgroundColorPeach4 alignSelfFlexStart smallTagDiv'>
                                <span className='regularFont fontSize11 colorWhite'>{stepNum}</span>
                            </div>

                            <p className='boldFont fontSize24 lineHeight26 colorBlue8 alignSelfFlexStart textAlignLeft margin26-0-21'>{title}</p>

                            <ContentfulMarkdown children={text1} className='boldFont fontSize15 lineHeight20 colorBlack1 marginBottom16 contentfulMarkdown' />

                            <form className='marginBottom74 flexColumn'>
                                {questionsContent}
                            </form>

                    
                            <LargeButton
                                largeButtonFunc={() => this.completeThisStep()}
                                text={btn2Text}
                                textColor={'colorWhite'}
                                backgroundColor={'backgroundColorBlue6'}
                            />

                            <div className='marginBottom15'></div>

                            <LargeButton
                                largeButtonFunc={() => this.goToResources()}
                                text={<p className='width100Percent flexRow justifyCenter alignCenter marginBottom0'>
                                {/* <img src={messageIcon} style={{ width: '18px', height: 'auto', marginRight: '10px' }} alt='' /> */}
                                {btn1Text}
                            </p>}
                                textColor={'colorWhite'}
                                backgroundColor={'backgroundColorBlue8'}
                            />

                            <div className='marginBottom15'></div>

                            <LargeButton
                                largeButtonFunc={() => this.goToSelfCare()}
                                text={btn3Text}
                                textColor={'colorBlue8'}
                                backgroundColor={'backgroundColorPink'}
                            />

                            <WHOLink 
                                fontColor="#071f56"
                                appLanguage={this.props.appLanguage}
                                {...this.props} 
                            /> {/* This is the WHO link that is necessary for iOS app store */}

                        </div> {/* .topRoundDivInnerDiv */}
                    </div> {/* .topRoundDiv */}

                </div> {/* .pagesInnerDiv */}

                <BottomNavBar appLanguage={this.props.appLanguage} />
            </div>
        )
    }
}
