// import React from 'react';
// import Carousel from 'react-bootstrap/Carousel'
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { TopNavBar } from '../../Components/TopNavBar'
// /* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */
// import * as ContentfulMarkdown from 'react-markdown'
// import { LargeButton } from '../../Components/LargeButton'
// import { SelfCareSlide1 } from './SelfCareSlide1'
// import { SelfCareSlide2 } from './SelfCareSlide2'
// import { SelfCareSlide3 } from './SelfCareSlide3'
// import { SelfCareSlide4 } from './SelfCareSlide4'
// import { BottomNavBar } from '../../Components/BottomNavBar'
// import ReactGA from 'react-ga4';
// import { WHOLink } from '../../Components/WHOLink';


// export class SelfCareAdvice extends React.Component {
//   constructor(props) {
//     super(props)

//     this.state = {
//       page: 1,
//       headerImage: '',
//       headerImageAlt: '',
//       title: '',
//       text: '',
//       btnText: '',
//       carouselIndex: 0,
//     }
//   }


//   componentDidMount() {
//     window.scrollTo(0, 0)
//     ReactGA.send("/self-care-advice")

//     document.title = "Self Care Advice"

//         // this hides the Crisp Chat 
//         $crisp.push(["do", "chat:hide"])


//     const getEntries = localStorage.getItem('ayaAppEntries')
//     const entries = JSON.parse(getEntries)
//     // Content type ID
//     const getContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'sectionBeginningendingScreen');
//     // Then Entry ID instead
//     const getStepEntries = getContentEntries.filter(obj => obj.sys.id === '7kmBNio8rEnz06SMZIoUPA');

//     var selfCareEntries = []
//     var title1 = ''
//     var text1 = ''
//     var ctaCopy = ''

//     if (getStepEntries && getStepEntries.length > 0) {
//       selfCareEntries.push(getStepEntries[0].fields)

//       if (selfCareEntries.length > 0) {

//         if (selfCareEntries[0].title) {
//           title1 = selfCareEntries[0].title[this.props.appLanguage]
//         }
//         if (selfCareEntries[0].paragraph) {
//           text1 = selfCareEntries[0].paragraph[this.props.appLanguage]
//         }
//         if (selfCareEntries[0].ctaCopy1) {
//           ctaCopy = selfCareEntries[0].ctaCopy1[this.props.appLanguage]
//         }
//       }
//     }



//     // Get page Assets
//     const getAssets = localStorage.getItem('ayaAppAssets')
//     const assets = JSON.parse(getAssets)

//     const getHeaderImg = assets.filter(obj => obj.sys.id === '6mFKkxO7C6PQWuywQtGr91');

//     var pageMainImage = ''
//     var pageMainImageAlt = ''

//   /*   if (getHeaderImg && getHeaderImg.length > 0 && getHeaderImg[0].fields.file[this.props.appLanguage].url) {
//       pageMainImage = 'https:' + getHeaderImg[0].fields.file[this.props.appLanguage].url
//       pageMainImageAlt = getHeaderImg[0].fields.description[this.props.appLanguage]
//     } */

//     if (getHeaderImg && getHeaderImg.length > 0) {
//       const imageUrl = getHeaderImg[0].fields.file?.[this.props.appLanguage]?.url || getHeaderImg[0].fields.file?.['es-VE']?.url;
//       pageMainImage = 'https:' + (imageUrl || '');

//       console.log("Final Image URL:", pageMainImage);

//       pageMainImageAlt = getHeaderImg[0].fields.description?.[this.props.appLanguage] || getHeaderImg[0].fields.description?.['es-VE'] || '';

//     }
  


//     this.setState({
//       title: title1,
//       text: text1,
//       btnText: ctaCopy,
//       headerImage: pageMainImage,
//       headerImageAlt: pageMainImageAlt,
//     })


//   } // componentDidMount()



//   showCarousel = () => {
//     this.setState({
//       page: 2,
//     })
//   }

//   nextSlide = (selectedIndex, e) => {
//     // window.scrollTo(0, 0)
//     e.preventDefault()

//     this.setState({
//       carouselIndex: selectedIndex,
//     })
//   };

//   goBack = () => {
//     this.props.history.goBack()
//   }

//   render() {

//     var bellColor = ''
//     var menuIconColor = ''
//     if (this.state.page === 1) {
//       bellColor = 'blue'
//       menuIconColor = 'blue'
//     } else {
//       bellColor = 'blue'
//       menuIconColor = 'blue'
//     }

//     return (
//       <div className="minPageHeight100vh flexColumn flexGrow1 selfCarePage">
        
//         <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={menuIconColor} logoColor={'peach'} bellColor={bellColor} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

//         { this.renderChildren() } {/* Down below */}

//       </div>
//     )
//   }


//   renderChildren() {
//     const { page, headerImage, headerImageAlt, title, text, btnText } = this.state

//     if (page === 1) {
//       return (
//         <div className='pageWithBottomMainNav flexColumn flexGrow1 textAlignCenter paddingTop125 paddingLeftRight35 backgroundColorWhite page1'>
//            {/* <button onClick={() => this.goBack()} className='prevBtnTop1 marginLeft60 positionAbsolute marginLeft25 paddingTop24PercentVw'>
//                     <img src={prevBtnImgBlue} className='width88Percent prevImg' alt='back' />
//         </button> */}
//           <img src={headerImage} className='width65Percent selfcareMenuImage marginBottom30 marginLeftRightAuto' alt={headerImageAlt} />

//           <div className='paddingLeftRight15'>
//             <ContentfulMarkdown children={title} className='boldFont fontSize17 lineHeight20 colorBlue8 marginBottom16 marginLeftRightAuto psMargin0 contentfulMarkdown' />

//             <ContentfulMarkdown children={text} className='regularFont fontSize15 lineHeight20 colorBlue8 marginBottom30 psMargin0 contentfulMarkdown' />

//             <LargeButton
//               largeButtonFunc={() => this.showCarousel()}
//               text={btnText}
//               textColor={'colorBlue8'}
//               backgroundColor={'backgroundColorPink'}
//               />
//             <WHOLink 
//               fontColor="#071f56"
//               appLanguage={this.props.appLanguage}
//               {...this.props} 
//             /> {/* This is the WHO link that is necessary for iOS app store */}
//             </div>
//             {/* <BottomNavBar appLanguage={this.props.appLanguage} /> */}
//         </div>

//         )
//     } else {
//       return (
//         <div className='pageWithBottomMainNav flexColumn flexGrow1 backgroundColorWhite'>
//           <Carousel
//             activeIndex={this.state.carouselIndex}
//             // defaultActiveIndex={0}
//             interval={100000000000000}
//             controls={false}
//             indicators={true}
//             onSelect={this.nextSlide}
//           >
//             <Carousel.Item>
//               <SelfCareSlide1 appLanguage={this.props.appLanguage} />
//             </Carousel.Item>

//             <Carousel.Item>
//               <SelfCareSlide2 appLanguage={this.props.appLanguage} />
//             </Carousel.Item>

//             <Carousel.Item>
//               <SelfCareSlide3 appLanguage={this.props.appLanguage} />
//             </Carousel.Item>

//             <Carousel.Item>
//               <SelfCareSlide4 appLanguage={this.props.appLanguage} history={this.props.history} />
//             </Carousel.Item>
//           </Carousel>
//           <div className='marginBottom50'></div>

//           <BottomNavBar appLanguage={this.props.appLanguage} />
//         </div>
//       )
//     }
//   }
// }



import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import 'bootstrap/dist/css/bootstrap.min.css';
import { TopNavBar } from '../../Components/TopNavBar'
/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */
import * as ContentfulMarkdown from 'react-markdown'
import { LargeButton } from '../../Components/LargeButton'
import { SelfCareSlide1 } from './SelfCareSlide1'
import { SelfCareSlide2 } from './SelfCareSlide2'
import { SelfCareSlide3 } from './SelfCareSlide3'
import { SelfCareSlide4 } from './SelfCareSlide4'
import { BottomNavBar } from '../../Components/BottomNavBar'
import ReactGA from 'react-ga4';
import { WHOLink } from '../../Components/WHOLink';


export class SelfCareAdvice extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1,
      headerImage: '',
      headerImageAlt: '',
      title: '',
      text: '',
      btnText: '',
      carouselIndex: 0,
    }
  }


  componentDidMount() {
    window.scrollTo(0, 0)
    ReactGA.send("/self-care-advice")

    document.title = "Self Care Advice"

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */


    const getEntries = localStorage.getItem('ayaAppEntries')
    const entries = JSON.parse(getEntries)
    // Content type ID
    const getContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'sectionBeginningendingScreen');
    // Then Entry ID instead
    const getStepEntries = getContentEntries.filter(obj => obj.sys.id === '7kmBNio8rEnz06SMZIoUPA');

    var selfCareEntries = []
    var title1 = ''
    var text1 = ''
    var ctaCopy = ''

    if (getStepEntries && getStepEntries.length > 0) {
      selfCareEntries.push(getStepEntries[0].fields)

      if (selfCareEntries.length > 0) {

        if (selfCareEntries[0].title) {
          title1 = selfCareEntries[0].title[this.props.appLanguage]
        }
        if (selfCareEntries[0].paragraph) {
          text1 = selfCareEntries[0].paragraph[this.props.appLanguage]
        }
        if (selfCareEntries[0].ctaCopy1) {
          ctaCopy = selfCareEntries[0].ctaCopy1[this.props.appLanguage]
        }
      }
    }



    // Get page Assets
    const getAssets = localStorage.getItem('ayaAppAssets')
    const assets = JSON.parse(getAssets)

    const getHeaderImg = assets.filter(obj => obj.sys.id === '6mFKkxO7C6PQWuywQtGr91');

    var pageMainImage = ''
    var pageMainImageAlt = ''

  /*   if (getHeaderImg && getHeaderImg.length > 0 && getHeaderImg[0].fields.file[this.props.appLanguage].url) {
      pageMainImage = 'https:' + getHeaderImg[0].fields.file[this.props.appLanguage].url
      pageMainImageAlt = getHeaderImg[0].fields.description[this.props.appLanguage]
    } */

    if (getHeaderImg && getHeaderImg.length > 0) {
      const imageUrl = getHeaderImg[0].fields.file?.[this.props.appLanguage]?.url || getHeaderImg[0].fields.file?.['es-VE']?.url;
      pageMainImage = 'https:' + (imageUrl || '');

      console.log("Final Image URL:", pageMainImage);

      pageMainImageAlt = getHeaderImg[0].fields.description?.[this.props.appLanguage] || getHeaderImg[0].fields.description?.['es-VE'] || '';

    }
  


    this.setState({
      title: title1,
      text: text1,
      btnText: ctaCopy,
      headerImage: pageMainImage,
      headerImageAlt: pageMainImageAlt,
    })


  } // componentDidMount()



  showCarousel = () => {
    this.setState({
      page: 2,
    })
  }

  nextSlide = (selectedIndex, e) => {
    // window.scrollTo(0, 0)
    e.preventDefault()

    this.setState({
      carouselIndex: selectedIndex,
    })
  };

  goBack = () => {
    this.props.history.goBack()
  }

  render() {
    var bellColor = '';
    var menuIconColor = '';
    if (this.state.page === 1) {
      bellColor = 'blue';
      menuIconColor = 'blue';
    } else {
      bellColor = 'blue';
      menuIconColor = 'blue';
    }

    return (
      <div className="minPageHeight100vh flexColumn flexGrow1 selfCarePage">
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={menuIconColor} logoColor={'peach'} bellColor={bellColor} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

        {this.state.page === 1 && (
          <div className='pageWithBottomMainNav flexColumn flexGrow1 textAlignCenter paddingTop125 paddingLeftRight35 backgroundColorWhite page1'>
            <img src={this.state.headerImage} className='width65Percent selfcareMenuImage marginBottom30 marginLeftRightAuto' alt={this.state.headerImageAlt} />

            <div className='paddingLeftRight15'>
              <ContentfulMarkdown children={this.state.title} className='boldFont fontSize17 lineHeight20 colorBlue8 marginBottom16 marginLeftRightAuto psMargin0 contentfulMarkdown' />

              <ContentfulMarkdown children={this.state.text} className='regularFont fontSize15 lineHeight20 colorBlue8 marginBottom30 psMargin0 contentfulMarkdown' />

              <LargeButton
                largeButtonFunc={() => this.showCarousel()}
                text={this.state.btnText}
                textColor={'colorBlue8'}
                backgroundColor={'backgroundColorPink'}
              />
              <WHOLink
                fontColor="#071f56"
                appLanguage={this.props.appLanguage}
                {...this.props}
              /> {/* This is the WHO link that is necessary for iOS app store */}
            </div>
            <div style={{ position: 'fixed', bottom: '0', width: '100%' }} className='bottomNavBarTwo'>
              <BottomNavBar appLanguage={this.props.appLanguage} /> {/* Position it at the bottom */}
            </div>
          </div>
        )}

        {this.state.page === 1 ? null : (
          <div className='pageWithBottomMainNav flexColumn flexGrow1 backgroundColorWhite'>
            <Carousel
              activeIndex={this.state.carouselIndex}
              // defaultActiveIndex={0}
              interval={100000000000000}
              controls={false}
              indicators={true}
              onSelect={this.nextSlide}
            >
              <Carousel.Item>
                <SelfCareSlide1 appLanguage={this.props.appLanguage} />
              </Carousel.Item>

              <Carousel.Item>
                <SelfCareSlide2 appLanguage={this.props.appLanguage} />
              </Carousel.Item>

              <Carousel.Item>
                <SelfCareSlide3 appLanguage={this.props.appLanguage} />
              </Carousel.Item>

              <Carousel.Item>
                <SelfCareSlide4 appLanguage={this.props.appLanguage} history={this.props.history} />
              </Carousel.Item>
            </Carousel>
          </div>
        )}
      </div>
    );
  }
}