import React from 'react';
import ReactGA from 'react-ga4';
import { TopNavBar } from '../../Components/TopNavBar';
import importantIconPeach from '../../Images/important-outline-icon-peach.svg'
import * as ContentfulMarkdown from 'react-markdown'
import { LargeButton } from '../../Components/LargeButton'
/* import prevBtnImg from '../../Images/prevBtnImage-whiteAndBlue.svg' */
import messageIcon from '../../Images/message-icon-white.svg'
import { WHOLink } from '../../Components/WHOLink';


export class AbortionAssessmentFailed extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            title: '',
            allSelectedAnswers: [],
            paragraph: '',
            btnText: '',
        }
    }


    componentDidMount() {
        ReactGA.send("/abortion-assessment-failed")
        window.scrollTo(0, 0)

        document.title = "Abortion Assessment Failed"
                        
        
        // this shows the Crisp Chat 
       /*  $crisp.push(["do", "chat:show"]) */

        const checkPageAccess = localStorage.getItem('isAbortionAssessmentComplete')
        if (checkPageAccess !== 'true') {
            this.props.history.goBack()
        }

        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)
        // Content type ID
        const getContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'alert');
        // Then Entry ID instead
        const getpageEntries = getContentEntries.filter(obj => obj.sys.id === '4j3xuhWjdETMm6kN3kP4Rb');

        var pageEntries = []
        var title1 = ''
        var paragraph1 = ''
        var ctaCopy = ''

        if (getpageEntries && getpageEntries.length > 0) {
            pageEntries.push(getpageEntries[0].fields)

            if (pageEntries.length > 0) {

                if (pageEntries[0].title) {
                    title1 = pageEntries[0].title[this.props.appLanguage]
                }
                if (pageEntries[0].paragraph) {
                    paragraph1 = pageEntries[0].paragraph[this.props.appLanguage]
                }
                if (pageEntries[0].ctaCopy) {
                    ctaCopy = pageEntries[0].ctaCopy[this.props.appLanguage]
                }
            }
        }


        // Get selected answers
        const getQ2SelectedAnswers = localStorage.getItem('abortionAssessmentQ2SelectedAnswers')
        const getQ3SelectedAnswers = localStorage.getItem('abortionAssessmentQ3SelectedAnswers')
        const getQ4SelectedAnswers = localStorage.getItem('abortionAssessmentQ4SelectedAnswers')

        var q2SelectedAnswers = ''
        if (getQ2SelectedAnswers.length !== 0) {
            q2SelectedAnswers = getQ2SelectedAnswers.split('.,')
        }
        var q3SelectedAnswers = ''
        if (getQ2SelectedAnswers.length !== 0) {
            q3SelectedAnswers = getQ3SelectedAnswers.split('.,')
        }
        var q4SelectedAnswers = ''
        if (getQ2SelectedAnswers.length !== 0) {
            q4SelectedAnswers = getQ4SelectedAnswers.split('.,')
        }

        var allSelectedAnswers1 = []
        allSelectedAnswers1 = allSelectedAnswers1.concat(q2SelectedAnswers, q3SelectedAnswers, q4SelectedAnswers)

        allSelectedAnswers1 = allSelectedAnswers1.filter(function (item) {
            return item !== "";
        });
        console.log(allSelectedAnswers1)


        this.setState({
            title: title1,
            allSelectedAnswers: allSelectedAnswers1,
            paragraph: paragraph1,
            btnText: ctaCopy
        })
    } // componentDidMount()



    goBack = () => {
        this.props.history.goBack()
    }

    goToFeedback = () => {
        this.props.history.push('/feedback')
    }

    render() {

        const { title, allSelectedAnswers, paragraph, btnText } = this.state

        const selectedAnswersContent = allSelectedAnswers.map((string, i) =>
            <div className='flexRow textAlignLeft marginBottom10' id={i} key={i}>
                <div>
                    <input
                        type='checkbox'
                        checked={true}
                        className='flexColumn justifyCenter alignCenter marginRight10 backgroundColorBlue8 radioCheckPeach mediumRadioBtn'
                        // onChange={() => this.toggleRadio(info.stateName)}
                        readOnly
                    />
                </div>
                <span className='regularFont fontSize13 lineHeight17 colorBlue8'>{string}</span>
            </div>
        )

        return (
            <div className="minPageHeight100vh flexColumn flexGrow1 backgroundColorWhite">
                 
                <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />
                <div className='paddingBottom20'></div>
                <div className='flexColumn flexGrow1 justifyCenter alignCenter textAlignCenter marginTop54 landscapeWidth67Percent landscapeMarginTop95 pagesInnerDiv'>

                    <img src={importantIconPeach} className='width52Height52 marginTop12 marginLeftRightAuto marginBottom25' alt='!' />

                    <ContentfulMarkdown children={title} className='boldFont fontSize20 lineHeight18 colorBlue8 psMargin0 paddingLeftRight40 marginBottom20 contentfulMarkdown' />

                    <div className='paddingLeftRight20'>
                        <div className='width100Percent borderWidth1 borderColorBlue8 borderRadius20 padding10-20-0 marginBottom20'>
                            {selectedAnswersContent}
                        </div>
                    </div>

                    <ContentfulMarkdown children={paragraph} className='boldFont fontSize20 lineHeight18 colorPeach3 psMargin0 paddingLeftRight40 marginBottom30 contentfulMarkdown' />

                    {/* <LargeButton
                        largeButtonFunc={() => this.goToFeedback()}
                        text={<p className='width100Percent flexRow justifyCenter alignCenter marginBottom0'>
                            <img src={messageIcon} style={{ width: '18px', height: 'auto', marginRight: '10px' }} alt='' />
                            {btnText}
                        </p>}
                        textColor={'colorWhite'}
                        backgroundColor={'backgroundColorBlue8'}
                    /> */}


                    <WHOLink 
                        fontColor="#071f56"
                        appLanguage={this.props.appLanguage}
                        {...this.props} 
                    /> {/* This is the WHO link that is necessary for iOS app store */}
                </div>

               
            </div>
        )
    }
}
