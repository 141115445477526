import styles from './PlanC.modules.css'
import BasicAccordion from '../../Components/BasicAccordion'
import { useState, useEffect } from 'react'
import { BottomNavBar } from '../../Components/BottomNavBar'
import  { TopNavBar }  from '../../Components/TopNavBar';
import ReactGA from 'react-ga4';



function PlanC(props) {
	const [pageTitleOne, setPageTitleOne] = useState('')
	const [pageDescriptionOne, setPageDescriptionOne] = useState('')
	const [accordionTextOne, setAccordionTextOne] = useState('')
	const [accordionContentOne, setAccordionContentOne] = useState('')
	const [accordionTextTwo, setAccordionTextTwo] = useState('')
	const [accordionContentTwo, setAccordionContentTwo] = useState('')
	const [accordionTextThree, setAccordionTextThree] = useState('')
	const [accordionContentThree, setAccordionContentThree] = useState('')
	const [accordionTextFour, setAccordionTextFour] = useState('')
	const [accordionContentFour, setAccordionContentFour] = useState('')
	const [accordionTextFive, setAccordionTextFive] = useState('')
	const [accordionContentFive, setAccordionContentFive] = useState('')
	const [lastButton, setLastButton] = useState('')
	const [lastButtonArrow, setLastButtonArrow] = useState('');
	const [arrowIconOne, setArrowIconOne] = useState('');
	const [buttonStepOne, setButtonStepOne] = useState('');
	const [buttonStepFour, setButtonStepFour] = useState('');




	useEffect(() => {
	// GA4 code
	ReactGA.send("/resources-plan-c");
	//this makes sure the page always scrolls to the top of the page when rendered
	window.scrollTo(0, 0); 

	document.title = "Resources Plan C";
	
        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

		// GET ALL ENTRIES
		const getEntries = localStorage.getItem('ayaAppEntries');
    const entries = JSON.parse(getEntries);

		// GET MODEL
		const getModel = entries.filter(
      (obj) => obj.sys.contentType.sys.id === "resourcesStepByStep"
    );
		
		// GET CONTENT
		const getContent = getModel.filter(
		(obj) => obj.sys.id === "2Ki4SsL70U1CTUtXOqgOUS"
		)


		// GET 	PLAN C PAGE title
			let pageTitle = ''
				if (getContent?.length > 0) {
					const pageTitleData = getContent[0].fields;
					pageTitle = pageTitleData.title? pageTitleData.title[props.appLanguage] : '';
				}
				setPageTitleOne(pageTitle);
	


		// GET PAGE description
			let pageDescription = ''
			if (getContent?.length > 0) {
				const pageDescriptionData = getContent[0].fields;
				pageDescription = pageDescriptionData.description? pageDescriptionData.description[props.appLanguage] : '';
			}
			setPageDescriptionOne(pageDescription);



		// GET ACCORDION TITLE TEXT ONE
			let titleTextOne = ''
			if (getContent?.length > 0) {
				const accordionTextData = getContent[0].fields;
				titleTextOne = accordionTextData.step1Title ? accordionTextData.step1Title[props.appLanguage] : '';
			}
			setAccordionTextOne(titleTextOne)


		// GET ACCORDION ONE content
		let accordionContent1 = ''

		if (getContent?.length > 0) {
			const accordionContentData = getContent[0].fields;
			accordionContent1 = accordionContentData.descriptionStep1  ? accordionContentData.descriptionStep1[props.appLanguage] : '';
		}
		setAccordionContentOne(accordionContent1)


		
		// GET ACCORDION TEXT TWO
			let titleTextTwo = ''
			if (getContent?.length > 0) {
				const accordionTextData = getContent[0].fields;
				titleTextTwo = accordionTextData.step2Title ? accordionTextData.step2Title[props.appLanguage] : '';
			}
			setAccordionTextTwo(titleTextTwo)


			
		// GET ACCORDION content TWO
		let accordionContent2 = ''

		if (getContent?.length > 0) {
			const accordionContentData = getContent[0].fields;
			accordionContent2 = accordionContentData.descriptionStep2  ? accordionContentData.descriptionStep2[props.appLanguage] : '';
		}
		setAccordionContentTwo(accordionContent2)



		// GET ACCORDION TEXT THREE
			let titleTextThree = ''
			if (getContent?.length > 0) {
				const accordionTextData = getContent[0].fields;
				titleTextThree = accordionTextData.step3Title ? accordionTextData.step3Title[props.appLanguage] : '';
			}
			setAccordionTextThree(titleTextThree)



		// GET ACCORDION content THREE 
		let accordionContent3 = ''

		if (getContent?.length > 0) {
			const accordionContentData = getContent[0].fields;
			accordionContent3 = accordionContentData.descriptionStep3  ? accordionContentData.descriptionStep3[props.appLanguage] : '';
		}
		setAccordionContentThree(accordionContent3)



		// GET ACCORDION TEXT FOUR
			let titleTextFour = ''
			if (getContent?.length > 0) {
				const accordionTextData = getContent[0].fields;
				titleTextFour = accordionTextData.step4Title ? accordionTextData.step4Title[props.appLanguage] : '';
			}
			setAccordionTextFour(titleTextFour)



		// GET ACCORDION content FOUR 
		let accordionContent4 = ''

		if (getContent?.length > 0) {
			const accordionContentData = getContent[0].fields;
			accordionContent4 = accordionContentData.descriptionStep4  ? accordionContentData.descriptionStep4[props.appLanguage] : '';
		}
		setAccordionContentFour(accordionContent4)



		// GET ACCORDION TEXT FIVE
			let titleTextFive = ''
			if (getContent?.length > 0) {
				const accordionTextData = getContent[0].fields;
				titleTextFive = accordionTextData.step5Title ? accordionTextData.step5Title[props.appLanguage] : '';
			}
			setAccordionTextFive(titleTextFive)



		// GET ACCORDION content FIVE
		let accordionContent5 = ''

		if (getContent?.length > 0) {
			const accordionContentData = getContent[0].fields;
			accordionContent5 = accordionContentData.descriptionStep5  ? accordionContentData.descriptionStep5[props.appLanguage] : '';
		}
		setAccordionContentFive(accordionContent5)



		// GET LAST button TEXT
		let lastButton1 = ''

		if (getContent?.length > 0) {
			const lastButtonData = getContent[0].fields;
			lastButton1 = lastButtonData.lastButton  ? lastButtonData.lastButton[props.appLanguage] : '';
		}
		setLastButton(lastButton1)



		// GET BUTTON STEP ONE text
		let buttonStepOneText = ''

		if (getContent?.length > 0) {
			const buttonStepOneData = getContent[0].fields;
			buttonStepOneText = buttonStepOneData.buttonStep1 ? buttonStepOneData.buttonStep1[props.appLanguage] : '';
		}
		setButtonStepOne(buttonStepOneText)



		// GET BUTTON STEP Four text
		let buttonStepFourText = ''

		if (getContent?.length > 0) {
			const buttonStepFourData = getContent[0].fields;
			buttonStepFourText = buttonStepFourData.buttonStep4 ? buttonStepFourData.buttonStep4[props.appLanguage] : '';
		}
		setButtonStepFour(buttonStepFourText)




		// ASSETS
		// GET ALL ASSETS
		const getAssets = localStorage.getItem('ayaAppAssets');
    const assets = JSON.parse(getAssets);



		// GET WHITE ARROW ICON (last Button arrow)
		const getWhiteArrowIcon = assets.filter(
      obj => obj.sys.id === '1H7uB5gW6dKQU9jin0AmK6'
    );

    let whiteArrowIcon = '';
    if (getWhiteArrowIcon?.length > 0 && getWhiteArrowIcon[0]?.fields?.file?.[props.appLanguage]?.url) {
			whiteArrowIcon = 'https:' + getWhiteArrowIcon[0].fields.file[props.appLanguage].url;
		}
    setLastButtonArrow(whiteArrowIcon);



		// GET ARROW ICON
		const getArrowIcon = assets.filter(
      obj => obj.sys.id === '3Xegltxk8ROHntvPdStc3A'
    );

    let arrowIcon = '';
    if (getArrowIcon?.length > 0 && getArrowIcon[0]?.fields?.file?.[props.appLanguage]?.url) {
			arrowIcon = 'https:' + getArrowIcon[0].fields.file[props.appLanguage].url;
		}
    setArrowIconOne(arrowIcon);



		
	}, [props.appLanguage])



	const language = props.appLanguage;
	let linkOne;
  if (language === 'en') {
    linkOne = 'https://www.plancpills.org/';
  } else if (language === 'es-US') {
    linkOne = 'https://es.plancpills.org/';
  }

	return (
		<>
		<TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...props} appLanguage={props.appLanguage} />

		<div className='planCContainer' style={{ backgroundColor: 'white' }}>
			
		<div style={{maxWidth: '340px'}}>
			<div className='plan-c-title'>{pageTitleOne}</div>
			<div className='plan-c-page-description'>	{pageDescriptionOne}</div>
			<div className='cardsContainer'>

			<BasicAccordion
				heading={accordionTextOne}
				content={
					<>
						{accordionContentOne}
						<div
							style={{
								backgroundColor: '#D9D9D9',
								borderRadius: '12px',
								fontFamily: 'GTWalsheimPro-Regular, sans-serif',
								display: 'flex', 
								alignItems: 'center', 
								justifyContent: 'space-between',
								padding: '10px',
								margin: '10px 0'
							}}
						>
							<a
								href={linkOne}
								target="_blank"
								rel="noopener noreferrer"
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									textDecoration: 'none',
									color: '#051D58',
									width: '100%' 
								}}
							>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									{buttonStepOne}
								</div>
								<div style={{ marginLeft: 'auto' }}>
									<img src={arrowIconOne} alt="Arrow" style={{ marginLeft: '10px' }} /> 
								</div>
							</a>
						</div>
					</>
				}
				headingStyle={{
					color: 'white',
					fontSize: '14px',
					fontFamily: 'GTWalsheimPro-Bold, sans-serif'
				}}
				contentStyle={{
					color: '#524F4F',
					fontSize: '14px',
					fontFamily: 'GTWalsheimPro-Regular, sans-serif'
				}}
			/>

				<div className='accordion-break'></div>
        <BasicAccordion
          heading={accordionTextTwo}
          content={accordionContentTwo}
          headingStyle={{ color: 'white', fontSize: '14px', fontFamily: 'GTWalsheimPro-Bold, sans-serif' }}
          contentStyle={{ color: '#524F4F', fontSize: '14px', fontFamily: 'GTWalsheimPro-Regular, sans-serif'}}
        />

				<div className='accordion-break'></div>
        <BasicAccordion
          heading={accordionTextThree}
          content={accordionContentThree}
          headingStyle={{ color: 'white', fontSize: '14px', fontFamily: 'GTWalsheimPro-Bold, sans-serif' }}
          contentStyle={{ color: '#524F4F', fontSize: '14px', fontFamily: 'GTWalsheimPro-Regular, sans-serif' }}
        />

				<div className='accordion-break'></div>
				<BasicAccordion
					heading={accordionTextFour}
					content={
						<>
							{accordionContentFour}
							<div
								style={{
									backgroundColor: '#D9D9D9',
									borderRadius: '12px',
									fontFamily: 'GTWalsheimPro-Regular, sans-serif',
									display: 'flex', 
									alignItems: 'center', 
									justifyContent: 'space-between', 
									padding: '10px',
									margin: '10px 0'
								}}
							>
								<a
									href="https://es.plancpills.org/support-resources#support-legal"
									target="_blank"
									rel="noopener noreferrer"
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
										textDecoration: 'none',
										color: '#051D58',
										width: '100%' 
									}}
								>
									{buttonStepFour}
									<div style={{ marginLeft: 'auto' }}> 
										<img src={arrowIconOne} alt="Arrow" /> 
									</div>
								</a>
							</div>
						</>
					}
					headingStyle={{
						color: 'white',
						fontSize: '14px',
						fontFamily: 'GTWalsheimPro-Bold, sans-serif'
					}}
					contentStyle={{
						color: '#524F4F',
						fontSize: '14px',
						fontFamily: 'GTWalsheimPro-Regular, sans-serif'
					}}
				/>

        <div className='accordion-break'></div>
        <BasicAccordion
          heading={accordionTextFive}
          content={accordionContentFive}
          headingStyle={{ color: 'white', fontSize: '14px', fontFamily: 'GTWalsheimPro-Bold, sans-serif' }}
          contentStyle={{ color: '#524F4F', fontSize: '14px', fontFamily: 'GTWalsheimPro-Regular, sans-serif' }}
        />
			</div>

			<div className='last-button'>
  				<a href='/resources-more-info'>
   					 {lastButton}
   				 <img src={lastButtonArrow} alt='Arrow' className='arrow-image' />
  				</a>
			</div>

			</div>

				<div className='end-of-page'></div>
				<BottomNavBar appLanguage={props.appLanguage} />
		</div>
		</>
	)
}

export default PlanC


