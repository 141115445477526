import React from 'react';
import { TopNavBar } from '../../Components/TopNavBar'
import * as ContentfulMarkdown from 'react-markdown'
import { LargeButton } from '../../Components/LargeButton'
/* import prevBtnImg from '../../Images/prevBtnImage-whiteAndBlue.svg' */
/* import prevBtnImg from '../../Images/prevBtnImg-white.svg'; */
/* import { WHOLink } from '../../Components/WHOLink' */



export class SingleContraceptionAssessmentWithYesNO extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            questionYesSelected: false,
            questionNoSelected: false,
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */
    }




    toggleRadio = (currentSelectedStateName, otherStateName) => {
        // Get the state name of the radio selected for updates and update it's counter part radio state name
        var getSelectedStateName = this.state[currentSelectedStateName]

        if (getSelectedStateName === true) {
            this.setState({
                [currentSelectedStateName]: false,
                [otherStateName]: true
            })
        } else if (getSelectedStateName === false) {
            this.setState({
                [currentSelectedStateName]: true,
                [otherStateName]: false
            })
        }

    }



    continue = () => {
        if (this.state.questionYesSelected === true) {
            this.props.continue('yes')
        } else if (this.state.questionNoSelected === true) {
            this.props.continue('no')
        }
    }



    goBack = () => {
        this.props.history.goBack()
    }




    render() {

        const { content } = this.props

        return (
            <div className='minPageHeight100vh flexGrow1 alignCenter backgroundColorPeach1 contraceptionAssessmentPage'>
                
                <TopNavBar bgColor="#F77478" showHamburgerMenu={true} menuIconColor={'white'} logoColor={'blue'} bellColor={'white'} newNotificationColor={'blue'} {...this.props} appLanguage={this.props.appLanguage} />

                <div className='flexColumn flexGrow1 justifyCenter alignCenter textAlignCenter paddingTop80'>
                    <div className={this.props.questionWidth + ' landscapeWidth67Percent'}>
                        <ContentfulMarkdown children={content.stepNumber} className='boldFont fontSize12 lineHeight14 colorBlue8 marginBottom32 contentfulMarkdown' />

                        <ContentfulMarkdown children={content.question} className='boldFont fontSize22 lineHeight25 colorWhite psMargin0 marginBottom30 contentfulMarkdown' />
                    </div>

                    {/* <form className='flexColumn textAlignLeft'> */}
                    <form className='width78Percent landscapeWidth67Percent flexColumn textAlignLeft regularFont fontSize15 lineHeight18 colorWhite marginBottom30 answerDiv'>
                            <div className='flexRow alignCenter marginBottom10'>
                                <input
                                    type='radio'
                                    name={'question1'}
                                    checked={this.state.questionYesSelected}
                                    className='flexColumn justifyCenter alignCenter marginRight10 backgroundColorNone radioCheckBlue mediumRadioBtn'
                                    onChange={() => this.toggleRadio('questionYesSelected', 'questionNoSelected')}
                                />
                                <span className='lineHeight15'>{content.yesOptionText}</span>
                            </div>
                            <div className='flexRow alignCenter'>
                                <input
                                    type='radio'
                                    name={'question1'}
                                    checked={this.state.questionNoSelected}
                                    className='flexColumn justifyCenter alignCenter marginRight10 backgroundColorNone radioCheckBlue mediumRadioBtn'
                                    onChange={() => this.toggleRadio('questionNoSelected', 'questionYesSelected')}
                                />
                                <span className='lineHeight15'>{content.noOptionText}</span>
                            </div>
                        </form>
                    {/* </form> */}

                        <LargeButton
                            largeButtonFunc={() => this.continue()}
                            text={content.btn1Text}
                            textColor={'colorBlue8'}
                            backgroundColor={'backgroundColorWhite'}
                        />

{/*                         <WHOLink 
                        fontColor="#fff"
                        appLanguage={this.props.appLanguage}
                        {...this.props} />  */}
                </div>

                {/* <button onClick={() => this.goBack()} className='width30Height30 margin20 justifySelfFlexEnd alignSelfFlexStart'>
                    <img src={prevBtnImg} className='width100Percent prevImg' alt='back' />
                </button> */}
            </div>
        )
    }
}
