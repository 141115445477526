import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function BasicAccordion(props) {
  const { heading, content, expanded, onChange, disabled, headingStyle, contentStyle } = props;

  return (
    <Accordion expanded={expanded} onChange={onChange} disabled={disabled}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: 'white', transform: expanded ? 'rotate(270deg)' : 'rotate(0deg)' }} />}
        aria-controls="panel-content"
        id="panel-header"
        sx={{
          backgroundColor: '#051D58',
          color: 'white',
          borderRadius: '8px 8px 0 0',
          display: 'flex',
          flexDirection: 'row-reverse',
          alignItems: 'center',
          paddingLeft: '16px',
        }}
      >
        <Typography style={headingStyle}>{heading}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: '#F1F1F1', padding: '16px',}}>
        <Typography style={contentStyle}>
          {content}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default BasicAccordion;


