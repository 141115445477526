import React from 'react'
import ReactGA from 'react-ga4'
import * as ContentfulMarkdown from 'react-markdown'

import { TopNavBar } from '../../Components/TopNavBar'
// import { LargeButton } from '../../Components/LargeButton'
/* import { PrevAndNextBottomNav } from '../../Components/PrevAndNextBottomNav' */
import { BottomNavBar } from '../../Components/BottomNavBar'

/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */


export class UserSecurity extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            image1: '',
            title: '',
            paragraph1: '',
            btn1Text: '',
        }
    }


    componentDidMount() {
        ReactGA.send("/user-security")
        window.scrollTo(0, 0)

        document.title = "User Security"

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

        // Get page Entries
        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)

        const getSecurityEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'userSecurity');


        // Get page Assets
        const getAssets = localStorage.getItem('ayaAppAssets')
        const assets = JSON.parse(getAssets)

        const getMainImage = assets.filter(obj => obj.fields.title[this.props.appLanguage] === 'CYBERSECURITY-02 (1)');

        let mainImage = ''

        if (getMainImage && getMainImage.length > 0 && getMainImage[0].fields.file[this.props.appLanguage].url) {
            mainImage = 'https:' + getMainImage[0].fields.file[this.props.appLanguage].url
        }



        let securityEntries = []
        let getTitle = ''
        let getParagraph1 = ''
        let getBtn1Text = ''

        if (getSecurityEntries && getSecurityEntries.length > 0) {
            securityEntries.push(getSecurityEntries[0].fields)

            if (securityEntries.length > 0) {

                if (securityEntries[0].heading) {
                    getTitle = securityEntries[0].heading[this.props.appLanguage]
                }
                if (securityEntries[0].paragraph) {
                    getParagraph1 = securityEntries[0].paragraph[this.props.appLanguage]
                }
                if (securityEntries[0].buttonText) {
                    getBtn1Text = securityEntries[0].buttonText[this.props.appLanguage]
                }

                this.setState({
                    image1: mainImage,
                    title: getTitle,
                    paragraph1: getParagraph1,
                    btn1Text: getBtn1Text,
                })
            }
        }
    }

    goBack = () => {
        this.props.history.goBack()
      }

  

    render() {
        let privacyLink;
        if (this.props.appLanguage === 'en') {
            privacyLink = 'https://digitaldefensefund.org/ddf-guides/abortion-privacy';
        } else if (this.props.appLanguage === 'es-US') {
            privacyLink = 'https://digitaldefensefund.org/privacidad-del-aborto';
        }

        return (
            <div className="minPageHeight100vh flexColumn flexGrow1 pageWithBottomMainNav userSecurityPage">
               
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

                <div className='pagesInnerDiv paddingTop60'>
                    <div className='paddingTop80 justText headerArea'>
                        <div className='padding0-30-57 textAlignCenter'>
                            <img src={this.state.image1} style={{ width: '145px', height: 'auto' }} alt="nav header"/>
                        </div>
                    </div>

                    <div className='flexGrow1 backgroundColorWhite topRoundDiv'>
                        <div className='topRoundDivInnerDiv'>
                            <div className='padding56-21-30-34'>
                                <ContentfulMarkdown children={this.state.title} className='boldFont fontSize20 lineHeight26 colorBlue8' allowDangerousHtml={true} />

                                <ContentfulMarkdown children={this.state.paragraph1} className='regularFont fontSize12 lineHeight15 colorBlack3 marginBottom10' allowDangerousHtml={true} />

                                <a href={privacyLink} className='flexRow justifyCenter marginBottom15' /* href={`https://www.hola.ayacontigo.org/cuidados-digitales`} */ target='_blank' rel='noopener noreferrer'>
                                    <button className='backgroundColorBlue8 textAlignCenter largeButton'>
                                        <span className='boldFont fontSize15 lineHeight26 colorWhite'>{this.state.btn1Text}</span>
                                    </button>
                                </a>
                            </div>

                            {/* <div className='paddingLeftRight16'>
                                <PrevAndNextBottomNav
                                    showPrevButton={true}
                                    prevBtnFunc={() => this.props.history.goBack()}
                                    blue
                                    showNextBtn={false}
                                    nextBtnFunc={null}
                                />
                            </div> */}
                        </div>
                    </div>

                </div> {/* pagesInnerDiv */}

                <BottomNavBar appLanguage={this.props.appLanguage} />
            </div>
        )
    }
}
