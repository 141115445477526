import React from 'react';
import ReactGA from 'react-ga4'
import * as ContentfulMarkdown from 'react-markdown'
import { TopNavBar } from '../../Components/TopNavBar'
import { BottomNavBar } from '../../Components/BottomNavBar'
import { LargeButton } from '../../Components/LargeButton'
import messageIcon from '../../Images/message-icon-white.svg'
import btn1Image from '../../Images/noun_Network_1380081.svg'
/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */
/* import { WHOLink } from '../../Components/WHOLink'; */
import technicalSupportIcon from '../../Images/technical-support-icon.svg'


/* Retroalimentación */
export class Feedback extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      title: '',
      contactHeadingText: '',
      contactParagraph1: '',
      appChatImg: '',
      contactParagraph2: '',
/*       whatsAppImg: '', */
      contactParagraph3: '',
      contactParagraph4: '',
      contactBtnText1: '',
      feedbackHeading: '',
      feedbackSubHeadingText: '',
      feedbackParagraph1: '',
      feedbackParagraph2: '',
      feedbackBtnText1: '',
    }
  }

  componentDidMount() {
    ReactGA.send("/feedback")
    window.scrollTo(0, 0)

    document.title = "Feedback"

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

    const getEntries = localStorage.getItem('ayaAppEntries')
    const entries = JSON.parse(getEntries)

    const getFeedbackEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'feedback');


    // Get page Assets
    const getAssets = localStorage.getItem('ayaAppAssets')
    const assets = JSON.parse(getAssets)

    const getAppChatImage = assets.filter(obj => obj.fields.title[this.props.appLanguage] === 'Message Icon');

  /*   const getWhatsAppImage = assets.filter(obj => obj.fields.title[this.props.appLanguage] === 'WhatsApp Icon'); */

    let appChatImage = ''
/*     let whatsAppImage = '' */

    if (getAppChatImage && getAppChatImage.length > 0 && getAppChatImage[0].fields.file[this.props.appLanguage].url) {
      appChatImage = 'https:' + getAppChatImage[0].fields.file[this.props.appLanguage].url
    }

/*     if (getWhatsAppImage && getWhatsAppImage.length > 0 && getWhatsAppImage[0].fields.file[this.props.appLanguage].url) {
      whatsAppImage = 'https:' + getWhatsAppImage[0].fields.file[this.props.appLanguage].url
    } */

    var feedbackEntries = []
    var getPageTitle = ''
    var getContactHeadingText = ''
    var getContactParagraph1 = ''
    var getContactParagraph2 = ''
    var getContactParagraph3 = ''
    var getContactParagraph4 = ''
    var getContactBtnText1 = ''

    var getFeedbackHeading = ''
    var getFeedbackParagraph1 = ''
    var getFeedbackParagraph2 = ''
    var getFeedbackParagraph3 = ''
    var getFeedbackParagraph4 = ''
    var getFeedbackBtnText2 = ''

    if (getFeedbackEntries && getFeedbackEntries.length > 0) {
      feedbackEntries.push(getFeedbackEntries[0].fields)

      if (feedbackEntries.length > 0) {
        if (feedbackEntries[0].title) {
          getPageTitle = feedbackEntries[0].title[this.props.appLanguage]
        }

        if (feedbackEntries[0].contactHeading1) {
          getContactHeadingText = feedbackEntries[0].contactHeading1[this.props.appLanguage]
        }
        if (feedbackEntries[0].contactParagraph1) {
          getContactParagraph1 = feedbackEntries[0].contactParagraph1[this.props.appLanguage]
        }
        if (feedbackEntries[0].contactParagraph2) {
          getContactParagraph2 = feedbackEntries[0].contactParagraph2[this.props.appLanguage]
        }
        if (feedbackEntries[0].contactParagraph3) {
          getContactParagraph3 = feedbackEntries[0].contactParagraph3[this.props.appLanguage]
        }
        if (feedbackEntries[0].contactParagraph4) {
          getContactParagraph4 = feedbackEntries[0].contactParagraph4[this.props.appLanguage]
        }
        if (feedbackEntries[0].buttonText) {
          getContactBtnText1 = feedbackEntries[0].buttonText[this.props.appLanguage]
        }


        if (feedbackEntries[0].feedbackHeading) {
          getFeedbackHeading = feedbackEntries[0].feedbackHeading[this.props.appLanguage]
        }
        if (feedbackEntries[0].feedbackParagraph1) {
          getFeedbackParagraph1 = feedbackEntries[0].feedbackParagraph1[this.props.appLanguage]
        }
        if (feedbackEntries[0].feedbackParagraph2) {
          getFeedbackParagraph2 = feedbackEntries[0].feedbackParagraph2[this.props.appLanguage]
        }
        if (feedbackEntries[0].feedbackParagraph3) {
          getFeedbackParagraph3 = feedbackEntries[0].feedbackParagraph3[this.props.appLanguage]
        }
        if (feedbackEntries[0].feedbackParagraph4) {
          getFeedbackParagraph4 = feedbackEntries[0].feedbackParagraph4[this.props.appLanguage]
        }
        if (feedbackEntries[0].buttonText2) {
          getFeedbackBtnText2 = feedbackEntries[0].buttonText2[this.props.appLanguage]
        }

        this.setState({
          title: getPageTitle,
          contactHeadingText: getContactHeadingText,
          contactParagraph1: getContactParagraph1,
          appChatImg: appChatImage,
          contactParagraph2: getContactParagraph2,
/*           whatsAppImg: whatsAppImage, */
          contactParagraph3: getContactParagraph3,
          contactParagraph4: getContactParagraph4,
          contactBtnText1: getContactBtnText1,

          feedbackHeading: getFeedbackHeading,
          feedbackParagraph1: getFeedbackParagraph1,
          feedbackParagraph2: getFeedbackParagraph2,
          feedbackParagraph3: getFeedbackParagraph3,
          feedbackParagraph4: getFeedbackParagraph4,
          feedbackBtnText1: getFeedbackBtnText2,
        })
      }
    }
  } // componentDidMount()

  goBack = () => {
    this.props.history.goBack()
}


  render() {
    return (
      <div className="minPageHeight100vh flexColumn flexGrow1 pageWithBottomMainNav feedbackPage">
        
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

        <div className='pagesInnerDiv paddingTop40'>
          <div className='paddingTop90 justText headerArea'>
            <div className='padding0-30-57'>
              <div className='flexRow'>
                <img src={technicalSupportIcon} style={{width: '27px', height: '24px', marginRight: '10px'}} alt='' />
                <p className='boldFont fontSize20 lineHeight26 colorWhite marginBottom0'>{this.state.title}</p>
                </div>
            </div>
          </div>

          {/* Contact */}
          <div className='flexGrow1 backgroundColorWhite topRoundDiv'>
            <div className='padding30-34 topRoundDivInnerDiv'>
              <p className='boldFont fontSize20 lineHeight26 colorBlue8 marginBottom20'>{this.state.contactHeadingText}</p>

              <ContentfulMarkdown children={this.state.contactParagraph1} className='regularFont fontSize15 lineHeight16 colorBlue8 hasColorPeach4Span marginBottom30 contentfulMarkdown' allowDangerousHtml={true} />

              <div className='flexRow alignCenter marginBottom20'>
                  {/* <img src={this.state.appChatImg} style={{ width: 44, height: 44, marginLeft: '4px', marginRight: '26px' }} alt='App Chat' /> */}
                <ContentfulMarkdown children={this.state.contactParagraph2} className='regularFont fontSize10 lineHeight11 colorBlue8 contentfulMarkdown psMargin0' allowDangerousHtml={true} />
              </div>

{/*               <div className='flexRow alignCenter marginBottom20'>
                <a href='https://wa.me/+17787155101' rel='noopener noreferrer' target='_blank'><img src={this.state.whatsAppImg} style={{ width: 50, height: 50, marginRight: '24px' }} alt='WhatsApp' /></a>
                <a href='https://wa.me/+17787155101' rel='noopener noreferrer' target='_blank'><ContentfulMarkdown children={this.state.contactParagraph3} className='regularFont fontSize10 lineHeight11 colorBlue8 contentfulMarkdown psMargin0' allowDangerousHtml={true} /></a>
              </div> */}

              <ContentfulMarkdown children={this.state.contactParagraph4} className='regularFont fontSize15 lineHeight16 colorBlue8 marginBottom30 contentfulMarkdown' allowDangerousHtml={true} />

              {/* <ContentfulMarkdown children={this.state.feedbackParagraph2} className='mediumFont fontSize15 lineHeight16 colorBlack3 hasColorPeach4Span' allowDangerousHtml={true} /> */}
              {/* /ally-organizations */}
              <LargeButton
                largeButtonFunc={() => this.props.history.push('/resources')}
                text={<p className='alignCenter marginBottom0'><img src={btn1Image} style={{width: '20px', height: '18px', marginRight: '15px'}} alt='' /> {this.state.contactBtnText1}</p>}
                textColor={"colorWhite"}
                backgroundColor={"backgroundColorBlue8"}
              />
              {/* <a className='flexRow justifyCenter marginTop30' href='mailto:aya@vitalaglobal.org' target='_blank' rel='noopener noreferrer'>
                <button className='backgroundColorBlue8 textAlignCenter largeButton'>
                  <span className='boldFont fontSize15 lineHeight26 colorWhite'>{this.state.contactBtnText1}</span>
                </button>
              </a> */}
            </div>
          </div>

          {/* Feedback */}
          {/* <div className='flexGrow1 backgroundColorWhite topRoundDiv hasBlueShadow'>
            <div className='padding30-34 topRoundDivInnerDiv'>
              <p className='boldFont fontSize18 lineHeight22 colorBlue8 marginBottom0'>{this.state.feedbackHeading}</p>

              <p className='regularFont fontSize12 lineHeight22 colorBlue8 marginBottom20'>{this.state.feedbackParagraph1}</p>

              <p className='regularObliqueFont fontSize16 lineHeight22 colorPeach4 marginBottom15'>{this.state.feedbackParagraph2}</p>

              <ContentfulMarkdown children={this.state.feedbackParagraph3} className='boldFont fontSize15 lineHeight16 colorBlack3 hasColorPeach4Span contentfulMarkdown' allowDangerousHtml={true} />

              <ContentfulMarkdown children={this.state.feedbackParagraph4} className='mediumFont fontSize15 lineHeight16 colorBlack3 hasColorPeach4Span contentfulMarkdown' allowDangerousHtml={true} />


              <a className='flexRow justifyCenter marginTop30' href='mailto:aya@vitalaglobal.org' target='_blank' rel='noopener noreferrer'>
                <button className='backgroundColorBlue8 textAlignCenter largeButton'>
                  <span className='boldFont fontSize15 lineHeight26 colorWhite'>{this.state.feedbackBtnText1}</span>
                </button>
              </a>
            </div>
          </div> */}

        </div>

        <BottomNavBar appLanguage={this.props.appLanguage} />
      </div>
    )
  }
}
