import React from 'react'
import ReactGA from 'react-ga4'
import { SingleContraceptionAssessmentWithOptions } from './SingleContraceptionAssessmentWithOptions'


export class ContraceptionAssessment8  extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1,
      contentObj: { 'options': [], },
      popUp1Content: {},
    }
  }



  componentDidMount() {
    ReactGA.send("/contraception-assessment-q8")
    window.scrollTo(0, 0)

    document.title = "Contraception Assessment Q8"

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

    const getEntries = localStorage.getItem('ayaAppEntries')
    const entries = JSON.parse(getEntries)

    const getContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'multipleChoice');
    const getPageEntries = getContentEntries.filter(obj => obj.sys.id === '41mQNYBAW0JOiw2VBrlg9K');

    var pageEntries = []
    var pageContentObj = { 'options': [],}

    if (getPageEntries && getPageEntries.length > 0) {
      pageEntries.push(getPageEntries[0].fields)

      if (pageEntries.length > 0) {
        
        if (pageEntries[0].stepNumber) {
          pageContentObj['stepNumber'] = pageEntries[0].stepNumber[this.props.appLanguage]
        }
        if (pageEntries[0].title) {
          pageContentObj['question'] = pageEntries[0].title[this.props.appLanguage]
        }
        if (pageEntries[0].subtitle) {
          pageContentObj['subTitle'] = pageEntries[0].subtitle[this.props.appLanguage]
        }
        if (pageEntries[0].choice1) {
          pageContentObj.options.push({ 'option': pageEntries[0].choice1[this.props.appLanguage], 'stateName': 'option1' })
        }
        if (pageEntries[0].choice2) {
          pageContentObj.options.push({ 'option': pageEntries[0].choice2[this.props.appLanguage], 'stateName': 'option2' })
        }
        if (pageEntries[0].choice3) {
          pageContentObj.options.push({ 'option': pageEntries[0].choice3[this.props.appLanguage], 'stateName': 'option3' })
        }
        if (pageEntries[0].ifNoneApplyCopy) {
          pageContentObj['noneApplyText'] = pageEntries[0].ifNoneApplyCopy[this.props.appLanguage]
        }
        if (pageEntries[0].ctaCopy1) {
          pageContentObj['btn1Text'] = pageEntries[0].ctaCopy1[this.props.appLanguage]
        }
        if (pageEntries[0].popUpText1) {
          pageContentObj['linkToPopUpText1'] = pageEntries[0].popUpText1[this.props.appLanguage]
        }
        if (pageEntries[0].popUpText2) {
          pageContentObj['linkToPopUpText2'] = pageEntries[0].popUpText2[this.props.appLanguage]
        }
      }
    }




    // Get pop up 1 content
    const getPopUpContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'popUp');
    const getPopUp1Entries = getPopUpContentEntries.filter(obj => obj.sys.id === '2k41oqNb6dzXiRdyVUV3lU');

    var popUp1Entries = []
    var popUp1ContentObj = {}
   

    if (getPopUp1Entries && getPopUp1Entries.length > 0) {
      popUp1Entries.push(getPopUp1Entries[0].fields)

      if (popUp1Entries.length > 0) {

        if (popUp1Entries[0].title) {
          popUp1ContentObj['title'] = popUp1Entries[0].title[this.props.appLanguage]
        }
        if (popUp1Entries[0].paragraph) {
          popUp1ContentObj['text1'] = popUp1Entries[0].paragraph[this.props.appLanguage]
        }
        if (popUp1Entries[0].ctaCopy1) {
          popUp1ContentObj['btn1Text'] = popUp1Entries[0].ctaCopy1[this.props.appLanguage]
        }
       
      }
    }




    this.setState({
      contentObj: pageContentObj,
      popUp1Content: popUp1ContentObj,
    })
  }


  continue = (trueCount) => {
    if (trueCount === 0){
      window.localStorage.setItem('contraceptionAssessmentQ8Answer', 'no')
      this.props.history.push('/contraception-assessment-q9')
    } else {
      window.localStorage.setItem('contraceptionAssessmentQ8Answer', 'yes')
      this.props.history.push('/contraception-assessment-q9')
    }
  }



  goBackFunc = () => {
   
  }



  render() {

    const { contentObj, popUp1Content } = this.state
    return (
      <div className='flexColumn flexGrow1'>
        <SingleContraceptionAssessmentWithOptions
          appLanguage={this.props.appLanguage}
          history={this.props.history}
          content={contentObj}
          questionWidth={'width83Percent'}
          popUp1Content={popUp1Content}
          continue={this.continue}
          goBack={() => this.goBackFunc()}
        />
      </div>
    )
  }
}