import React from 'react';
import ReactGA from 'react-ga4';
import { TopNavBar } from '../../Components/TopNavBar'
/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */
import { LargeButton } from '../../Components/LargeButton'
import * as ContentfulMarkdown from 'react-markdown'
import { BottomNavBar } from '../../Components/BottomNavBar'
import messageIcon from '../../Images/message-icon-white.svg'
import { WHOLink } from '../../Components/WHOLink';



export class AbortionStep4 extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            headerImage: '',
            stepNum: '',
            title: '',
            // image2: '',
            // image2Alt: '',
            // image3: '',
            // image3Alt: '',
            // image4: '',
            // image4Alt: '',
            text1: '',
            text2: '',
            text3: '',
            text4: '',
            // text5: '',
            option1Image: '',
            option1ImageAlt: '',
            option2Image: '',
            option2ImageAlt: '',
            btn1Text: '',
            btn2Text: '',
            // btn3Text: '',
        }
    }


    componentDidMount() {
        ReactGA.send("/abortion-step-4")
        window.scrollTo(0, 0)

        document.title = "Abortion Step 4"
                        
        // this hides the Crisp Chat Window from the page
        /* $crisp.push(["do", "chat:hide"]); */


        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)
        // Content type ID
        const getAbortionStepsEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'step1');
        // Then Entry ID instead
        const getStepEntries = getAbortionStepsEntries.filter(obj => obj.sys.id === '5dstkUxnvv4ATwSIFzNE6G');

        var stepEntries = []
        var stepNumber = ''
        var stepTitle = ''
        var stepText1 = ''
        var stepText2 = ''
        var stepText3 = ''
        var stepText4 = ''
        // var stepText5 = ''
        var ctaCopy1 = ''
        var ctaCopy2 = ''
        // var ctaCopy3 = ''


        if (getStepEntries && getStepEntries.length > 0) {
            stepEntries.push(getStepEntries[0].fields)

            if (stepEntries.length > 0) {

                if (stepEntries[0].stepNumber) {
                    stepNumber = stepEntries[0].stepNumber[this.props.appLanguage]
                }
                if (stepEntries[0].title) {
                    stepTitle = stepEntries[0].title[this.props.appLanguage]
                }
                if (stepEntries[0].text1) {
                    stepText1 = stepEntries[0].text1[this.props.appLanguage]
                }
                if (stepEntries[0].text2) {
                    stepText2 = stepEntries[0].text2[this.props.appLanguage]
                }
                if (stepEntries[0].text3) {
                    stepText3 = stepEntries[0].text3[this.props.appLanguage]
                }
                if (stepEntries[0].text4) {
                    stepText4 = stepEntries[0].text4[this.props.appLanguage]
                }
                // if (stepEntries[0].text5) {
                //     stepText5 = stepEntries[0].text5[this.props.appLanguage]
                // }
                if (stepEntries[0].ctaCopy1) {
                    ctaCopy1 = stepEntries[0].ctaCopy1[this.props.appLanguage]
                }
                if (stepEntries[0].ctaCopy2) {
                    ctaCopy2 = stepEntries[0].ctaCopy2[this.props.appLanguage]
                }
                // if (stepEntries[0].ctaCopy3) {
                //     ctaCopy3 = stepEntries[0].ctaCopy3[this.props.appLanguage]
                // }
            } // stepEntries.length
        } // getAbortionStepsEntries.length

        this.setState({
            stepNum: stepNumber,
            title: stepTitle,
            text1: stepText1,
            text2: stepText2,
            text3: stepText3,
            text4: stepText4,
            // text5: stepText5,
            btn1Text: ctaCopy1,
            btn2Text: ctaCopy2,
            // btn3Text: ctaCopy3,
        })


        // Get page Assets
        const getAssets = localStorage.getItem('ayaAppAssets')
        const assets = JSON.parse(getAssets)

        const getAbortionStepHeaderImg = assets.filter(obj => obj.sys.id === '19o2GbDSKFeC6DhhQa4PWv');
        const getOption1BtnImage = assets.filter(obj => obj.sys.id === 'ZVpBGr7JpyUftOoqnqDAy');
        const getOption2BtnImage = assets.filter(obj => obj.sys.id === '3buN0qV5gsoIqyDvghlqzC');

        // ZVpBGr7JpyUftOoqnqDAy

        var stepMainImage = ''
        var option1BtnImage = ''
        var option1BtnImageAlt = ''
        var option2BtnImage = ''
        var option2BtnImageAlt = ''
        // var pageImage4 = ''
        // var pageImage4Alt = ''

        if (getAbortionStepHeaderImg && getAbortionStepHeaderImg.length > 0 && getAbortionStepHeaderImg[0].fields.file[this.props.appLanguage].url) {
            stepMainImage = 'https:' + getAbortionStepHeaderImg[0].fields.file[this.props.appLanguage].url
        }

        if (getOption1BtnImage && getOption1BtnImage.length > 0 && getOption1BtnImage[0].fields.file[this.props.appLanguage].url) {
            option1BtnImage = 'https:' +  getOption1BtnImage[0].fields.file[this.props.appLanguage].url
            option1BtnImageAlt = getOption1BtnImage[0].fields.title[this.props.appLanguage]
        }

        if (getOption2BtnImage && getOption2BtnImage.length > 0 && getOption2BtnImage[0].fields.file[this.props.appLanguage].url) {
            option2BtnImage = 'https:' +  getOption2BtnImage[0].fields.file[this.props.appLanguage].url
            option2BtnImageAlt = getOption2BtnImage[0].fields.title[this.props.appLanguage]
        }

        this.setState({
            headerImage: stepMainImage,
            option1Image: option1BtnImage,
            option1ImageAlt: option1BtnImageAlt,
            option2Image: option2BtnImage,
            option2ImageAlt: option2BtnImageAlt,
            // image2: pageImage2,
            // image2Alt: pageImage2Alt,
            // image3: pageImage3,
            // image3Alt: pageImage3Alt,
            // image4: pageImage4,
            // image4Alt: pageImage4Alt,
        })

    } // componentDidMount()




    goBackAStep = () => {
        this.props.history.push('/abortion-step-3');
    }


    goToPillRegimenA = () => {
        this.props.history.push('/abortion-step-4-pill-regimen-a');
    }

    goToPillRegimenB = () => {
        this.props.history.push('/abortion-step-4-pill-regimen-b');
    }


    goToResources = () => {
        this.props.history.push('/resources');
    }

    goToSelfCare = () => {
        this.props.history.push('/self-care-advice');
    }

    goBack = () => {
        this.props.history.goBack()
      }


    render() {

        const { headerImage, stepNum, title, option1Image, option1ImageAlt, option2Image, option2ImageAlt, text1, text2, text3, text4, btn1Text, btn2Text } = this.state

        return (
            <div className="minPageHeight100vh flexColumn flexGrow1 pageWithBottomMainNav backgroundColorBlue9 abortionStepPages">
               
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

                <div className='pagesInnerDiv paddingTop100'>
                    <div className='backgroundImgWAutoH77Percent backgroundImgX45Percent backgroundImgYBottom dontRepeatImage backgroundColorBlue9 headerArea' style={{ backgroundImage: `url(${headerImage})` }}>
                        {/* <div className='flexRow justifyEnd alignFlexEnd padding0-25-25'>
                            <button onClick={() => this.goBackAStep()} className='width50Height50'>
                                <img src={prevBtnImg} className='width100Percent prevImg' alt='back' />
                            </button>
                        </div> */}
                    </div> {/* .headerArea */}

                    <div className='flexGrow1 backgroundColorWhite topRoundDiv'>
                        <div className='flexColumn alignCenter padding37-30-34 topRoundDivInnerDiv'>
                            <div className='backgroundColorPeach4 alignSelfFlexStart marginBottom26 smallTagDiv'>
                                <span className='regularFont fontSize11 colorWhite'>{stepNum}</span>
                            </div>

                            <p className='boldFont fontSize24 lineHeight26 colorBlue8 alignSelfFlexStart textAlignLeft marginBottom21'>{title}</p>

                            <ContentfulMarkdown children={text1} className='boldFont fontSize15 lineHeight20 colorPeach4 marginBottom16 contentfulMarkdown' />
                            {/* className='regularFont fontSize15 lineHeight15 colorBlue8 padding10-0 margin0' */}

                            {/* <img src={image2} className='width70Percent marginTopBottom14 marginLeftRightAuto' alt={image2Alt} /> */}

                            <ContentfulMarkdown children={text2} className='regularFont fontSize16 lineHeight20 colorBlack1 marginBottom14 contentfulMarkdown' />

                            {/* <img src={image3} className='width93Percent marginTop0Bottom50 marginLeftRightAuto' alt={image3Alt} /> */}

                            <ContentfulMarkdown children={text3} className='width55Percent boldFont fontSize16 lineHeight18 colorBlack1 alignSelfFlexStart textAlignLeft underline psMargin5 contentfulMarkdown' />

                            {/* <img src={image4} className='width74Percent marginTop0Bottom50 marginLeftRightAuto' alt={image4Alt} /> */}

                            {/* <img src={importantIconPeach} className='width8-4Percent marginBottom14' alt='!' /> */}

                            <ContentfulMarkdown children={text4} className='boldFont fontSize9 lineHeight18 colorBlack1 marginBottom22 alignSelfFlexStart contentfulMarkdown' />

                            {/* <ContentfulMarkdown children={text5} className='regularFont fontSize15 lineHeight20 colorBlack1 marginTopBottom50 contentfulMarkdown' /> */}

                            <button className='width100Percent buttonWidth55Percent marginBottom26' onClick={() => this.goToPillRegimenA()}>
                                <img src={option1Image} className='width100Percent' alt={option1ImageAlt} />
                            </button>

                            <button className='width100Percent buttonWidth55Percent marginBottom70' onClick={() => this.goToPillRegimenB()}>
                                <img src={option2Image} className='width100Percent' alt={option2ImageAlt} />
                            </button>


                            <LargeButton
                                largeButtonFunc={() => this.goToResources()}
                                text={<p className='width100Percent flexRow justifyCenter alignCenter marginBottom0'>
                                    {/* <img src={messageIcon} style={{ width: '18px', height: 'auto', marginRight: '10px' }} alt='' /> */}
                                    {btn1Text}
                                </p>}
                                textColor={'colorWhite'}
                                backgroundColor={'backgroundColorBlue8'}
                            />

                            <div className='marginBottom24'></div>

                            <LargeButton
                                largeButtonFunc={() => this.goToSelfCare()}
                                text={btn2Text}
                                textColor={'colorBlue8'}
                                backgroundColor={'backgroundColorPink'}
                            />

                            <WHOLink 
                                fontColor="#071f56"
                                appLanguage={this.props.appLanguage}
                                {...this.props} 
                            /> {/* This is the WHO link that is necessary for iOS app store */}

                        </div> {/* .topRoundDivInnerDiv */}
                    </div> {/* .topRoundDiv */}

                </div> {/* .pagesInnerDiv */}

                <BottomNavBar appLanguage={this.props.appLanguage} />
            </div>
        )
    }
}
