/*global React*/
import React from 'react'
/* import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom' */
import styles from './LanguageSelectionPage.modules.css'
import logo from '../../Images/small-logo-peach.svg'

export class LanguageSelectionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: null
    };
  }

  handleLanguageChange = (event) => {
    // Update the selectedLanguage state
    this.setState({ selectedLanguage: event.target.value });
  
    // Send event to Google Analytics 4
    if (window.gtag) { // Check if gtag is available
      window.gtag('event', 'language_selected', {
        'selected_language': event.target.value // 'en' or 'es-US'
      });
    }
  }
  

  handleEnterClick = () => {
    // Check if a language has been selected
    if (this.state.selectedLanguage) {
      // Call the handleLanguageChange method from the App component
      this.props.handleLanguageChange(this.state.selectedLanguage);

      // Navigate to the home page
      this.props.history.push('/IntroPage');
    } else {
      // Show an alert if no language has been selected
      alert('Please select a language.');
    }
  }

  componentDidMount() {
    // Show the Crisp chat when the component mounts
    /* $crisp.push(["do", "chat:hide"]); */
  }

  render() {
    console.log(this.props);
    const isButtonDisabled = !this.state.selectedLanguage;
    const buttonClass = isButtonDisabled ? 'buttonDisabled' : 'buttonActive'; // Choose the class based on the state    
    const buttonText = this.state.selectedLanguage === 'en' ? 'Continue' : 'Continuar';


    return (
      
      <div className='header-container'>
        <div className="marginBottom40"></div>
        <img className="logo" src={logo} alt="logo" />
        <h1 className='heading'>Bienvenidx</h1>
        <h1 className='subheading'>Welcome</h1>


        <div className="radio-container">

          
          <div className="radio-item">
            <input className="colorWhite" type="radio" id="english" name="language" value="en" onChange={this.handleLanguageChange} />
            <label className="colorWhite" htmlFor="english">English</label>
          </div>
          
          <div className="radio-item">
            <input className="colorWhite" type="radio" id="spanish" name="language" value="es-US" onChange={this.handleLanguageChange} />
            <label className="colorWhite" htmlFor="spanish">Español</label>
          </div>
        </div>

        <button 
        className={`colorWhite continueButton ${buttonClass}`} 
        onClick={this.handleEnterClick}
        disabled={isButtonDisabled} 
      >
        {buttonText}
      </button>  
      </div>
    );
  }
}