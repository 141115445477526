import React from 'react'
import PropTypes from 'prop-types'
import * as ContentfulMarkdown from 'react-markdown'
// import { LargeButton } from '../../Components/LargeButton'
import { PrevAndNextBottomNav } from '../../Components/PrevAndNextBottomNav'

export class IntroProp extends React.Component {


  componentDidMount() {
    window.scrollTo(0, 0)

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

  }

  render() {

    const { topImageUrl, topImageAlt, title, paragraph, pageNavIcon, pageNavText } = this.props

    return (
      <div className='appBackgroundColor minPageHeight100vh flexColumn flexGrow1 introPropPages'>
        <div className='flexColumn flexGrow1 pagesInnerDiv'>
          <div className='minHeight100vw maxHeight100vw backgroundImgW100HAuto backgroundImgXYCenter dontRepeatImage topDiv' style={{ backgroundImage: `url(${topImageUrl})` }} alt={topImageAlt}>

        </div>

        <div className='flexColumn flexGrow1 justifyBetween backgroundColorPeach2 bottomDiv'>
            <div className='flexColumn justifyBetween alignCenter textAlignCenter padding8vh-0-3vh contentDiv'> {/* padding27-30-70 */}
              {/* {/* <div className={contentDivClassNames}> */}
              <div className='width100Percent flexRow justifyCenter'>
              <PrevAndNextBottomNav
                showPrevButton={true}
                prevBtnFunc={this.props.prevBtnFunc}
                showNextBtn={true}
                nextBtnFunc={this.props.nextBtnFunc}
                />
                </div>

              <ContentfulMarkdown children={title} className='maxWidth90Percent boldFont colorWhite fontSize24 lineHeight25 psMargin0 paddingLeftRight30 marginBottom5vh contentfulMarkdown' allowDangerousHtml={true} />

              <p className='regularFont colorWhite fontSize15 lineHeight18 paddingLeftRight30 marginBottom5vh'>{paragraph}</p>

              <div className='justifyCenter'>
                <img src={pageNavIcon} style={{ width: '33px', height: 'auto', marginBottom: '5px'}} alt=''/>
                <p className='boldFont colorWhite fontSize10 lineHeight15'>{pageNavText}</p>
                {/* <LargeButton
                  largeButtonFunc={() => this.props.largeButtonFunc()}
                  text={buttonText}
                  textColor={'colorBlue8'}
                  backgroundColor={'backgroundColorWhite'}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


IntroProp.propTypes = {
  // info: PropTypes.object
  largeButtonFunc: PropTypes.func,
  topImageUrl: PropTypes.string,
  topImageAlt: PropTypes.string,
  title: PropTypes.string,
  paragraph: PropTypes.string,
  buttonText: PropTypes.string,
};