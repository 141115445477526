import React from 'react';
import ReactGA from 'react-ga4';
import { TopNavBar } from '../../Components/TopNavBar'
/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */
import { LargeButton } from '../../Components/LargeButton'
import * as ContentfulMarkdown from 'react-markdown'
import { BottomNavBar } from '../../Components/BottomNavBar'
import messageIcon from '../../Images/message-icon-white.svg'
import { WHOLink } from '../../Components/WHOLink';


export class AbortionStep2 extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            headerImage: '',
            stepNum: '',
            title: '',
            image2: '',
            image2Alt: '',
            image3: '',
            image3Alt: '',
            image4: '',
            image4Alt: '',
            text1: '',
            text2: '',
            text3: '',
            text4: '',
            text5: '',
            btn1Text: '',
            btn2Text: '',
            btn3Text: '',
        }
    }


    componentDidMount() {
        ReactGA.send("/abortion-step-2")
        window.scrollTo(0, 0)

        document.title = "Abortion Step 2"
                        
        // this hides the Crisp Chat Window from the page
        /* $crisp.push(["do", "chat:hide"]); */


        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)
        // Content type ID
        const getAbortionStepsEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'step1');
        // Then Entry ID instead
        const getStepEntries = getAbortionStepsEntries.filter(obj => obj.sys.id === '1oGFeHbJuuwz0WujXOIcgY');

        var stepEntries = []
        var stepNumber = ''
        var stepTitle = ''
        var stepText1 = ''
        var stepText2 = ''
        var stepText3 = ''
        var stepText4 = ''
        var stepText5 = ''
        var ctaCopy1 = ''
        var ctaCopy2 = ''
        var ctaCopy3 = ''


        if (getStepEntries && getStepEntries.length > 0) {
            stepEntries.push(getStepEntries[0].fields)

            if (stepEntries.length > 0) {
                if (stepEntries[0].stepNumber) {
                    stepNumber = stepEntries[0].stepNumber[this.props.appLanguage]
                }
                if (stepEntries[0].title) {
                    stepTitle = stepEntries[0].title[this.props.appLanguage]
                }
                if (stepEntries[0].text1) {
                    stepText1 = stepEntries[0].text1[this.props.appLanguage]
                }
                if (stepEntries[0].text2) {
                    stepText2 = stepEntries[0].text2[this.props.appLanguage]
                }
                if (stepEntries[0].text3) {
                    stepText3 = stepEntries[0].text3[this.props.appLanguage]
                }
                if (stepEntries[0].text4) {
                    stepText4 = stepEntries[0].text4[this.props.appLanguage]
                }
                if (stepEntries[0].text5) {
                    stepText5 = stepEntries[0].text5[this.props.appLanguage]
                }
                if (stepEntries[0].ctaCopy1) {
                    ctaCopy1 = stepEntries[0].ctaCopy1[this.props.appLanguage]
                }
                if (stepEntries[0].ctaCopy2) {
                    ctaCopy2 = stepEntries[0].ctaCopy2[this.props.appLanguage]
                }
                if (stepEntries[0].ctaCopy3) {
                    ctaCopy3 = stepEntries[0].ctaCopy3[this.props.appLanguage]
                }
            } // stepEntries.length
        } // getAbortionStepsEntries.length

        this.setState({
            stepNum: stepNumber,
            title: stepTitle,
            text1: stepText1,
            text2: stepText2,
            text3: stepText3,
            text4: stepText4,
            text5: stepText5,
            btn1Text: ctaCopy1,
            btn2Text: ctaCopy2,
            btn3Text: ctaCopy3,
        })


        // Get page Assets
        const getAssets = localStorage.getItem('ayaAppAssets')
        const assets = JSON.parse(getAssets)

        const getAbortionStepHeaderImg = assets.filter(obj => obj.sys.id === '6bPETZEIIxRDeDIOrfbuTA');

        const getImage2 = assets.filter(obj => obj.sys.id === '7Haa2Y6YIBWeMs4e0muYVw');
        const getImage3 = assets.filter(obj => obj.sys.id === '3u8OgFBobMgNppC9mrTTUP');
        const getImage4 = assets.filter(obj => obj.sys.id === '6oqeBqmJuU5rltbFsUEIGH');

        var stepMainImage = ''
        var pageImage2 = ''
        var pageImage2Alt = ''
        var pageImage3 = ''
        var pageImage3Alt = ''
        var pageImage4 = ''
        var pageImage4Alt = ''

        if (getAbortionStepHeaderImg && getAbortionStepHeaderImg.length > 0 && getAbortionStepHeaderImg[0].fields.file[this.props.appLanguage].url) {
            stepMainImage = 'https:' + getAbortionStepHeaderImg[0].fields.file[this.props.appLanguage].url
        }

        if (getImage2 && getImage2.length > 0 && getImage2[0].fields.file[this.props.appLanguage].url) {
            pageImage2 = 'https:' + getImage2[0].fields.file[this.props.appLanguage].url
            pageImage2Alt = getImage2[0].fields.description[this.props.appLanguage]
        }

        if (getImage3 && getImage3.length > 0 && getImage3[0].fields.file[this.props.appLanguage].url) {
            pageImage3 = 'https:' + getImage3[0].fields.file[this.props.appLanguage].url
            pageImage3Alt = getImage3[0].fields.description[this.props.appLanguage]
        }

        if (getImage4 && getImage4.length > 0 && getImage4[0].fields.file[this.props.appLanguage].url) {
            pageImage4 = 'https:' + getImage4[0].fields.file[this.props.appLanguage].url
            pageImage4Alt = getImage4[0].fields.description[this.props.appLanguage]
        }

        this.setState({
            headerImage: stepMainImage,
            image2: pageImage2,
            image2Alt: pageImage2Alt,
            image3: pageImage3,
            image3Alt: pageImage3Alt,
            image4: pageImage4,
            image4Alt: pageImage4Alt,
        })

    } // componentDidMount()



    goBackAStep = () => {
        this.props.history.push('/abortion-step-1');
    }

    goToLegal= () => {
        this.props.history.push('/resources-legal-assistance');
    }

    completeThisStep = () => {
        window.localStorage.setItem('isAbortionStep2Complete', true)
        this.props.history.push('/abortion');
    }

    goToSelfCare = () => {
        this.props.history.push('/self-care-advice');
    }

    goBack = () => {
        this.props.history.goBack()
      }


    render() {

        const { headerImage, stepNum, title, image2, image2Alt, image3, image3Alt, image4, image4Alt, text1, text2, text3, text5, btn1Text, btn2Text, btn3Text } = this.state

        return (
            <div className="minPageHeight100vh flexColumn flexGrow1 pageWithBottomMainNav backgroundColorBlue9 abortionStepPages">
                
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

                <div className='pagesInnerDiv paddingTop100'>
                    <div className='backgroundImgWAutoH81Percent backgroundXCenterYBottom dontRepeatImage backgroundColorBlue9 headerArea' style={{ backgroundImage: `url(${headerImage})` }}>
                        {/* <div className='flexRow justifyEnd alignFlexEnd padding0-25-25'>
                            <button className='width50Height50' onClick={() => this.goBackAStep()}>
                                <img src={prevBtnImg} className='width100Percent prevImg' alt='back' />
                            </button>
                        </div> */}
                    </div> {/* .headerArea */}

                    <div className='flexGrow1 backgroundColorWhite topRoundDiv'>
                        <div className='flexColumn alignCenter padding37-30-34 topRoundDivInnerDiv'>
                            <div className='backgroundColorPeach4 alignSelfFlexStart marginBottom26 smallTagDiv'>
                                <span className='regularFont fontSize11 colorWhite'>{stepNum}</span>
                            </div>

                            <ContentfulMarkdown children={title} className='boldFont fontSize24 lineHeight26 colorBlue8 alignSelfFlexStart textAlignLeft marginBottom21' allowDangerousHtml={true} />

                            <ContentfulMarkdown children={text1} className='regularFont fontSize15 lineHeight20 colorBlack1 hasColorPeach4Span marginBottom14 contentfulMarkdown' allowDangerousHtml={true} />
                            {/* className='regularFont fontSize15 lineHeight15 colorBlue8 padding10-0 margin0' */}

                            <img src={image2} className='widthAuto marginBottom14' alt={image2Alt} />

                            <ContentfulMarkdown children={text2} className='regularFont fontSize15 lineHeight20 colorBlack1 hasColorPeach4Span marginBottom36 contentfulMarkdown' allowDangerousHtml={true} />

                            <img src={image3} className='width93Percent marginBottom36 marginLeftRightAuto' alt={image3Alt} />

                            <ContentfulMarkdown className='regularFont fontSize15 lineHeight20 colorBlack1 marginBottom36 contentfulMarkdown' children={text3} />

                            <img src={image4} className='width74Percent marginBottom36 marginLeftRightAuto' alt={image4Alt} />

                            {/* <img src={importantIconPeach} className='width8-4Percent marginBottom14' alt='!' /> */}

                            {/* <ContentfulMarkdown children={text4} className='textAlignCenter regularFont fontSize15 lineHeight20 colorPeach4 borderTopWidth1 borderLeftRight0 borderBottomWidth1 borderColorPeach4 paddingTop14 marginBottom50 contentfulMarkdown' /> */}

                            <ContentfulMarkdown children={text5} className='regularFont fontSize15 lineHeight20 colorBlack1 marginBottom36 contentfulMarkdown' />

                            <LargeButton
                                largeButtonFunc={() => this.goToLegal()}
                                text={<p className='width100Percent flexRow justifyCenter alignCenter marginBottom0'>
                                    {/* <img src={messageIcon} style={{ width: '18px', height: 'auto', marginRight: '10px' }} alt='' /> */}
                                    {btn1Text}
                                </p>}
                                textColor={'colorWhite'}
                                backgroundColor={'backgroundColorBlue8'}
                            />

                            <div className='marginBottom15'></div>

                            <LargeButton
                                largeButtonFunc={() => this.completeThisStep()}
                                text={btn2Text}
                                textColor={'colorWhite'}
                                backgroundColor={'backgroundColorBlue6'}
                            />

                            <div className='marginBottom15'></div>

                            <LargeButton
                                largeButtonFunc={() => this.goToSelfCare()}
                                text={btn3Text}
                                textColor={'colorBlue8'}
                                backgroundColor={'backgroundColorPink'}
                            />

                            <WHOLink 
                                fontColor="#071f56"
                                appLanguage={this.props.appLanguage}
                                {...this.props} 
                            /> {/* This is the WHO link that is necessary for iOS app store */}

                        </div> {/* .topRoundDivInnerDiv */}
                    </div> {/* .topRoundDiv */}

                </div> {/* .pagesInnerDiv */}

                <BottomNavBar appLanguage={this.props.appLanguage} />
            </div>
        )
    }
}
