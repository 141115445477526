import React from 'react';
import ReactGA from 'react-ga4';
import { TopNavBar } from '../../Components/TopNavBar'
import { AbortionMenuCardContent } from './AbortionMenuCardContent'
import { BottomNavBar } from '../../Components/BottomNavBar'

/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */

export class AbortionStepsMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      pageTitle: '',
      startOverBtnText: '',
      abortionMenuCardContent: [],
      resetAllData: false,
    }
  }


  componentDidMount() {
    ReactGA.send("/abortion")
    window.scrollTo(0, 0)

    document.title = "Abortion Steps Menu"
                    
        // this hides the Crisp Chat Window from the page
       /*  $crisp.push(["do", "chat:hide"]); */


    const getEntries = localStorage.getItem('ayaAppEntries')
    const entries = JSON.parse(getEntries)

    const getAbortionMenuEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'stepsMenu');

    var abortionMenuEntries = []
    var abortionMenuCardContentArray = []
    var abortionMenuPageTitle = ''
    var startOverBtnTextCopy = ''
    var card1 = {}
    var card2 = {}
    var card3 = {}
    var card4 = {}
    var card5 = {}
    var card6 = {}
    var card7 = {}


    if (getAbortionMenuEntries && getAbortionMenuEntries.length > 0) {
      abortionMenuEntries.push(getAbortionMenuEntries[0].fields)

      if (abortionMenuEntries.length > 0) {
        console.log(abortionMenuEntries)
        if (abortionMenuEntries[0].title) {
          abortionMenuPageTitle = abortionMenuEntries[0].title[this.props.appLanguage]
        }

        if (abortionMenuEntries[0].stepsResetButtonCopy) {
          startOverBtnTextCopy = abortionMenuEntries[0].stepsResetButtonCopy[this.props.appLanguage]
        }

        if (abortionMenuEntries[0].step1) {
          card1['stepNumber'] = abortionMenuEntries[0].step1[this.props.appLanguage]
        }
        if (abortionMenuEntries[0].stepCopy1) {
          card1['stepTitle'] = abortionMenuEntries[0].stepCopy1[this.props.appLanguage]
        }
        card1['backgroundColor'] = 'backgroundColorBlue8'
        card1['goToPageLink'] = '/abortion-step-1'
        card1['stepCompletedStorageName'] = 'isAbortionStep1Complete'

        if (abortionMenuEntries[0].step2) {
          card2['stepNumber'] = abortionMenuEntries[0].step2[this.props.appLanguage]
        }
        if (abortionMenuEntries[0].stepCopy2) {
          card2['stepTitle'] = abortionMenuEntries[0].stepCopy2[this.props.appLanguage]
        }
        card2['backgroundColor'] = 'backgroundColorBlue7'
        card2['goToPageLink'] = '/abortion-step-2'
        card2['stepCompletedStorageName'] = 'isAbortionStep2Complete'

        if (abortionMenuEntries[0].step3) {
          card3['stepNumber'] = abortionMenuEntries[0].step3[this.props.appLanguage]
        }
        if (abortionMenuEntries[0].stepCopy3) {
          card3['stepTitle'] = abortionMenuEntries[0].stepCopy3[this.props.appLanguage]
        }
        card3['backgroundColor'] = 'backgroundColorBlue6'
        card3['goToPageLink'] = '/abortion-step-3'
        card3['stepCompletedStorageName'] = 'isAbortionStep3Complete'

        if (abortionMenuEntries[0].step4) {
          card4['stepNumber'] = abortionMenuEntries[0].step4[this.props.appLanguage]
        }
        if (abortionMenuEntries[0].stepCopy4) {
          card4['stepTitle'] = abortionMenuEntries[0].stepCopy4[this.props.appLanguage]
        }
        card4['backgroundColor'] = 'backgroundColorBlue5'
        card4['goToPageLink'] = '/abortion-step-4'
        card4['stepCompletedStorageName'] = 'isAbortionStep4Complete'

        if (abortionMenuEntries[0].step5) {
          card5['stepNumber'] = abortionMenuEntries[0].step5[this.props.appLanguage]
        }
        if (abortionMenuEntries[0].stepCopy5) {
          card5['stepTitle'] = abortionMenuEntries[0].stepCopy5[this.props.appLanguage]
        }
        card5['backgroundColor'] = 'backgroundColorBlue4'
        card5['goToPageLink'] = '/abortion-step-5'
        card5['stepCompletedStorageName'] = 'isAbortionStep5Complete'

        if (abortionMenuEntries[0].step6) {
          card6['stepNumber'] = abortionMenuEntries[0].step6[this.props.appLanguage]
        }
        if (abortionMenuEntries[0].stepCopy6) {
          card6['stepTitle'] = abortionMenuEntries[0].stepCopy6[this.props.appLanguage]
        }
        card6['backgroundColor'] = 'backgroundColorBlue3'
        card6['goToPageLink'] = '/abortion-step-6'
        card6['stepCompletedStorageName'] = 'isAbortionStep6Complete'

        if (abortionMenuEntries[0].step7) {
          card7['stepNumber'] = abortionMenuEntries[0].step7[this.props.appLanguage]
        }
        if (abortionMenuEntries[0].stepCopy7) {
          card7['stepTitle'] = abortionMenuEntries[0].stepCopy7[this.props.appLanguage]
        }
        card7['backgroundColor'] = 'backgroundColorBlue2'
        card7['goToPageLink'] = '/abortion-step-7'
        card7['stepCompletedStorageName'] = 'isAbortionStep7Complete'


      } // abortionMenuEntries.length
    } // getAbortionMenuEntries.length

    abortionMenuCardContentArray.push(card1, card2, card3, card4, card5, card6, card7)

    this.setState({
      pageTitle: abortionMenuPageTitle,
      startOverBtnText: startOverBtnTextCopy,
      abortionMenuCardContent: abortionMenuCardContentArray,
    })

  } // componentDidMount()



  startStepsOver = () => {
    // const amcc = this.state.abortionMenuCardContent

    window.localStorage.setItem('isAbortionStep1Complete', null)
    window.localStorage.setItem('isAbortionStep2Complete', null)
    window.localStorage.setItem('isAbortionStep3Complete', null)
    window.localStorage.setItem('isAbortionStep4Complete', null)
    window.localStorage.setItem('isAbortionStep5Complete', null)
    window.localStorage.setItem('isAbortionStep6Complete', null)
    window.localStorage.setItem('isAbortionStep7Complete', null)
    // Clear abortion assessment info
    window.localStorage.setItem('abortionAssessmentQ2TrueCount', 0)
    window.localStorage.setItem('abortionAssessmentQ2SelectedAnswers', [])
    window.localStorage.setItem('abortionAssessmentQ3TrueCount', 0)
    window.localStorage.setItem('abortionAssessmentQ3SelectedAnswers', [])
    window.localStorage.setItem('abortionAssessmentQ4TrueCount', 0)
    window.localStorage.setItem('abortionAssessmentQ4SelectedAnswers', [])
    window.localStorage.setItem('isAbortionAssessmentComplete', null)
    // Clear pill regimen info
    window.localStorage.setItem('abortionRegimenAStepAIsComplete', false)
    window.localStorage.setItem('abortionRegimenAStepCIsComplete', false)
    window.localStorage.setItem('abortionRegimenBStepBIsComplete', false)
    window.localStorage.setItem('abortionRegimenBStepB3HoursIsComplete', false)
    window.localStorage.setItem('abortionRegimenBStepBStepB6HoursIsComplete', false)
    window.localStorage.setItem('abortionPillRegimenSelected', null)
    window.localStorage.setItem("userPillDateAndTime", null);

    console.log(window.localStorage.getItem('isAbortionStep7Complete'))
    this.setState({
      resetAllData: true,
    })
    // window.location.reload()
  }

  goBack = () => {
    this.props.history.goBack()
  }

  render() {

    const { startOverBtnText, abortionMenuCardContent } = this.state

    const abortionMenuCardContentItems = abortionMenuCardContent.map((info, i) => <div className={'' + info.backgroundColor + ' topRoundDiv'} id={i} key={i}><AbortionMenuCardContent id={i} key={i} content={info} resetAllData={this.state.resetAllData} /></div>)


    return (
      <div className="minPageHeight100vh flexColumn flexGrow1 pageWithBottomMainNav backgroundColorWhite stepsMenuPage abortionStepsMenuPage">
        
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />
        <div className='pagesInnerDiv paddingTop40'>
          <div className='justText headerArea'>
            <div className='flexColumn padding90-30-62'>
              <p className='boldFont fontSize20 lineHeight26 colorBlue8'>{this.state.pageTitle}</p>

              <button className='alignSelfFlexEnd backgroundColorPeach4 borderRadius3rem padding0-15' onClick={this.startStepsOver}>
                <span className='regularFont fontSize11 lineHeight26 colorWhite'>{startOverBtnText}</span>
              </button>
            </div>
          </div> {/* .headerArea */}

          <div className='flexColumn'>
            {abortionMenuCardContentItems}
          </div>

        </div> {/* .pagesInnerDiv */}

        <BottomNavBar appLanguage={this.props.appLanguage} />
      </div>
    )
  }
}
