import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import * as ContentfulMarkdown from 'react-markdown'

import ReactGA from 'react-ga4'


import { IntroProp } from './IntroProp'

/* import { LargeButton } from '../../Components/LargeButton' */
import { PrevAndNextBottomNavTwo } from '../../Components/PrevAndNextBottomNavTwo'

import logoPeach from '../../Images/small-logo-peach.svg'
/* import arrowRight from '../../Images/arrowRight-white.svg'
import menuIcon from '../../Images/hamburger-menu-icon-white.svg' */
import abortionNavIcon from '../../Images/abortionNavIcon.svg'
import contraceptiveNavIcon from '../../Images/contraceptiveNavIcon.svg'
import resourcesIcon from '../../Images/resourcesIcon.svg'
/* import lockIcon from '../../Images/lockIcon-white.svg' */

/* import { TermsOfUseAlert } from '../TermsOfUse/TermsOfUseAlert'; */
/* import { TermsOfUseAlertTest2 } from '../../Components/TermsOfUseAlertTest2.js';
import TermsofuseModal from '../../Components/TermsofuseModal' */
/* import { CarouselItem } from 'react-bootstrap' */





export class IntroPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      /* showModal: true, */ // this is for the Termsofuse Modal Popup to show up as soon as the page loads
      // fadeIn: false,
      carouselIndex: 0,
      // currentPage: 3,
      totalPages: 4,

      intro1ImageUrl: '',
      intro1ImageUrlAlt: '',

      introInfoPara1: '',
      introInfoPara2: '',
      introInfoPara3: '',

      privacyTitle: '',
      privacyParagraph: '',
      privacyLogoText: '',
      privacyMenuText: '',
      privacyLockIconText: '',
      privacyBtnText: '',

      intro2ImageUrl: '',
      intro2ImageAlt: '',
      intro2Title: '',
      intro2Paragraph: '',
      intro2ButtonText: '',
      intro2NavText: '',

      intro3ImageUrl: '',
      intro3Title: '',
      intro3Paragraph: '',
      intro3ButtonText: '',
      intro3NavText: '',

      intro4ImageUrl: '',
      intro4Title: '',
      intro4Paragraph: '',
      intro4ButtonText: '',
      intro4NavText: '',
    }

    // this.fadeIn = false
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    ReactGA.send("/IntroPage")

    document.title = "Intro Page"

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */


    let getPrivacyTitle = ''
    let getPrivacyParagraph = ''
    let getPrivacyLogoText = ''
    let getPrivacyMenuText = ''
    let getPrivacyLockIconText = ''
    let getPrivacyBtnText = ''

/*     let title1 = ''
    let paragraph1 = ''
    let button1 = ''
 */
    let title2 = ''
    let paragraph2 = ''
    let button2 = ''
    let title3 = ''
    let paragraph3 = ''
    let button3 = ''
    let title4 = ''
    let paragraph4 = ''
    let button4 = ''

    let intro1MainImageUrl = ''
    let intro1MainImageUrlAlt = ''
    let intro2MainImageUrl = ''
    let intro2MainImageAlt = ''
    let intro3MainImageUrl = ''
    let intro3MainImageAlt = ''
    let intro4MainImageUrl = ''
    let intro4MainImageAlt = ''

    let splashEntries = []
    let introInfo1 = ''
    let introInfo2 = ''
    let introInfo3 = ''


/*     await (function () { */
      // Get page Entries
      const getEntries = localStorage.getItem('ayaAppEntries')
      const entries = JSON.parse(getEntries)

      const getIntroEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'valuePropositions');

      const getIntroPrivacyEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'introPrivacy');
/*       const getPageEntries = getIntroPrivacyEntries.filter(obj => obj.sys.id === '3huC2Zqs6UnQRUR0epbsTV');
 */   const getSplashEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'intro');
      



      if (getIntroEntries && getIntroEntries.length > 0) {
        let introEntries = getIntroEntries[0].fields


        if (introEntries) {
          if (introEntries.title1) {
            title2 = introEntries.title1[this.props.appLanguage]
          }
          if (introEntries.paragraph1) {
            paragraph2 = introEntries.paragraph1[this.props.appLanguage]
          }
          if (introEntries.button1) {
            button2 = introEntries.button1[this.props.appLanguage]
          }

          if (introEntries.title2) {
            title3 = introEntries.title2[this.props.appLanguage]
          }
          if (introEntries.paragraph2) {
            paragraph3 = introEntries.paragraph2[this.props.appLanguage]

          }
          if (introEntries.button2) {
            button3 = introEntries.button2[this.props.appLanguage]
          }

          if (introEntries.title3) {
            title4 = introEntries.title3[this.props.appLanguage]
          }
          if (introEntries.paragraph3) {
            paragraph4 = introEntries.paragraph3[this.props.appLanguage]
          }
          if (introEntries.button3) {
            button4 = introEntries.button3[this.props.appLanguage]
          }

        }
      } // getIntroEntries

      if (getIntroPrivacyEntries) {
        let introPrivacyEntries = getIntroPrivacyEntries[0].fields

        if (introPrivacyEntries) {
          if (introPrivacyEntries.headingText) {
            getPrivacyTitle = introPrivacyEntries.headingText[this.props.appLanguage]
          }
          if (introPrivacyEntries.paragraph) {
            getPrivacyParagraph = introPrivacyEntries.paragraph[this.props.appLanguage]
          }
          if (introPrivacyEntries.circleIconText) {
            getPrivacyLogoText = introPrivacyEntries.circleIconText[this.props.appLanguage]
          }
          if (introPrivacyEntries.dotIconText) {
            getPrivacyMenuText = introPrivacyEntries.dotIconText[this.props.appLanguage]
          }
          if (introPrivacyEntries.lockIconText) {
            getPrivacyLockIconText = introPrivacyEntries.lockIconText[this.props.appLanguage]
          }
          if (introPrivacyEntries.buttonText) {
            getPrivacyBtnText = introPrivacyEntries.buttonText[this.props.appLanguage]
          }
        }
      } // getIntroPrivacyEntries

      if (getSplashEntries && getSplashEntries.length > 0) {
        splashEntries.push(getSplashEntries[0].fields)
  
        if (splashEntries.length > 0) {
  
          if (splashEntries[0].introInfoPara1) {
            introInfo1 = splashEntries[0].introInfoPara1[this.props.appLanguage]
          }
          if (splashEntries[0].introInfoPara2) {
            introInfo2 = splashEntries[0].introInfoPara2[this.props.appLanguage]
          }
          if (splashEntries[0].introInfoPara3) {
            introInfo3 = splashEntries[0].introInfoPara3[this.props.appLanguage]
          }

        }
      }
      // Get page Assets
      const getAssets = localStorage.getItem('ayaAppAssets')
      const assets = JSON.parse(getAssets)

      const getIntro1MainImage = assets.filter(obj => obj.fields.title[this.props.appLanguage] === 'Intro Screen Illustration');
      const getIntro2MainImage = assets.filter(obj => obj.fields.title[this.props.appLanguage] === 'Value Proposition 1');
      const getIntro3MainImage = assets.filter(obj => obj.fields.title[this.props.appLanguage] === 'Value Prop 2 Illustration');
      const getIntro4MainImage = assets.filter(obj => obj.fields.title[this.props.appLanguage] === 'Value Proposition 3 Illustration');

      if (getIntro1MainImage && getIntro1MainImage.length > 0 && getIntro1MainImage[0].fields.file[this.props.appLanguage].url) {
        intro1MainImageUrl = 'https:' + getIntro1MainImage[0].fields.file[this.props.appLanguage].url
        if (getIntro1MainImage[0].fields.description) {
          intro1MainImageUrlAlt = getIntro1MainImage[0].fields.description[this.props.appLanguage]
        }
      }

      if (getIntro2MainImage && getIntro2MainImage.length > 0) {
        if (getIntro2MainImage[0].fields.file[this.props.appLanguage].url) {
          intro2MainImageUrl = 'https:' + getIntro2MainImage[0].fields.file[this.props.appLanguage].url
        }
        if (getIntro2MainImage[0].fields.description[this.props.appLanguage]) {
          intro2MainImageAlt = getIntro2MainImage[0].fields.description[this.props.appLanguage]
        }
      }
      if (getIntro3MainImage && getIntro3MainImage.length > 0) {
        if (getIntro3MainImage[0].fields.file[this.props.appLanguage].url) {
          intro3MainImageUrl = 'https:' + getIntro3MainImage[0].fields.file[this.props.appLanguage].url
        }
        if (getIntro3MainImage[0].fields.description[this.props.appLanguage]) {
          intro3MainImageAlt = getIntro3MainImage[0].fields.description[this.props.appLanguage]
        }
      }
      if (getIntro4MainImage && getIntro4MainImage.length > 0) {
        if (getIntro4MainImage[0].fields.file[this.props.appLanguage].url) {
          intro4MainImageUrl = 'https:' + getIntro4MainImage[0].fields.file[this.props.appLanguage].url
        }
        if (getIntro4MainImage[0].fields.description[this.props.appLanguage]) {
          intro4MainImageAlt = getIntro4MainImage[0].fields.description[this.props.appLanguage]
        }
      }


  /*   }).bind(this)() */


    this.setState({
      privacyTitle: getPrivacyTitle,
      privacyParagraph: getPrivacyParagraph,
      privacyLogoText: getPrivacyLogoText,
      privacyMenuText: getPrivacyMenuText,
      privacyLockIconText: getPrivacyLockIconText,
      privacyBtnText: getPrivacyBtnText,

      introInfoPara1: introInfo1,
      introInfoPara2: introInfo2,
      introInfoPara3: introInfo3,


      intro2Title: title2,
      intro2Paragraph: paragraph2,
      intro2ButtonText: button2,
      intro3Title: title3,
      intro3Paragraph: paragraph3,
      intro3ButtonText: button3,
      intro4Title: title4,
      intro4Paragraph: paragraph4,
      intro4ButtonText: button4,

      intro1ImageUrl: intro1MainImageUrl,
      intro1ImageUrlAlt: intro1MainImageUrlAlt,
      intro2ImageUrl: intro2MainImageUrl,
      intro2ImageAlt: intro2MainImageAlt,
      intro3ImageUrl: intro3MainImageUrl,
      intro3ImageAlt: intro3MainImageAlt,
      intro4ImageUrl: intro4MainImageUrl,
      intro4ImageAlt: intro4MainImageAlt,
    })
  } // componentDidMount()

 /*  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  }; */

  nextSlide = (selectedIndex, e) => {
    // window.scrollTo(0, 0)
    // e.preventDefault()

    this.setState({
      carouselIndex: selectedIndex,
    })

    if (selectedIndex === 4) { // 4 is the last page, so we redirect to home
      this.props.history.push('/TermsOfUseAlertTest');
    }
  };


  goToPrevPage = (e) => {
    if (this.state.carouselIndex !== 0) {
      this.nextSlide(this.state.carouselIndex - 1, e)
    }
    // const currentPageState = this.state.currentPage

    // if (currentPageState !== 1) {
    //   this.setState({
    //     currentPage: currentPageState - 1,
    //   })
    // }
  }


  goToNextPage = (e) => {
    if (this.state.carouselIndex !== this.state.totalPages - 1) {
      this.nextSlide(this.state.carouselIndex + 1, e)
    } else {
      localStorage.setItem("hasVisitedIntroAndTerms", "true");
      this.props.history.push('/TermsOfUseAlert');
    }
    // const currentPageState = this.state.currentPage
    // const totalPagesState = this.state.totalPages

    // if (currentPageState !== totalPagesState) {
    //   this.setState({
    //     currentPage: currentPageState + 1,
    //   })
    // } else {
    //   this.props.history.push('/home');
    // }
  }


  goToAbortionStepOne = () => {
    this.props.history.push('/abortion-step-1');
  }

  goToContraceptionAssessment = () => {
    this.props.history.push('/contraception-assessment');
  }

  goToAllyOrganizations = () => {
    this.props.history.push('/ally-organizations');
  }

 /*  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  handleModalOpen = () => {
    this.setState({ showModal: true });
  }; */

  render() {
    // const introInfo = this.state.introInfo.map((info, i) => <IntroProp id={i} key={i} info={info} />) // <p id={i} key={i}>{info}</p>
    // IntroProp.js has comments for this example above ^^

    const { intro1ImageUrl, intro1ImageUrlAlt, /* introInfoPara1, introInfoPara2, introInfoPara3,  privacyTitle, privacyParagraph, privacyLogoText */ /* privacyMenuText, privacyLockIconText, privacyBtnText, */ intro2ImageUrl, intro2ImageAlt, intro2Title, intro2Paragraph, intro2NavText, intro3ImageUrl, intro3ImageAlt, intro3Title, intro3Paragraph, intro3NavText, intro4ImageUrl, intro4ImageAlt, intro4Title, intro4Paragraph, intro4NavText } = this.state


    return (
      <div>
        <div className="marginBottom40"></div>
        <Carousel
          activeIndex={this.state.carouselIndex}
          // defaultActiveIndex={0}
          interval={100000000000000}
          controls={false}
          indicators={false}
          onSelect={this.nextSlide}
          wrap={false}
          className='owl-theme owl-item '
        >

          <Carousel.Item>
          
            {/* <div className={this.fadeIn ? 'fadePageTransition go' : 'fadePageTransition'}>
            {this.fadePageTransition()} */}
            <div className='appBackgroundColor minPageHeight100vh flexColumn introPageTwo'>
              <div className='flexColumn flexGrow1 justifyBetween pagesInnerDiv'>
                <div className='flexColumn alignCenter paddingTop23 paddingLeft36 paddingRight23 marginBottomNegative20'>
                  <img src={logoPeach} className='marginBottom20 smallLogo' alt='aya' />
{/*                   <p className='boldFont fontSize28 lineHeight28 colorWhite marginBottom10'>{this.props.introInfoPara1}</p>
 */}                  <ContentfulMarkdown children={this.state.introInfoPara1} className='boldFont fontSize28 lineHeight28 colorWhite marginBottom10 contentfulMarkdown' allowDangerousHtml={true} />
                  <ContentfulMarkdown children={this.state.introInfoPara2} className='colorPeach2 regularFont fontSize15 lineHeight16 contentfulMarkdown' allowDangerousHtml={true} />

{/*                   <p className='colorPeach2 regularFont fontSize15 lineHeight16'>{this.props.introInfoPara2}</p>
 */}                  <ContentfulMarkdown children={this.state.introInfoPara3} className='colorWhite regularFont fontSize15 lineHeight16 psMargin0 contentfulMarkdown' allowDangerousHtml={true} />
                  
{/*                   <div className='flexColumn flexGrow1'> */}

{/*  <TermsOfUseAlertTest/> */}
 
{/*    <IntroPage
   history={this.props.history}
   appLanguage={this.props.appLanguage}
   introInfoPara1={introInfoPara1}
   introInfoPara2={introInfoPara2}
   introInfoPara3={introInfoPara3}
 />
</div> */}
                  <PrevAndNextBottomNavTwo
                    showPrevButton={false}
                    prevBtnFunc={this.goToPrevPage}
                    showNextBtn={true}
                    nextBtnFunc={this.goToNextPage}
                  />
                  
                </div>

                <img src={intro1ImageUrl} className='width100Percent' alt={intro1ImageUrlAlt} />
              </div>
            </div>
            {/* </div> */}
          </Carousel.Item>





          <Carousel.Item>
            <div className='flexColumn flexGrow1'>
{/*             <TermsofuseModal
              show={showModal}
              onClose={this.handleModalClose}
              title="Modal Title"
              content="This is the content of the modal."
            /> */}

              {/*  <TermsOfUseAlert/> */}
              <IntroProp
                id={'IntroProp2'}
                key={'IntroProp2'}
                topImageUrl={intro2ImageUrl}
                topImageAlt={intro2ImageAlt}
                title={intro2Title}
                paragraph={intro2Paragraph}
                // buttonText={intro2ButtonText}
                // largeButtonFunc={this.goToAbortionStepOne}
                pageNavIcon={abortionNavIcon}
                pageNavText={intro2NavText}
                prevBtnFunc={this.goToPrevPage}
                nextBtnFunc={this.goToNextPage}
              />
            </div>
            {/* <TermsOfUseAlertTest2/> */}
          </Carousel.Item>

          <Carousel.Item>
            <div className='flexColumn flexGrow1'>
              <IntroProp
                id={'IntroProp3'}
                key={'IntroProp3'}
                topImageUrl={intro3ImageUrl}
                topImageAlt={intro3ImageAlt}
                title={intro3Title}
                paragraph={intro3Paragraph}
                // buttonText={intro3ButtonText}
                // largeButtonFunc={this.goToContraceptionAssessment}
                pageNavIcon={contraceptiveNavIcon}
                pageNavText={intro3NavText}
                prevBtnFunc={this.goToPrevPage}
                nextBtnFunc={this.goToNextPage}
              />
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className='owl-theme owl-item flexColumn flexGrow1'>
              <IntroProp
                id={'IntroProp4'}
                key={'IntroProp4'}
                topImageUrl={intro4ImageUrl}
                topImageAlt={intro4ImageAlt}
                title={intro4Title}
                paragraph={intro4Paragraph}
                // buttonText={intro4ButtonText}
                // largeButtonFunc={this.goToAllyOrganizations}
                pageNavIcon={resourcesIcon}
                pageNavText={intro4NavText}
                prevBtnFunc={this.goToPrevPage}
                nextBtnFunc={this.goToNextPage}
              />
            </div>
      
        </Carousel.Item>

          <Carousel.Item>
            <div></div> {/* This will go to /home */}
          </Carousel.Item>
        </Carousel>

      </div>
    )
  }
}
