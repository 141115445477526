import React from "react";
import { TopNavBar } from "../../Components/TopNavBar";
import { BottomNavBar } from "../../Components/BottomNavBar";
import { HomePageCardContent } from "./HomePageCardContent";
/* import { PrevButton } from '../../Components/PrevButton' */
import { withRouter } from "react-router-dom";

/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg';  */

import ReactGA from "react-ga4";
/* import { TermsOfUseAlert } from '../../Components/TermsOfUseAlert'; */

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      headerImage: "",
      homeCardContent: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.send("/home");

    document.title = "Home";


        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:show"]) */


    // Get page Entries
    const getEntries = localStorage.getItem("ayaAppEntries");
    const entries = JSON.parse(getEntries);

    const getHomeEntries = entries.filter(
      (obj) => obj.sys.contentType.sys.id === "home"
    );

    var homeEntries = [];
    var homeCardContentArray = [];
    var card1 = {};
    var card2 = {};
    var card3 = {};
    var card4 = {};
    var card5 = {};

    if (getHomeEntries && getHomeEntries.length > 0) {
      homeEntries.push(getHomeEntries[0].fields);

      if (homeEntries.length > 0) {
        if (homeEntries[0].title1) {
          card1["title"] = homeEntries[0].title1[this.props.appLanguage];
        }
        if (homeEntries[0].tag) {
          card1["tag"] = homeEntries[0].tag[this.props.appLanguage];
        }
        if (homeEntries[0].paragraph1) {
          card1["paragraph"] =
            homeEntries[0].paragraph1[this.props.appLanguage];
        }
        card1["backgroundColor"] = "backgroundColorPeach1";
        card1["goToPageLink"] = "/onboarding1";
        /*  card1['goToPageLink'] = this.navigateToOnboardingOrSteps; */
        card1["tagColor"] = "colorPeach1";
        card1["titleColor"] = "colorWhite";
        card1["paragraghColor"] = "colorWhite";

        if (homeEntries[0].title2) {
          card2["title"] = homeEntries[0].title2[this.props.appLanguage];
        }
        if (homeEntries[0].tag2) {
          card2["tag"] = homeEntries[0].tag2[this.props.appLanguage];
        }
        if (homeEntries[0].paragraph2) {
          card2["paragraph"] =
            homeEntries[0].paragraph2[this.props.appLanguage];
        }
        card2["backgroundColor"] = "backgroundColorBlue6";
        card2["goToPageLink"] = "/contraception-assessment";
        /* card2['goToPageLink'] = '/resources' */
        card2["tagColor"] = "colorBlue6";
        card2["titleColor"] = "colorWhite";
        card2["paragraghColor"] = "colorWhite";

        if (homeEntries[0].title3) {
          card3["title"] = homeEntries[0].title3[this.props.appLanguage];
        }
        if (homeEntries[0].tag3) {
          card3["tag"] = homeEntries[0].tag3[this.props.appLanguage];
        }
        if (homeEntries[0].paragraph3) {
          card3["paragraph"] =
            homeEntries[0].paragraph3[this.props.appLanguage];
        }
        card3["backgroundColor"] = "backgroundColorBlue8";
        card3["goToPageLink"] = "/testimonials";
        card3["tagColor"] = "colorBlue8";
        card3["titleColor"] = "colorWhite";
        card3["paragraghColor"] = "colorWhite";

        if (homeEntries[0].title4) {
          card4["title"] = homeEntries[0].title4[this.props.appLanguage];
        }
        if (homeEntries[0].tag4) {
          card4["tag"] = homeEntries[0].tag4[this.props.appLanguage];
        }
        if (homeEntries[0].paragraph4) {
          card4["paragraph"] =
            homeEntries[0].paragraph4[this.props.appLanguage];
        }
        card4["backgroundColor"] = "backgroundColorPink";
        card4["goToPageLink"] = "/self-care-advice";
        card4["tagColor"] = "colorPink";
        card4["titleColor"] = "colorBlue8";
        card4["paragraghColor"] = "colorBlue8";

        if (homeEntries[0].title5) {
          card5["title"] = homeEntries[0].title5[this.props.appLanguage];
        }
        if (homeEntries[0].tag5) {
          card5["tag"] = homeEntries[0].tag5[this.props.appLanguage];
        }
        card5["backgroundColor"] = "backgroundColorPurple";
        card5["goToPageLink"] = "/resources";
        card5["tagColor"] = "colorPurple";
        card5["titleColor"] = "colorWhite";
      }
    }

    homeCardContentArray.push(card1, card2, card5, card3, card4);

    this.setState({
      homeCardContent: homeCardContentArray,
    });

    // Get page Assets
    const getAssets = localStorage.getItem("ayaAppAssets");
    const assets = JSON.parse(getAssets);

    const getHomeMainImage = assets.filter(
      (obj) =>
        obj.fields.title[this.props.appLanguage] === "Home Screen Illustration"
    );

    var homeMainImage = "";

    if (
      getHomeMainImage &&
      getHomeMainImage.length > 0 &&
      getHomeMainImage[0].fields.file[this.props.appLanguage].url
    ) {
      homeMainImage =
        "https:" + getHomeMainImage[0].fields.file[this.props.appLanguage].url;
    }

    this.setState({
      headerImage: homeMainImage,
    });
  } // componentDidMount()

  /* white previous arrow button  */
  goBack = () => {
    this.props.history.goBack();
  };

  navigateToOnboardingOrSteps = () => {
    const lastOnboardingStep = localStorage.getItem("lastOnboardingStep");

    if (lastOnboardingStep === "onboarding2") {
      // if user has completed onboarding2, go to abortion page
      this.props.history.push("/abortion"); // go to abortion page
    } else if (lastOnboardingStep === "onboarding3") {
      // if user has completed onboarding3, go to abortion page
      this.props.history.push("/abortion"); // go to abortion page
    } else {
      // if user has not completed onboarding, go to onboarding1
      this.props.history.push("/onboarding1"); // go to onboarding1
    }
  };

  render() {
    const { homeCardContent, headerImage } = this.state;

    const homeCardContentItems = homeCardContent.map((info, i) => (
      <div
        className={"" + info.backgroundColor + " topRoundDiv"}
        id={i}
        key={i}
      >
        <HomePageCardContent
          id={i}
          key={i}
          content={info}
          onClick={
            i === 0
              ? this.navigateToOnboardingOrSteps
              : () => this.props.history.push(info.goToPageLink)
          } //
        />
      </div>
    ));

    /*     <div className={'' + info.backgroundColor + ' topRoundDiv'} id={i} key={i}><HomePageCardContent id={i} key={i} content={info} /></div>)
     */

    // <p id={i} key={i}>{info}</p>
    // IntroProp.js has comments for this example above ^^

    return (
      <div className="minPageHeight100vh flexColumn flexGrow1 pageWithBottomMainNav appBackgroundColor">
        <TopNavBar
          bgColor="#fff"
          showHamburgerMenu={true}
          menuIconColor={"blue"}
          logoColor={"peach"}
          bellColor={"blue"}
          newNotificationColor={"peach"}
          {...this.props}
          appLanguage={this.props.appLanguage}
          handleLanguageChange={this.props.handleLanguageChange}
          key={this.props.appLanguage}
        />
        <div className=" paddingTop70">
          <div
            className="backgroundColorBlue8 backgroundImgSizeCover dontRepeatImage headerArea"
            style={{ backgroundImage: `url(${headerImage})` }}
          >
            {/* <img src={this.state.backgroundImage} /> */}
          </div>

          <div className="flexColumn">{homeCardContentItems}</div>
        </div>{" "}
        {/* .pagesInnerDiv */}
        <BottomNavBar appLanguage={this.props.appLanguage} />
      </div>
    );
  }
}

export default withRouter(Home);

/**
 * The `withRouter` Higher-Order Component (HOC) is used to enhance the `Home`
 * component with routing-related props. This allows the component to access
 * the `history`, `location`, and `match` props, which are necessary for
 * navigation and route-related actions.
 *
 * In this specific case, the `withRouter` HOC is used to enable the `Home`
 * component to conditionally navigate to different pages based on the user's
 * abortion onboarding status. The component checks whether the user has completed
 * the abortion onboarding and navigates to the appropriate page when the main menu abortion button
 * is clicked.
 *
 * The `withRouter` HOC is part of the `react-router-dom` library and works by
 * taking the original `Home` component as input and returning a new component
 * with the additional routing props. This approach allows us to enhance the
 * component without modifying its implementation, promoting code reusability
 * and separation of concerns.
 */
