import React from 'react'
import { TopNavBar } from './TopNavBar'
import * as ContentfulMarkdown from 'react-markdown'
import thinkingIcon from '../Images/person-thinking-icon-white.svg'
import { LargeButton } from './LargeButton'
/* import prevBtnImg from '../Images/prevBtnImage-whiteAndBlue.svg' */
import { WHOLink } from './WHOLink'

export class HelpfulPopUp extends React.Component {
    // constructor(props) {
    //     super(props)
    // }


    componentDidMount() {
        window.scrollTo(0,0)
    } 


    goBack = () => {
        this.props.backToFunc()
    }

    btn1Function = () => {
        this.props.btn1Function()
    }

    btn2Function = () => {
        this.props.btn2Function()
    }

    render() {

        const { pageName, pageBackgroundColor, logoColor, bellColor, newNotificationColor, appLanguage, helpfulDivPaddingLeftRight, helpfulDivBackgroundColor, noThinkingIcon, helpfulDivTextColor, content, btn1TextColor, btn1BackgroundColor, btn2TextColor, btn2BackgroundColor } = this.props

        let extraPaddingTopBottom = ''

        if (noThinkingIcon) {
            extraPaddingTopBottom = 'paddingTop60 paddingBottom43'
        }

        return (
            <div className={'minPageHeight100vh flexColumn flexGrow1 ' + pageBackgroundColor + ' helpfulPopUp'}>
                <TopNavBar bgColor="#071f56" showHamburgerMenu={true} menuIconColor={'white'} logoColor={logoColor} bellColor={bellColor} newNotificationColor={newNotificationColor} {...this.props} appLanguage={appLanguage} />

                <div className='width100Percent flexColumn flexGrow1 justifyCenter alignCenter paddingTop75 paddingLeftRight20 pagesInnerDiv'>

                    <div className='marginBottom45'></div>

                    <div className={'width100Percent flexColumn alignCenter textAlignCenter paddingBottom25 borderRadius3rem ' + helpfulDivPaddingLeftRight + ' ' + helpfulDivBackgroundColor + ' ' + helpfulDivTextColor + ' ' + extraPaddingTopBottom}>

                        {!noThinkingIcon &&
                            <div className={'' + helpfulDivBackgroundColor + ' padding20-25 marginTopNegative45 borderRadius50Percent topCircle'}>
                                <img src={thinkingIcon} className='height60 widthAuto thinkingIcon' alt='thinking' />
                            </div>
                        }

                        {pageName === 'gestationalPopUp' ?
                            <div className='boldFont fontSize18 lineHeight20 colorBlue8 psMargin0 marginBottom16'>
                                <span>{content.titleText1}</span>
                                <span> {content.titleTextNumOfWeeks} </span>
                                <span>{content.titleText2}</span>
                            </div>
                            :
                            <ContentfulMarkdown children={content.title ? content.title : content.heading1} className='boldFont fontSize18 lineHeight20 psMargin0 marginBottom16 contentfulMarkdown' allowDangerousHtml={true} />
                        }

                        <ContentfulMarkdown children={content.text1} className='regularFont fontSize15 lineHeight18 textAlignLeft psMargin0 marginBottom18 contentfulMarkdown' allowDangerousHtml={true} />

                        {content.text2 ?
                            <ContentfulMarkdown children={content.text2} className='regularFont fontSize13 lineHeight15 textAlignLeft psMargin0 marginBottom18 contentfulMarkdown' allowDangerousHtml={true} />
                        : null }

                        {content.btn1Text ?
                            <LargeButton
                                largeButtonFunc={() => this.btn1Function()}
                                text={content.btn1Text}
                                textColor={btn1TextColor}
                                backgroundColor={btn1BackgroundColor}
                            />
                        : null}

                        {content.btn2Text ?
                            <div>
                                <div className='marginBottom10'></div>
                                <LargeButton
                                    largeButtonFunc={() => this.btn2Function()}
                                    text={content.btn2Text}
                                    textColor={btn2TextColor}
                                    backgroundColor={btn2BackgroundColor}
                                />
                            </div>
                        : null}
                        <div className='paddingTop25'></div>
                        <WHOLink 
                        fontColor="#fff"
                        appLanguage={this.props.appLanguage}
                        {...this.props} /> {/* This is the WHO link that is necessary for iOS app store */}
                        </div>
                </div>

               {/*  <button onClick={() => this.goBack()} className='width30Height30 margin20 justifySelfFlexEnd'>
                    <img src={prevBtnImg} className='width100Percent prevImg' alt='back' />
                </button> */}
            </div>
        )
    }
}
