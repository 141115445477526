import React from 'react';
import * as ContentfulMarkdown from 'react-markdown'
import { WHOLink } from '../../Components/WHOLink';


export class SelfCareSlide3 extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            headerImage: '',
            headerImageAlt: '',
            title: '',
            text: '',
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)

        // this hides the Crisp Chat 
       /*  $crisp.push(["do", "chat:hide"]) */

        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)
        // Content type ID
        const getSelfCareEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'selfCareAdviceSlider');
        // Then Entry ID instead
        const getpageEntries = getSelfCareEntries.filter(obj => obj.sys.id === 'zPoL1AOpoXc3aHwkeMlCd');

        var pageEntries = []
        var title1 = ''
        var text1 = ''

        if (getpageEntries && getpageEntries.length > 0) {
            pageEntries.push(getpageEntries[0].fields)

            if (pageEntries.length > 0) {

                if (pageEntries[0].title) {
                    title1 = pageEntries[0].title[this.props.appLanguage]
                }
                if (pageEntries[0].copy1) {
                    text1 = pageEntries[0].copy1[this.props.appLanguage]
                }
            }
        }


        this.setState({
            title: title1,
            text: text1
        })




        // Get page Assets
        const getAssets = localStorage.getItem('ayaAppAssets')
        const assets = JSON.parse(getAssets)

        const getHeaderImg = assets.filter(obj => obj.sys.id === '3eGb9EmLXmzWdPgiKmXlnT');

        var pageMainImage = ''
        var pageMainImageAlt = ' '

        if (getHeaderImg && getHeaderImg.length > 0 && getHeaderImg[0].fields.file[this.props.appLanguage].url) {
            pageMainImage = 'https:' + getHeaderImg[0].fields.file[this.props.appLanguage].url

            if (getHeaderImg[0].fields.description) {
                pageMainImageAlt = getHeaderImg[0].fields.description[this.props.appLanguage]
            }
        }


        this.setState({
            headerImage: pageMainImage,
            headerImageAlt: pageMainImageAlt,
        })


    } // componentDidMount()


    
    // goBackAStep = () => {
    //     this.props.history.push('/abortion-step-7');
    // }

    // goToOrganizations = () => {
    //     this.props.history.push('/ally-organizations');
    // }

    render() {

        const { headerImage, headerImageAlt, title, text } = this.state

        return (
            
            <div className='flexColumn flexGrow1 backgroundColorBlue8 selfCareSlides slide3 paddingTop70'>
                <div className='flexColumn headerArea'>
                    <img src={headerImage} className='width75Percent positionAbsolute absoluteTopRight0 zIndex50' alt={headerImageAlt} />
                </div>

                <div className='flexGrow1 backgroundColorWhite topRoundDiv'>
                    <div className='flexColumn selfCareSlidePadding paddingTop24PercentVw paddingLeftRight35 paddingBottom40 topRoundDivInnerDiv'>
                        <ContentfulMarkdown children={title} className='boldFont fontSize24 lineHeight26 colorBlue8 psMargin0 marginBottom24 contentfulMarkdown' />

                        <ContentfulMarkdown children={text} className='regularFont fontSize15 lineHeight16 colorDarkGrey psMargin0 contentfulMarkdown' allowDangerousHtml={true} />
                        <WHOLink 
                        fontColor="#071f56"
                        appLanguage={this.props.appLanguage}
                        {...this.props} /> {/* This is the WHO link that is necessary for iOS app store */}
                        </div>
                </div>
            </div>
        
        )
    }
}
