import React from 'react';
import * as ContentfulMarkdown from 'react-markdown'
import { WHOLink } from '../../Components/WHOLink';


export class SelfCareSlide2 extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            subHeader1: '',
            text1: '',
            subHeader2: '',
            text2: '',
            subHeader3: '',
            text3: '',
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)
        // Content type ID
        const getContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'selfCareAdviceSlider');
        // Then Entry ID instead
        const getpageEntries = getContentEntries.filter(obj => obj.sys.id === '4J7emaY1SZyTW4Qy0AGT4l');

        var pageEntries = []
        var subheader1 = ''
        var pageText1 = ''
        var subheader2 = ''
        var pageText2 = ''
        var subheader3 = ''
        var pageText3 = ''

        if (getpageEntries && getpageEntries.length > 0) {
            pageEntries.push(getpageEntries[0].fields)

            if (pageEntries.length > 0) {

                if (pageEntries[0].subheader1) {
                    subheader1 = pageEntries[0].subheader1[this.props.appLanguage]
                }
                if (pageEntries[0].copy1) {
                    pageText1 = pageEntries[0].copy1[this.props.appLanguage]
                }
                if (pageEntries[0].subheader2) {
                    subheader2 = pageEntries[0].subheader2[this.props.appLanguage]
                }
                if (pageEntries[0].copy2) {
                    pageText2 = pageEntries[0].copy2[this.props.appLanguage]
                }
                if (pageEntries[0].subheader3) {
                    subheader3 = pageEntries[0].subheader3[this.props.appLanguage]
                }
                if (pageEntries[0].copy3) {
                    pageText3 = pageEntries[0].copy3[this.props.appLanguage]
                }
            }
        }


        this.setState({
            subHeader1: subheader1,
            text1: pageText1,
            subHeader2: subheader2,
            text2: pageText2,
            subHeader3: subheader3,
            text3: pageText3,
        })

    } // componentDidMount()

   
  

    // goBackAStep = () => {
    //     this.props.history.push('/abortion-step-7');
    // }

    // goToOrganizations = () => {
    //     this.props.history.push('/ally-organizations');
    // }

    render() {

        const { subHeader1, text1, subHeader2, text2, subHeader3, text3 } = this.state

        return (
            
            <div className='flexColumn flexGrow1 backgroundColorBlue8 selfCareSlides slide2 paddingTop70'>
                <div className='flexColumn height100'>

                </div>

                <div className='flexGrow1 backgroundColorWhite topRoundDiv'>
                    <div className='flexColumn padding30-35-7 topRoundDivInnerDiv'>
                        <ContentfulMarkdown children={subHeader1} className='boldFont fontSize16 lineHeight16 colorPeach4 psMargin0 paddingBottom7 needsBorders borderBottomWidth1 borderColorPeach4 marginBottom7 contentfulMarkdown' />

                        <ContentfulMarkdown children={text1} className='regularFont fontSize15 lineHeight16 colorDarkGrey psMargin0 marginBottom16 contentfulMarkdown' allowDangerousHtml={true} />

                        <ContentfulMarkdown children={subHeader2} className='boldFont fontSize16 lineHeight16 colorPeach4 psMargin0 paddingBottom7 needsBorders borderBottomWidth1 borderColorPeach4 marginBottom7 contentfulMarkdown' />

                        <ContentfulMarkdown children={text2} className='regularFont fontSize15 lineHeight16 colorDarkGrey psMargin0 marginBottom16 contentfulMarkdown' allowDangerousHtml={true} />

                        <ContentfulMarkdown children={subHeader3} className='boldFont fontSize16 lineHeight16 colorPeach4 psMargin0 paddingBottom7 needsBorders borderBottomWidth1 borderColorPeach4 marginBottom7 contentfulMarkdown' />

                        <ContentfulMarkdown children={text3} className='regularFont fontSize15 lineHeight16 colorDarkGrey psMargin0 marginBottom16 contentfulMarkdown' allowDangerousHtml={true} />
                        <WHOLink 
                        fontColor="#071f56"
                        appLanguage={this.props.appLanguage}
                        {...this.props} /> {/* This is the WHO link that is necessary for iOS app store */}
                        </div>
                </div>
            </div>
        
        )
    }
}
