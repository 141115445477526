const prod = {
  apiGateway: {
    REGION: "us-west-1",
    URL: "https://xms8fmygq8.execute-api.us-west-1.amazonaws.com/dev/",
  },
};

// const staging = {
//   apiGateway: {
//     REGION: "us-west-1",
//     URL: "https://xms8fmygq8.execute-api.us-west-1.amazonaws.com/staging/",
//   },
// };

const config = prod;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
