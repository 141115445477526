import React from 'react';
import ReactGA from 'react-ga4';
/* import { Link } from 'react-router-dom' */
import { TopNavBar } from '../../Components/TopNavBar'
import importantIconPeach from '../../Images/important-outline-icon-peach.svg'
import * as ContentfulMarkdown from 'react-markdown'
import { LargeButton } from '../../Components/LargeButton'
/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */
import messageIcon from '../../Images/message-icon-white.svg'
import { WHOLink } from '../../Components/WHOLink';


export class AbortionStep7Failed extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            titleA: '',
            textA: '',
            titleB: '',
            textB: '',
            titleC: '',
            textC: '',
            btnText: '',
        }
    }


    componentDidMount() {
        ReactGA.send("/abortion-step-7-failed")
        window.scrollTo(0, 0)

        document.title = "Abortion Step 7 Failed"
                
        // this shows the Crisp Chat 
        /* $crisp.push(["do", "chat:show"]) */

        const checkPageAccess = localStorage.getItem('isAbortionStep7Complete')
        const getTextToShow = localStorage.getItem('showAbortionStep7FailText')

        if (checkPageAccess !== 'true' || getTextToShow === null) {
            this.props.history.push('/abortion-step-7');
        }


        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)
        // Content type ID
        const getStep7FailedEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'alert');
        // Then Entry ID instead
        // Alert A
        const getAlertAEntries = getStep7FailedEntries.filter(obj => obj.sys.id === '7G3uilFRkQttNsfN62JLc0');

        var alertAEntries = []
        var titleForA = ''
        var textForA = ''
        var ctaCopy = ''

        if (getAlertAEntries && getAlertAEntries.length > 0) {
            alertAEntries.push(getAlertAEntries[0].fields)

            if (alertAEntries.length > 0) {

                if (alertAEntries[0].title) {
                    titleForA = alertAEntries[0].title[this.props.appLanguage]
                }
                if (alertAEntries[0].paragraph) {
                    textForA = alertAEntries[0].paragraph[this.props.appLanguage]
                }
                if (alertAEntries[0].ctaCopy) {
                    ctaCopy = alertAEntries[0].ctaCopy[this.props.appLanguage]
                }
            }
        }

        // Alert B
        const getAlertBEntries = getStep7FailedEntries.filter(obj => obj.sys.id === 'FXszGlz2gVAns61DfBgCu');

        var alertBEntries = []
        var titleForB = ''
        var textForB = ''

        if (getAlertBEntries && getAlertBEntries.length > 0) {
            alertBEntries.push(getAlertBEntries[0].fields)

            if (alertBEntries.length > 0) {

                if (alertBEntries[0].title) {
                    titleForB = alertBEntries[0].title[this.props.appLanguage]
                }
                if (alertBEntries[0].paragraph) {
                    textForB = alertBEntries[0].paragraph[this.props.appLanguage]
                }
            }
        }

        // Alert C
        const getAlertCEntries = getStep7FailedEntries.filter(obj => obj.sys.id === '5rTgqfMy6sb53DefCrtirl');

        var alertCEntries = []
        var titleForC = ''
        var textForC = ''

        if (getAlertCEntries && getAlertCEntries.length > 0) {
            alertCEntries.push(getAlertCEntries[0].fields)

            if (alertCEntries.length > 0) {

                if (alertCEntries[0].title) {
                    titleForC = alertCEntries[0].title[this.props.appLanguage]
                }
                if (alertCEntries[0].paragraph) {
                    textForC = alertCEntries[0].paragraph[this.props.appLanguage]
                }
            }
        }


        this.setState({
            textToShow: getTextToShow,
            titleA: titleForA,
            textA: textForA,
            titleB: titleForB,
            textB: textForB,
            titleC: titleForC,
            textC: textForC,
            btnText: ctaCopy
        })
    } // componentDidMount()



    goBackAStep = () => {
        this.props.history.push('/abortion-step-7');
    }

    goToFeedback = () => {
        this.props.history.push('/feedback');
    }

    goBack = () => {
        this.props.history.goBack()
      }

    render() {

        const { textToShow, titleA, textA, titleB, textB, titleC, textC, btnText } = this.state

        var title = ''
        var text = ''

        if (textToShow === 'A') {
            title = titleA
            text = textA
        } else if (textToShow === 'B') {
            title = titleB
            text = textB
        } else if (textToShow === 'C') {
            title = titleC
            text = textC
        }

        return (
            <div className="minPageHeight100vh flexColumn flexGrow1 backgroundColorWhite abortionStepPages">
               
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

                <div className='flexColumn flexGrow1 justifyBetween textAlignCenter marginTop135 landscapeMarginTop95 pagesInnerDiv'>
                    <div className='paddingLeftRight35 landscapeWidth67Percent'>
                        <img src={importantIconPeach} className='width52Height52 marginBottom36 marginLeftRightAuto' alt='!' />

                        <ContentfulMarkdown children={title} className='boldFont fontSize18 lineHeight23 colorPeach4 marginBottom8 contentfulMarkdown' />

                        <ContentfulMarkdown children={text} className='boldFont fontSize15 lineHeight23 colorBlue8 marginBottom26 contentfulMarkdown' />

                        {/* <LargeButton
                            largeButtonFunc={() => this.goToFeedback()}
                            className='textAlignCenter'
                            text={<p className='width100Percent flexRow justifyCenter alignCenter marginBottom0'>
                                <img src={messageIcon} style={{ width: '25px', height: 'auto', marginRight: '10px' }} alt='' />
                                {btnText}
                            </p>}
                            textColor={'colorWhite'}
                            backgroundColor={'backgroundColorBlue8'}
                        /> */}

                        <WHOLink 
                            fontColor="#071f56"
                            appLanguage={this.props.appLanguage}
                            {...this.props} 
                        /> {/* This is the WHO link that is necessary for iOS app store */}
                    </div>

                   {/*  <Link to='/abortion-step-7' className='width30Height30 margin20 justifySelfFlexEnd'>
                        <img src={prevBtnImg} className='width100Percent prevImg' alt='back' />
                    </Link> */}
                </div>
            </div>
        )
    }
}
