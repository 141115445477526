import React from 'react'
import ReactGA from 'react-ga4'
/* import { IntroPage } from './IntroPage' */
import ProgressBar from "@badrap/bar-of-progress";
/* import { TermsOfUseAlert } from '../TermsOfUse/TermsOfUseAlert';
 */

/* Splash screen and Welcome intro slides */
export class SplashPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showSplashPage: true,
      bigLogoUrl: '',
      splashPageInfo: [],
      subHeading: '',
      poweredByText: '',
      introInfoPara1: '',
      introInfoPara2: '',
      introInfoPara3: '',
      mainImageUrl: '',
      mainImageUrlAlt: '',
    }
  }

  componentDidMount() {
    ReactGA.send("/")
    window.scrollTo(0, 0)

    document.title = "Intro"

    /* document.documentElement.style.setProperty('--status-bar-height', '20px', 'important'); */


        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

        const computedStyle = window.getComputedStyle(document.querySelector('.iPhoneSafeArea'));
  console.log("Computed margin-top:", computedStyle.marginTop);


    const hasVisitedIntroAndTerms = localStorage.getItem("hasVisitedIntroAndTerms");

    const redirectTo = hasVisitedIntroAndTerms === "true" ? "/home" : "/language-selection";

    const getEntries = localStorage.getItem('ayaAppEntries')
    
    const entries = JSON.parse(getEntries)
    const getSplashEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'intro');
    
    console.log('getSplashEntries:', getSplashEntries); // Moved here

    /* progress bar const and modifications to its styling.
    progress bar gets started here with the .start method */
    const progress = new ProgressBar(
      {
        size: 10,
        color: "#F85258",
        delay: 0,
        /* className: "iPhoneSafeArea", */
      }
    );
    progress.start();  

/*     const progressBarElement = document.querySelector('.bar-of-progress');
if (progressBarElement) {
  progressBarElement.style.marginTop = '50px';
  progressBarElement.style.paddingTop = '50px';
} */


    var splashEntries = []
    var splashSubHeading = ''
    var splashPoweredByText = ''
    var introInfo1 = ''
    var introInfo2 = ''
    var introInfo3 = ''

    console.log('splashEntries:', splashEntries);
    console.log('appLanguage:', this.props.appLanguage);




    if (getSplashEntries && getSplashEntries.length > 0) {
      splashEntries.push(getSplashEntries[0].fields)
    
      console.log('getSplashEntries:', getSplashEntries);
    
    
    

      if (splashEntries.length > 0) {
        console.log('attribution:', splashEntries[0].attribution);
        console.log('introInfoTitle:', splashEntries[0].introInfoTitle);
/*         if (splashEntries[0].attribution) {
          splashSubHeading = splashEntries[0].attribution[this.props.appLanguage].toLowerCase()
        }
        if (splashEntries[0].introInfoTitle) {
          splashPoweredByText = splashEntries[0].introInfoTitle[this.props.appLanguage]
        } */

        if (splashEntries[0]?.attribution) {
          splashSubHeading = splashEntries[0].attribution[this.props.appLanguage]?.toLowerCase() ?? '';
        }
        if (splashEntries[0]?.introInfoTitle) {
          splashPoweredByText = splashEntries[0].introInfoTitle[this.props.appLanguage] ?? '';
        }
        if (splashEntries[0].introInfoPara1) {
          introInfo1 = splashEntries[0].introInfoPara1[this.props.appLanguage]
        }
        if (splashEntries[0].introInfoPara2) {
          introInfo2 = splashEntries[0].introInfoPara2[this.props.appLanguage]
        }
        if (splashEntries[0].introInfoPara3) {
          introInfo3 = splashEntries[0].introInfoPara3[this.props.appLanguage]
        }

        this.setState({
          subHeading: splashSubHeading,
          poweredByText: splashPoweredByText,
          introInfoPara1: introInfo1,
          introInfoPara2: introInfo2,
          introInfoPara3: introInfo3,
        })
      }
    }



    // Get page Assets
    const getAssets = localStorage.getItem('ayaAppAssets')
    const assets = JSON.parse(getAssets)
    
    const getBigLogo = assets.filter(obj => obj.fields.title[this.props.appLanguage] === "Aya Logo");
    const getSplashImage = assets.filter(obj => obj.fields.title[this.props.appLanguage] === 'Splash Screen Illustration');

    var splashLogoUrl = ''
    var bottomImageUrl = ''
    var bottomImageUrlAlt = ''

    if (getBigLogo && getBigLogo.length > 0 && getBigLogo[0].fields.file[this.props.appLanguage].url) {
      splashLogoUrl = 'https:' + getBigLogo[0].fields.file[this.props.appLanguage].url
    }

    if (getSplashImage && getSplashImage.length > 0 && getSplashImage[0].fields.file[this.props.appLanguage].url) {
      bottomImageUrl = 'https:' + getSplashImage[0].fields.file[this.props.appLanguage].url
      if (getSplashImage[0].fields.description) {
        bottomImageUrlAlt = getSplashImage[0].fields.description[this.props.appLanguage]
      }
    }

    this.setState({
      bigLogoUrl: splashLogoUrl,
      mainImageUrl: bottomImageUrl,
      mainImageUrlAlt: bottomImageUrlAlt,
    })
  

    

    // Show intro screens after 4 seconds, show progress bar while loading

    /*function that gets called below in setTimeout() 
    in order to show the progress bar until the intro page is loaded */
    function progressBar () {
      setTimeout(() => {
        progress.finish();
        this.props.history.push(redirectTo);
      }, 4500);
  }


/*     setTimeout(() => {
      this.setState({
        showSplashPage: false,
      })
    }, 4500);
  } */
  
   setTimeout(() => {
    this.setState({
      showSplashPage: false,
    })
  }, 5000, progressBar.bind(this)());

/*   setTimeout(progressBar.bind(this), 4000); */
}
  // componentDidMount()





  render() {

    const { bigLogoUrl, /* showSplashPage, introInfoPara1, introInfoPara2, introInfoPara3, */ mainImageUrl, mainImageUrlAlt } = this.state

      return (
        
        <div className='flexColumn flexGrow1 iPhoneSafeArea'>
          {/* { */}

              <div className='minPageHeight100vh splashPage iPhoneSafeArea'>
              <div className="marginBottom40"></div>
                {/* Will show intro info after a few sseconds(4.5 secs) */}
                <div className='width49Percent marginAuto flexColumn alignCenter paddingTop100 pagesInnerDiv'>
                  <img src={bigLogoUrl} className='width100HeightAuto splashPageLogo' alt='Aya' />
                  <p className='colorPeach3 pattayaFont fontSize25 alignSelfFlexEnd marginTopNegative18'>{this.state.subHeading}</p>
                  <p className='boldFont colorWhite fontSize9 marginTop54'>{this.state.poweredByText}</p>
                </div>
                <img src={mainImageUrl} className='width100Percent justifySelfFlexEnd marginTopNegative30' alt={mainImageUrlAlt} />
              </div >

             

            

               <div className='flexColumn flexGrow1'>

               
{/*                 
                  <IntroPage
                  history={this.props.history}
                  appLanguage={this.props.appLanguage}
                  introInfoPara1={introInfoPara1}
                  introInfoPara2={introInfoPara2}
                  introInfoPara3={introInfoPara3}
                /> */}
              </div>
        {/*   } */}
        </div>
      )
  }
}