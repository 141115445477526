import React from 'react'
import ReactGA from 'react-ga4'
import * as ContentfulMarkdown from 'react-markdown'

import { TopNavBar } from '../../Components/TopNavBar'
import { LargeButton } from '../../Components/LargeButton'
import { BottomNavBar } from '../../Components/BottomNavBar'
/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */


export class PrivacyPrinciples extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            image1: '',
            headingText: '',
            paragraph1: '',
            btn1Text: '',
            paragraph2: '',
            btn2Text: '',
            paragraph3: '',
            btn3Text: '',
        }
    }


    componentDidMount() {
        ReactGA.send("/privacy-principles")
        window.scrollTo(0, 0)

        document.title = "Privacy Principles"

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

        // Get page Entries
        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)

        const getPrinciplesEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'privacyPrinciples');


        // Get page Assets
        const getAssets = localStorage.getItem('ayaAppAssets')
        const assets = JSON.parse(getAssets)

        const getMainImage = assets.filter(obj => obj.fields.title[this.props.appLanguage] === 'CYBERSECURITY-02 (1)');

        let mainImage = ''

        if (getMainImage && getMainImage.length > 0 && getMainImage[0].fields.file[this.props.appLanguage].url) {
            mainImage = 'https:' + getMainImage[0].fields.file[this.props.appLanguage].url
        }


        let principleEntries = []
        let getHeadingText = ''
        let getParagraph1 = ''
        let getBtn1Text = ''
        let getParagraph2 = ''
        let getBtn2Text = ''
        let getParagraph3 = ''
        let getBtn3Text = ''

        if (getPrinciplesEntries && getPrinciplesEntries.length > 0) {
            principleEntries.push(getPrinciplesEntries[0].fields)

            if (principleEntries.length > 0) {

                if (principleEntries[0].headingText) {
                    getHeadingText = principleEntries[0].headingText[this.props.appLanguage]
                }
                if (principleEntries[0].paragraph) {
                    getParagraph1 = principleEntries[0].paragraph[this.props.appLanguage]
                }
                if (principleEntries[0].buttonText1) {
                    getBtn1Text = principleEntries[0].buttonText1[this.props.appLanguage]
                }

                if (principleEntries[0].shortText) {
                    getParagraph2 = principleEntries[0].shortText[this.props.appLanguage]
                }

                if (principleEntries[0].buttonText2) {
                    getBtn2Text = principleEntries[0].buttonText2[this.props.appLanguage]
                }

                if (principleEntries[0].shortText2) {
                    getParagraph3 = principleEntries[0].shortText2[this.props.appLanguage]
                }

                if (principleEntries[0].buttonText3) {
                    getBtn3Text = principleEntries[0].buttonText3[this.props.appLanguage]
                }

                this.setState({
                    image1: mainImage,
                    headingText: getHeadingText,
                    paragraph1: getParagraph1,
                    btn1Text: getBtn1Text,
                    paragraph2: getParagraph2,
                    btn2Text: getBtn2Text,
                    paragraph3: getParagraph3,
                    btn3Text: getBtn3Text,
                })
            }
        }
    }


    goBack = () => {
        this.props.history.goBack()
      }

    render() {
        return (
            <div className="minPageHeight100vh flexColumn flexGrow1 pageWithBottomMainNav privacyPrinciplesPage">
              
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

                <div className='pagesInnerDiv paddingTop60'>
                    <div className='paddingTop80 justText headerArea'>
                        <div className='padding0-30-57 textAlignCenter'>
                            <img src={this.state.image1} style={{ width: '145px', height: 'auto' }} alt='' />
                        </div>
                    </div>

                    <div className='flexGrow1 backgroundColorWhite topRoundDiv'>
                        <div className='padding56-21-30-34 topRoundDivInnerDiv'>
                            <ContentfulMarkdown children={this.state.headingText} className='boldFont fontSize20 lineHeight28 colorBlue8' allowDangerousHtml={true} />

                            <ContentfulMarkdown children={this.state.paragraph1} className='boldFont regularFont fontSize14 lineHeight14 colorPeach4' allowDangerousHtml={true} />

                            <LargeButton
                                largeButtonFunc={() => this.props.history.push('/privacypolicypage')}
                                text={this.state.btn1Text}
                                textColor={'colorWhite'}
                                backgroundColor={'backgroundColorBlue8 marginBottom28'}
                            />

                            <ContentfulMarkdown children={this.state.paragraph2} className='boldFont fontSize14 lineHeight15 colorPeach4' allowDangerousHtml={true} />

                            <LargeButton
                                largeButtonFunc={() => this.props.history.push('/pin-setup')}
                                text={this.state.btn2Text}
                                textColor={'colorWhite'}
                                backgroundColor={'backgroundColorBlue8 marginBottom28'}
                            />

                            <ContentfulMarkdown children={this.state.paragraph3} className='boldFont fontSize14 lineHeight15 colorPeach4' allowDangerousHtml={true} />

                            <LargeButton
                                largeButtonFunc={() => this.props.history.push('user-security')}
                                text={this.state.btn3Text}
                                textColor={'colorWhite'}
                                backgroundColor={'backgroundColorBlue8'}
                            />
                        </div>
                    </div>

                </div> {/* pagesInnerDiv */}

                <BottomNavBar appLanguage={this.props.appLanguage} />
            </div>
        )
    }
}
