import React from 'react';
import { TopNavBar } from '../../Components/TopNavBar'
/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */
import { LargeButton } from '../../Components/LargeButton'
import * as ContentfulMarkdown from 'react-markdown'
import bookmarkIcon from '../../Images/bookmark-icon-peach.svg'
import { BottomNavBar } from '../../Components/BottomNavBar'
import ReactGA from 'react-ga4';
/* import messageIcon from '../../Images/message-icon-white.svg' */
import { WHOLink } from '../../Components/WHOLink';


export class AbortionPillRegimenA extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            // stepNum: '',
            title: '',
            subHeader: '',
            stepATag: '',
            StepAIsComplete: false,
            stepARadioText: '',
            stepBTag: '',
            stepCTag: '',
            StepCIsComplete: false,
            stepCRadioText: '',
            text1: '',
            text2: '',
            text3: '',
            text4: '',
            text5: '',
            text6: '',
            image2: '',
            image2Alt: '',
            image3: '',
            image3Alt: '',
            // image4: '',
            // image4Alt: '',
            btn1Text: '',
            btn2Text: '',
            btn3Text: '',
            btn4Text: '',
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)
        ReactGA.send("/abortion-step-4-pill-regimen-a")

        document.title = "Abortion Pill Regimen A"
                      
        // this hides the Crisp Chat Window from the page
        /* $crisp.push(["do", "chat:show"]); */


        if (localStorage.getItem('abortionRegimenAStepAIsComplete') === 'true') {
            this.setState({
                StepAIsComplete: true,
            })
        }
        if (localStorage.getItem('abortionRegimenAStepCIsComplete') === 'true') {
            this.setState({
                StepCIsComplete: true,
            })
        }


        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)
        // Content type ID
        const getAbortionStepsEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'step1');
        // Then Entry ID instead
        const getStepEntries = getAbortionStepsEntries.filter(obj => obj.sys.id === '2TP6G86TGIUIUbkrLvny2x');

        var stepEntries = []
        // var stepNumber = ''
        var stepTitle = ''
        var stepSubHeader = ''
        var stepLetterA = ''
        var stepARadioText1 = ''
        var stepLetterB = ''
        var stepLetterC = ''
        var stepCRadioText1 = ''
        var stepText1 = ''
        var stepText2 = ''
        var stepText3 = ''
        var stepText4 = ''
        var stepText5 = ''
        var stepText6 = ''
        var ctaCopy1 = ''
        var ctaCopy2 = ''
        var ctaCopy3 = ''
        var ctaCopy4 = ''


        if (getStepEntries && getStepEntries.length > 0) {
            stepEntries.push(getStepEntries[0].fields)

            if (stepEntries.length > 0) {

                // if (stepEntries[0].stepNumber) {
                //     stepNumber = stepEntries[0].stepNumber[this.props.appLanguage]
                // }
                if (stepEntries[0].title) {
                    stepTitle = stepEntries[0].title[this.props.appLanguage]
                }
                if (stepEntries[0].subheader1) {
                    stepSubHeader = stepEntries[0].subheader1[this.props.appLanguage]
                }

                if (stepEntries[0].stepLetterA) {
                    stepLetterA = stepEntries[0].stepLetterA[this.props.appLanguage]
                }
                if (stepEntries[0].completed1) {
                    stepARadioText1 = stepEntries[0].completed1[this.props.appLanguage]
                }
                if (stepEntries[0].stepLetterB) {
                    stepLetterB = stepEntries[0].stepLetterB[this.props.appLanguage]
                }
                if (stepEntries[0].stepLetterC) {
                    stepLetterC = stepEntries[0].stepLetterC[this.props.appLanguage]
                }
                if (stepEntries[0].completed2) {
                    stepCRadioText1 = stepEntries[0].completed2[this.props.appLanguage]
                }

                if (stepEntries[0].text1) {
                    stepText1 = stepEntries[0].text1[this.props.appLanguage]
                }
                if (stepEntries[0].text2) {
                    stepText2 = stepEntries[0].text2[this.props.appLanguage]
                }
                if (stepEntries[0].text3) {
                    stepText3 = stepEntries[0].text3[this.props.appLanguage]
                }
                if (stepEntries[0].text4) {
                    stepText4 = stepEntries[0].text4[this.props.appLanguage]
                }
                if (stepEntries[0].text5) {
                    stepText5 = stepEntries[0].text5[this.props.appLanguage]
                }
                if (stepEntries[0].text6) {
                    stepText6 = stepEntries[0].text6[this.props.appLanguage]
                }

                if (stepEntries[0].ctaCopy1) {
                    ctaCopy1 = stepEntries[0].ctaCopy1[this.props.appLanguage]
                }
                if (stepEntries[0].ctaCopy2) {
                    ctaCopy2 = stepEntries[0].ctaCopy2[this.props.appLanguage]
                }
                if (stepEntries[0].ctaCopy3) {
                    ctaCopy3 = stepEntries[0].ctaCopy3[this.props.appLanguage]
                }
                if (stepEntries[0].ctaCopy4) {
                    ctaCopy4 = stepEntries[0].ctaCopy4[this.props.appLanguage]
                }
            } // stepEntries.length
        } // getAbortionStepsEntries.length



        // Get page Assets
        const getAssets = localStorage.getItem('ayaAppAssets')
        const assets = JSON.parse(getAssets)

        // const getAbortionStepHeaderImg = assets.filter(obj => obj.sys.id === '6bPETZEIIxRDeDIOrfbuTA');

        const getImage2 = assets.filter(obj => obj.sys.id === '4NuRoWINECD9OzmawATLAq');
        const getImage3 = assets.filter(obj => obj.sys.id === '36qarNFSB2hn0Ljij5VduG');


        // var stepMainImage = ''
        var pageImage2 = ''
        var pageImage2Alt = ''
        var pageImage3 = ''
        var pageImage3Alt = ''
        // var pageImage4 = ''
        // var pageImage4Alt = ''

        // if (getAbortionStepHeaderImg && getAbortionStepHeaderImg.length > 0 && getAbortionStepHeaderImg[0].fields.file[this.props.appLanguage].url) {
        //     stepMainImage = 'https:' +  getAbortionStepHeaderImg[0].fields.file[this.props.appLanguage].url
        // }

        if (getImage2 && getImage2.length > 0 && getImage2[0].fields.file[this.props.appLanguage].url) {
            pageImage2 = 'https:' + getImage2[0].fields.file[this.props.appLanguage].url
            pageImage2Alt = getImage2[0].fields.description[this.props.appLanguage]
        }

        if (getImage3 && getImage3.length > 0 && getImage3[0].fields.file[this.props.appLanguage].url) {
            pageImage3 = 'https:' + getImage3[0].fields.file[this.props.appLanguage].url
            pageImage3Alt = getImage3[0].fields.description[this.props.appLanguage]
        }

        // if (getImage4 && getImage4.length > 0 && getImage4[0].fields.file[this.props.appLanguage].url) {
        //     pageImage4 = 'https:' + getImage4[0].fields.file[this.props.appLanguage].url
        //     pageImage4Alt = getImage4[0].fields.description[this.props.appLanguage]
        // }


        this.setState({
            // stepNum: stepNumber,
            title: stepTitle,
            subHeader: stepSubHeader,
            stepATag: stepLetterA,
            stepARadioText: stepARadioText1,
            stepBTag: stepLetterB,
            stepCTag: stepLetterC,
            stepCRadioText: stepCRadioText1,
            text1: stepText1,
            text2: stepText2,
            text3: stepText3,
            text4: stepText4,
            text5: stepText5,
            text6: stepText6,
            btn1Text: ctaCopy1,
            btn2Text: ctaCopy2,
            btn3Text: ctaCopy3,
            btn4Text: ctaCopy4,

            // headerImage: stepMainImage,
            image2: pageImage2,
            image2Alt: pageImage2Alt,
            image3: pageImage3,
            image3Alt: pageImage3Alt,
            // image4: pageImage4,
            // image4Alt: pageImage4Alt,
        })
    } // componentDidMount()




    goBackAStep = () => {
        this.props.history.push('/abortion-step-4');
    }


    toggleRadio = (stateName) => {
        const getOptionState = this.state[stateName]
        var localStorageName = 'abortionRegimenA' + [stateName]

        if (getOptionState === true) {
            this.setState({
                [stateName]: false,
            })
            window.localStorage.setItem(localStorageName, false)
        } else if (getOptionState === false) {
            this.setState({
                [stateName]: true,
            })
            window.localStorage.setItem(localStorageName, true)
        }
    }



    goToInputPillTime = () => {
        window.localStorage.setItem('abortionPillRegimenSelected', 'A')
        this.props.history.push('/notifications-opt-in');
    }

    completeThisStep = () => {
        window.localStorage.setItem('abortionPillRegimenSelected', 'A')
        window.localStorage.setItem('isAbortionStep4Complete', true)
        this.props.history.push('/abortion');
    }

    goToSelfCare = () => {
        this.props.history.push('/self-care-advice');
    }

    goToMedicalAdvice = () => {
        this.props.history.push('/resources-medical-advice');
    }

    goBack = () => {
        this.props.history.goBack()
      }


    render() {

        const { title, subHeader, stepATag, stepBTag, stepCTag, image2, stepARadioText, stepCRadioText, image2Alt, image3, image3Alt, text1, text2, text3, text4, text5, text6, btn1Text, btn2Text, btn3Text, btn4Text } = this.state

        return (
            <div className="minPageHeight100vh flexColumn flexGrow1 pageWithBottomMainNav abortionStepPages">
               
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />
                <div className='pagesInnerDiv paddingTop90'>
                    <div className='empty headerArea'></div> {/* .headerArea */}

                    <div className='flexGrow1 backgroundColorWhite topRoundDiv'>
                        <div className='flexColumn textAlignLeft padding37-30-34 topRoundDivInnerDiv'>
                            <div className='flexRow justifyBetween'>
                                <ContentfulMarkdown children={subHeader} className='width50Percent boldFont fontSize15 lineHeight18 colorPeach4 psMargin0 contentfulMarkdown marginBottom8' />
                               {/*  <button onClick={() => this.goBackAStep()} className='width50Height50'>
                                    <img src={prevBtnImg} className='width100Percent prevImg' alt='back' />
                                </button> */}
                            </div>

                            <p className='width55Percent boldFont fontSize24 lineHeight26 colorBlue8 alignSelfFlexStart textAlignLeft marginBottom21'>{title}</p>

                            <div className='backgroundColorPeach4 alignSelfFlexStart marginBottom13 smallTagDiv'>
                                <span className='regularFont fontSize11 colorWhite'>{stepATag}</span>
                            </div>

                            <ContentfulMarkdown children={text1} className='regularFont fontSize15 lineHeight16 colorBlack2 marginBottom16 contentfulMarkdown' />
                            {/* className='regularFont fontSize15 lineHeight15 colorBlue8 padding10-0 margin0' */}

                            <img src={image2} className='width55Percent marginBottom20 marginLeftRightAuto' alt={image2Alt} />

                            <div className='flexRow alignCenter marginBottom36'>
                                <input
                                    type='checkbox'
                                    // name={'question2'}
                                    checked={this.state.StepAIsComplete}
                                    className='flexColumn justifyCenter alignCenter marginRight10 backgroundNone radioCheckPeach smallMediumRadioBtn'
                                    onChange={() => this.toggleRadio('StepAIsComplete')}
                                />

                                <span className='boldFont fontSize13 lineHeight16 colorBlue8'>{stepARadioText}</span>
                            </div>

                            <div className='flexRow marginBottom13'>
                                <img src={bookmarkIcon} className='marginRight4' alt=' ' />
                                <ContentfulMarkdown children={text2} className='alignSelfFlexStart boldFont fontSize16 lineHeight15 colorPeach4 psMargin0 contentfulMarkdown' />
                            </div>

                            {/* <img src={image3} className='width93Percent marginTop0Bottom50 marginLeftRightAuto' alt={image3Alt} /> */}

                            <div className='backgroundColorPeach4 alignSelfFlexStart marginBottom13 smallTagDiv'>
                                <span className='regularFont fontSize11 colorWhite'>{stepBTag}</span>
                            </div>

                            <ContentfulMarkdown className='regularFont fontSize15 lineHeight16 colorBlack2 marginBottom16 contentfulMarkdown' children={text3} />

                            <div className='backgroundColorPeach4 alignSelfFlexStart marginBottom13 smallTagDiv'>
                                <span className='regularFont fontSize11 colorWhite'>{stepCTag}</span>
                            </div>

                            <ContentfulMarkdown children={text4} className='regularFont fontSize15 lineHeight16 colorBlack2 marginBottom16 contentfulMarkdown' />

                            <img src={image3} className='width88Percent marginBottom30 marginLeftRightAuto' alt={image3Alt} />

                            <ContentfulMarkdown children={text5} className='regularFont fontSize15 lineHeight16 colorBlack2 hasColorPeach4Span circleBullets marginBottom20 contentfulMarkdown' allowDangerousHtml={true} />

                            <div className='flexRow alignCenter marginBottom36'>
                                <input
                                    type='checkbox'
                                    // name={'question2'}
                                    checked={this.state.StepCIsComplete}
                                    className='flexColumn justifyCenter alignCenter marginRight10 backgroundColorNone radioCheckPeach smallMediumRadioBtn'
                                    onChange={() => this.toggleRadio('StepCIsComplete')}
                                />

                                <span className='boldFont fontSize13 lineHeight16 colorBlue8'>{stepCRadioText}</span>
                            </div>

                            <ContentfulMarkdown children={text6} className='regularFont fontSize15 lineHeight16 colorBlack2 hasColorPeach4Span circleBullets marginBottom20 contentfulMarkdown' allowDangerousHtml={true} />
{/* 
                            <LargeButton
                                largeButtonFunc={() => this.goToInputPillTime()}
                                text={btn1Text}
                                textColor={'colorWhite'}
                                backgroundColor={'backgroundColorPeach5'}
                            /> */}

                            <div className='marginBottom40'></div>

                            <LargeButton
                                largeButtonFunc={() => this.goToMedicalAdvice()}
                                text={<p className='width100Percent flexRow justifyCenter alignCenter marginBottom0'>
                                    {/* <img src={messageIcon} style={{ width: '18px', height: 'auto', marginRight: '10px' }} alt='' /> */}
                                    {btn2Text}
                                </p>}
                                textColor={'colorWhite'}
                                backgroundColor={'backgroundColorBlue8'}
                            />

                            <div className='marginBottom15'></div>

                            <LargeButton
                                largeButtonFunc={() => this.completeThisStep()}
                                text={btn3Text}
                                textColor={'colorWhite'}
                                backgroundColor={'backgroundColorBlue6'}
                            />

                            <div className='marginBottom15'></div>

                            <LargeButton
                                largeButtonFunc={() => this.goToSelfCare()}
                                text={btn4Text}
                                textColor={'colorBlue8'}
                                backgroundColor={'backgroundColorPink'}
                            />

                            <WHOLink 
                                fontColor="#071f56"
                                appLanguage={this.props.appLanguage}
                                {...this.props} /> {/* This is the WHO link that is necessary for iOS app store */}

                        </div> {/* .topRoundDivInnerDiv */}
                    </div> {/* .topRoundDiv */}

                </div> {/* .pagesInnerDiv */}

                <BottomNavBar appLanguage={this.props.appLanguage} />
            </div>
        )
    }
}
