import React from 'react';
import { Link } from "react-router-dom";


export class BottomNavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navItem1Name: '',
      navItem2Name: '',
      navItem3Name: '',
      navItem1Img: '',
      navItem2Img: '',
      navItem3Img: '',
    }
  }

  componentDidMount() {
    this.fetchContent();
  }


  componentDidUpdate(prevProps) {
    if (prevProps.appLanguage !== this.props.appLanguage) {
      this.fetchContent();
    }
  }

    //bottomNavBar 
    // Get link text
    fetchContent = () => {
    const getEntries = localStorage.getItem('ayaAppEntries')
    const entries = JSON.parse(getEntries)


    // GET MODEL 
    const getModel = entries.filter(
      obj => obj.sys.contentType.sys.id === 'bottomNavBar');

    // GET CONTENT
    const getContent = getModel.filter(
      obj => obj.sys.id === '4rojILAkg1bItQ0nU7yij4');

    
    var navLinks = []
    var navLink1Name = ''
    var navLink2Name = ''
    var navLink3Name = ''

    if (getContent && getContent.length > 0) {
      navLinks.push(getContent[0].fields)

      if (navLinks.length > 0) {
        if (navLinks[0].title) {
          navLink1Name = navLinks[0].title[this.props.appLanguage]
        }
        if (navLinks[0].title2) {
          navLink2Name = navLinks[0].title2[this.props.appLanguage]
        }
        if (navLinks[0].title3) {
          navLink3Name = navLinks[0].title3[this.props.appLanguage]
        }
      }
    }

    this.setState({
      navItem1Name: navLink1Name,
      navItem2Name: navLink2Name,
      navItem3Name: navLink3Name,
    })


    // Get nav Assets
    const getAssets = localStorage.getItem('ayaAppAssets')
    const assets = JSON.parse(getAssets)
  
    const getImage1 = assets.filter(obj => obj.fields.title[this.props.appLanguage] === 'Aborto Icon');
    const getImage2 = assets.filter(obj => obj.fields.title[this.props.appLanguage] === 'Anticonceptivo Icon');
    const getImage3 = assets.filter(obj => obj.fields.title[this.props.appLanguage] === 'Recursos Icon');

    var navLink1Img = ''
    var navLink2Img = ''
    var navLink3Img = ''

    if (getImage1 && getImage1.length > 0 && getImage1[0].fields.file[this.props.appLanguage].url) {
      navLink1Img = 'https:' + getImage1[0].fields.file[this.props.appLanguage].url
    }

    if (getImage2 && getImage2.length > 0 && getImage2[0].fields.file[this.props.appLanguage].url) {
      navLink2Img = 'https:' + getImage2[0].fields.file[this.props.appLanguage].url
    }

    if (getImage3 && getImage3.length > 0 && getImage3[0].fields.file[this.props.appLanguage].url) {
      navLink3Img = 'https:' + getImage3[0].fields.file[this.props.appLanguage].url
    }

    this.setState({
      navItem1Img: navLink1Img,
      navItem2Img: navLink2Img,
      navItem3Img: navLink3Img,
    })
  } // componentDidMount()
  
  render() {

    const { navItem1Name, navItem2Name, navItem3Name } = this.state
    const { navItem1Img, navItem2Img, navItem3Img } = this.state

    return (
      <div className='flexRow justifyCenter backgroundColorPeach5 bottomNavBar'>
        <Link className='width33Percent flexColumn justifyCenter boldFont fontSize10 lineHeight20 colorWhite' to='/abortion'>
          <div className='flexColumn justifyCenter alignCenter'>
            <div className='flexRow justifyCenter alignFlexStart width25 height25'>
              <img src={navItem1Img} className='width100Percent heightAuto' alt='' />
            </div>
            <span>{navItem1Name}</span>
          </div>
        </Link>

        <Link className='width33Percent flexColumn justifyCenter boldFont fontSize10 lineHeight20 colorWhite' to='/contraception-assessment'>
          <div className='flexColumn justifyCenter alignCenter'>
            <div className='flexRow justifyCenter alignFlexStart width25 height25'>
              <img src={navItem2Img} className='width100Percent heightAuto' alt='' />
            </div>
            <span>{navItem2Name}</span>
          </div>
        </Link>

        <Link className='width33Percent flexColumn justifyCenter boldFont fontSize10 lineHeight20 colorWhite' to='/resources'>
          <div className='flexColumn justifyCenter alignCenter'>
            <div className='flexRow justifyCenter alignFlexStart width25 height25'>
              <img src={navItem3Img} className='width100Percent heightAuto' alt='' />
              </div>
            <span>{navItem3Name}</span>
          </div>
        </Link>
      </div>
    )
  }
}