import React from 'react';
import ReactGA from 'react-ga4';
import { TopNavBar } from '../../Components/TopNavBar'
import * as ContentfulMarkdown from 'react-markdown'
import { LargeButton } from '../../Components/LargeButton'
import messageIcon from '../../Images/talking-bubble-icon-peach.svg'
/* import prevBtnImg from '../../Images/prevBtnImg-white.svg'
import prevBtnImgWhite from '../../Images/prevBtnImg-white.svg'; */

import { HelpfulPopUp } from '../../Components/HelpfulPopUp'
import { WHOLink } from '../../Components/WHOLink';


export class AbortionAssessment4 extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            page: '4',
            stepNumber: '',
            title: '',
            questionOptions: [],
            option1: false,
            option2: false,
            option3: false,
            noneApplyText: '',
            btn1Text: '',

            linkToPopUpText1: '',
            linkToPopUpText2: '',
            linkToPopUpText3: '',

            popUp1Content: [],
            popUp2Content: [],
            popUp3Content: [],
        }
    }


    componentDidMount() {
        ReactGA.send("/abortion-assessment-q4")
        window.scrollTo(0, 0)

        document.title = "Abortion Assessment Q4"
                        
        // this hides the Crisp Chat Window from the page
        /* $crisp.push(["do", "chat:hide"]); */

        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)
        // Content type ID
        const getContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'multipleChoice');
        // Then Entry ID instead
        const getPageEntries = getContentEntries.filter(obj => obj.sys.id === '1RcDbe9kjQcP711cODlEA1');

        var pageEntries = []
        var options = []
        var stepNum = ''
        var title1 = ''
        var noneApplyText1 = ''
        var ctaCopy1 = ''

        var popUpText1 = ''
        var popUpText2 = ''
        var popUpText3 = ''

        if (getPageEntries && getPageEntries.length > 0) {
            pageEntries.push(getPageEntries[0].fields)

            if (pageEntries.length > 0) {

                if (pageEntries[0].stepNumber) {
                    stepNum = pageEntries[0].stepNumber[this.props.appLanguage]
                }
                if (pageEntries[0].title) {
                    title1 = pageEntries[0].title[this.props.appLanguage]
                }
                if (pageEntries[0].ctaCopy1) {
                    ctaCopy1 = pageEntries[0].ctaCopy1[this.props.appLanguage]
                }
                if (pageEntries[0].choice1) {
                    options.push({ 'option': pageEntries[0].choice1[this.props.appLanguage], 'stateName': 'option1' })
                }
                if (pageEntries[0].choice2) {
                    options.push({ 'option': pageEntries[0].choice2[this.props.appLanguage], 'stateName': 'option2' })
                }
                if (pageEntries[0].choice3) {
                    options.push({ 'option': pageEntries[0].choice3[this.props.appLanguage], 'stateName': 'option3' })
                }
                if (pageEntries[0].ifNoneApplyCopy) {
                    noneApplyText1 = pageEntries[0].ifNoneApplyCopy[this.props.appLanguage]
                }
                if (pageEntries[0].popUpText1) {
                    popUpText1 = pageEntries[0].popUpText1[this.props.appLanguage]
                }
                if (pageEntries[0].popUpText2) {
                    popUpText2 = pageEntries[0].popUpText2[this.props.appLanguage]
                }
                if (pageEntries[0].popUpText3) {
                    popUpText3 = pageEntries[0].popUpText3[this.props.appLanguage]
                }
            }
        }




        // Helpful popup entries
        // Content type ID
        const getPopUpEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'popUp');
        // Then Entry ID instead
        const getPopUp1Entries = getPopUpEntries.filter(obj => obj.sys.id === '4ZdNsb27VTLLBMzl6oCacs');

        var popUp1Entries = []
        var popUp1Info = {}

        if (getPopUp1Entries && getPopUp1Entries.length > 0) {
            popUp1Entries.push(getPopUp1Entries[0].fields)

            if (popUp1Entries.length > 0) {

                if (popUp1Entries[0].title) {
                    popUp1Info['title'] = popUp1Entries[0].title[this.props.appLanguage]
                }
                if (popUp1Entries[0].paragraph) {
                    popUp1Info['text1'] = popUp1Entries[0].paragraph[this.props.appLanguage]
                }
                if (popUp1Entries[0].ctaCopy1) {
                    popUp1Info['btn1Text'] = popUp1Entries[0].ctaCopy1[this.props.appLanguage]
                }
            }
        }


        // Then Entry ID
        const getPopUp2Entries = getPopUpEntries.filter(obj => obj.sys.id === '4IxV3FNxcfhhfO2prrb2Yi');

        var popUp2Entries = []
        var popUp2Info = []

        if (getPopUp2Entries && getPopUp2Entries.length > 0) {
            popUp2Entries.push(getPopUp2Entries[0].fields)

            if (popUp2Entries.length > 0) {

                if (popUp2Entries[0].title) {
                    popUp2Info['title'] = popUp2Entries[0].title[this.props.appLanguage]
                }
                if (popUp2Entries[0].paragraph) {
                    popUp2Info['text1'] = popUp2Entries[0].paragraph[this.props.appLanguage]
                }
                if (popUp2Entries[0].ctaCopy1) {
                    popUp2Info['btn1Text'] = popUp2Entries[0].ctaCopy1[this.props.appLanguage]
                }
            }
        }


        // Then Entry ID
        const getPopUp3Entries = getPopUpEntries.filter(obj => obj.sys.id === '3kbFjxhYpsTiNSege0tmuj');

        var popUp3Entries = []
        var popUp3Info = []

        if (getPopUp3Entries && getPopUp3Entries.length > 0) {
            popUp3Entries.push(getPopUp3Entries[0].fields)

            if (popUp3Entries.length > 0) {

                if (popUp3Entries[0].title) {
                    popUp3Info['title'] = popUp3Entries[0].title[this.props.appLanguage]
                }
                if (popUp3Entries[0].paragraph) {
                    popUp3Info['text1'] = popUp3Entries[0].paragraph[this.props.appLanguage]
                }
                if (popUp3Entries[0].ctaCopy1) {
                    popUp3Info['btn1Text'] = popUp3Entries[0].ctaCopy1[this.props.appLanguage]
                }
            }
        }


        this.setState({
            stepNumber: stepNum,
            title: title1,
            questionOptions: options,
            noneApplyText: noneApplyText1,
            btn1Text: ctaCopy1,

            linkToPopUpText1: popUpText1,
            linkToPopUpText2: popUpText2,
            linkToPopUpText3: popUpText3,

            popUp1Content: popUp1Info,
            popUp2Content: popUp2Info,
            popUp3Content: popUp3Info,
        })
    } // componentDidMount()




    toggleRadio = (stateName) => {
        const getOptionStateName = this.state[stateName]

        if (getOptionStateName === true) {
            this.setState({
                [stateName]: false,
            })
        } else if (getOptionStateName === false) {
            this.setState({
                [stateName]: true,
            })
        }
    }



    continue = () => {
        const q4AnswerBoolArray = [this.state.option1, this.state.option2, this.state.option3]
        const thisTrueCount = q4AnswerBoolArray.filter(Boolean).length

        window.localStorage.setItem('abortionAssessmentQ4TrueCount', thisTrueCount)

        var selectedQuestions = []

        if (this.state.option1 === true) {
            const getQuestionObj = this.state.questionOptions.filter(obj => obj.stateName === 'option1');
            selectedQuestions.push(getQuestionObj[0].option)
        }

        if (this.state.option2 === true) {
            const getQuestionObj = this.state.questionOptions.filter(obj => obj.stateName === 'option2');
            selectedQuestions.push(getQuestionObj[0].option)
        }

        if (this.state.option3 === true) {
            const getQuestionObj = this.state.questionOptions.filter(obj => obj.stateName === 'option3');
            selectedQuestions.push(getQuestionObj[0].option)
            // console.log(selectedQuestions)
        }

        window.localStorage.setItem('abortionAssessmentQ4SelectedAnswers', selectedQuestions)

        const q2TrueCount = localStorage.getItem('abortionAssessmentQ2TrueCount')
        const q3TrueCount = localStorage.getItem('abortionAssessmentQ3TrueCount')
        const q4TrueCount = thisTrueCount

        const allAnswerBoolArray = [q2TrueCount, q3TrueCount, q4TrueCount]
        const allTrueCount = allAnswerBoolArray.filter(num => num > 0).length

        if (allTrueCount === 0) {
            window.localStorage.setItem('isAbortionAssessmentComplete', true)
            window.localStorage.setItem('isAbortionStep1Complete', true)
            this.props.history.push('/abortion-assessment-passed')
        } else {
            window.localStorage.setItem('isAbortionAssessmentComplete', true)
            window.localStorage.setItem('isAbortionStep1Complete', true)
            this.props.history.push('/abortion-assessment-failed')
        }
    }

    showPopUp1 = () => {
        this.setState({
            page: 'popUp1'
        })
    }


    showPopUp2 = () => {
        this.setState({
            page: 'popUp2'
        })
    }


    showPopUp3 = () => {
        this.setState({
            page: 'popUp3'
        })
    }


    comeBackToMainQuestion = () => {
        this.setState({
            page: '4'
        })
    }


    goBack = () => {
        this.props.history.goBack()
    }




    render() {
        return (
            <div className='flexColumn flexGrow1'>
                { this.renderChildren()} {/* Down below */}
            </div>
        )
    }

// checkbox

    renderChildren = () => {
        const { page, stepNumber, title, questionOptions, noneApplyText, btn1Text, linkToPopUpText1, linkToPopUpText2, linkToPopUpText3, popUp1Content, popUp2Content, popUp3Content } = this.state

        const questionsContent = questionOptions.map((info, i) =>
            <div className='flexRow textAlignLeft marginBottom16 questionsDiv' id={i} key={i}>
                <div>
                    <input
                        type='checkbox'
                        // name={'question2'}
                        checked={this.state[info.stateName]}
                        className='flexColumn justifyCenter alignCenter marginRight10 backgroundColorNone radioCheckPeach mediumRadioBtn'
                        onChange={() => this.toggleRadio(info.stateName)}
                        />
                    </div>
                <span className='regularFont fontSize13 lineHeight17 colorWhite'>{info.option}</span>
            </div>
        )



        if (page === '4') {
            return (
                <div className='minPageHeight100vh flexColumn flexGrow1 abortionAssessmentPage'>
           
        <TopNavBar bgColor="#071f56" showHamburgerMenu={true} menuIconColor={'white'} logoColor={'peach'} bellColor={'white'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

                    <div className='flexColumn flexGrow1 justifyCenter alignCenter textAlignCenter paddingTop80 landscapeWidth75Percent'>
                        <div className='paddingLeftRight85'>
                            <ContentfulMarkdown children={stepNumber} className='boldFont fontSize12 lineHeight14 colorPeach4 marginBottom4 contentfulMarkdown' />

                            <ContentfulMarkdown children={title} className='boldFont fontSize22 lineHeight25 colorWhite psMargin0 marginBottom26 contentfulMarkdown' />
                        </div>

                        <form className='flexColumn padding0-25-14-45'>
                            {questionsContent}
                        </form>

                        <ContentfulMarkdown children={noneApplyText} className='regularFont fontSize13 lineHeight17 colorPeach4 psMargin0 padding0-25-25 contentfulMarkdown' />



                        <div className='paddingLeftRight35 landscapeWidth75Percent'>
                            <LargeButton
                                largeButtonFunc={() => this.continue()}
                                text={btn1Text}
                                textColor={'colorBlue8'}
                                backgroundColor={'backgroundColorWhite'}
                            />

                            <div className='marginBottom12'></div>


                            <WHOLink 
                        fontColor="#fff"
                        appLanguage={this.props.appLanguage}
                        {...this.props} /> {/* This is the WHO link that is necessary for iOS app store */}

                            <div className='flexRow paddingLeftRight40'>
                                <img src={messageIcon} className='width16Height16 marginTop3 marginRight5' alt='...' />
                                <button onClick={() => this.showPopUp1()} >
                                    <ContentfulMarkdown children={linkToPopUpText1} className='boldFont fontSize13 lineHeight15 colorPeach4 underline textAlignLeft psMargin0 contentfulMarkdown' />
                                </button>
                            </div>

                            <div className='marginBottom12'></div>

                            <div className='flexRow paddingLeftRight40'>
                                <img src={messageIcon} className='width16Height16 marginTop3 marginRight5' alt='...' />
                                <button onClick={() => this.showPopUp2()} >
                                    <ContentfulMarkdown children={linkToPopUpText2} className='boldFont fontSize13 lineHeight15 colorPeach4 underline textAlignLeft psMargin0 contentfulMarkdown' />
                                </button>
                            </div>

                            <div className='marginBottom12'></div>

                            <div className='flexRow paddingLeftRight40'>
                                <img src={messageIcon} className='width16Height16 marginTop3 marginRight5' alt='...' />
                                <button onClick={() => this.showPopUp3()} >
                                    <ContentfulMarkdown children={linkToPopUpText3} className='boldFont fontSize13 lineHeight15 colorPeach4 underline textAlignLeft psMargin0 contentfulMarkdown' />
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* <button onClick={() => this.goBack()} className='width30Height30 margin20 justifySelfFlexEnd'>
                        <img src={prevBtnImg} className='width100Percent prevImg' alt='back' />
                    </button> */}
                </div >
            )
        } else if (page === 'popUp1') {
            return (
                <HelpfulPopUp
                    pageBackgroundColor={'backgroundColorBlue8'}
                    logoColor={'peach'}
                    bellColor={'white'}
                    newNotificationColor={'peach'}
                    appLanguage={this.props.appLanguage}
                    helpfulDivPaddingLeftRight={'paddingLeftRight35'}
                    helpfulDivBackgroundColor={'backgroundColorPeach4'}
                    helpfulDivTextColor={'colorWhite'}
                    content={popUp1Content}
                    btn1TextColor={'colorBlue8'}
                    btn1BackgroundColor={'backgroundColorWhite'}
                    // btn2TextColor={'colorWhite'}
                    // btn2BackgroundColor={'backgroundColorBlue8'}
                    btn1Function={() => this.comeBackToMainQuestion()}
                    // btn2Function={() => this.comeBackToMainQuestion()}
                    backToFunc={() => this.comeBackToMainQuestion()}
                />
            )
        } else if (page === 'popUp2') {
            return (
                <HelpfulPopUp
                    pageBackgroundColor={'backgroundColorBlue8'}
                    logoColor={'peach'}
                    bellColor={'white'}
                    newNotificationColor={'peach'}
                    appLanguage={this.props.appLanguage}
                    helpfulDivPaddingLeftRight={'paddingLeftRight35'}
                    helpfulDivBackgroundColor={'backgroundColorPeach4'}
                    helpfulDivTextColor={'colorWhite'}
                    content={popUp2Content}
                    btn1TextColor={'colorBlue8'}
                    btn1BackgroundColor={'backgroundColorWhite'}
                    // btn2TextColor={'colorWhite'}
                    // btn2BackgroundColor={'backgroundColorBlue8'}
                    btn1Function={() => this.comeBackToMainQuestion()}
                    // btn2Function={() => this.comeBackToMainQuestion()}
                    backToFunc={() => this.comeBackToMainQuestion()}
                />
            )
        } else if (page === 'popUp3') {
            return (
                <HelpfulPopUp
                    pageBackgroundColor={'backgroundColorBlue8'}
                    logoColor={'peach'}
                    bellColor={'white'}
                    newNotificationColor={'peach'}
                    appLanguage={this.props.appLanguage}
                    helpfulDivPaddingLeftRight={'paddingLeftRight35'}
                    helpfulDivBackgroundColor={'backgroundColorPeach4'}
                    helpfulDivTextColor={'colorWhite'}
                    content={popUp3Content}
                    btn1TextColor={'colorBlue8'}
                    btn1BackgroundColor={'backgroundColorWhite'}
                    // btn2TextColor={'colorWhite'}
                    // btn2BackgroundColor={'backgroundColorBlue8'}
                    btn1Function={() => this.comeBackToMainQuestion()}
                    // btn2Function={() => this.comeBackToMainQuestion()}
                    backToFunc={() => this.comeBackToMainQuestion()}
                />
            )
        }
    }
}
