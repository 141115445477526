import React from 'react';

export class WHOLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sources: [
        {
          linkText: {
            'en': 'World Health Organization',
            'es-US': 'Organización Mundial de la Salud'
          },
          linkUrl: {
            'en': 'https://apps.who.int/iris/bitstream/handle/10665/278968/9789241550406-eng.pdf',
            'es-US': 'https://apps.who.int/iris/bitstream/handle/10665/328166/9789243550404-spa.pdf?sequence=1&isAllowed=y'
          }
        },
        {
          linkText: {
            'en': 'Guttmacher Institute',
            'es-US': 'Instituto Guttmacher'
          },
          linkUrl: 'https://www.guttmacher.org/',
        },
        {
          linkText: {
            'en': 'Abortion Care Network',
            'es-US': 'Red de Atención al Aborto'
          },
          linkUrl: 'https://abortioncarenetwork.org',
        },
        {
          linkText: {
            'en': 'National Abortion Federation',
            'es-US': 'Federación Nacional del Aborto'
          },
          linkUrl: 'https://prochoice.org/',
        },
      ]
    };
  }

  render() {
    const { fontColor, appLanguage } = this.props;
    const linkFontColor = {
      color: fontColor,
    };

    return (
      <div className="WHOLink-div WHOLink">
        <span className="sourceTitle" style={linkFontColor}>
          {appLanguage === 'es-US' ? '* Fuentes:' : '* Sources:'}
          <br />
        </span>

        {this.state.sources.map((source, index) => (
          <div key={index} className="link-container">
            <a 
              href={typeof source.linkUrl === 'string' ? source.linkUrl : (source.linkUrl[appLanguage] || source.linkUrl['en'])} 
              style={linkFontColor} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              {source.linkText[appLanguage] || source.linkText['en']}
            </a>
            <br />
          </div>
        ))}
      </div>
    );
  }
}
