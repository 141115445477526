import React from 'react'
import ReactGA from 'react-ga4'
import { SingleContraceptionAssessmentWithOptions } from './SingleContraceptionAssessmentWithOptions'


export class ContraceptionAssessment7  extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1,
      contentObj: { 'options': [], },
      noneApplyText: '',
      popUp1Content: {},
      popUp2Content: {},
    }
  }



  componentDidMount() {
    ReactGA.send("/contraception-assessment-q7")
    window.scrollTo(0, 0)

    document.title = "Contraception Assessment Q7"

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

    const getEntries = localStorage.getItem('ayaAppEntries')
    const entries = JSON.parse(getEntries)

    const getContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'multipleChoice');
    const getPageEntries = getContentEntries.filter(obj => obj.sys.id === '2EtNSZ7t5FKSgB50TwDpuX');

    var pageEntries = []
    var pageContentObj = { 'options': [], }

    if (getPageEntries && getPageEntries.length > 0) {
      pageEntries.push(getPageEntries[0].fields)

      if (pageEntries.length > 0) {
        
        if (pageEntries[0].stepNumber) {
          pageContentObj['stepNumber'] = pageEntries[0].stepNumber[this.props.appLanguage]
        }
        if (pageEntries[0].title) {
          pageContentObj['question'] = pageEntries[0].title[this.props.appLanguage]
        }
        if (pageEntries[0].subtitle) {
          pageContentObj['subTitle'] = pageEntries[0].subtitle[this.props.appLanguage]
        }
        if (pageEntries[0].choice1) {
          pageContentObj.options.push({ 'option': pageEntries[0].choice1[this.props.appLanguage], 'stateName': 'option1' })
        }
        if (pageEntries[0].choice2) {
          pageContentObj.options.push({ 'option': pageEntries[0].choice2[this.props.appLanguage], 'stateName': 'option2' })
        }
        if (pageEntries[0].choice3) {
          pageContentObj.options.push({ 'option': pageEntries[0].choice3[this.props.appLanguage], 'stateName': 'option3' })
        }
        if (pageEntries[0].ifNoneApplyCopy) {
          pageContentObj['noneApplyText'] = pageEntries[0].ifNoneApplyCopy[this.props.appLanguage]
        }
        if (pageEntries[0].ctaCopy1) {
          pageContentObj['btn1Text'] = pageEntries[0].ctaCopy1[this.props.appLanguage]
        }
        if (pageEntries[0].popUpText1) {
          pageContentObj['linkToPopUpText1'] = pageEntries[0].popUpText1[this.props.appLanguage]
        }
        if (pageEntries[0].popUpText2) {
          pageContentObj['linkToPopUpText2'] = pageEntries[0].popUpText2[this.props.appLanguage]
        }
      }
    }




    // Get pop up 1 content
    const getPopUpContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'popUp');
    const getPopUp1Entries = getPopUpContentEntries.filter(obj => obj.sys.id === '2ub8bIuozmpWEGWqtfguSU');

    var popUp1Entries = []
    var popUp1ContentObj = {}
   

    if (getPopUp1Entries && getPopUp1Entries.length > 0) {
      popUp1Entries.push(getPopUp1Entries[0].fields)

      if (popUp1Entries.length > 0) {

        if (popUp1Entries[0].title) {
          popUp1ContentObj['title'] = popUp1Entries[0].title[this.props.appLanguage]
        }
        if (popUp1Entries[0].paragraph) {
          popUp1ContentObj['text1'] = popUp1Entries[0].paragraph[this.props.appLanguage]
        }
        if (popUp1Entries[0].ctaCopy1) {
          popUp1ContentObj['btn1Text'] = popUp1Entries[0].ctaCopy1[this.props.appLanguage]
        }
       
      }
    }



    // Get pop up 2 content
    const getPopUp2Entries = getPopUpContentEntries.filter(obj => obj.sys.id === '6kKuWGttuzUtrA6pmJ91Kl');

    var popUp2Entries = []
    var popUp2ContentObj = {}


    if (getPopUp2Entries && getPopUp2Entries.length > 0) {
      popUp2Entries.push(getPopUp2Entries[0].fields)

      if (popUp2Entries.length > 0) {

        if (popUp2Entries[0].title) {
          popUp2ContentObj['title'] = popUp2Entries[0].title[this.props.appLanguage]
        }
        if (popUp2Entries[0].paragraph) {
          popUp2ContentObj['text1'] = popUp2Entries[0].paragraph[this.props.appLanguage]
        }
        if (popUp2Entries[0].ctaCopy1) {
          popUp2ContentObj['btn1Text'] = popUp2Entries[0].ctaCopy1[this.props.appLanguage]
        }

      }
    }



    this.setState({
      contentObj: pageContentObj,
      popUp1Content: popUp1ContentObj,
      popUp2Content: popUp2ContentObj,
    })
  }


  continue = (trueCount) => {
    if (trueCount === 0){
      window.localStorage.setItem('contraceptionAssessmentQ7Answer', 'no')
      this.props.history.push('/contraception-assessment-q8')
    } else {
      window.localStorage.setItem('contraceptionAssessmentQ7Answer', 'yes')
      this.props.history.push('/contraception-assessment-q8')
    }
  }



  goBackFunc = () => {
   
  }



  render() {

    const { contentObj, popUp1Content, popUp2Content } = this.state
    
    return (
      <div className='flexColumn flexGrow1'>
        <SingleContraceptionAssessmentWithOptions
          appLanguage={this.props.appLanguage}
          history={this.props.history}
          content={contentObj}
          questionWidth={'width83Percent'}
          popUp1Content={popUp1Content}
          popUp2Content={popUp2Content}
          continue={this.continue}
          goBack={() => this.goBackFunc()}
        />
      </div>
    )
  }
}