import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function BasicCard(props) {
  const { title, content, image, arrow, button1, button2, arrowIconOne, phoneIcon, arrowIconTwo } = props;

  const cardStyle = {
    backgroundColor: 'white',
    border: '1px solid #ccc',
    borderRadius: '12px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    padding: '0px',
    // width: '313px',
    width: '324px',
    marginTop: '5px',
    marginBottom: '15px',
    position: 'relative',
  };

  const imageStyle = {
    position: 'absolute',
    top: '10px',
    left: '18px',
    width: props.isCardOne ? '100px' : '50px', 
    height: props.isCardOne? '40px':'50px', 
  }

  const iconContainerStyle = {
    position: 'absolute',
    top: '9px', 
    right: '13px', 
    display: 'flex',
    alignItems: 'center',
  };

  const arrowStyle = {
    width: '20px',
    height: '20px',
    marginRight: '1px',
  };

  const phoneStyle = {
    width: '20px',
    height: '20px',
  };

  const titleStyle = {
    fontSize: '14px',
    fontFamily: 'GTWalsheimPro-Bold, sans-serif',
    color: '#051D58',
    marginTop: '45px',
    marginBottom: '5px',
  };

  const contentStyle = {
    fontFamily: 'GTWalsheimPro-Regular, sans-serif',
    fontSize: '14px',
    color: '#524F4F',
  };

  const buttonStyle = {
    fontSize: '14px',
    fontFamily: 'GTWalsheimPro-Regular, sans-serif',
    color: '#051D58',
    backgroundColor: '#E5F1FA',
    borderRadius: '12px',
    border: 'none',
    padding: '5px 10px',
    marginTop: '10px',
  };

  return (
    <Card style={cardStyle}>
      <CardContent>
        {image && <img src={image} alt="Legal" style={imageStyle} />}
        <Typography variant="h5" component="h2" style={titleStyle}>
          {title}
        </Typography>
        <Typography variant="body2" style={contentStyle}>
          {content}
        </Typography>

        {button1 && (
          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px', backgroundColor: '#E5F1FA', borderRadius: '12px', marginTop: '20px' }}>
            <button style={buttonStyle}>{button1}</button>
            {arrowIconOne && (
              <div style={iconContainerStyle}>
                <img src={arrowIconOne} alt="Arrow" style={arrowStyle} />
              </div>
            )}
          </div>
        )}
        {button2 && (
          <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#E5F1FA', marginTop: '5px',  borderRadius: '12px'}}>
            <div style={iconContainerStyle}>
              <img src={phoneIcon} alt="Phone" style={phoneStyle} />
            </div>
            <button style={buttonStyle}>{button2}</button>
          </div>
        )}
        {arrow && (
          <div style={iconContainerStyle}>
            <img src={arrow} alt="Arrow" style={arrowStyle} />
          </div>
        )}
        {arrowIconTwo && (
          <div style={{ ...iconContainerStyle, top: '5px', right: '25px' }}> 
            <img src={arrowIconTwo} alt="ArrowTwo" style={arrowStyle} />
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default BasicCard;
