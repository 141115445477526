import React from 'react';
import ReactGA from 'react-ga4';
import { TopNavBar } from '../../Components/TopNavBar'
/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */
import { LargeButton } from '../../Components/LargeButton'
import * as ContentfulMarkdown from 'react-markdown'
import { BottomNavBar } from '../../Components/BottomNavBar'
import messageIcon from '../../Images/message-icon-white.svg'
import { WHOLink } from '../../Components/WHOLink';


export class AbortionStep6 extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            headerImage: '',
            stepNum: '',
            title: '',
            // subHeader: '',
            // image2: '',
            // image2Alt: '',
            // image3: '',
            // image3Alt: '',
            // image4: '',
            // image4Alt: '',
            text1: '',
            // text2: '',
            // text3: '',
            // text4: '',
            // text5: '',
            btn1Text: '',
            btn2Text: '',
            btn3Text: '',
        }
    }


    componentDidMount() {
        ReactGA.send("/abortion-step-6")
        window.scrollTo(0, 0)

        document.title = "Abortion Step 6"
                        
        // this hides the Crisp Chat Window from the page
        /* $crisp.push(["do", "chat:hide"]); */


        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)
        // Content type ID
        const getAbortionStepsEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'step1');
        // Then Entry ID instead
        const getStepEntries = getAbortionStepsEntries.filter(obj => obj.sys.id === '2aBKLifs42LcxKswweYnV5');

        var stepEntries = []
        var stepNumber = ''
        var stepTitle = ''
        // var stepSubHeader = ''
        var stepText1 = ''
        // var stepText2 = ''
        // var stepText3 = ''
        // var stepText4 = ''
        // var stepText5 = ''
        var ctaCopy1 = ''
        var ctaCopy2 = ''
        var ctaCopy3 = ''


        if (getStepEntries && getStepEntries.length > 0) {
            stepEntries.push(getStepEntries[0].fields)

            if (stepEntries.length > 0) {

                if (stepEntries[0].stepNumber) {
                    stepNumber = stepEntries[0].stepNumber[this.props.appLanguage]
                }
                if (stepEntries[0].title) {
                    stepTitle = stepEntries[0].title[this.props.appLanguage]
                }
                // if (stepEntries[0].subheader1) {
                //     stepSubHeader = stepEntries[0].subheader1[this.props.appLanguage]
                // }
                if (stepEntries[0].text1) {
                    stepText1 = stepEntries[0].text1[this.props.appLanguage]
                }
                // if (stepEntries[0].text2) {
                //     stepText2 = stepEntries[0].text2[this.props.appLanguage]
                // }
                // if (stepEntries[0].text3) {
                //     stepText3 = stepEntries[0].text3[this.props.appLanguage]
                // }
                // if (stepEntries[0].text4) {
                //     stepText4 = stepEntries[0].text4[this.props.appLanguage]
                // }
                // if (stepEntries[0].text5) {
                //     stepText5 = stepEntries[0].text5[this.props.appLanguage]
                // }
                if (stepEntries[0].ctaCopy1) {
                    ctaCopy1 = stepEntries[0].ctaCopy1[this.props.appLanguage]
                }
                if (stepEntries[0].ctaCopy2) {
                    ctaCopy2 = stepEntries[0].ctaCopy2[this.props.appLanguage]
                }
                if (stepEntries[0].ctaCopy3) {
                    ctaCopy3 = stepEntries[0].ctaCopy3[this.props.appLanguage]
                }
            } // stepEntries.length
        } // getAbortionStepsEntries.length

        this.setState({
            stepNum: stepNumber,
            title: stepTitle,
            // subHeader: stepSubHeader,
            text1: stepText1,
            // text2: stepText2,
            // text3: stepText3,
            // text4: stepText4,
            // text5: stepText5,
            btn1Text: ctaCopy1,
            btn2Text: ctaCopy2,
            btn3Text: ctaCopy3,
        })


        // Get page Assets
        const getAssets = localStorage.getItem('ayaAppAssets')
        const assets = JSON.parse(getAssets)

        const getAbortionStepHeaderImg = assets.filter(obj => obj.sys.id === '3FrStCrKQnixVsVSNe66fA');
        // const getOption1BtnImage = assets.filter(obj => obj.sys.id === 'ZVpBGr7JpyUftOoqnqDAy');
        // const getOption2BtnImage = assets.filter(obj => obj.sys.id === '3buN0qV5gsoIqyDvghlqzC');

        // ZVpBGr7JpyUftOoqnqDAy

        var stepMainImage = ''
        // var pageImage4 = ''
        // var pageImage4Alt = ''

        if (getAbortionStepHeaderImg && getAbortionStepHeaderImg.length > 0 && getAbortionStepHeaderImg[0].fields.file[this.props.appLanguage].url) {
            stepMainImage = 'https:' + getAbortionStepHeaderImg[0].fields.file[this.props.appLanguage].url
        }

        this.setState({
            headerImage: stepMainImage,
            // image2: pageImage2,
            // image2Alt: pageImage2Alt,
            // image3: pageImage3,
            // image3Alt: pageImage3Alt,
            // image4: pageImage4,
            // image4Alt: pageImage4Alt,
        })

    } // componentDidMount()




    goBackAStep = () => {
        this.props.history.push('/abortion-step-5');
    }

    goToEmotionalSupport = () => {
        this.props.history.push('/resources-emotional-support');
    }

    completeThisStep = () => {
        window.localStorage.setItem('isAbortionStep6Complete', true)
        this.props.history.push('/abortion');
    }

    goToSelfCare = () => {
        this.props.history.push('/self-care-advice');
    }

    goBack = () => {
        this.props.history.goBack()
      }

    render() {

        const { headerImage, stepNum, title, text1, btn1Text, btn2Text, btn3Text } = this.state

        return (
            <div className="minPageHeight100vh flexColumn flexGrow1 pageWithBottomMainNav backgroundColorBlue9 abortionStepPages">
 
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

                <div className='pagesInnerDiv paddingTop100'>
                    <div className='backgroundImgWAutoH77Percent backgroundImgX45Percent backgroundImgYBottom dontRepeatImage backgroundColorBlue9 headerArea' style={{ backgroundImage: `url(${headerImage})` }}>
                        {/* <div className='flexRow justifyEnd alignFlexEnd padding0-25-25'>
                            <button onClick={() => this.goBackAStep()} className='width50Height50'>
                                <img src={prevBtnImg} className='width100Percent prevImg' alt='back' />
                            </button>
                        </div> */}
                    </div> {/* .headerArea */}

                    <div className='flexGrow1 backgroundColorWhite topRoundDiv'>
                        <div className='flexColumn alignCenter padding37-30-34 topRoundDivInnerDiv'>
                            <div className='backgroundColorPeach4 alignSelfFlexStart smallTagDiv'>
                                <span className='regularFont fontSize11 colorWhite'>{stepNum}</span>
                            </div>

                            <p className='boldFont fontSize24 lineHeight26 colorBlue8 alignSelfFlexStart textAlignLeft margin26-0-21'>{title}</p>

                            {/* <ContentfulMarkdown children={subHeader} className='boldFont fontSize15 lineHeight20 colorPeach4 alignSelfFlexStart textAlignLeft marginBottom16 contentfulMarkdown' /> */}

                            <ContentfulMarkdown children={text1} className='regularFont fontSize15 lineHeight20 colorBlack1 marginBottom36 contentfulMarkdown' />


                            <LargeButton
                                largeButtonFunc={() => this.goToEmotionalSupport()}
                                text={<p className='width100Percent flexRow justifyCenter alignCenter marginBottom0'>
                                    {/* <img src={messageIcon} style={{ width: '18px', height: 'auto', marginRight: '10px' }} alt='' /> */}
                                    {btn1Text}
                                </p>}
                                textColor={'colorWhite'}
                                backgroundColor={'backgroundColorBlue8'}
                            />

                            <div className='marginBottom15'></div>

                            <LargeButton
                                largeButtonFunc={() => this.completeThisStep()}
                                text={btn2Text}
                                textColor={'colorWhite'}
                                backgroundColor={'backgroundColorBlue6'}
                            />

                            <div className='marginBottom15'></div>

                            <LargeButton
                                largeButtonFunc={() => this.goToSelfCare()}
                                text={btn3Text}
                                textColor={'colorBlue8'}
                                backgroundColor={'backgroundColorPink'}
                            />

                            <WHOLink 
                                fontColor="#071f56"
                                appLanguage={this.props.appLanguage}
                                {...this.props} 
                            /> {/* This is the WHO link that is necessary for iOS app store */}

                        </div> {/* .topRoundDivInnerDiv */}
                    </div> {/* .topRoundDiv */}

                </div> {/* .pagesInnerDiv */}

                <BottomNavBar appLanguage={this.props.appLanguage} />
            </div>
        )
    }
}
