import React from 'react'
import { Alert, Button, Form } from 'react-bootstrap';
import * as ContentfulMarkdown from 'react-markdown'
/* import ReactGA from 'react-ga4'; */

/* import { IntroPage } from '../IntroPage'; 
 */


export class  TermsOfUseAlert extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showAlert: true,
      checkboxChecked: false,
      title: '',
      headerIcon: '',
      headerImageAlt: '',
      paragraph: '',
      checkboxText: '',
      buttonText: ''

    }
    this.handleClose = this.handleClose.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }


  componentDidMount() {
    window.scrollTo(0, 0)
    /* ReactGA.send("/TermsOfUseAlertTest") */

    document.title = "Terms of Use Popup"

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"])  
 */
    const getEntries = localStorage.getItem('ayaAppEntries')
    const entries = JSON.parse(getEntries)

    const getContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'introPrivacy');
    const getPageEntries = getContentEntries.filter(obj => obj.sys.id === '7nHgwYRF9DXFjIIqnzyzM1');

    var pageEntries = []
    var title = ''
    var paragraph = ''
    var checkboxText = ''
    var buttonText = ''


    if (getPageEntries && getPageEntries.length > 0) {
      pageEntries.push(getPageEntries[0].fields)

      if (pageEntries.length > 0) {

        if (pageEntries[0].title) {
          title = pageEntries[0].title[this.props.appLanguage]
        }
        if (pageEntries[0].paragraph) {
          paragraph = pageEntries[0].paragraph[this.props.appLanguage]
        }
        if (pageEntries[0].checkboxText) {
            checkboxText = pageEntries[0].checkboxText[this.props.appLanguage]
        }
        if (pageEntries[0].buttonText) {
            buttonText = pageEntries[0].buttonText[this.props.appLanguage]
        }

        this.setState({
          title: title,
          paragraph: paragraph,
          checkboxText: checkboxText,
          buttonText: buttonText
        })


      } // pageEntries.length
    } // getContentEntries.length



    // Get page Assets
    const getAssets = localStorage.getItem('ayaAppAssets')
    const assets = JSON.parse(getAssets)

    const getHeaderImg = assets.filter(obj => obj.sys.id === '6448JnC26zOfTuXrfOuYRO');

    var pageMainImage = ''
    var pageMainImageAlt = ''

    if (getHeaderImg && getHeaderImg.length > 0 && getHeaderImg[0].fields.file[this.props.appLanguage].url) {
      pageMainImage = 'https:' + getHeaderImg[0].fields.file[this.props.appLanguage].url
      pageMainImageAlt = getHeaderImg[0].fields.description[this.props.appLanguage]
    }

    /* console.log(buttonText) */


    this.setState({
      headerImage: pageMainImage,
      headerImageAlt: pageMainImageAlt,
    })

  } // componentDidMount()


  goBack = () => {
    this.props.history.goBack()
  }

  handleClose = () => {
    if (this.state.checkboxChecked) {
      localStorage.setItem("hasVisitedIntroAndTerms", "true");
      this.setState({ showAlert: false });
      this.props.history.push('/home');
    }
  }

  handleCheckboxChange = (event) => {
    this.setState({ checkboxChecked: event.target.checked });
  }


  render() {
    
    const { title, paragraph, checkboxText, buttonText, headerImage, headerImageAlt } = this.state

    return (
      <div className="minPageHeight100vh flexColumn flexGrow1 privacyPolicyPage paddingBottom20">
        <div className="marginBottom40"></div>
        <div className='termsofuse-div-padding'>
                    <div className='paddingTop100 justText headerArea'>
                    </div>
        {this.state.showAlert && (
            
            <>
            <Alert
            variant="primary"
            onClose={this.handleClose}
            bsPrefix="termsofuse-bottomRoundDiv"
            className="backgroundColorWhite termsofuse-bottomRoundDiv">
            <img src={headerImage} alt={headerImageAlt} className='marginTop75 termsofuse-icon marginBottom4' />
            <Alert.Heading bsPrefix="termsofuse-alert-heading">
              <div className="marginTop150">
                <ContentfulMarkdown children={title} className='boldFont fontSize18 lineHeight18 colorBlue8 contentfulMarkdown' allowDangerousHtml={true} />
              </div>
            </Alert.Heading>
            {/* <div className='flexRow'> */}
            <ContentfulMarkdown children={paragraph} className='termsofuse-alert-text regularFont fontSize15 lineHeight18 colorBlue8 contentfulMarkdown' allowDangerousHtml={true}  />
            {/* </div> */}
            <div className="form-group-container">
              <Form.Check
                bsPrefix custom
                type="checkbox"
                id="closeCheckbox"
                onChange={this.handleCheckboxChange}>
                  </Form.Check>
                <Form.Check.Label className='termsofuse-alert-checkbox-text'>
                <ContentfulMarkdown children={checkboxText} className='termsofuse-alert-checkbox-text  regularFont fontSize15 lineHeight18 colorBlue8 contentfulMarkdown' allowDangerousHtml={true}/>
                </Form.Check.Label>
              
            </div>
            <Button variant="outline-primary"
              onClick={this.handleClose}
              bsPrefix="termsofuse-button"
              disabled={!this.state.checkboxChecked}
              className="termsofuse-button-opacity">
              {buttonText}
            </Button>
              <div className="marginBottom50  paddingBottom40"></div>
               
          </Alert>
          </>
          )}
    </div>
    </div>
    )
    }
};