import React from 'react';
import ReactGA from 'react-ga4';
/* import { Link } from 'react-router-dom' */
import { TopNavBar } from '../../Components/TopNavBar'
import * as ContentfulMarkdown from 'react-markdown'
import { LargeButton } from '../../Components/LargeButton'
/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */
import { WHOLink } from '../../Components/WHOLink';


export class AbortionStep7Passed extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            headerImage: '',
            headerImageAlt: '',
            title: '',
            text: '',
            btnText: '',
        }
    }


    componentDidMount() {
        ReactGA.send("/-passed")
        window.scrollTo(0, 0)

        document.title = "Abortion Step 7 Passed"
                        
        // this hides the Crisp Chat Window from the page
        /* $crisp.push(["do", "chat:hide"]); */


        const checkPageAccess = localStorage.getItem('isAbortionStep7Complete')
        if (checkPageAccess !== 'true') {
            this.props.history.push('/abortion-step-7');
        }


        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)
        // Content type ID
        const getStep7PassedEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'sectionBeginningendingScreen');
        // Then Entry ID instead
        const getStepEntries = getStep7PassedEntries.filter(obj => obj.sys.id === '4groB1DRgccijO3RcqUSPR');

        var step7PassedEntries = []
        var title1 = ''
        var text1 = ''
        var ctaCopy = ''

        if (getStepEntries && getStepEntries.length > 0) {
            step7PassedEntries.push(getStepEntries[0].fields)

            if (step7PassedEntries.length > 0) {

                if (step7PassedEntries[0].title) {
                    title1 = step7PassedEntries[0].title[this.props.appLanguage]
                }
                if (step7PassedEntries[0].paragraph) {
                    text1 = step7PassedEntries[0].paragraph[this.props.appLanguage]
                }
                if (step7PassedEntries[0].ctaCopy1) {
                    ctaCopy = step7PassedEntries[0].ctaCopy1[this.props.appLanguage]
                }
            }
        }


        this.setState({
            title: title1,
            text: text1,
            btnText: ctaCopy
        })




        // Get page Assets
        const getAssets = localStorage.getItem('ayaAppAssets')
        const assets = JSON.parse(getAssets)

        const getAbortionStepHeaderImg = assets.filter(obj => obj.sys.id === '2L0bXX0iHuUgiYPQDHwZBL');

        var stepMainImage = ''
        var stepMainImageAlt = ''

        if (getAbortionStepHeaderImg && getAbortionStepHeaderImg.length > 0 && getAbortionStepHeaderImg[0].fields.file[this.props.appLanguage].url) {
            stepMainImage = 'https:' + getAbortionStepHeaderImg[0].fields.file[this.props.appLanguage].url
            stepMainImageAlt = getAbortionStepHeaderImg[0].fields.description[this.props.appLanguage]
        }

        this.setState({
            headerImage: stepMainImage,
            headerImageAlt: stepMainImageAlt,
        })


    } // componentDidMount()



    goBackAStep = () => {
        this.props.history.push('/abortion-step-7');
    }

    goToContraceptionAssessment = () => {
        this.props.history.push('/contraception-assessment');
    }

    goBack = () => {
        this.props.history.goBack()
      }

    render() {

        const { headerImage, headerImageAlt, title, text, btnText } = this.state

        return (
            <div className="minPageHeight100vh flexColumn flexGrow1 backgroundColorWhite abortionStepPages">
               
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

                <div className='flexColumn flexGrow1 justifyBetween textAlignCenter pagesInnerDiv paddingTop80'>
                    <img src={headerImage} className='width100Percent marginBottom26' alt={headerImageAlt} />

                    <div className='paddingLeftRight35 landscapeWidth67Percent'>
                        <ContentfulMarkdown children={title} className='width62Percent boldFont fontSize18 lineHeight23 colorPeach3 marginBottom21 marginLeftRightAuto contentfulMarkdown' />

                        <ContentfulMarkdown children={text} className='regularFont fontSize15 lineHeight18 colorBlue8 marginBottom30 contentfulMarkdown' />

                        <LargeButton
                            largeButtonFunc={() => this.goToContraceptionAssessment()}
                            text={btnText}
                            textColor={'colorWhite'}
                            backgroundColor={'backgroundColorBlue8'}
                        />
                    </div>
                        <WHOLink 
                            fontColor="#071f56"
                            appLanguage={this.props.appLanguage}
                            {...this.props} 
                        /> {/* This is the WHO link that is necessary for iOS app store */}
                    <div className='marginBottom20'>

                    </div>

                   {/*  <Link to='/abortion-step-7' className='width30Height30 margin20 justifySelfFlexEnd'>
                        <img src={prevBtnImg} className='width100Percent prevImg' alt='back' />
                    </Link> */}
                </div>
            </div>
        )
    }
}
