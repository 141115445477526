import React from 'react'
import { TopNavBar } from '../../Components/TopNavBar'
import { LargeButton } from '../../Components/LargeButton'
/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */
import * as ContentfulMarkdown from 'react-markdown'
import ReactGA from 'react-ga4';
/* import { TopNavBar } from '../../Components/TopNavBar'; */

// import prevBtnImg from "../../Images/prevBtnImage-blue.svg";



export class UnderConstructionAbortionBucket extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      headerImage: '',
      headerImageAlt: '',
      title: '',
      text: '',
      btn1Text: '',
      btn2Text: '',

    }
  }


  componentDidMount() {
    window.scrollTo(0, 0)
    ReactGA.send("/page-under-construction")

    document.title = "Page Under Construction"

    const getEntries = localStorage.getItem('ayaAppEntries')
    const entries = JSON.parse(getEntries)

    const getContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'sectionBeginningendingScreen');
    const getPageEntries = getContentEntries.filter(obj => obj.sys.id === 'r0T19USs6iF92N56ylc4b');

    var pageEntries = []
    var pageTitle = ''
    var pageText = ''
    var ctaCopy1 = ''
    var ctaCopy2 = ''


    if (getPageEntries && getPageEntries.length > 0) {
      pageEntries.push(getPageEntries[0].fields)

      if (pageEntries.length > 0) {

        if (pageEntries[0].title) {
          pageTitle = pageEntries[0].title[this.props.appLanguage]
        }
        if (pageEntries[0].paragraph) {
          pageText = pageEntries[0].paragraph[this.props.appLanguage]
        }
        if (pageEntries[0].ctaCopy1) {
          ctaCopy1 = pageEntries[0].ctaCopy1[this.props.appLanguage]
        }
        if (pageEntries[0].ctaCopy2) {
          ctaCopy2 = pageEntries[0].ctaCopy2[this.props.appLanguage]
        }


      } // pageEntries.length
    } // getContentEntries.length



    // Get page Assets
    const getAssets = localStorage.getItem('ayaAppAssets')
    const assets = JSON.parse(getAssets)

    const getHeaderImg = assets.filter(obj => obj.sys.id === '15d5yEKPSE6SOUK1BjUQWk');

    var pageMainImage = ''
    var pageMainImageAlt = ''

    if (getHeaderImg && getHeaderImg.length > 0 && getHeaderImg[0].fields.file[this.props.appLanguage].url) {
      pageMainImage = 'https:' + getHeaderImg[0].fields.file[this.props.appLanguage].url
      pageMainImageAlt = getHeaderImg[0].fields.description[this.props.appLanguage]
    }



    this.setState({
      title: pageTitle,
      text: pageText,
      btn1Text: ctaCopy1,
      btn2Text: ctaCopy2,
      headerImage: pageMainImage,
      headerImageAlt: pageMainImageAlt,
    })

  } // componentDidMount()


  goToCAssessmentQ1 = () => {
    this.props.history.push('/contraception-assessment-q1')
  }

  toContactPage = () => {
    this.props.history.push('/feedback')
  }

  goBack = () => {
    this.props.history.goBack()
  }

  render() {

    const { title, text, btn1Text, btn2Text, headerImage, headerImageAlt } = this.state

    return (
      <div className="minPageHeight100vh underconstrucionPage  flexColumn flexGrow1 pageWithBottomMainNav backgroundColorWhite ">
        {/* <div className='prevBtnTopDivWhite'>
            <button onClick={() => this.goBack()} className='paddingTop10 paddingLeftRight5 paddingBottom13 prevBtnTopResponsive flexRow justifyBetween '>
                <img src={prevBtnImg} className='width100Percent prevImg' alt='back' />
           </button>
        </div> */}
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />
        <div className="alignCenter marginLeft18Vw paddingTop90">
        <div className='width78Percent landscapeWidth67Percent flexColumn flexGrow1 alignCenter textAlignCenter paddingTop46 pagesInnerDiv'>
          <img src={headerImage} className='width100Percent marginBottom4' alt={headerImageAlt} />

          <ContentfulMarkdown children={title} className='boldFont fontSize18 lineHeight18 colorWhite psMargin0 marginBottom20 contentfulMarkdown' />

          <ContentfulMarkdown children={text} className='boldFont fontSize13 lineHeight18 colorWhite psMargin0 marginBottom20 contentfulMarkdown' />

          <ContentfulMarkdown children={btn1Text} className='regularFont fontSize13 lineHeight18 colorWhite psMargin0 marginBottom20 contentfulMarkdown' />
        {/*   <LargeButton
            largeButtonFunc={() => this.goToCAssessmentQ1()}
            text={btn1Text}
            textColor={'colorWhite'}
            backgroundColor={'backgroundColorPeach2'}
          /> */}

          <div className='marginBottom16'></div>

          <LargeButton
            largeButtonFunc={() => this.toContactPage()}
            text={btn2Text}
            textColor={'colorWhite'}
            backgroundColor={'backgroundColorBlue6 '}
          />
          </div>
        </div>
      </div>
    )
  }
}