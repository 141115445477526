import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'



export class HomePageCardContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
    console.log(this.props)
  }

  handleLinkClick = (e) => {
    if (this.props.onClick) {
      e.preventDefault();
      this.props.onClick();
    }
  };

  render() {
    const { content, /* onClick */ } = this.props

    return (
      <>
      
      <Link
        to={content.goToPageLink}
        className="flexColumn padding24-30-36-25 topRoundDivInnerDiv homePageCardContent"
        onClick={this.handleLinkClick} // this handles the onClick event to navigate to onboarding or abortion step menu
      >
        <div className="alignSelfFlexEnd backgroundColorWhite smallTagDiv">
          <span
            className={
              '' + content.tagColor + ' boldFont colorPeach1 fontSize10'
            }
          >
            {content.tag}
          </span>
        </div>
        <p
          className={
            '' +
            content.titleColor +
            ' width85Percent boldFont fontSize24 lineHeight26 colorWhite marginBottom10'
          }
        >
          {content.title}
        </p>
{/*         <p
          className={
            '' +
            content.paragraghColor +
            ' width74Percent regularFont fontSize15 lineHeight18 colorWhite'
          }
        >
          {content.paragraph}
        </p> */}
      </Link>
      </>
    )
  }
}

HomePageCardContent.propTypes = {
  content: PropTypes.object,
}
