import React from 'react';
import { TopNavBar } from '../../Components/TopNavBar'
/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */
import { LargeButton } from '../../Components/LargeButton'
import * as ContentfulMarkdown from 'react-markdown'
import { BottomNavBar } from '../../Components/BottomNavBar'
import ReactGA from 'react-ga4';
import messageIcon from '../../Images/message-icon-white.svg'
import { WHOLink } from '../../Components/WHOLink';


export class AbortionPillRegimenB extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            // stepNum: '',
            title: '',
            subHeader: '',
            stepATag: '',
            stepBTag: '',
            StepBIsComplete: false,
            stepBRadioText: '',
            StepB3HoursIsComplete: false,
            stepB3HoursRadioText: '',
            StepB6HoursIsComplete: false,
            stepB6HoursRadioText: '',
            // stepCTag: '',
            text1: '',
            // text2: '',
            text3: '',
            extraText1: '',
            text4: '',
            extraText2: '',
            text5: '',
            image1LabelText: '',
            image1: '',
            image1Alt: '',
            image2: '',
            image2Alt: '',
            // image4: '',
            // image4Alt: '',
            btn1Text: '',
            btn2Text: '',
            btn3Text: '',
            btn4Text: '',
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)
        ReactGA.send("/abortion-step-4-pill-regimen-b")

        document.title = "Abortion Pill Regimen B"

                        
        // this hides the Crisp Chat Window from the page. "chat:show" for visibility, "chat:hide" to hide the chat
        $crisp.push(["do", "chat:show"]);


        var forStepBIsComplete = false
        var forStepB3HoursIsComplete = false
        var forStepB6HoursIsComplete = false

        if (localStorage.getItem('abortionRegimenBStepBIsComplete') === 'true') {
            forStepBIsComplete = true
        }
        if (localStorage.getItem('abortionRegimenBStepB3HoursIsComplete') === 'true') {
            forStepB3HoursIsComplete = true
        }
        if (localStorage.getItem('abortionRegimenBStepB6HoursIsComplete') === 'true') {
            forStepB6HoursIsComplete = true
        }


        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)
        // Content type ID
        const getAbortionStepsEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'step1');
        // Then Entry ID instead
        const getStepEntries = getAbortionStepsEntries.filter(obj => obj.sys.id === '4xPjdEKLHc3DG3X05AcsAZ');

        var stepEntries = []
        // var stepNumber = ''
        var stepTitle = ''
        var stepSubHeader = ''
        var stepLetterA = ''
        var stepLetterB = ''
        var stepBRadioText1 = ''
        var stepB3HoursRadioText1 = ''
        var stepB6HoursRadioText1 = ''
        // var stepLetterC = ''
        var stepText1 = ''
        // var stepText2 = ''
        var stepText3 = ''
        var extraStepText1 = ''
        var stepText4 = ''
        var extraStepText2 = ''
        var stepText5 = ''
        var image1LabelText1 = ''
        var ctaCopy1 = ''
        var ctaCopy2 = ''
        var ctaCopy3 = ''
        var ctaCopy4 = ''


        if (getStepEntries && getStepEntries.length > 0) {
            stepEntries.push(getStepEntries[0].fields)

            if (stepEntries.length > 0) {

                // if (stepEntries[0].stepNumber) {
                //     stepNumber = stepEntries[0].stepNumber[this.props.appLanguage]
                // }
                if (stepEntries[0].title) {
                    stepTitle = stepEntries[0].title[this.props.appLanguage]
                }
                if (stepEntries[0].subheader1) {
                    stepSubHeader = stepEntries[0].subheader1[this.props.appLanguage]
                }

                if (stepEntries[0].stepLetterA) {
                    stepLetterA = stepEntries[0].stepLetterA[this.props.appLanguage]
                }
                if (stepEntries[0].stepLetterB) {
                    stepLetterB = stepEntries[0].stepLetterB[this.props.appLanguage]
                }
                if (stepEntries[0].completed1) {
                    stepBRadioText1 = stepEntries[0].completed1[this.props.appLanguage]
                }
                if (stepEntries[0].completed2) {
                    stepB3HoursRadioText1 = stepEntries[0].completed2[this.props.appLanguage]
                }
                if (stepEntries[0].completed3) {
                    stepB6HoursRadioText1 = stepEntries[0].completed3[this.props.appLanguage]
                }
                // if (stepEntries[0].stepLetterC) {
                //     stepLetterC = stepEntries[0].stepLetterC[this.props.appLanguage]
                // }

                if (stepEntries[0].text1) {
                    stepText1 = stepEntries[0].text1[this.props.appLanguage]
                }
                // if (stepEntries[0].text2) {
                //     stepText2 = stepEntries[0].text2[this.props.appLanguage]
                // }
                if (stepEntries[0].text3) {
                    stepText3 = stepEntries[0].text3[this.props.appLanguage]
                }
                if (stepEntries[0].extraStepsText) {
                    extraStepText1 = stepEntries[0].extraStepsText[this.props.appLanguage]
                }
                if (stepEntries[0].text4) {
                    stepText4 = stepEntries[0].text4[this.props.appLanguage]
                }
                if (stepEntries[0].extraText) {
                    extraStepText2 = stepEntries[0].extraText[this.props.appLanguage]
                }
                if (stepEntries[0].text5) {
                    stepText5 = stepEntries[0].text5[this.props.appLanguage]
                }
                if (stepEntries[0].text6) {
                    image1LabelText1 = stepEntries[0].text6[this.props.appLanguage]
                }

                if (stepEntries[0].ctaCopy1) {
                    ctaCopy1 = stepEntries[0].ctaCopy1[this.props.appLanguage]
                }
                if (stepEntries[0].ctaCopy2) {
                    ctaCopy2 = stepEntries[0].ctaCopy2[this.props.appLanguage]
                }
                if (stepEntries[0].ctaCopy3) {
                    ctaCopy3 = stepEntries[0].ctaCopy3[this.props.appLanguage]
                }
                if (stepEntries[0].ctaCopy4) {
                    ctaCopy4 = stepEntries[0].ctaCopy4[this.props.appLanguage]
                }
            } // stepEntries.length
        } // getAbortionStepsEntries.length




        // Get page Assets
        const getAssets = localStorage.getItem('ayaAppAssets')
        const assets = JSON.parse(getAssets)

        // const getAbortionStepHeaderImg = assets.filter(obj => obj.sys.id === '6bPETZEIIxRDeDIOrfbuTA');

        const getimage1 = assets.filter(obj => obj.sys.id === '36qarNFSB2hn0Ljij5VduG');
        const getimage2 = assets.filter(obj => obj.sys.id === '3flLhxi1OvtnIRf3qbEwdW');


        // var stepMainImage = ''
        var pageimage1 = ''
        var pageimage1Alt = ''
        var pageimage2 = ''
        var pageimage2Alt = ''
        // var pageImage4 = ''
        // var pageImage4Alt = ''

        // if (getAbortionStepHeaderImg && getAbortionStepHeaderImg.length > 0 && getAbortionStepHeaderImg[0].fields.file[this.props.appLanguage].url) {
        //     stepMainImage = 'https:' + getAbortionStepHeaderImg[0].fields.file[this.props.appLanguage].url
        // }

        if (getimage1 && getimage1.length > 0 && getimage1[0].fields.file[this.props.appLanguage].url) {
            pageimage1 = 'https:' + getimage1[0].fields.file[this.props.appLanguage].url
            pageimage1Alt = getimage1[0].fields.description[this.props.appLanguage]
        }

        if (getimage2 && getimage2.length > 0 && getimage2[0].fields.file[this.props.appLanguage].url) {
            pageimage2 = 'https:' + getimage2[0].fields.file[this.props.appLanguage].url
            pageimage2Alt = getimage2[0].fields.description[this.props.appLanguage]
        }

        // if (getImage4 && getImage4.length > 0 && getImage4[0].fields.file[this.props.appLanguage].url) {
        //     pageImage4 = 'https:' + getImage4[0].fields.file[this.props.appLanguage].url
        //     pageImage4Alt = getImage4[0].fields.description[this.props.appLanguage]
        // }



        this.setState({
            // stepNum: stepNumber,
            title: stepTitle,
            subHeader: stepSubHeader,
            stepATag: stepLetterA,
            stepBTag: stepLetterB,
            StepBIsComplete: forStepBIsComplete,
            stepBRadioText: stepBRadioText1,
            StepB3HoursIsComplete: forStepB3HoursIsComplete,
            stepB3HoursRadioText: stepB3HoursRadioText1,
            StepB6HoursIsComplete: forStepB6HoursIsComplete,
            stepB6HoursRadioText: stepB6HoursRadioText1,
            // stepCTag: stepLetterC,
            text1: stepText1,
            // text2: stepText2,
            text3: stepText3,
            extraText1: extraStepText1,
            text4: stepText4,
            extraText2: extraStepText2,
            text5: stepText5,
            image1LabelText: image1LabelText1,
            btn1Text: ctaCopy1,
            btn2Text: ctaCopy2,
            btn3Text: ctaCopy3,
            btn4Text: ctaCopy4,

            // headerImage: stepMainImage,
            image1: pageimage1,
            image1Alt: pageimage1Alt,
            image2: pageimage2,
            image2Alt: pageimage2Alt,
            // image4: pageImage4,
            // image4Alt: pageImage4Alt,
        })

    } // componentDidMount()




    goBackAStep = () => {
        this.props.history.push('/abortion-step-4');
    }


    toggleRadio = (stateName) => {
        const getOptionState = this.state[stateName]
        var localStorageName = 'abortionRegimenB' + [stateName]

        if (getOptionState === true) {
            this.setState({
                [stateName]: false,
            })
            window.localStorage.setItem(localStorageName, false)
        } else if (getOptionState === false) {
            this.setState({
                [stateName]: true,
            })
            window.localStorage.setItem(localStorageName, true)
        }
    }


    goToInputPillTime = () => {
        window.localStorage.setItem('abortionPillRegimenSelected', 'B')
        this.props.history.push('/notifications-opt-in');
    }

    completeThisStep = () => {
        window.localStorage.setItem('abortionPillRegimenSelected', 'B')
        window.localStorage.setItem('isAbortionStep4Complete', true)

        this.props.history.push('/abortion');
    }

    goToSelfCare = () => {
        this.props.history.push('/self-care-advice');
    }

    goToMedicalAdvice = () => {
        this.props.history.push('/resources-medical-advice');
    }

    goBack = () => {
        this.props.history.goBack()
      }

    render() {

        const { title, subHeader, stepATag, stepBTag, stepBRadioText, stepB3HoursRadioText, stepB6HoursRadioText, image1LabelText, image1, image1Alt, image2, image2Alt, text1, text3, extraText1, text4, extraText2, text5, btn1Text, btn2Text, btn3Text, btn4Text } = this.state

        return (
            <div className="minPageHeight100vh flexColumn flexGrow1 pageWithBottomMainNav abortionStepPages">
                
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

                <div className='pagesInnerDiv paddingTop80'>
                    <div className='empty headerArea'></div> {/* .headerArea */}

                    <div className='flexGrow1 backgroundColorWhite topRoundDiv'>
                        <div className='flexColumn textAlignLeft padding37-30-34 topRoundDivInnerDiv'>
                            <div className='flexRow justifyBetween'>
                                <ContentfulMarkdown children={subHeader} className='width50Percent boldFont fontSize15 lineHeight18 colorPeach4 psMargin0 contentfulMarkdown marginBottom8' />
                               {/*  <button onClick={() => this.goBackAStep()} className='width50Height50'>
                                    <img src={prevBtnImg} className='width100Percent prevImg' alt='back' />
                                </button> */}
                            </div>

                            <p className='width55Percent boldFont fontSize24 lineHeight26 colorBlue8 alignSelfFlexStart textAlignLeft marginBottom21'>{title}</p>

                            <div className='backgroundColorPeach4 alignSelfFlexStart marginBottom13 smallTagDiv'>
                                <span className='regularFont fontSize11 colorWhite'>{stepATag}</span>
                            </div>

                            <ContentfulMarkdown children={text1} className='regularFont fontSize15 lineHeight16 colorBlack2 psMargin0 marginBottom30 circleBullets contentfulMarkdown' />
                            {/* className='regularFont fontSize15 lineHeight15 colorBlue8 padding10-0 margin0' */}

                            <div className='backgroundColorPeach4 alignSelfFlexStart marginBottom13 smallTagDiv'>
                                <span className='regularFont fontSize11 colorWhite'>{stepBTag}</span>
                            </div>

                            <ContentfulMarkdown className='regularFont fontSize15 lineHeight16 colorBlack2 psMargin0 marginBottom30 circleBullets contentfulMarkdown' children={text3} />

                            <img src={image1} className='width88Percent marginBottom30 marginLeftRightAuto' alt={image1Alt} />


                            <ContentfulMarkdown children={extraText1} className='regularFont fontSize15 lineHeight16 colorBlack2 hasColorPeach4Span psMargin0 circleBullets contentfulMarkdown' allowDangerousHtml={true} />

                            <div className='flexRow alignCenter marginBottom40'>
                                <input
                                    type='checkbox'
                                    // name={'question2'}
                                    checked={this.state.StepBIsComplete}
                                    className='flexColumn justifyCenter alignCenter marginRight10 backgroundColorNone radioCheckPeach smallMediumRadioBtn'
                                    onChange={() => this.toggleRadio('StepBIsComplete')}
                                />

                                <span className='boldFont fontSize13 lineHeight16 colorBlue8'>{stepBRadioText}</span>
                            </div>

                            <ContentfulMarkdown children={text4} className='regularFont fontSize15 lineHeight16 colorBlack2 hasColorPeach4Span hasBookmarkIcon psMargin0 marginBottom16 contentfulMarkdown' allowDangerousHtml={true} />

                            <div className='flexRow alignCenter marginBottom40'>
                                <input
                                    type='checkbox'
                                    // name={'question2'}
                                    checked={this.state.StepB3HoursIsComplete}
                                    className='flexColumn justifyCenter alignCenter marginRight10 backgroundColorNone radioCheckPeach smallMediumRadioBtn'
                                    onChange={() => this.toggleRadio('StepB3HoursIsComplete')}
                                />

                                <span className='boldFont fontSize13 lineHeight16 colorBlue8'>{stepB3HoursRadioText}</span>
                            </div>

                            <ContentfulMarkdown children={extraText2} className='regularFont fontSize15 lineHeight16 colorBlack2 hasColorPeach4Span hasBookmarkIcon psMargin0 marginBottom16 contentfulMarkdown' allowDangerousHtml={true} />

                            <div className='flexRow alignCenter marginBottom40'>
                                <input
                                    type='checkbox'
                                    // name={'question2'}
                                    checked={this.state.StepB6HoursIsComplete}
                                    className='flexColumn justifyCenter alignCenter marginRight10 backgroundColorNone radioCheckPeach smallMediumRadioBtn'
                                    onChange={() => this.toggleRadio('StepB6HoursIsComplete')}
                                />

                                <span className='boldFont fontSize13 lineHeight16 colorBlue8'>{stepB6HoursRadioText}</span>
                            </div>

                            {/* <div className='flexRow marginBottom13'>
                                <img src={bookmarkIcon} className='marginRight4' alt=' ' />
                                <ContentfulMarkdown children={text2} className='alignSelfFlexStart boldFont fontSize16 lineHeight15 colorPeach4 psMargin0 contentfulMarkdown' />
                            </div> */}

                            {/* <img src={image2} className='width93Percent marginTop0Bottom50 marginLeftRightAuto' alt={image2Alt} /> */}

                            {/* <div className='backgroundColorPeach4 alignSelfFlexStart marginBottom13 smallTagDiv'>
                                <span className='regularFont fontSize11 colorWhite'>{stepCTag}</span>
                            </div> */}

                            <ContentfulMarkdown className='boldFont fontSize15 lineHeight16 colorBlack2 psMargin0 marginBottom16 contentfulMarkdown' children={image1LabelText} allowDangerousHtml={true} />

                            <img src={image2} className='width100Percent marginBottom30 marginLeftRightAuto' alt={image2Alt} />

                            <ContentfulMarkdown children={text5} className='regularFont fontSize15 lineHeight16 colorBlack2 hasColorPeach4Span psMargin0 marginBottom30 circleBullets contentfulMarkdown' allowDangerousHtml={true} />

{/*                             <LargeButton
                                largeButtonFunc={() => this.goToInputPillTime()}
                                text={btn1Text}
                                textColor={'colorWhite'}
                                backgroundColor={'backgroundColorPeach5'}
                            />
 */}
                            <div className='marginBottom40'></div>

                            <LargeButton
                                largeButtonFunc={() => this.goToMedicalAdvice()}
                                text={<p className='width100Percent flexRow justifyCenter alignCenter marginBottom0'>
                                    {/* <img src={messageIcon} style={{ width: '18px', height: 'auto', marginRight: '10px' }} alt='' /> */}
                                    {btn2Text}
                                </p>}
                                textColor={'colorWhite'}
                                backgroundColor={'backgroundColorBlue8'}
                            />

                            <div className='marginBottom15'></div>

                            <LargeButton
                                largeButtonFunc={() => this.completeThisStep()}
                                text={btn3Text}
                                textColor={'colorWhite'}
                                backgroundColor={'backgroundColorBlue6'}
                            />

                            <div className='marginBottom15'></div>

                            <LargeButton
                                largeButtonFunc={() => this.goToSelfCare()}
                                text={btn4Text}
                                textColor={'colorBlue8'}
                                backgroundColor={'backgroundColorPink'}
                            />

                            <WHOLink 
                                fontColor="#071f56"
                                appLanguage={this.props.appLanguage}
                                {...this.props}   
                            /> {/* This is the WHO link that is necessary for iOS app store */}

                        </div> {/* .topRoundDivInnerDiv */}
                    </div> {/* .topRoundDiv */}

                </div> {/* .pagesInnerDiv */}

                <BottomNavBar appLanguage={this.props.appLanguage} />
            </div>
        )
    }
}
