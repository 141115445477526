import React from "react";
/* import { Link } from "react-router-dom"; */
import { TopNavBar } from "../../Components/TopNavBar";
import * as ContentfulMarkdown from "react-markdown";
import Calendar from "react-calendar";
import { LargeButton } from "../../Components/LargeButton";
import messageIcon from "../../Images/talking-bubble-icon-peach.svg";
import prevBtnImg from "../../Images/prevBtnImage-whiteAndBlue.svg";
import nextBtnImg from "../../Images/nextBtnImage-whiteAndBlue.svg";
/* import prevBtnImgWhite from "../../Images/prevBtnImg-white.svg"; */
import { GestationalAgeAlert } from "./GestationalAgeAlert";
import { HelpfulPopUp } from "../../Components/HelpfulPopUp";
import ReactGA from 'react-ga4';
export class AbortionAssessment1A extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: "1A",
      stepNumber: "",
      title: "",
      calendarValue: "",
      userGestationalAgeInDays: 0,
      userGestationalAgeInWeeks: 0,
      btn1Text: "",
      btn2Text: "",
      linkToPopUpText: "",

      gaPopUpTitleText1: '',
      gaPopUpTitleText2: '',
      gaPopUpText1: '',
      gaPopUpBtn1Text: '',

      popUp1Title: "",
      popUp1Text1: "",
      popUp1Btn1Text: "",
      popUp1Btn2Text: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.send("/abortion-assessment-q1a")

    document.title = "Abortion Assessment 1A";

            
        // this shows the Crisp Chat 
        /* $crisp.push(["do", "chat:show"]) */

    const getEntries = localStorage.getItem("ayaAppEntries");
    const entries = JSON.parse(getEntries);
    // Content type ID
    const getContentEntries = entries.filter(
      (obj) => obj.sys.contentType.sys.id === "calendarPicker"
    );
    // Then Entry ID instead
    const getPageEntries = getContentEntries.filter(
      (obj) => obj.sys.id === "KF6Z2745ExbBRKgJwmvJK"
    );

    var pageEntries = [];
    var stepNum = "";
    var title1 = "";
    var ctaCopy1 = "";
    var ctaCopy2 = "";
    var popUpText = "";

    if (getPageEntries && getPageEntries.length > 0) {
      pageEntries.push(getPageEntries[0].fields);

      if (pageEntries.length > 0) {
        if (pageEntries[0].stepNumber) {
          stepNum = pageEntries[0].stepNumber[this.props.appLanguage];
        }
        if (pageEntries[0].title) {
          title1 = pageEntries[0].title[this.props.appLanguage];
        }
        if (pageEntries[0].ctaCopy1) {
          ctaCopy1 = pageEntries[0].ctaCopy1[this.props.appLanguage];
        }
        if (pageEntries[0].ctaCopy2) {
          ctaCopy2 = pageEntries[0].ctaCopy2[this.props.appLanguage];
        }
        if (pageEntries[0].popUpText) {
          popUpText = pageEntries[0].popUpText[this.props.appLanguage];
        }
      }
    }


    // Continue to questions/ gestational age pop up
    // Content type ID
    const getPopUpEntries = entries.filter(
              (obj) => obj.sys.contentType.sys.id === "popUp"
            );
    // Then Entry ID instead
    const getContinueGaPopUpEntries = getPopUpEntries.filter(
      (obj) => obj.sys.id === "2Db5ddZ0eJnL6DZUoDliWI"
    );

    var continueGaPopUpEntries = [];
    var cGaPopUpTitleText1 = '';
    var cGaPopUpTitleText2 = '';
    var cGaPopUpText1 = '';
    var cGaPopUpBtn1Text = '';

    if (getContinueGaPopUpEntries && getContinueGaPopUpEntries.length > 0) {
      continueGaPopUpEntries.push(getContinueGaPopUpEntries[0].fields);

      if (continueGaPopUpEntries.length > 0) {

        if (continueGaPopUpEntries[0].youHave) {
          cGaPopUpTitleText1 = continueGaPopUpEntries[0].youHave[this.props.appLanguage];
        }
        if (continueGaPopUpEntries[0].weeks) {
          cGaPopUpTitleText2 = continueGaPopUpEntries[0].weeks[this.props.appLanguage];
        }
        if (continueGaPopUpEntries[0].paragraph) {
          cGaPopUpText1 = continueGaPopUpEntries[0].paragraph[this.props.appLanguage];
        }
        if (continueGaPopUpEntries[0].ctaCopy1) {
          cGaPopUpBtn1Text = continueGaPopUpEntries[0].ctaCopy1[this.props.appLanguage];
        }
      }
    }


    // Helpful popup 1 entries
    // Then Entry ID instead
    const getPopUp1Entries = getPopUpEntries.filter(
      (obj) => obj.sys.id === "3bnqqUOsJx3JRHMpxqEME2"
    );

    var popUp1Entries = [];
    var popUp1EntryTitle = "";
    var popUp1Entrytext1 = "";
    var popUp1EntryBtn1 = "";
    var popUp1EntryBtn2 = "";

    if (getPopUp1Entries && getPopUp1Entries.length > 0) {
      popUp1Entries.push(getPopUp1Entries[0].fields);

      if (popUp1Entries.length > 0) {
        if (popUp1Entries[0].title) {
          popUp1EntryTitle = popUp1Entries[0].title[this.props.appLanguage];
        }
        if (popUp1Entries[0].paragraph) {
          popUp1Entrytext1 = popUp1Entries[0].paragraph[this.props.appLanguage];
        }
        if (popUp1Entries[0].ctaCopy1) {
          popUp1EntryBtn1 = popUp1Entries[0].ctaCopy1[this.props.appLanguage];
        }
        if (popUp1Entries[0].ctaCopy2) {
          popUp1EntryBtn2 = popUp1Entries[0].ctaCopy2[this.props.appLanguage];
        }
      }
    }

    this.setState({
      calendarValue: new Date(),
      stepNumber: stepNum,
      title: title1,
      btn1Text: ctaCopy1,
      btn2Text: ctaCopy2,
      linkToPopUpText: popUpText,

      gaPopUpTitleText1: cGaPopUpTitleText1,
      gaPopUpTitleText2: cGaPopUpTitleText2,
      gaPopUpText1: cGaPopUpText1,
      gaPopUpBtn1Text: cGaPopUpBtn1Text,

      popUp1Title: popUp1EntryTitle,
      popUp1Text1: popUp1Entrytext1,
      popUp1Btn1Text: popUp1EntryBtn1,
      popUp1Btn2Text: popUp1EntryBtn2,
    });

   
  } // componentDidMount()

  onCalendarDateChange = (value, event) => {
    // get gestational Age In Days then update calendar date selection
    console.log(value);
    var getMilliSecs = new Date().getTime() - new Date(value).getTime();
    var getGestationalAge = Math.floor(getMilliSecs / 8.64e7);
    var getGestationalWeekAge = Math.floor(getGestationalAge / 7)

    this.setState({
      calendarValue: value,
      userGestationalAgeInDays: getGestationalAge,
      userGestationalAgeInWeeks: getGestationalWeekAge,
    });
  };

  continue = () => {
    const userGa = this.state.userGestationalAgeInDays;
    if (userGa >= 0) {
      if (userGa >= 84) {
        this.setState({
          page: "gaAlert",
        });
      } else {
        this.setState({
          page: 'continueToQuestions'
        })
      }
    }
  };

  goToQ2 = () => {
    this.props.history.push("/abortion-assessment-q2");
  }

  goTo1B = () => {
    this.props.history.push("/abortion-assessment-q1b");
  };

  showPopUp1 = () => {
    this.setState({
      page: "popUp1",
    });
  };

  comeBackToMainQuestion = () => {
    this.setState({
      page: "1A",
    });
  };

  goBack = () => {
    this.props.history.goBack()
  };

  render() {
    return (
      <div className="flexColumn flexGrow1">
        {this.renderChildren()} {/* Down below */}
      </div>
    );
  }

  renderChildren = () => {
    const {
      page,
      stepNumber,
      title,
      calendarValue,
      btn1Text,
      btn2Text,
      gaPopUpTitleText1,
      gaPopUpTitleText2,
      gaPopUpText1,
      gaPopUpBtn1Text,
      linkToPopUpText,
      popUp1Title,
      popUp1Text1,
      popUp1Btn1Text,
      popUp1Btn2Text,
      userGestationalAgeInWeeks
    } = this.state;

    if (page === "1A") {
      return (
        <div className="minPageHeight100vh flexColumn flexGrow1 abortionAssessmentPage">
         
        <TopNavBar bgColor="#071f56" showHamburgerMenu={true} menuIconColor={'white'} logoColor={'peach'} bellColor={'white'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

          <div className="flexColumn flexGrow1 justifyCenter alignCenter textAlignCenter paddingTop80 landscapeWidth75Percent">
            <div className="paddingLeftRight45">
              <ContentfulMarkdown
                children={stepNumber}
                className="boldFont fontSize12 lineHeight14 colorPeach4 marginBottom4 contentfulMarkdown"
              />

              <ContentfulMarkdown
                children={title}
                className="boldFont fontSize22 lineHeight25 colorWhite psMargin0 marginBottom30 contentfulMarkdown"
              />
            </div>

            <div className="paddingLeftRight35">
              <Calendar
                onChange={(value, event) =>
                  this.onCalendarDateChange(value, event)
                }
                value={calendarValue}
                days={calendarValue}
                calendarType={"US"}
                locale={this.props.datePickerLanguage}
                className={"boldFont colorWhite marginBottom30"}
                prevLabel={
                  <img
                    src={prevBtnImg}
                    className="width14Height14 margin10"
                    alt="back"
                  />
                }
                nextLabel={
                  <img
                    src={nextBtnImg}
                    className="width14Height14 margin10"
                    alt="next"
                  />
                }
                prev2Label={null}
                next2Label={null}
              />

              <LargeButton
                largeButtonFunc={() => this.continue()}
                text={btn1Text}
                textColor={"colorBlue8"}
                backgroundColor={"backgroundColorWhite"}
              />

              <div className="marginBottom12"></div>

              <LargeButton
                largeButtonFunc={() => this.goTo1B()}
                text={btn2Text}
                textColor={"colorWhite"}
                backgroundColor={"backgroundColorPeach2"}
              />

              <div className="marginBottom12"></div>

              <div className="flexRow paddingLeftRight40 landscapeWidth75Percent">
                <img
                  src={messageIcon}
                  className="width16Height16 marginTop3 marginRight5"
                  alt="..."
                />
                <button onClick={() => this.showPopUp1()}>
                  <ContentfulMarkdown
                    children={linkToPopUpText}
                    className="boldFont fontSize13 lineHeight15 colorPeach4 underline textAlignLeft psMargin0 contentfulMarkdown"
                  />
                </button>
              </div>
            </div>
          </div>

          {/* <Link
            to="/abortion-step-1"
            className="width30Height30 margin20 justifySelfFlexEnd"
          >
            <img
              src={prevBtnImg}
              className="width100Percent prevImg"
              alt="back"
            />
          </Link> */}
        </div>
      );
    } else if (page === "gaAlert") {
      return (
        <GestationalAgeAlert
          userGestationalAgeInWeeks={userGestationalAgeInWeeks}
          backToFunc={() => this.comeBackToMainQuestion()}
          appLanguage={this.props.appLanguage}
          history={this.props.history}
        />
      );
    } else if (page === "continueToQuestions") {
      return (
        <HelpfulPopUp
          pageName={'gestationalPopUp'}
          pageBackgroundColor={"backgroundColorBlue8"}
          logoColor={"peach"}
          bellColor={"white"}
          newNotificationColor={"peach"}
          appLanguage={this.props.appLanguage}
          helpfulDivPaddingLeftRight={"paddingLeftRight35"}
          helpfulDivBackgroundColor={"backgroundColorPeach4"}
          helpfulDivTextColor={"colorWhite"}
          content={{
            titleText1: gaPopUpTitleText1,
            titleTextNumOfWeeks: userGestationalAgeInWeeks,
            titleText2: gaPopUpTitleText2,
            text1: gaPopUpText1,
            btn1Text: gaPopUpBtn1Text,
          }}
          btn1TextColor={"colorBlue8"}
          btn1BackgroundColor={"backgroundColorWhite"}
          // btn2TextColor={"colorWhite"}
          // btn2BackgroundColor={"backgroundColorBlue8"}
          btn1Function={() => this.goToQ2()}
          // btn2Function={() => this.goTo1B()}
          backToFunc={() => this.comeBackToMainQuestion()}
        />
      );
    } else if (page === "popUp1") {
      return (
        <HelpfulPopUp
          pageBackgroundColor={"backgroundColorBlue8"}
          logoColor={"peach"}
          bellColor={"white"}
          newNotificationColor={"peach"}
          appLanguage={this.props.appLanguage}
          helpfulDivPaddingLeftRight={"paddingLeftRight35"}
          helpfulDivBackgroundColor={"backgroundColorPeach4"}
          helpfulDivTextColor={"colorWhite"}
          content={{
            title: popUp1Title,
            text1: popUp1Text1,
            btn1Text: popUp1Btn1Text,
            btn2Text: popUp1Btn2Text,
          }}
          btn1TextColor={"colorBlue8"}
          btn1BackgroundColor={"backgroundColorWhite"}
          btn2TextColor={"colorWhite"}
          btn2BackgroundColor={"backgroundColorBlue8"}
          btn1Function={() => this.comeBackToMainQuestion()}
          btn2Function={() => this.goTo1B()}
          backToFunc={() => this.comeBackToMainQuestion()}
        />
      );
    }
  };
}
