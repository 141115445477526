import React from "react";
import ReactGA from "react-ga4";
import { TopNavBar } from "../../Components/TopNavBar";
import * as ContentfulMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { LargeButton } from "../../Components/LargeButton";
/* import { WHOLink } from '../../Components/WHOLink'; */

export class Onboarding3 extends React.Component {
  constructor(props) {
    super(props);

    console.log(props, "props");
    this.state = {
      page: "2",
      stepNumber: "",
      title: "",
      questionOptions: [],
      optionText1: false,
      optionText2: false,
      optionText3: false,
      optionText4: false,
      optionText5: false,
      noneApplyText: "",
      buttonText: "",
      linkText1: "",
      linkText2: "",
    };
  }

  componentDidMount() {
    console.log("appLanguage in Onboarding3:", this.props.appLanguage); // Log appLanguage prop

    ReactGA.send("/onboarding3");
    window.scrollTo(0, 0);

    document.title = "Onboarding 3";
        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */
    const getEntries = localStorage.getItem("ayaAppEntries");
    const entries = JSON.parse(getEntries);
    // Content type ID
    const getContentEntries = entries.filter(
      (obj) => obj.sys.contentType.sys.id === "abortionFlow"
    );
    // Then Entry ID instead
    const getPageEntries = getContentEntries.filter(
      (obj) => obj.sys.id === "2JEC2pCa9rGrpFExEdC7Kg"
    );

    var pageEntries = [];
    var options = [];
    var stepNum = "";
    var title1 = "";
    var noneApplyText1 = "";
    var buttonText1 = "";
    var linkText2 = "";

    if (getPageEntries && getPageEntries.length > 0) {
      pageEntries.push(getPageEntries[0].fields);

      if (pageEntries.length > 0) {
        if (pageEntries[0].stepNumber) {
          stepNum = pageEntries[0].stepNumber[this.props.appLanguage];
        }
        if (pageEntries[0].title) {
          title1 = pageEntries[0].title[this.props.appLanguage];
        }
        if (pageEntries[0].buttonText) {
          buttonText1 = pageEntries[0].buttonText[this.props.appLanguage];
        }
        if (pageEntries[0].optionText1) {
          options.push({
            option: pageEntries[0].optionText1[this.props.appLanguage],
            stateName: "optionText1",
          });
        }
        if (pageEntries[0].optionText2) {
          options.push({
            option: pageEntries[0].optionText2[this.props.appLanguage],
            stateName: "optionText2",
          });
        }
        if (pageEntries[0].optionText3) {
          options.push({
            option: pageEntries[0].optionText3[this.props.appLanguage],
            stateName: "optionText3",
          });
        }
        if (pageEntries[0].optionText4) {
          options.push({
            option: pageEntries[0].optionText4[this.props.appLanguage],
            stateName: "optionText4",
          });
        }
        if (pageEntries[0].optionText5) {
          options.push({
            option: pageEntries[0].optionText5[this.props.appLanguage],
            stateName: "optionText5",
          });
        }
        if (pageEntries[0].ifNoneApplyCopy) {
          noneApplyText1 =
            pageEntries[0].ifNoneApplyCopy[this.props.appLanguage];
        }
        if (pageEntries[0].linkText1) {
          linkText2 = pageEntries[0].linkText1[this.props.appLanguage];
        }
      }
    }

    this.setState(
      {
        stepNumber: stepNum,
        title: title1,
        questionOptions: options,
        noneApplyText: noneApplyText1,
        buttonText: buttonText1,
        linkText1: linkText2,
      },
      () => {
        this.props.firstTimeLogin();
      }
    );
  } // componentDidMount()

  toggleRadio = (stateName) => {
    this.props.firstTimeLogin();
    const getOptionStateName = this.state[stateName];

    if (getOptionStateName === false) {
      this.setState({
        optionText1: false,
        optionText2: false,
        optionText3: false,
        optionText4: false,
        optionText5: false,
        [stateName]: true,
      });
    } else if (getOptionStateName === true) {
      this.setState({
        optionText1: false,
        optionText2: false,
        optionText3: false,
        optionText4: false,
        optionText5: false,
        [stateName]: false,
      });
    }
  };

  continue = () => {
    const answerBoolArray = [
      this.state.optionText1,
      this.state.optionText2,
      this.state.optionText3,
      this.state.optionText4,
      this.state.optionText5,
    ];

    const trueCount = answerBoolArray.filter(Boolean).length;

    window.localStorage.setItem("onbording1truecount", trueCount);

    var selectedQuestions = [];

    if (this.state.optionText1 === true) {
      const getQuestionObj = this.state.questionOptions.filter(
        (obj) => obj.stateName === "optionText1"
      );
      selectedQuestions.push(getQuestionObj[0].option);
      this.props.history.push("/abortion-step-7");
    }

    if (this.state.optionText2 === true) {
      const getQuestionObj = this.state.questionOptions.filter(
        (obj) => obj.stateName === "optionText2"
      );
      selectedQuestions.push(getQuestionObj[0].option);
      this.props.history.push("/abortion-step-3");
    }

    if (this.state.optionText3 === true) {
      const getQuestionObj = this.state.questionOptions.filter(
        (obj) => obj.stateName === "optionText3"
      );
      selectedQuestions.push(getQuestionObj[0].option);
      this.props.history.push("/abortion-step-5");
    }

    if (this.state.optionText4 === true) {
      const getQuestionObj = this.state.questionOptions.filter(
        (obj) => obj.stateName === "optionText4"
      );
      selectedQuestions.push(getQuestionObj[0].option);
      this.props.history.push("/abortion-step-6");
    }

    if (this.state.optionText5 === true) {
      const getQuestionObj = this.state.questionOptions.filter(
        (obj) => obj.stateName === "optionText5"
      );
      selectedQuestions.push(getQuestionObj[0].option);
      this.props.history.push("/contraception");
    }
    window.localStorage.setItem("onboarding1", selectedQuestions);

    //this.props.history.push("/onboarding1");

    localStorage.setItem("lastOnboardingStep", "onboarding3"); //  this helps us track if the user has
  };

  backToHome = () => {
    this.setState({
      page: "home",
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div className="flexColumn flexGrow1">
        {this.renderChildren()} {/* Down below */}
      </div>
    );
  }

  renderChildren = () => {
    const { title, questionOptions, buttonText, linkText1 } = this.state;
    const questionsContent = questionOptions.map((info, i) => {
      return (
        <div
          className="flexRow textAlignLeft marginBottom16 questionsDiv"
          key={i}
        >
          <div>
            <input
              // name='agreed'
              type="radio"
              name={i}
              value={this.state[info.stateName]}
              key={i}
              checked={this.state[info.stateName]}
              className="flexColumn justifyCenter alignCenter marginRight10 backgroundColorNone radioCheckPeach mediumRadioBtn"
              onChange={() => this.toggleRadio(info.stateName)}
            />
          </div>
          <span className="regularFont fontSize13 lineHeight17 colorWhite">
            {info.option}
          </span>
        </div>
      );
    });

    return (
      <div className="minPageHeight100vh flexColumn flexGrow1 abortionAssessmentPage">
        <TopNavBar
          bgColor="#071f56"
          showHamburgerMenu={true}
          menuIconColor={"white"}
          logoColor={"peach"}
          bellColor={"white"}
          newNotificationColor={"peach"}
          {...this.props}
          appLanguage={this.props.appLanguage}
          handleLanguageChange={this.props.handleLanguageChange}
          key={this.props.appLanguage}
        />

        <div className="flexColumn flexGrow1 justifyCenter alignCenter textAlignCenter paddingTop80 landscapeWidth75Percent">
          <div className="paddingLeftRight85">
            <ContentfulMarkdown
              children={title}
              className="boldFont fontSize22 lineHeight25 colorPeach4 psMargin0 marginBottom26 contentfulMarkdown"
            />
          </div>

          <form className="flexColumn padding0-25-14-45">
            {questionsContent}
          </form>

          <div className="paddingLeftRight35 landscapeWidth75Percent">
            <Link to="/home">
              <ContentfulMarkdown
                children={linkText1}
                className="colorWhite fontsize10 underlineLink"
              ></ContentfulMarkdown>
            </Link>

            <LargeButton
              largeButtonFunc={() => this.continue()}
              type="submit"
              text={buttonText}
              buttonColor={"colorWhite"}
              backgroundColor={"backgroundColorPeach4"}
            />
            <div className="marginBottom12"></div>

            {/*  <WHOLink 
                        fontColor="#fff"
                        appLanguage={this.props.appLanguage}
                        {...this.props} />  */}
          </div>
        </div>
      </div>
    );
  };
}
