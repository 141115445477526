import React from 'react'
import ReactGA from 'react-ga4'
import * as ContentfulMarkdown from 'react-markdown'

import { TopNavBar } from '../../Components/TopNavBar'
import { LargeButton } from '../../Components/LargeButton'
import { HelpfulPopUp } from '../../Components/HelpfulPopUp'
import { BottomNavBar } from '../../Components/BottomNavBar'

/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */


export class PinSetUp extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            heading1: '',
            paragraph1: '',
            btn1Text: '',
            btn2Text: '',
            paragraph2: '',
            pinInputOne: '',
            pinInputTwo: '',
            pinInputThree: '',
            pinInputFour: '',
            pinError: false,
            pinErrorMessage: '',
            pinSuccess: false,

            successHeading: '',
            successParagraph: '',
            successBtn1Text: '',
        }

        this.pinInputOneRef = React.createRef();
        this.pinInputTwoRef = React.createRef();
        this.pinInputThreeRef = React.createRef();
        this.pinInputFourRef = React.createRef();
    }


    async componentDidMount() {
        ReactGA.send("/pin-setup")
        window.scrollTo(0, 0)

        document.title = "Pin Setup"

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

        const appLanguage = this.props.appLanguage


        let getHeading1 = ''
        let getParagraph1 = ''
        let getBtn1Text = ''
        let getBtn2Text = ''
        let getParagraph2 = ''

        let pinSuccessEntries = []
        let getSuccessHeading = ''
        let getSuccessParagraph = ''
        let getSuccessBtn1Text = ''


        await (function () {
            // Get page Entries
            const getEntries = localStorage.getItem('ayaAppEntries')
            const entries = JSON.parse(getEntries)

            const getPinSuccessEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'pinSuccess');

            const getPinSetupEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'pinSetupPage');


            if (getPinSetupEntries && getPinSetupEntries[0].fields) {
                let pinSetupEntries = getPinSetupEntries[0].fields

                // if (pinSetupEntries) {
                    if (pinSetupEntries.headline) {
                        getHeading1 = pinSetupEntries.headline[appLanguage]
                    }
                    if (pinSetupEntries.paragraph) {
                        getParagraph1 = pinSetupEntries.paragraph[appLanguage]
                    }

                    if (pinSetupEntries.buttonText1) {
                        getBtn1Text = pinSetupEntries.buttonText1[appLanguage]
                    }
                    if (pinSetupEntries.buttonText2) {
                        getBtn2Text = pinSetupEntries.buttonText2[appLanguage]
                    }
                    if (pinSetupEntries.text) {
                        getParagraph2 = pinSetupEntries.text[appLanguage]
                    }

                // }
            }


            if (getPinSuccessEntries && getPinSuccessEntries.length > 0) {
                pinSuccessEntries.push(getPinSuccessEntries[0].fields)

                if (pinSuccessEntries && pinSuccessEntries[0]) {

                    if (pinSuccessEntries[0].headline) {
                        getSuccessHeading = pinSuccessEntries[0].headline[appLanguage]
                    }

                    if (pinSuccessEntries[0].text) {
                        getSuccessParagraph = pinSuccessEntries[0].text[appLanguage]
                    }

                    if (pinSuccessEntries[0].buttonText) {
                        getSuccessBtn1Text = pinSuccessEntries[0].buttonText[appLanguage]
                    }
                }
            }
        })();

        this.setState({
            heading1: getHeading1,
            paragraph1: getParagraph1,
            btn1Text: getBtn1Text,
            btn2Text: getBtn2Text,
            paragraph2: getParagraph2,

            successHeading: getSuccessHeading,
            successParagraph: getSuccessParagraph,
            successBtn1Text: getSuccessBtn1Text,
        })
    }



    onPinNumChange = async (e) => {
        await this.setState({ [e.target.name]: e.target.value })

        if (this.state.pinInputOne || this.state.pinInputTwo || this.state.pinInputThree || this.state.pinInputFour) {
            this.pinInputOneRef.current.placeholder = ''
            this.pinInputTwoRef.current.placeholder = ''
            this.pinInputThreeRef.current.placeholder = ''
            this.pinInputFourRef.current.placeholder = ''
        } else {
            this.pinInputOneRef.current.placeholder = '1'
            this.pinInputTwoRef.current.placeholder = '2'
            this.pinInputThreeRef.current.placeholder = '3'
            this.pinInputFourRef.current.placeholder = '4'
        }

        if (this.state.pinError) {
            this.setState({ pinError: false })
        }
    }


    setPin = async () => {
        const pin1 = this.state.pinInputOne
        const pin2 = this.state.pinInputTwo
        const pin3 = this.state.pinInputThree
        const pin4 = this.state.pinInputFour

        const userPin = `${pin1}${pin2}${pin3}${pin4}`

        if (pin1 === pin2 && pin2 === pin3 && pin3 === pin4) {
            // Please enter a unique pin
            this.setState({
                pinError: true, pinErrorMessage: 'Por favor ingrese un pin único'
            })
        } else {
            if (userPin.length === 4) {

                try {
                    // Add user's pin to local storage
                    await localStorage.setItem('userPin', userPin)

                    if (localStorage.getItem('userPin')) {
                        sessionStorage.setItem('hasAccess', true);
                        this.setState({ pinSuccess: true })
                    }
                } catch (err) {
                    console.log('err', err)
                    // Error setting your pin. Please try again
                    this.setState({
                        pinError: true, pinErrorMessage: 'Error al establecer su PIN. Inténtalo de nuevo.'
                    })
                }
            } else {
                this.setState({ pinError: true, pinErrorMessage: 'Por favor ingrese un PIN de 4 dígitos' })
            }
        }
    }


    skipPin = () => {
        // ...?
        this.props.history.push('/home');
    }

    goBack = () => {
        this.props.history.goBack()
      }

    render() {
        const { pinSuccess } = this.state

        if (!pinSuccess) {
            return (
                <div className="minPageHeight100vh flexGrow1 alignCenter pageWithBottomMainNav">
                   
                   
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

                    <div className='width78Percent pin-set-up-margin-left landscapeWidth67Percent flexColumn flexGrow1 alignCenter paddingTop125 paddingBottom35 pagesInnerDiv'>

                        <ContentfulMarkdown children={this.state.heading1} className='boldFont textAlignLeft fontSize22 lineHeight28 colorWhite' allowDangerousHtml={true} />

                        <ContentfulMarkdown children={this.state.paragraph1} className='regularFont textAlignLeft fontSize14 lineHeight15 colorWhite' allowDangerousHtml={true} />

                        <div className='width100Percent maxWidth275 marginBottom30'>
                            <div className='width100Percent flexRow justifyBetween marginBottom5'>
                                <input
                                    ref={this.pinInputOneRef}
                                    type='number'
                                    name='pinInputOne'
                                    value={this.state.pinInputOne}
                                    onChange={(e) => this.onPinNumChange(e)}
                                    max='1'
                                    onInput={(e) => {
                                        if (e.target.value.length > 0) {
                                            e.target.value = e.target.value.slice(0, 1);
                                            this.pinInputTwoRef.current.focus();
                                        } else {
                                            this.pinInputOneRef.current.focus();
                                        }
                                    }}
                                    placeholder='1'
                                    className='pinInputs'
                                />

                                <input
                                    ref={this.pinInputTwoRef}
                                    type='number'
                                    name='pinInputTwo'
                                    value={this.state.pinInputTwo}
                                    onChange={(e) => this.onPinNumChange(e)}
                                    max='1'
                                    onInput={(e) => {
                                        if (e.target.value.length > 0) {
                                            e.target.value = e.target.value.slice(0, 1);
                                            this.pinInputThreeRef.current.focus();
                                        } else {
                                            this.pinInputTwoRef.current.focus();
                                        }
                                    }}
                                    placeholder='2'
                                    className='pinInputs'
                                />

                                <input
                                    ref={this.pinInputThreeRef}
                                    type='number'
                                    name='pinInputThree'
                                    value={this.state.pinInputThree}
                                    onChange={(e) => this.onPinNumChange(e)}
                                    max='1'
                                    onInput={(e) => {
                                        if (e.target.value.length > 0) {
                                            e.target.value = e.target.value.slice(0, 1);
                                            this.pinInputFourRef.current.focus();
                                        } else {
                                            this.pinInputThreeRef.current.focus();
                                        }
                                    }}
                                    placeholder='3'
                                    className='pinInputs'
                                />

                                <input
                                    ref={this.pinInputFourRef}
                                    type='number'
                                    name='pinInputFour'
                                    value={this.state.pinInputFour}
                                    onChange={(e) => this.onPinNumChange(e)}
                                    max='1'
                                    onInput={(e) => {
                                        if (e.target.value.length > 0) {
                                            e.target.value = e.target.value.slice(0, 1);
                                        } else {
                                            this.pinInputFourRef.current.focus();
                                        }
                                    }}
                                    placeholder='4'
                                    className='pinInputs'
                                />
                            </div>

                            {this.state.pinError &&
                                <p className='boldFont fontSize15 lineHeight19 colorPeach4 marginBottom0 textAlignCenter'>{this.state.pinErrorMessage}</p>
                            }
                        </div>


                        <LargeButton
                            largeButtonFunc={this.setPin}
                            text={this.state.btn1Text}
                            textColor={'colorBlue8'}
                            backgroundColor={'backgroundColorPeach3'}
                            marginBottom={'20px'}
                        />

                        <LargeButton
                            largeButtonFunc={this.skipPin}
                            text={this.state.btn2Text}
                            textColor={'colorBlue8'}
                            backgroundColor={'backgroundColorWhite'}
                            marginBottom={'20px'}
                        />

                        <ContentfulMarkdown children={this.state.paragraph2} className='regularFont textAlignLeft fontSize14 lineHeight15 colorPeach3' allowDangerousHtml={true} />
                    </div> {/* pagesInnerDiv */}

                    <BottomNavBar appLanguage={this.props.appLanguage} />
                </div>
            )
        } else if (pinSuccess) {
            return (
                <HelpfulPopUp
                    pageName={'pinSetSuccessPopUp'}
                    pageBackgroundColor={"backgroundColorBlue8"}
                    logoColor={"peach"}
                    bellColor={"white"}
                    newNotificationColor={"peach"}
                    appLanguage={this.props.appLanguage}
                    noThinkingIcon
                    helpfulDivPaddingLeftRight={"paddingLeftRight35"}
                    helpfulDivBackgroundColor={"backgroundColorPeach4"}
                    helpfulDivTextColor={"colorWhite"}
                    content={{
                        heading1: this.state.successHeading,
                        text1: this.state.successParagraph,
                        btn1Text: this.state.successBtn1Text,
                    }}
                    btn1TextColor={"colorBlue8"}
                    btn1BackgroundColor={"backgroundColorWhite"}
                    btn1Function={() => this.props.history.push('/home')}
                    backToFunc={() => this.props.history.goBack()}
                />
            )
        }
    }
}
