import React from 'react';
import { Link } from 'react-router-dom'

export class ContraceptionAssessmentOptionsMenuCardContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }



    render() {

        const { content } = this.props

        return (
            <Link to={content.goToPageLink} className='flexRow padding40-38 justifyCenter topRoundDivInnerDiv contraceptionAssessmentOptionsMenuCardContent'>
                <p className={'boldFont fontSize20 lineHeight22 colorWhite textAlignCenter marginBottom0'}>{content.title}</p>
            </Link>
        )
    }
}
