import React from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import hamburgerMenuIconWhite from "../Images/hamburger-menu-icon-stripes-white.svg";
import infoIcon from "../Images/info-icon-circle-white.svg";
import messageIcon from "../Images/message-icon-white.svg";
/* import bellIcon from '../Images/notification-bell-white.svg' */
import lockIcon from "../Images/lockIcon-white.svg";
import idiomaIcon from "../Images/Idioma.svg";

import technicalSupportIcon from "../Images/technical-support-icon.svg";

export class HamburgerMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      menuItem1Name: "",
      menuItemPrivacyName: "",
      menuItem2Name: "",
      languageOptionLabel: "Idioma", // Initialize the languageOptionLabel

      // menuIdiomaName: { 'en': 'Language', 'es-VE': 'Idioma' }, // Default translations for "Language"
      showLanguageOptions: false, // Initially hide language options
      selectedLanguage: "es-VE",
      /* menuItem3Name: '', */
    };
    this.fetchMenuContent = this.fetchMenuContent.bind(this);
  }

  handleMenuItemClick = () => {
    // Close the hamburger menu when a menu item is clicked
    this.props.closeHamburgerMenu();
  };

  handleLanguageIconClick = () => {
    // Toggle the visibility of language options
    this.setState((prevState) => ({
      showLanguageOptions: !prevState.showLanguageOptions,
    }));
  };


  handleLanguageChange = (newLanguage) => {
    // Call the handleLanguageChange prop to update the language across the app
    this.props.handleLanguageChange(newLanguage);
  
    // Toggle the hamburger menu reload
    this.props.toggleHamburgerMenuReload();
  
    // Update state for language option label
    const languageOptionLabel =
      newLanguage === "es-VE" ? "Idioma" : "Language";
    this.setState({ selectedLanguage: newLanguage, languageOptionLabel });
  };
  
  

  fetchMenuContent = (language) => {
    this.setState({ loading: true });

    setTimeout(() => {
      // Set loading to false after fetching
      this.setState({ loading: false });
    }, 1000);

    // Get stored entries from localStorage
    const getEntries = localStorage.getItem("ayaAppEntries");
    const entries = JSON.parse(getEntries);

    // Filter for hamburger menu entries
    const getMenuEntries = entries.filter(
      (obj) => obj.sys.contentType.sys.id === "hamburgerMenuLinks"
    );

    // Assuming each menu item is a separate entry in Contentful
    const menuEntries = getMenuEntries.map((entry) => entry.fields);

    // Find and set the state for each menu item based on the current language
    const menuItems = menuEntries.map((entry) => ({
      id: entry.id,
      name: entry[language],
      link: entry.link,
    }));

    this.setState({
      menuItems,
      loading: false,
    });
  };

  componentDidUpdate(prevProps) {
    // Check if the appLanguage prop has changed
    if (prevProps.appLanguage !== this.props.appLanguage) {
      // Update the selectedLanguage state with the new language
      this.setState({ selectedLanguage: this.props.appLanguage }, () => {
        // Fetch menu content based on the new selected language
        this.fetchMenuContent(this.props.appLanguage);
      });
    }
  }

  componentDidMount() {
    // Get menu links
    const getEntries = localStorage.getItem("ayaAppEntries");
    const entries = JSON.parse(getEntries);
  
    const getMenuEntries = entries.filter(
      (obj) => obj.sys.contentType.sys.id === "hamburgerMenuLinks"
    );
  
    this.fetchMenuContent(this.props.appLanguage);
      let menuIdiomaName = "";

  
    if (getMenuEntries && getMenuEntries.length > 0) {
      const menuLinks = getMenuEntries[0].fields;
      const { appLanguage } = this.props;
  
      let menuItem1Name = "";
      let menuItemPrivacyName = "";
      let menuItem2Name = "";
  
      if (menuLinks.menuItem1) {
        menuItem1Name = menuLinks.menuItem1[appLanguage];
      }
      if (menuLinks.menuItem2) {
        menuItem2Name = menuLinks.menuItem2[appLanguage];
      }
      if (menuLinks.menuItem4) {
        menuItemPrivacyName = menuLinks.menuItem4[appLanguage];
      }
      if (appLanguage === "es-VE") {
        menuIdiomaName = "Idioma";
      } else {
        menuIdiomaName = "Language";
      }
      this.setState({
        menuItem1Name,
        menuItemPrivacyName,
        menuItem2Name,
        menuIdiomaName
      });
    }
  }
  
  
  

  render() {
    const { hamburgerMenuIsOpen, closeHamburgerMenu } = this.props;
    const {
      menuItem1Name,
      menuItemPrivacyName,
      menuItem2Name,
      selectedLanguage,
      menuTranslations,
    } = this.state;
    // const [isOpen, setOpen] = useState(false)
    const { showLanguageOptions } = this.state;
    const languageOptionLabel = this.props.appLanguage === "es-VE" ? "Idioma" : "Language";

    var boxShadowClass = "";
    var opacityClass = "";

    if (hamburgerMenuIsOpen) {
      boxShadowClass = "blue8BoxShadow";
      opacityClass = "hamburgerMenuOpacity1";
      // <Hamburger toggled={isOpen} toggle={setOpen} />
    } else {
      boxShadowClass = "";
      opacityClass = "hamburgerMenuOpacity0";
    }

    return (
      <div className="hamburgerMenuContainer">
        <Menu
          isOpen={hamburgerMenuIsOpen}
          width={"75%"}
          menuClassName={
            "" +
            opacityClass +
            " " +
            boxShadowClass +
            " backgroundColorBlue8 colorWhite hamburgerMenu"
          }
          onClose={closeHamburgerMenu}
        >
          <div className="paddingTop23 paddingLeftRight20 paddingBottom45">
            <button
              className="flexRow justifyStart width40"
              onClick={closeHamburgerMenu}
            >
              <img
                src={hamburgerMenuIconWhite}
                className="hamburgerMenuIcon"
                alt="Menu"
              />
            </button>
          </div>

          <Link
            className="boldFont fontSize18 lineHeight26 colorWhite hamburgerMenuItem"
            to="/about-vitala"
          >
            <div className="flexRow alignCenter paddingTopBottom20 paddingLeft20 paddingRight10 borderTopWidth1 borderLeftRight0 borderColorWhite">
              <div className="flexRow justifyCenter alignCenter width23 height23 marginRight15">
                <img
                  src={infoIcon}
                  className="widthAuto height100Percent"
                  alt=""
                />
              </div>
              <span>{menuItem1Name}</span>
            </div>
          </Link>

          <Link
            className="boldFont fontSize18 lineHeight26 colorWhite hamburgerMenuItem"
            to="/privacy-principles"
          >
            <div className="flexRow alignCenter paddingTopBottom20 paddingLeft20 paddingRight10 borderTopWidth1 borderLeftRight0 borderColorWhite">
              <div className="flexRow justifyCenter alignCenter width23 marginRight15">
                <img src={lockIcon} className="width100HeightAuto" alt="" />
              </div>
              <span>{menuItemPrivacyName}</span>
            </div>
          </Link>

          <Link
            className="boldFont fontSize18 lineHeight26 colorWhite hamburgerMenuItem"
            to="/feedback"
          >
            <div className="flexRow alignCenter paddingTopBottom20 paddingLeft20 paddingRight10 borderTopWidth1 borderLeftRight0 borderColorWhite">
              <div className="flexRow justifyCenter alignCenter width23 height23 marginRight15">
                <img
                  src={technicalSupportIcon}
                  className="widthAuto height100Percent"
                  alt=""
                />
              </div>
              <span>{menuItem2Name}</span>
            </div>
          </Link>

          {/*          <Link className='boldFont fontSize18 lineHeight26 colorWhite hamburgerMenuItem' to='/notifications'>
            <div className='flexRow alignCenter paddingTopBottom20 paddingLeft20 paddingRight10 borderTopWidth1 borderBottomWidth1 borderLeftRight0 borderColorWhite'>
              <div className='flexRow justifyCenter alignCenter width23 height23 marginRight15'>
                <img src={bellIcon} className='widthAuto height100Percent' alt='' />
              </div>
              <span>{menuItem3Name}</span>
            </div>
          </Link>
 */}
          <Link
            className="boldFont fontSize18 lineHeight26 colorWhite hamburgerMenuItem"
            to="/language"
          >
            <div className="flexRow alignCenter paddingTopBottom20 paddingLeft20 paddingRight10 borderTopWidth1 borderLeftRight0 borderColorWhite">
              <div className="flexRow justifyCenter alignCenter width23 height23 marginRight15">
                <img
                  src={idiomaIcon}
                  className="widthAuto height100Percent"
                  alt=""
                />
              </div>
              <span>
              <span>{languageOptionLabel}</span>
              </span>{" "}
            </div>
          </Link>
        </Menu>
      </div>
    );
  }
}
