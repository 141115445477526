import React from 'react'
/* import { Link } from 'react-router-dom' */
import { TopNavBar } from '../../Components/TopNavBar'
/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */
import { LargeButton } from '../../Components/LargeButton'
import * as ContentfulMarkdown from 'react-markdown'
import { BottomNavBar } from '../../Components/BottomNavBar'
import ReactGA from 'react-ga4';
import { WHOLink } from '../../Components/WHOLink';


export class AbortionStep1 extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            headerImage: '',
            stepNum: '',
            title: '',
            image2: '',
            image2Alt: '',
            subHeading: '',
            text1: '',
            text2: '',
            btn1Text: '',
            btn2Text: '',
        }
    }


    componentDidMount() {
        window.scrollTo(0, 0)
        ReactGA.send("/abortion-step-1")

        document.title = "Abortion Step 1"
                        
        // this hides the Crisp Chat Window from the page
        /* $crisp.push(["do", "chat:hide"]); */


        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)
        // Content type ID
        const getAbortionStepsEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'step1');
        // Then Entry ID instead
        const getStep1Entries = getAbortionStepsEntries.filter(obj => obj.sys.id === 'TrbWeikEM0WL3j9gLRLGo');



        var stepEntries = []
        var stepNumber = ''
        var stepTitle = ''
        var subheader1 = ''
        var stepText1 = ''
        var stepText2 = ''
        var ctaCopy1 = ''
        var ctaCopy2 = ''


        if (getStep1Entries && getStep1Entries.length > 0) {
            stepEntries.push(getStep1Entries[0].fields)

            if (stepEntries.length > 0) {
                if (stepEntries[0].stepNumber) {
                    stepNumber = stepEntries[0].stepNumber[this.props.appLanguage]
                }
                if (stepEntries[0].title) {
                    stepTitle = stepEntries[0].title[this.props.appLanguage]
                }
                if (stepEntries[0].subheader1) {
                    subheader1 = stepEntries[0].subheader1[this.props.appLanguage]
                }
                if (stepEntries[0].text1) {
                    stepText1 = stepEntries[0].text1[this.props.appLanguage]
                }
                if (stepEntries[0].text2) {
                    stepText2 = stepEntries[0].text2[this.props.appLanguage]
                }
                if (stepEntries[0].ctaCopy1) {
                    ctaCopy1 = stepEntries[0].ctaCopy1[this.props.appLanguage]
                }
                if (stepEntries[0].ctaCopy2) {
                    ctaCopy2 = stepEntries[0].ctaCopy2[this.props.appLanguage]
                }
            } // stepEntries.length
        } // getAbortionStepsEntries.length

        this.setState({
            stepNum: stepNumber,
            title: stepTitle,
            subHeading: subheader1,
            text1: stepText1,
            text2: stepText2,
            btn1Text: ctaCopy1,
            btn2Text: ctaCopy2,
        })


        // Get page Assets
        const getAssets = localStorage.getItem('ayaAppAssets')
        const assets = JSON.parse(getAssets)

        const getAbortionStep1HeaderImg = assets.filter(obj => obj.fields.title[this.props.appLanguage] === 'Abortion Step 1 Illustration');

        const getImage2 = assets.filter(obj => obj.fields.title[this.props.appLanguage] === 'Step 1 Hand with Pills Illustration');

        var step1MainImage = ''
        var pageImage2 = ''
        var pageImage2Alt = ''

        if (getAbortionStep1HeaderImg && getAbortionStep1HeaderImg.length > 0 && getAbortionStep1HeaderImg[0].fields.file[this.props.appLanguage].url) {
            console.log(getAbortionStep1HeaderImg[0].fields)
            step1MainImage = 'https:' + getAbortionStep1HeaderImg[0].fields.file[this.props.appLanguage].url
        }

        if (getImage2 && getImage2.length > 0 && getImage2[0].fields.file[this.props.appLanguage].url) {
            pageImage2 = 'https:' + getImage2[0].fields.file[this.props.appLanguage].url
            pageImage2Alt = getImage2[0].fields.description[this.props.appLanguage]
        }

        this.setState({
            headerImage: step1MainImage,
            image2: pageImage2,
            image2Alt: pageImage2Alt,
        })

    } // componentDidMount()




    goToAbortionAssessment = () => {
        this.props.history.push('/abortion-assessment-q1a');
    }


    goToSelfCare = () => {
        this.props.history.push('/self-care-advice');
    }

    // Step 1 is not completed on this page but is completed on 'Abortion Assessment Approved' page (Just here for reference)

    goBack = () => {
        this.props.history.goBack()
      }

    render() {

        const { headerImage, stepNum, title, image2, image2Alt, subHeading, text1, text2, btn1Text, btn2Text } = this.state

        return (
            <div className="minPageHeight100vh flexColumn flexGrow1 pageWithBottomMainNav backgroundColorBlue9 abortionStepPages">
               
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

                <div className='pagesInnerDiv paddingTop70'>
                    <div className='backgroundImgWAutoH100 backgroundImgXRightYTop dontRepeatImage backgroundColorBlue9 headerArea' style={{ backgroundImage: `url(${headerImage})` }}>
                        
                    </div> {/* .headerArea */}

                    <div className='flexGrow1 backgroundColorWhite topRoundDiv'>
                        <div className='flexColumn alignCenter textAlignCenter padding37-30-34 topRoundDivInnerDiv'>
                            <div className='backgroundColorPeach4 alignSelfFlexStart marginBottom26 smallTagDiv'>
                                <span className='regularFont fontSize11 colorWhite'>{stepNum}</span>
                            </div>

                            <p className='boldFont fontSize24 lineHeight26 colorBlue8 alignSelfFlexStart textAlignLeft marginBottom21'>{title}</p>

                            <img src={image2} className='width36Percent marginBottom21 marginLeftRightAuto' alt={image2Alt} />

                            <p className='width76Percent boldFont fontSize18 lineHeight20 colorPeach3 marginBottom21  marginLeftRightAuto'>{subHeading}</p>

                            <ContentfulMarkdown children={text1} className='regularFont fontSize15 lineHeight15 colorBlue8 borderTopWidth1 borderLeftRight0 borderBottomWidth1 borderColorBlue6 padding10-0 marginBottom21 contentfulMarkdown' allowDangerousHtml={true} />

                            <p className='regularFont fontSize15 lineHeight18 colorBlue8 marginBottom21'>{text2}</p>

                            <LargeButton
                                largeButtonFunc={() => this.goToAbortionAssessment()}
                                text={btn1Text}
                                textColor={'colorWhite'}
                                backgroundColor={'backgroundColorBlue6'}
                            />

                            <div className='marginBottom15'></div>

                            <LargeButton
                                largeButtonFunc={() => this.goToSelfCare()}
                                text={btn2Text}
                                textColor={'colorBlue8'}
                                backgroundColor={'backgroundColorPink'}
                            />

                            <WHOLink 
                                fontColor="#071f56"
                                appLanguage={this.props.appLanguage}
                                {...this.props} 
                            /> {/* This is the WHO link that is necessary for iOS app store */}

                        </div> {/* .topRoundDivInnerDiv */}
                    </div> {/* .topRoundDiv */}

                </div> {/* .pagesInnerDiv */}

                <BottomNavBar appLanguage={this.props.appLanguage} />
            </div>
        )
    }
}
