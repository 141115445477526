import React from 'react'
import { TopNavBar } from '../../Components/TopNavBar'

/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */
import { LargeButton } from '../../Components/LargeButton'
import * as ContentfulMarkdown from 'react-markdown'
import { BottomNavBar } from '../../Components/BottomNavBar'
/* import messageIcon from '../../Images/message-icon-white.svg' */

import { ContraceptionLink } from '../../Components/ContraceptionLink'



export class SingleContraceptionOptionShell extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
        }
    }


    goBack = () => {
        this.props.history.goBack()
    }

    goToContraceptionMenu = () => {
        this.props.history.push('/contraception')
    }

    goToContraceptionAssessmentOptions = () => {
        this.props.history.push('/contraception-assessment-options')
    }

    goToFeedback = () => {
        const { appLanguage } = this.props;
    
        let feedbackURL =
            appLanguage === 'en'
                ? 'https://www.plannedparenthood.org/get-care/our-services/birth-control'
                : appLanguage === 'es-US'
                ? 'https://www.plannedparenthood.org/es/obten-atencion/nuestros-servicios/metodos-anticonceptivos'
                : ''; 
        window.open(feedbackURL, '_blank');
    }
    


    render() {

        const { appLanguage, content } = this.props

        return (
            <div className="minPageHeight100vh flexColumn flexGrow1 pageWithBottomMainNav backgroundColorWhite abortionStepPages">
                
                <TopNavBar 
                bgColor="#fff" 
                showHamburgerMenu={true} 
                menuIconColor={'blue'} 
                logoColor={'peach'} 
                bellColor={'blue'} 
                newNotificationColor={'peach'} 
                {...this.props} 
                appLanguage={this.props.appLanguage} />

                <div className='flexColumn flexGrow1 paddingTop125 pagesInnerDiv'>
                    <div className='flexColumn alignCenter textAlignCenter padding0-20-30'>
                        {/* <button onClick={() => this.goBack()} className='width30Height30 alignSelfFlexStart'>
                            <img src={prevBtnImg} className='width100Percent prevImg' alt='back' />
                        </button> */}

                        {content.image1 ?
                            <img src={content.image1} className={content.image1Width} alt={content.image1Alt} />
                            : null}

                        <div className='marginBottom20'></div>

                        <ContentfulMarkdown children={content.title} className='boldFont fontSize23 lineHeight24 colorPeach4 psMargin0 contentfulMarkdown' allowDangerousHtml={true} />

                        {content.optionsAndTime1 ?
                            <div className='marginBottom16'></div>
                            : null}

                        <div className='flexRow width100Percent justifyCenter'>
                            {content.optionsAndTime1 ?
                                <div className='flexRow flexGrow1 justifyCenter'>
                                    <ContentfulMarkdown children={content.optionsAndTime1} className='boldFont fontSize15 lineHeight18 colorBlue8 psMargin0 contentfulMarkdown' allowDangerousHtml={true} />
                                </div>
                                : null}

                            {content.optionsAndTime2 ?
                                <div className='flexRow flexGrow1 justifyCenter'>
                                    <ContentfulMarkdown children={content.optionsAndTime2} className='boldFont fontSize15 lineHeight18 colorBlue8 psMargin0 contentfulMarkdown' allowDangerousHtml={true} />
                                </div>
                                : null}
                        </div>
                    </div>

                    <div className='flexColumn flexGrow1 alignCenter padding0-36-70'>
                        {content.paragraph ?
                            <ContentfulMarkdown children={content.paragraph} className='regularFont fontSize15 lineHeight16 colorBlack0 psMargin0 linkHasUnderline contentfulMarkdown' allowDangerousHtml={true} />
                            : null}

                        <div className='marginBottom26'></div>

                        {content.image2 ?
                            <img src={content.image2} className={content.image2Width} alt={content.image2Alt} />
                            : null}

                        {content.image3 ?
                            <div className='textAlignCenter'>
                                <div className='marginTop30'></div>
                                <img src={content.image3} className={content.image3Width} alt={content.image3Alt} />
                            </div>
                            : null}


                        <div className='marginBottom40'></div>

                        {content.btn1Text ?
                            <LargeButton
                                largeButtonFunc={() => this.goToContraceptionAssessmentOptions()}
                                text={content.btn1Text}
                                textColor={'colorBlue8'}
                                backgroundColor={'backgroundColorPink'}
                            />
                            : null}

                        <div className='marginBottom20'></div>

                        {content.btn2Text ?
                            <LargeButton
                                largeButtonFunc={() => this.goToContraceptionMenu()}
                                text={content.btn2Text}
                                textColor={'colorWhite'}
                                backgroundColor={'backgroundColorBlue6'}
                            />
                            : null}

                        {content.btn3Text ?
                            <>
                                <div className='marginBottom20'></div>

                                <LargeButton
                                    largeButtonFunc={() => this.goToFeedback()}
                                    text={<p className='width100Percent flexRow justifyCenter alignCenter marginBottom0'>
                                        {/* <img src={messageIcon} style={{ width: '18px', height: 'auto', marginRight: '10px' }} alt='' /> */}
                                        {content.btn3Text}
                                    </p>}
                                    textColor={'colorWhite'}
                                    backgroundColor={'backgroundColorBlue8'}
                                />
                            </>
                            : null}

                    <ContraceptionLink 
                        fontColor="#071f56"
                        appLanguage={this.props.appLanguage}
                        {...this.props} /> {/* This is the WHO link that is necessary for iOS app store */}
                    </div>
                </div> {/* .pagesInnerDiv */}

                <BottomNavBar appLanguage={appLanguage} />
            </div>
        )
    }
}
