import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'


export class AbortionMenuCardContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resetAllData: false,
            checked: false,
        }
    }

    componentDidMount() {
        const { content } = this.props

                        
        // this hides the Crisp Chat Window from the page
        /* $crisp.push(["do", "chat:hide"]); */


        const getStepCompleteValue = localStorage.getItem(content.stepCompletedStorageName)

        if (getStepCompleteValue === 'true') {
            this.setState({
                checked: true
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.resetAllData !== prevProps.resetAllData) {
            if (this.props.resetAllData === true) {
                this.setState({ checked: false })
                console.log('newStateForCards changed')
            }
        }
    }

    // componentDidUpdate() {
    //     const { content } = this.props

    //     const getStepCompleteValue = localStorage.getItem(content.stepCompletedStorageName)

    //     if (getStepCompleteValue !== null) {
    //         this.setState({
    //             checked: true
    //         })
    //     } else {
    //         this.setState({
    //             checked: false
    //         })
    //     }
    // }

    render() {

        const { content } = this.props
        const { checked } = this.state

        return (
            <Link to={content.goToPageLink} className='flexRow padding24-30-36-25 topRoundDivInnerDiv abortionMenuCardContent'>
                <div className='flexColumn justifyEnd marginRight17 leftDiv'>
                    <input type="radio" checked={checked} className='flexColumn justifyCenter alignCenter backgroundColorWhite radioCheckPeach largeRadioBtn' readOnly></input>
                </div>
                <div>
                    <p className={'regularFont fontSize10 lineHeight26 colorWhite marginBottom7'}>{content.stepNumber}</p>
                    <p className={'boldFont fontSize20 lineHeight22 colorWhite marginBottom0'}>{content.stepTitle}</p>
                </div>
            </Link>
        )
    }
}

AbortionMenuCardContent.propTypes = {
    content: PropTypes.object
}
