import React from 'react'
import ReactGA from 'react-ga4'
import { TopNavBar } from '../../Components/TopNavBar'
import * as ContentfulMarkdown from 'react-markdown'
import { BottomNavBar } from '../../Components/BottomNavBar'
/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */
/* import { WHOLink } from '../../Components/WHOLink'; */
import Vitalalogoblue from '../../Images/Vitalalogoblue.svg';

export class AboutVitala extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      title: '',
      headingText: '',
      paragraph: '',
    }
  }

  componentDidMount() {
    ReactGA.send("/about-vitala")
    window.scrollTo(0, 0)

    document.title = "About Vitala"

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

    const getEntries = localStorage.getItem('ayaAppEntries')
    const entries = JSON.parse(getEntries)

    const getAboutEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'aboutVitala');

    var aboutEntries = []
    var aboutTitle = ''
    var aboutHeadingText = ''
    var aboutParagraph = ''

    if (getAboutEntries && getAboutEntries.length > 0) {
      aboutEntries.push(getAboutEntries[0].fields)

      if (aboutEntries.length > 0) {
        if (aboutEntries[0].title) {
          aboutTitle = aboutEntries[0].title[this.props.appLanguage]
        }
        if (aboutEntries[0].headingText) {
          aboutHeadingText = aboutEntries[0].headingText[this.props.appLanguage]
        }
        if (aboutEntries[0].paragraph) {
          aboutParagraph = aboutEntries[0].paragraph[this.props.appLanguage]
        }

        this.setState({
          title: aboutTitle,
          headingText: aboutHeadingText,
          paragraph: aboutParagraph,
        })
      }
    }
  } // componentDidMount()

  goBack = () => {
    this.props.history.goBack()
  }

  render() {
    return (
      <div className="minPageHeight100vh flexColumn flexGrow1 pageWithBottomMainNav aboutVitalaPage">
        
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

        <div className='pagesInnerDiv paddingTop40'>
          <div className='paddingTop90 justText headerArea'>
            <div className='padding0-30-57'>
              <p className='boldFont fontSize20 lineHeight26 colorWhite'>{this.state.title}</p>
            </div>
          </div>

          <div className='flexGrow1 backgroundColorWhite topRoundDiv'>
          <img src={Vitalalogoblue} alt="Vitala Logo" style={{width:'80%', paddingTop: '30px', paddingLeft: '70px'}}></img>
            <div className='padding56-21-30-34 topRoundDivInnerDiv'>
              <ContentfulMarkdown children={this.state.headingText} className='boldFont fontSize18 lineHeight22 colorPeach4 hasColorBlue8Span' allowDangerousHtml={true} />

             

              <a className='flexRow justifyCenter padding40-0' href='https://www.vitalaglobal.org' target='_blank' rel='noopener noreferrer'>
                <button className='backgroundColorBlue8 textAlignCenter largeButton'>
                  <span className='boldFont fontSize15 lineHeight26 colorWhite'>www.vitalaglobal.org</span>
                </button>
              </a>

              <ContentfulMarkdown children={this.state.paragraph} className='mediumFont fontSize15 lineHeight16 colorBlack3 linkHasUnderline' allowDangerousHtml={true} />
            
{/*               <WHOLink 
                        fontColor="#071f56"
                        appLanguage={this.props.appLanguage}
                        {...this.props} />  */}
            
            </div>
          </div>

        </div> {/* pagesInnerDiv */}

        <BottomNavBar appLanguage={this.props.appLanguage} />
      </div>
    )
  }
}
