import React from 'react';
import ReactGA from 'react-ga4';
import { SingleContraceptionOptionShell } from './SingleContraceptionOptionShell';

class ContraceptionRing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: {}
        };
    }

    componentDidMount() {
        ReactGA.send("/contraception-ring");
        window.scrollTo(0, 0);
        document.title = "Contraception Ring";

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

        const getEntries = localStorage.getItem('ayaAppEntries');
        const entries = JSON.parse(getEntries);

        const getContentEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'contraceptionOption');
        const getPageEntries = getContentEntries.filter(obj => obj.sys.id === '4xCNoCK6ZcUbJOMEdlj9P4');

        let pageEntries = [];
        let contentObj = {};

        if (getPageEntries && getPageEntries.length > 0) {
            pageEntries.push(getPageEntries[0].fields);

            if (pageEntries.length > 0) {
                const pageEntry = pageEntries[0];
                const appLanguage = this.props.appLanguage;

                contentObj = {
                    title: pageEntry.title && pageEntry.title[appLanguage],
                    optionsAndTime1: pageEntry.optionsAndTime && pageEntry.optionsAndTime[appLanguage],
                    optionsAndTime2: pageEntry.optionsAndTime2 && pageEntry.optionsAndTime2[appLanguage],
                    paragraph: pageEntry.paragraph && pageEntry.paragraph[appLanguage],
                    btn1Text: pageEntry.ctaCopy1 && pageEntry.ctaCopy1[appLanguage],
                    btn2Text: pageEntry.ctaCopy2 && pageEntry.ctaCopy2[appLanguage],
                    btn3Text: pageEntry.ctaCopy3 && pageEntry.ctaCopy3[appLanguage]
                };
            }
        }

        const getAssets = localStorage.getItem('ayaAppAssets');
        const assets = JSON.parse(getAssets);

        const getImg1 = assets.filter(obj => obj.sys.id === '4hwtIZCxeADC8rzQunnvzH');
        const getImg2 = assets.filter(obj => obj.sys.id === '3rh7riuBdJhGDgemkZvrwv');

        if (getImg1 && getImg1.length > 0 && getImg1[0].fields.file[this.props.appLanguage].url) {
            contentObj.image1 = 'https:' + getImg1[0].fields.file[this.props.appLanguage].url;
            contentObj.image1Alt = getImg1[0].fields.description[this.props.appLanguage];
        }

        contentObj.image1Width = 'width78Percent';

        if (getImg2 && getImg2.length > 0 && getImg2[0].fields.file[this.props.appLanguage].url) {
            contentObj.image2 = 'https:' + getImg2[0].fields.file[this.props.appLanguage].url;
            contentObj.image2Alt = getImg2[0].fields.description[this.props.appLanguage];
        }

        contentObj.image2Width = 'width76Percent';

        this.setState({
            content: contentObj
        });
    }

    render() {
        const { content } = this.state;
        return (
            <div className='flexColumn flexGrow1 linkHasUnderline'>
                <SingleContraceptionOptionShell
                    appLanguage={this.props.appLanguage}
                    history={this.props.history}
                    content={content}
                />
            </div>
        );
    }
}

export default ContraceptionRing;
