import React from 'react'
import ReactGA from 'react-ga4'
import * as ContentfulMarkdown from 'react-markdown'
import prevButton from '../../Images/prevBtnImg-white.svg'

/* import { PrevButton } from '../../Components/PrevButton' */

/* import prevBtnImg from '../../Images/prevBtnImage-blue.svg'; */


export class PrivacyPolicyPage extends React.Component {
    constructor(props) {
        super(props)
        this.goBack = this.goBack.bind(this);
        this.state = {
            image1: '',
            title: '',
            heading1: '',
            paragraph1: '',
        }
    }


    componentDidMount() {
        ReactGA.send("/privacy-policy-page")
        window.scrollTo(0, 0)

        document.title = "Privacy Policy Page"

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */


        // Get page Entries
        const getEntries = localStorage.getItem('ayaAppEntries')
        const entries = JSON.parse(getEntries)

        const getPrivacyEntries = entries.filter(obj => obj.sys.contentType.sys.id === 'politicaDePrivacidad');
        const getPageEntries = getPrivacyEntries.filter(obj => obj.sys.id === '49NUA1oCE9JLNMOm6p9CEI');


        // Get page Assets
        const getAssets = localStorage.getItem('ayaAppAssets')
        const assets = JSON.parse(getAssets)

        const getMainImage = assets.filter(obj => obj.fields.title[this.props.appLanguage] === 'CYBERSECURITY-02 (1)');

        let mainImage = ''

        if (getMainImage && getMainImage.length > 0 && getMainImage[0].fields.file[this.props.appLanguage].url) {
            mainImage = 'https:' + getMainImage[0].fields.file[this.props.appLanguage].url
        }



        let privacyEntries = []
        let getTitle = ''
        let getHeading1 = ''
        let getParagraph1 = ''

        if (getPageEntries && getPageEntries.length > 0) {
            privacyEntries.push(getPageEntries[0].fields)

            if (privacyEntries.length > 0) {

                if (privacyEntries[0].title) {
                    getTitle = privacyEntries[0].title[this.props.appLanguage]
                }
                if (privacyEntries[0].headingText) {
                    getHeading1 = privacyEntries[0].headingText[this.props.appLanguage]
                }
                if (privacyEntries[0].paragraph) {
                    getParagraph1 = privacyEntries[0].paragraph[this.props.appLanguage]
                }

                this.setState({
                    image1: mainImage,
                    title: getTitle,
                    heading1: getHeading1,
                    paragraph1: getParagraph1,
                })
            }
        }
    }

    goBack = () => {
        this.props.history.goBack();
      };

    

    render() {
        return (
            <div className="minPageHeight100vh flexColumn flexGrow1 privacyPolicyPage">
                <div className='paddingTop17'></div>
        {/* <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} /> */}
        <div className="">
        <button onClick={() => this.goBack()}>
                <img
                  className="generalCalendarPrevButton paddingLeft20 containerDivs spaceTop"
                  src={prevButton}
                  alt=""
                />
              </button>
              </div>
                <div className='pagesInnerDiv paddingTop10'>
                    <div className='paddingTop80 justText headerArea'>
                        <div className='padding0-30-57 textAlignCenter'>
                            <img src={ this.state.image1 } style={{width: '200px', height: 'auto'}} alt='' />
                        </div>
                    </div>

                    <div className='flexGrow1 backgroundColorWhite bottomRoundDiv'>
                        <div className='topRoundDivInnerDiv'>
                            <div className='padding56-21-30-34'>
                                <ContentfulMarkdown children={this.state.title} className='boldFont fontSize20 lineHeight26 colorBlue8' allowDangerousHtml={true} />

                                <ContentfulMarkdown children={this.state.heading1} className='regularFont fontSize12 lineHeight15 colorBlack3 marginBottom30 contentfulMarkdown' allowDangerousHtml={true} />

                                <ContentfulMarkdown children={this.state.paragraph1} className='regularFont fontSize12 lineHeight15 colorBlack3 contentfulMarkdown' allowDangerousHtml={true} />
                            </div>
                            {/* <div className='paddingLeftRight16 marginTop40'>
                                <PrevAndNextBottomNav
                                    showPrevButton={true}
                                    prevBtnFunc={() => this.props.history.goBack()}
                                    blue
                                    showNextBtn={false}
                                    nextBtnFunc={null}
                                />
                            </div> */}
                        </div>
                    </div>

                </div> {/* pagesInnerDiv */}

               {/*  <BottomNavBar appLanguage={this.props.appLanguage} /> */}
            </div>
        )
    }
}
