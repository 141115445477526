import React from 'react';
import ReactGA from 'react-ga4'
import { TopNavBar } from '../../Components/TopNavBar'
import { ContraceptionAssessmentOptionsMenuCardContent } from './ContraceptionAssessmentOptionsMenuCardContent'
import * as ContentfulMarkdown from 'react-markdown'
import { BottomNavBar } from '../../Components/BottomNavBar'



export class ContraceptionAssessmentOptionsMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      headerImg: "",
      headerImgAlt: "",
      pageTitle: "",
      menuCardContent: [],
    };
  }

  componentDidMount() {
    ReactGA.send('pageview', '/fp-assessment-options');
    window.scrollTo(0, 0)

    document.title = "Contraception Assessment Options"

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

    const checkPageAccess = localStorage.getItem(
      "contraceptionAssessmentComplete"
    );
    if (checkPageAccess === null) {
      this.props.history.push("/fp-assessment");
    }

    const getEntries = localStorage.getItem("ayaAppEntries");
    const entries = JSON.parse(getEntries);

    const getMenuContentEntries = entries.filter(
      (obj) => obj.sys.contentType.sys.id === "contraceptionMenu"
    );

    const getMenuEntries = getMenuContentEntries.filter(
      (obj) => obj.sys.id === "3oPpkaPXn6mspD4MI3hYw3"
    );

    var menuEntries = [];
    var menuCardContentArray = [];
    var menuPageTitle = "";
    var cardObj = {
      1: {},
      2: {},
      3: {},
      4: {},
      5: {},
      6: {},
      7: {},
      8: {},
      9: {},
      10: {},
      11: {},
      12: {},
      13: {},
    };

    if (getMenuEntries && getMenuEntries.length > 0) {
      menuEntries.push(getMenuEntries[0].fields);

      if (menuEntries.length > 0) {
        if (menuEntries[0].title) {
          menuPageTitle = menuEntries[0].title[this.props.appLanguage];
        }

        if (menuEntries[0].option1) {
          cardObj["1"]["title"] =
            menuEntries[0].option1[this.props.appLanguage];
          cardObj["1"]["goToPageLink"] = "/contraception-pills";
        }

        if (menuEntries[0].option2) {
          cardObj["2"]["title"] =
            menuEntries[0].option2[this.props.appLanguage];
          cardObj["2"]["goToPageLink"] = "/contraception-copper-iud";
        }

        if (menuEntries[0].option3) {
          cardObj["3"]["title"] =
            menuEntries[0].option3[this.props.appLanguage];
          cardObj["3"]["goToPageLink"] = "/contraception-mirena-ius";
        }

        if (menuEntries[0].option4) {
          cardObj["4"]["title"] =
            menuEntries[0].option4[this.props.appLanguage];
          cardObj["4"]["goToPageLink"] = "/contraception-implant";
        }

        if (menuEntries[0].option5) {
          cardObj["5"]["title"] =
            menuEntries[0].option5[this.props.appLanguage];
          cardObj["5"]["goToPageLink"] = "/contraception-injection";
        }

        if (menuEntries[0].option6) {
          cardObj["6"]["title"] =
            menuEntries[0].option6[this.props.appLanguage];
          cardObj["6"]["goToPageLink"] = "/contraception-condoms";
        }

        if (menuEntries[0].option7) {
          cardObj["7"]["title"] =
            menuEntries[0].option7[this.props.appLanguage];
          cardObj["7"]["goToPageLink"] = "/contraception-ec";
        }

        if (menuEntries[0].option8) {
          cardObj["8"]["title"] =
            menuEntries[0].option8[this.props.appLanguage];
          cardObj["8"]["goToPageLink"] = "/contraception-tl";
        }

        if (menuEntries[0].option9) {
          cardObj["9"]["title"] =
            menuEntries[0].option9[this.props.appLanguage];
          cardObj["9"]["goToPageLink"] = "/contraception-vasectomia";
        }

        if (menuEntries[0].option10) {
          cardObj["10"]["title"] =
            menuEntries[0].option10[this.props.appLanguage];
          cardObj["10"]["goToPageLink"] = "/contraception-fam";
        }

        if (menuEntries[0].option11) {
          cardObj["11"]["title"] =
            menuEntries[0].option11[this.props.appLanguage];
          cardObj["11"]["goToPageLink"] = "/contraception-withdrawal";
        }
        if (menuEntries[0].option12) {
          cardObj['12']['title'] = menuEntries[0].option12[this.props.appLanguage]
          cardObj['12']['goToPageLink'] = '/contraception-ring'
        }

        if (menuEntries[0].option13) {
          cardObj['13']['title'] = menuEntries[0].option13[this.props.appLanguage]
          cardObj['13']['goToPageLink'] = '/contraception-patch'
        }
      } 
    } 

    const q1Answer = localStorage.getItem("contraceptionAssessmentQ1Answer");
    const q2Answer = localStorage.getItem("contraceptionAssessmentQ2Answer");
    const q3Answer = localStorage.getItem("contraceptionAssessmentQ3Answer");
    const q4Answer = localStorage.getItem("contraceptionAssessmentQ4Answer");
    const q5Answer = localStorage.getItem("contraceptionAssessmentQ5Answer");
    const q6Answer = localStorage.getItem("contraceptionAssessmentQ6Answer");
    const q7Answer = localStorage.getItem("contraceptionAssessmentQ7Answer");
    const q8Answer = localStorage.getItem("contraceptionAssessmentQ8Answer");
    const q9Answer = localStorage.getItem("contraceptionAssessmentQ9Answer");

    var optionArray = [1, 2, 4, 5, 3, 6, 7, 8, 9, 10, 11, 12, 13];

    if (
      q1Answer &&
      q2Answer === "yes" &&
      q4Answer === "yes" &&
      q5Answer &&
      q6Answer === "yes" &&
      q7Answer === "no" &&
      q8Answer === "no" &&
      q9Answer
    ) {
      optionArray = [2, 4, 5, 1, 6, 7, 8, 3, 9, 10, 11, 12, 13];
      menuCardContentArray.push(cardObj["2"], cardObj["4"], cardObj["5"], cardObj["1"], cardObj["6"]); // cardObj['1'], cardObj['6'], cardObj['3'], cardObj['7'], cardObj['8'], cardObj['9'], cardObj['10'], cardObj['11'])
    } else if (
      q1Answer === "no" &&
      q2Answer === "yes" &&
      q4Answer === "no" &&
      q5Answer === "no" &&
      q6Answer === "no" &&
      q7Answer === "no" &&
      q8Answer === "no" &&
      q9Answer === "no"
    ) {
      menuCardContentArray.push(cardObj["2"], cardObj["4"], cardObj["8"], cardObj["3"], cardObj["9"] );
    } else if (
      q1Answer === "no" &&
      q2Answer === "no" &&
      q4Answer === "no" &&
      q5Answer === "yes" &&
      q6Answer === "no" &&
      q7Answer === "no" &&
      q8Answer === "no" &&
      q9Answer === "no"
    ) {
      menuCardContentArray.push(cardObj["2"], cardObj["4"], cardObj["7"], cardObj["8"], cardObj["9"]);
    } else {
      if (q2Answer === "no") {
        const hasOption8 = optionArray.filter((option) => option === 8);
        const hasOption9 = optionArray.filter((option) => option === 9);
        if (hasOption8.length === 1) {
          optionArray.splice(optionArray.indexOf(8), 1);
        }

        if (hasOption9.length === 1) {
          optionArray.splice(optionArray.indexOf(9), 1);
        }
      }

      if (q3Answer === "no") { 
        const hasOption1 = optionArray.filter((option) => option === 1);
        const hasOption3 = optionArray.filter((option) => option === 3);
        const hasOption4 = optionArray.filter((option) => option === 4);
        const hasOption5 = optionArray.filter((option) => option === 5);
        const hasOption12 = optionArray.filter((option) => option === 12);
        const hasOption13 = optionArray.filter((option) => option === 13);
        if (hasOption1.length === 1) {
          optionArray.splice(optionArray.indexOf(1), 1);
        }
        
        if (hasOption3.length === 1) {
          optionArray.splice(optionArray.indexOf(3), 1);
        }

        if (hasOption4.length === 1) {
          optionArray.splice(optionArray.indexOf(4), 1);
        }

        if (hasOption5.length === 1) {
          optionArray.splice(optionArray.indexOf(5), 1);
        }

        if (hasOption12.length === 1) {
          optionArray.splice(optionArray.indexOf(12), 1);
        }

        if (hasOption13.length === 1) {
          optionArray.splice(optionArray.indexOf(13), 1);
        }

        if (q3Answer === "yes" || q3Answer === "not sure") {
          optionArray = [1, 3, 4, 5, 12, 13];
        } 
        else if (q3Answer === "yes-two") {
          optionArray = [1, 3, 4]
        }
         // Add the new code snippet here
        if (q3Answer === "yes-two") {
          optionArray = [1, 3, 4];  // Default order when "yes-two" is selected for q3
        }

        

          if (q4Answer === "no") {
            const hasOption1 = optionArray.filter((option) => option === 1);
            if (hasOption1.length === 1) {
              optionArray.splice(optionArray.indexOf(1), 1);
        }}
      }
    
      if (q4Answer === "no") {
        const hasOption1 = optionArray.filter((option) => option === 1);
        if (hasOption1.length === 1) {
          optionArray.splice(optionArray.indexOf(1), 1);
        }
      }

      if (q6Answer === "no") {
        const hasOption5 = optionArray.filter((option) => option === 5);
        if (hasOption5.length === 1) {
          optionArray.splice(optionArray.indexOf(5), 1);
        }
      }

      if (q7Answer === "yes") {
        const hasOption1 = optionArray.filter((option) => option === 1);
        if (hasOption1.length === 1) {
          optionArray.splice(optionArray.indexOf(1), 1);
        }
      }

      if (q8Answer === "yes") {
        const hasOption1 = optionArray.filter((option) => option === 1);
        if (hasOption1.length === 1) {
          optionArray.splice(optionArray.indexOf(1), 1);
        }
      }

      console.log("Before filtering:", optionArray);
      
      if (q3Answer === "no") {
        const optionsToRemove = [1, 3, 4, 5, 12, 13];
        optionArray = optionArray.filter(option => !optionsToRemove.includes(option));
      }
      console.log("q3Answer:", q3Answer); 
      console.log("After filtering:", optionArray);

      // show only the first 5 options from the array

      for (var i = 0; i < 5; i++) {
        menuCardContentArray.push(cardObj[optionArray[i]]);
      }

      console.log("menuCardContentArray:", menuCardContentArray);

    } // else

    // Get page Assets
    const getAssets = localStorage.getItem("ayaAppAssets");
    const assets = JSON.parse(getAssets);

    const getHeaderImg = assets.filter(
      (obj) => obj.sys.id === "2IKBZyE7YvxRqQUJ9LrQIY"
    );

    var pageHeaderImg = "";
    var pageHeaderImgAlt = "";

    if (
      getHeaderImg &&
      getHeaderImg.length > 0 &&
      getHeaderImg[0].fields.file[this.props.appLanguage].url
    ) {
      pageHeaderImg =
        "https:" + getHeaderImg[0].fields.file[this.props.appLanguage].url;
      pageHeaderImgAlt =
        getHeaderImg[0].fields.description[this.props.appLanguage];
    }

    this.setState({
      pageTitle: menuPageTitle,
      headerImg: pageHeaderImg,
      headerImgAlt: pageHeaderImgAlt,
      menuCardContent: menuCardContentArray,
    });
  } // componentDidMount()

  goBack = () => {
    this.props.history.goBack()
}


  render() {
    const { pageTitle, headerImg, headerImgAlt, menuCardContent } = this.state;
    const backgroundColors = [
      "backgroundColorBlue8",
      "backgroundColorBlue7",
      "backgroundColorBlue6",
      "backgroundColorBlue5",
      "backgroundColorBlue4",
      "backgroundColorBlue3",
      "backgroundColorBlue2",
      "backgroundColorBlue3",
      "backgroundColorBlue4",
      "backgroundColorBlue5",
      "backgroundColorBlue7",
    ];

    const menuCardContentItems = menuCardContent.map((info, i) => (
      <div className={"" + backgroundColors[i] + " topRoundDiv"} id={i} key={i}>
        <ContraceptionAssessmentOptionsMenuCardContent
          id={i}
          key={i}
          content={info}
        />
      </div>
    ));

    return (
      <div className="minPageHeight100vh flexColumn flexGrow1 pageWithBottomMainNav backgroundColorWhite optionsMenuPage contraceptionAssessmentOptionsMenuPage">
        
        <TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...this.props} appLanguage={this.props.appLanguage} />

        <div id='scroll' className='pagesInnerDiv paddingTop100'>
          <div className='flexColumn alignCenter textAlignCenter justText headerArea'>
            <img src={headerImg} className='width74Percent marginLeftNeg3Percent' alt={headerImgAlt} />
            <div className='width81Percent padding11-0-62'>
              <ContentfulMarkdown children={pageTitle} className='boldFont fontSize23 lineHeight24 colorPeach4 psMargin0 contentfulMarkdown' />
            </div>
          </div> {/* .headerArea */}

          <div className='flexColumn'>
            {menuCardContentItems}
          </div>

        </div> {/* .pagesInnerDiv */}
      
        <BottomNavBar appLanguage={this.props.appLanguage} />
      </div>
    );
  }
}
