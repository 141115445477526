import React from 'react';
import { TopNavBar } from '../../Components/TopNavBar'
import * as ContentfulMarkdown from 'react-markdown'
import { LargeButton } from '../../Components/LargeButton'


export class SingleContraceptionAssessmentWithYesYesNoMaybe extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            questionYesSelected: false,
            question2YesSelected: false,
            questionNoSelected: false,
            questionMaybeSelected: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */
    }
    toggleRadio = (currentSelectedStateName, ...otherStateNames) => {
        // Get the state name of the radio selected for updates and update its counterpart radio state names
        var getSelectedStateName = this.state[currentSelectedStateName];

        // Reset all other radio buttons to false
        const otherStatesReset = {};
        otherStateNames.forEach(name => {
            otherStatesReset[name] = false;
        });

        if (getSelectedStateName === true) {
            // If the current selected state is true, set it to false and leave others as is
            this.setState({
                [currentSelectedStateName]: false,
                ...otherStatesReset
            });
        } else {
            // If the current selected state is false, set it to true and set all others to false
            this.setState({
                [currentSelectedStateName]: true,
                ...otherStatesReset
            });
        }
    }

    continue = () => {
        if (this.state.questionYesSelected === true) {
            this.props.continue('yes');
        } else if (this.state.questionNoSelected === true) {
            this.props.continue('no');
        } else if (this.state.question2YesSelected === true) {
            this.props.continue('question2Yes');
        } else if (this.state.questionMaybeSelected === true) {
            this.props.continue('maybe');
        }
    }
    goBack = () => {
        this.props.history.goBack()
    }
    render() {
        const { content } = this.props
        return (
            <div className='minPageHeight100vh flexGrow1 alignCenter backgroundColorPeach1 contraceptionAssessmentPage'>
                <TopNavBar bgColor="#F77478" showHamburgerMenu={true} menuIconColor={'white'} logoColor={'blue'} bellColor={'white'} newNotificationColor={'blue'} {...this.props} appLanguage={this.props.appLanguage} />
                <div className='flexColumn flexGrow1 justifyCenter alignCenter textAlignCenter paddingTop80'>
                    <div className={this.props.questionWidth + ' landscapeWidth67Percent'}>
                        <ContentfulMarkdown children={content.stepNumber} className='boldFont fontSize12 lineHeight14 colorBlue8 marginBottom32 contentfulMarkdown' />
                        <ContentfulMarkdown children={content.question} className='boldFont fontSize22 lineHeight25 colorWhite psMargin0 marginBottom30 contentfulMarkdown' />
                    </div>

                    {/* <form className='flexColumn textAlignLeft'> */}
                    <form className='width78Percent landscapeWidth67Percent flexColumn textAlignLeft regularFont fontSize15 lineHeight18 colorWhite marginBottom30 answerDiv'>
                        <div className='flexRow alignCenter marginBottom10'>
                            <input
                                type='radio'
                                name={'question1'}
                                checked={this.state.questionYesSelected}
                                className='flexColumn justifyCenter alignCenter marginRight10 backgroundColorNone radioCheckBlue mediumRadioBtn'
                                onChange={() => this.toggleRadio('questionYesSelected', 'questionNoSelected', 'question2YesSelected', 'questionMaybeSelected')}
                            />
                            <span className='lineHeight15'>{content.yesOptionText}</span>
                        </div>
                        <div className='flexRow alignCenter marginBottom10'>
                            <input
                                type='radio'
                                name={'question1'}
                                checked={this.state.question2YesSelected}
                                className='flexColumn justifyCenter alignCenter marginRight10 backgroundColorNone radioCheckBlue mediumRadioBtn'
                                onChange={() => this.toggleRadio('question2YesSelected', 'questionYesSelected', 'questionNoSelected', 'questionMaybeSelected')}
                            />
                            <span className='lineHeight15'>{content.secondYesOptionText}</span>
                        </div>
                        <div className='flexRow alignCenter marginBottom10'>
                            <input
                                type='radio'
                                name={'question1'}
                                checked={this.state.questionNoSelected}
                                className='flexColumn justifyCenter alignCenter marginRight10 backgroundColorNone radioCheckBlue mediumRadioBtn'
                                onChange={() => this.toggleRadio('questionNoSelected', 'questionYesSelected', 'question2YesSelected', 'questionMaybeSelected')}
                            />
                            <span className='lineHeight15'>{content.noOptionText}</span>
                        </div>
                        <div className='flexRow alignCenter'>
                            <input
                                type='radio'
                                name={'question1'}
                                checked={this.state.questionMaybeSelected}
                                className='flexColumn justifyCenter alignCenter marginRight10 backgroundColorNone radioCheckBlue mediumRadioBtn'
                                onChange={() => this.toggleRadio('questionMaybeSelected', 'questionYesSelected', 'question2YesSelected', 'questionNoSelected')}
                            />
                            <span className='lineHeight15'>{content.maybeOptionText}</span>
                        </div>
                    </form>

                        <LargeButton
                            largeButtonFunc={() => this.continue()}
                            text={content.btn1Text}
                            textColor={'colorBlue8'}
                            backgroundColor={'backgroundColorWhite'}
                        />

                </div>

            </div>
        )
    }
}

