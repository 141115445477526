import styles from './Steps.modules.css'
import BasicAccordion from '../../Components/BasicAccordion'
import { useState, useEffect } from 'react'
import  { TopNavBar }  from '../../Components/TopNavBar';
import { BottomNavBar } from '../../Components/BottomNavBar'
import ReactGA from 'react-ga4';


function Steps(props) {
	
	const [pageTitleOne, setPageTitleOne] = useState('')
	const [accordionTextOne, setAccordionTextOne] = useState('')
	const [accordionContentOne, setAccordionContentOne] = useState('')
	const [accordionTextTwo, setAccordionTextTwo] = useState('')
	const [accordionContentTwo, setAccordionContentTwo] = useState('')
	const [accordionTextThree, setAccordionTextThree] = useState('')
	const [accordionContentThree, setAccordionContentThree] = useState('')
	const [accordionTextFour, setAccordionTextFour] = useState('')
	const [accordionContentFour, setAccordionContentFour] = useState('')
	const [arrowIconOne, setArrowIconOne] = useState('');
	const [buttonStepOne, setButtonStepOne] = useState('');
	const [buttonStepThree, setButtonStepThree] = useState('');
	const [buttonStepFour, setButtonStepFour] = useState('');
	const [phoneIcon, setPhoneIcon] = useState('');



	useEffect(() => {
	// GA4 code
	ReactGA.send("/resources-steps");
	//this makes sure the page always scrolls to the top of the page when rendered
	window.scrollTo(0, 0); 

	document.title = "Resources Steps";
	
        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

		// GET ALL ENTRIES
		const getEntries = localStorage.getItem('ayaAppEntries');
    	const entries = JSON.parse(getEntries);

		// GET MODEL
		const getModel = entries.filter(
      (obj) => obj.sys.contentType.sys.id === "resourcesStepByStep"
    );
		
		// GET CONTENT
		const getContent = getModel.filter(
		(obj) => obj.sys.id === "3aBvFEpmBLMYWWh5v0zb9k"
		)
		

		// GET PAGE TITLE
			let pageTitle = ''
				if (getContent?.length > 0) {
					const pageTitleData = getContent[0].fields;
					pageTitle = pageTitleData.title? pageTitleData.title[props.appLanguage] : '';
				}
				setPageTitleOne(pageTitle);



		// GET ACCORDION ONE TEXT
			let titleTextOne = ''
			if (getContent?.length > 0) {
				const accordionTextData = getContent[0].fields;
				titleTextOne = accordionTextData.step1Title ? accordionTextData.step1Title[props.appLanguage] : '';
			}
			setAccordionTextOne(titleTextOne)



		// GET ACCORDION ONE CONTENT
		let accordionContent1 = ''

		if (getContent?.length > 0) {
			const accordionContentData = getContent[0].fields;
			accordionContent1 = accordionContentData.descriptionStep1  ? accordionContentData.descriptionStep1[props.appLanguage] : '';
		}
		setAccordionContentOne(accordionContent1)



		// GET BUTTON STEP ONE text
		let buttonStepOneText = ''

		if (getContent?.length > 0) {
			const buttonStepOneData = getContent[0].fields;
			buttonStepOneText = buttonStepOneData.buttonStep1 ? buttonStepOneData.buttonStep1[props.appLanguage] : '';
		}
		setButtonStepOne(buttonStepOneText)



		// GET ACCORDION TWO TEXT
			let titleTextTwo = ''
			if (getContent?.length > 0) {
				const accordionTextData = getContent[0].fields;
				titleTextTwo = accordionTextData.step2Title ? accordionTextData.step2Title[props.appLanguage] : '';
			}
			setAccordionTextTwo(titleTextTwo)



		// GET ACCORDION CONTENT TWO
		let accordionContent2 = ''

		if (getContent?.length > 0) {
			const accordionContentData = getContent[0].fields;
			accordionContent2 = accordionContentData.descriptionStep2  ? accordionContentData.descriptionStep2[props.appLanguage] : '';
		}
		setAccordionContentTwo(accordionContent2)



		// GET ACCORDION TEXT THREE
			let titleTextThree = ''
			if (getContent?.length > 0) {
				const accordionTextData = getContent[0].fields;
				titleTextThree = accordionTextData.step3Title ? accordionTextData.step3Title[props.appLanguage] : '';
			}
			setAccordionTextThree(titleTextThree)



		// GET ACCORDION CONTENT THREE
		let accordionContent3 = ''

		if (getContent?.length > 0) {
			const accordionContentData = getContent[0].fields;
			accordionContent3 = accordionContentData.descriptionStep3  ? accordionContentData.descriptionStep3[props.appLanguage] : '';
		}
		setAccordionContentThree(accordionContent3)



		// GET BUTTON STEP Three text
		let buttonStepThreeText = ''

		if (getContent?.length > 0) {
			const buttonStepThreeData = getContent[0].fields;
			buttonStepThreeText = buttonStepThreeData.buttonStep3 ? buttonStepThreeData.buttonStep3[props.appLanguage] : '';
		}
		setButtonStepThree(buttonStepThreeText)




		// GET ACCORDION FOUR TEXT
		let titleTextFour = ''
			if (getContent?.length > 0) {
				const accordionTextData = getContent[0].fields;
				titleTextFour = accordionTextData.step4Title ? accordionTextData.step4Title[props.appLanguage] : '';
			}
			setAccordionTextFour(titleTextFour)



		// GET ACCORDION FOUR CONTENT 
		let accordionContent4 = ''

			if (getContent?.length > 0) {
				const accordionContentData = getContent[0].fields;
				accordionContent4 = accordionContentData.descriptionStep4  ? accordionContentData.descriptionStep4[props.appLanguage] : '';
			}
			setAccordionContentFour(accordionContent4)



		// GET BUTTON STEP Four text
		let buttonStepFourText = ''

		if (getContent?.length > 0) {
			const buttonStepFourData = getContent[0].fields;
			buttonStepFourText = buttonStepFourData.buttonStep4 ? buttonStepFourData.buttonStep4[props.appLanguage] : '';
		}
		setButtonStepFour(buttonStepFourText)




		// ASSETS
		// GET ALL ASSETS
		const getAssets = localStorage.getItem('ayaAppAssets');
    const assets = JSON.parse(getAssets);


		// GET PHONE ICON
		const getPhoneIcon = assets.filter(
      obj => obj.sys.id === '2ROiC1rzhVlik9fUki9smc'
    );

    let phoneIconOne = '';
    if (getPhoneIcon?.length > 0 && getPhoneIcon[0]?.fields?.file?.[props.appLanguage]?.url) {
			phoneIconOne = 'https:' + getPhoneIcon[0].fields.file[props.appLanguage].url;
		}
    setPhoneIcon(phoneIconOne);

		

		// GET ARROW ICON
		const getArrowIcon = assets.filter(
      obj => obj.sys.id === '3Xegltxk8ROHntvPdStc3A'
    );

    let arrowIcon = '';
    if (getArrowIcon?.length > 0 && getArrowIcon[0]?.fields?.file?.[props.appLanguage]?.url) {
			arrowIcon = 'https:' + getArrowIcon[0].fields.file[props.appLanguage].url;
		}
    setArrowIconOne(arrowIcon);

	}, [props.appLanguage])



	const language = props.appLanguage;
	let linkOne;
  if (language === 'en') {
    linkOne = 'https://www.ineedana.com';
  } else if (language === 'es-US') {
    linkOne = 'https://www.ineedana.com/es';
  }

	return(
		<>
			<TopNavBar bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...props} appLanguage={props.appLanguage} />

		<div className='stepsContainer' style={{ backgroundColor: 'white' }}>

		<div style={{maxWidth: '340px'}}>
			<div className='steps-title'>{pageTitleOne}</div>

			<div className='cardsContainer'>

				<BasicAccordion
						heading={accordionTextOne}
						content={
								<>
										{accordionContentOne}
										<div
												style={{
														backgroundColor: '#D9D9D9',
														borderRadius: '12px',
														fontFamily: 'GTWalsheimPro-Regular, sans-serif',
														display: 'flex', 
														alignItems: 'center', 
														justifyContent: 'space-between',
														padding: '10px',
														margin: '10px 0'
												}}
										>
												<a
														href={linkOne}
														target="_blank"
														rel="noopener noreferrer"
														style={{
																display: 'flex', 
																alignItems: 'center', 
																color: '#051D58',
																textDecoration: 'none',
																flex: 1 
														}}
												>
														<div>
																{buttonStepOne}
														</div>
												</a>
												<img src={arrowIconOne} alt="Arrow" style={{ marginLeft: '10px' }} /> 
										</div>
								</>
						}
						headingStyle={{
								color: 'white',
								fontSize: '14px',
								fontFamily: 'GTWalsheimPro-Bold, sans-serif'
						}}
						contentStyle={{
								color: '#524F4F',
								fontSize: '14px',
								fontFamily: 'GTWalsheimPro-Regular, sans-serif'
						}}
				/>

				<div className='accordion-break'></div>
        <BasicAccordion
          heading={accordionTextTwo}
          content={accordionContentTwo}
          headingStyle={{ color: 'white', fontSize: '14px', fontFamily: 'GTWalsheimPro-Bold, sans-serif' }}
          contentStyle={{ color: '#524F4F', fontSize: '14px', fontFamily: 'GTWalsheimPro-Regular, sans-serif'}}
        />
				<div className='accordion-break'></div>
				<BasicAccordion
						heading={accordionTextThree}
						content={
								<>
										{accordionContentThree}
										<div
												style={{
														backgroundColor: '#D9D9D9',
														borderRadius: '12px',
														fontFamily: 'GTWalsheimPro-Regular, sans-serif',
														display: 'flex', 
														alignItems: 'center', 
														justifyContent: 'space-between',
														padding: '10px',
														margin: '10px 0'
												}}
										>
												<div
														style={{
																flex: 1 
														}}
												>
														<a href="tel:8477506224" style={{ textDecoration: 'none', color: '#051D58', display: 'flex', alignItems: 'center' }}>
																{buttonStepThree}
														</a>
												</div>
												<img src={phoneIcon} alt="phone" style={{ marginLeft: '10px' }} /> 
										</div>
								</>
						}
						headingStyle={{
								color: 'white',
								fontSize: '14px',
								fontFamily: 'GTWalsheimPro-Bold, sans-serif'
						}}
						contentStyle={{
								color: '#524F4F',
								fontSize: '14px',
								fontFamily: 'GTWalsheimPro-Regular, sans-serif'
						}}
				/>

				<div className='accordion-break'></div>
       	<BasicAccordion
						heading={accordionTextFour}
						content={
								<>
										{accordionContentFour}
										<div
												style={{
														backgroundColor: '#D9D9D9',
														borderRadius: '12px',
														fontFamily: 'GTWalsheimPro-Regular, sans-serif',
														display: 'flex', 
														alignItems: 'center', 
														justifyContent: 'space-between',
														padding: '10px',
														margin: '10px 0'
												}}
										>
												<div
														style={{
																flex: 1 
														}}
												>
														<a href="tel:8448682812" style={{ textDecoration: 'none', color: '#051D58', display: 'flex', alignItems: 'center' }}>
																{buttonStepFour}
														</a>
												</div>
												<img src={phoneIcon} alt="phone" style={{ marginLeft: '10px' }} /> 
										</div>
								</>
						}
						headingStyle={{
								color: 'white',
								fontSize: '14px',
								fontFamily: 'GTWalsheimPro-Bold, sans-serif'
						}}
						contentStyle={{
								color: '#524F4F',
								fontSize: '14px',
								fontFamily: 'GTWalsheimPro-Regular, sans-serif'
						}}
				/>

			</div>
			<div className='end-of-accordion'></div>

			</div>

			<div className='end-of-page'></div>
			<BottomNavBar appLanguage={props.appLanguage} />
		</div>
		</>
	)
}

export default Steps